import React from 'react';
import Title from './Title';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Divider, Grid, Typography } from '@material-ui/core';
import { HomeTableItem } from '../../../Models/HomeTableItem';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  table: {},
  split: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '100%',
    maxWidth: '100%',
    padding: 16,
  },
  cardData: {
    color: 'black',
    padding: 10,
    fontSize: '12px',
    height: '30%',
    display: 'flex',
    lineHeight: '20px',
    alignItems: 'right',
    justifyContent: 'end',
  },
});

interface IRelComm {
  itens: HomeTableItem[];
  loadingRellComm: boolean;
  data: string;
}

export default function RelComm({ itens, loadingRellComm, data }: IRelComm) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Title>
        <Grid container spacing={0} justifyContent="space-between">
          <Grid item xs={8} xl={8}>
            <img alt="Comissão" src="/assets/comissao.png" height="85px"></img>
            <span style={{ textAlign: 'center', verticalAlign: 'center' }}>
              Relatórios de comissão
            </span>
          </Grid>
          <Grid item xs={4} xl={4}>
            <Typography className={classes.cardData}>{data}</Typography>
          </Grid>
        </Grid>
      </Title>
      <Divider style={{ margin: '0px 16px' }} />
      <div className={classes.split}>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Tipo</TableCell>
                <TableCell align="center">Valor</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {itens.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.tipo}
                  </TableCell>
                  <TableCell align="center">
                    {row.valor?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {loadingRellComm && (
            <>
              <Skeleton height="30px" width="100%" />
              <Skeleton height="30px" width="100%" />
              <Skeleton height="30px" width="100%" />
              <Skeleton height="30px" width="100%" />
            </>
          )}
        </TableContainer>
      </div>
    </React.Fragment>
  );
}
