import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useStyles } from '../style';
import { Skeleton } from '@material-ui/lab';

export const GridItem = (props: any) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} xl={4}>
      <Paper style={{ backgroundColor: props.backgroundColor, margin: '5px' }}>
        <Typography className={classes.cardData}>{props.data}</Typography>
        <Typography className={classes.cardValor}>
          {props.loading ? (
            <>
              <Skeleton style={{ width: '60%' }}></Skeleton>
            </>
          ) : (
            props.valor
          )}
        </Typography>

        <Typography className={classes.cardDescription}>
          {props.descricao}
        </Typography>
      </Paper>
    </Grid>
  );
};
