import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { DatePicker } from '../../../../base/Forms/DatePicker';
import { ComissaoContext } from '../../../../providers/Comissao';
import { Loading } from '../../../../ui/Loading';
import { useStyles } from '../../style';
import { SelectComissao } from '../SelectComissao';

interface IFormComissoesRetidas {
  loading: boolean;
  fetchData: any;
  tipoRelatorio: string;
  setTipoRelatorio: any;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const FormComissoesRetidas = ({
  loading,
  fetchData,
  tipoRelatorio,
  setTipoRelatorio,
  setCurrentPage,
}: IFormComissoesRetidas) => {
  const classes = useStyles();
  var {
    dataInicial,
    setDataInicial,
    dataFinal,
    setDataFinal,
    searchField,
    setSearchField,
    searchText,
    setSearchText,
  } = useContext(ComissaoContext);

  return (
    <>
      <Grid container spacing={3}>
        <Grid container item md={8} xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={12} lg={4}>
              <SelectComissao loading={loading} />
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  disabled={loading}
                  required
                  disableToolbar
                  name="dataInicial"
                  variant="inline"
                  id="DataInicial"
                  autoComplete="DataInicial"
                  label="Data de Venda Inicial"
                  value={dataInicial}
                  onChange={(date: Date | null) => setDataInicial(date)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  disabled={loading}
                  required
                  disableToolbar
                  name="dataFinal"
                  variant="inline"
                  label="Data de Venda Final"
                  id="DataFinal"
                  value={dataFinal}
                  onChange={(date: Date | null) => setDataFinal(date)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="TipoConsulta" required>
                  Tipo Consulta
                </InputLabel>

                <Select
                  disabled={loading}
                  style={{ minWidth: 180 }}
                  labelId="TipoConsulta"
                  id="TipoConsulta"
                  value={tipoRelatorio}
                  onChange={(e) => setTipoRelatorio(e.target.value as string)}
                  label="Tipo Consulta"
                >
                  <MenuItem value={'total'}>Total</MenuItem>
                  <MenuItem value={'pendencia contrato'}>
                    Pendencia contrato
                  </MenuItem>
                  <MenuItem value={'atraso parcela'}>Atraso parcela</MenuItem>
                  <MenuItem value={'cancelamento'}>Cancelamento</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="Filtro" required>
                  Filtrar por
                </InputLabel>

                <Select
                  disabled={loading}
                  style={{ minWidth: 180 }}
                  labelId="Filtro"
                  id="Filtro"
                  value={searchField}
                  onChange={(e) => setSearchField(e.target.value as string)}
                  label="Tipo Comissão"
                >
                  <MenuItem value={' '}>Sem Filtro</MenuItem>
                  <MenuItem value={'contrato'}>Contrato</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {searchField !== ' ' && (
              <Grid item xs={12} lg={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled={loading}
                    id="busca"
                    label="Busca"
                    fullWidth
                    autoComplete="Busca"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              setCurrentPage(1);
              fetchData(0);
            }}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <div className={classes.loadingContainer}>
        <Loading loading={loading} />
      </div>
    </>
  );
};
