
import { Button, Grid, makeStyles, Modal, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from "@material-ui/icons/Close";
import { FilterRegulation } from '../../Models/FilterRegulation';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: "auto",
        maxWidth: "600px",
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    },
    paperWrap: {
        padding: theme.spacing(4),
        borderTopRightRadius: "0px",
        borderTopLeftRadius: "0px",
    },
    head: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#0288D1",
        color: "white",
        padding: theme.spacing(2),
        borderBottomRightRadius: "0px",
        borderBottomLeftRadius: "0px",
    },
    closeIcon: {
        color: "white",
    },
    foot: {
        padding: theme.spacing(4, 0, 0, 0),
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end"
    },
}));

export const RegulamentoCreate = ({
    openCadastro,
    handleCloseCadastro,
    loading,
    uploadRegulamento,
}: any) => {

    const [titulo, setTitulo] = useState<string | undefined>();
    const [file, setFile] = useState<any>(null);
    const [bttColor] = useState<boolean>(false);


    const uploadFile = () => {
        let data = new FormData();
        data.append('file', file[0])
        let regulamento: FilterRegulation;
        regulamento = {
            index: 0,
            size: 10,
            orderBy: "Id",
            orderType: "asc"
        };
        uploadRegulamento(data, titulo, regulamento);
        handleCloseCadastro();

    }

    const setFileCheck = (e: any) => {
        if(e[0].type == "application/pdf")
            setFile(e);
    };

    const classes = useStyles();

    return (
        <Modal
            open={openCadastro}
            onClose={handleCloseCadastro}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Grid className={classes.paper}>
                <Paper className={classes.head}>
                    <Typography variant="h6"> Upload de Regulamento - Quality Performance</Typography>
                    <Button>
                        <CloseIcon
                            className={classes.closeIcon}
                            onClick={handleCloseCadastro}
                        />
                    </Button>
                </Paper>

                <Paper className={classes.paperWrap}>
                    <div style={{ position: "relative", paddingTop: 40 }}>
                        <TextField
                            required
                            name="Tituto"
                            label="Titulo"
                            value={titulo}
                            fullWidth
                            onChange={e => setTitulo(e.target.value)}
                        />
                    </div>
                    <div style={{ position: "relative", paddingTop: 40 }}>
                        <Grid item xs={12}>
                            {file === null &&
                                <Button
                                    color="primary"
                                    variant="contained"
                                    component="label"
                                    startIcon={<DescriptionIcon />}
                                >

                                    ANEXAR ARQUIVO
                                    <input
                                        type="file"
                                        hidden
                                        accept="application/pdf"
                                        onChange={e => setFileCheck(e.target.files)}
                                    />
                                </Button>
                            }
                            {file !== null &&
                                <Button
                                    style={{
                                        background: "#388e3c",
                                        color: "#ffffff"
                                    }}
                                    variant="contained"
                                    component="label"
                                    startIcon={<DescriptionIcon />}
                                >

                                    ANEXAR ARQUIVO
                                    <input
                                        type="file"
                                        hidden
                                        accept="application/pdf"
                                        onChange={e => setFileCheck(e.target.files)}
                                    />
                                </Button>
                            }
                        </Grid>
                    </div>

                    <div className={classes.foot}>
                        <Grid item xs={12}>
                            {bttColor === false &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={uploadFile}
                                    disabled={!titulo || !file}
                                >
                                    SALVAR
                                </Button>
                            }
                        </Grid>
                    </div>
                </Paper>
            </Grid>
        </Modal>
    )

}