import {
  Box,
  Button,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import EmailIcon from "@material-ui/icons/Email";
import RoomIcon from "@material-ui/icons/Room";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "800px",
    maxHeight: "600px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(4),
  },
  closeIcon: {
    color: "white",
  },
  head: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#0288D1",
    color: "white",
    borderRadius: "0px",
    padding: theme.spacing(2),
  },

  content: { position: "relative", paddingTop: 20 },

  foot: {
    padding: theme.spacing(4, 0, 0, 0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  icon: { fontSize: "48px", color: "#757575", marginBottom: "20px" },
}));

export const ContatoModal = ({ isOpen, handleCloseModal }: any) => {
  const classes = useStyles();

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.head}>
          <Typography variant="h5">Contatos</Typography>
          <Button>
            <CloseIcon
              className={classes.closeIcon}
              fontSize="large"
              onClick={handleCloseModal}
            />
          </Button>
        </Paper>

        <Paper className={classes.paperWrap}>
          Precisando de ajuda, entre em contato.
          <Grid container style={{ marginTop: "36px", marginBottom: "36px" }}>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <PhoneInTalkIcon className={classes.icon} />
                <Typography>CRC</Typography>
                <Typography>(11) 2431 6000</Typography>
                <Typography>SAC</Typography>
                <Typography>0800 774 3233</Typography>
              </Box>
            </Grid>

            <Grid
              alignItems="center"
              item
              xs={4}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <EmailIcon className={classes.icon} />
                <Typography align="center">
                  {" "}
                  sac.consorcio@yamaha-motor.com.br
                </Typography>
              </Box>
            </Grid>

            <Grid
              alignItems="center"
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <RoomIcon className={classes.icon} />
                <Typography align="center">
                  Yamaha Motor Brasil Ltda.
                </Typography>
                <Typography align="center">
                  Rodovia Presidente Dutra Km 214.
                </Typography>
                <Typography align="center">
                  CEP 07178-580 - Guarulhos - SP
                </Typography>
                <Typography align="center">
                  A/C Serviço de Atendimento ao Consumidor
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Modal>
  );
};
