import React, { useState } from 'react';
import {
  List,
  Divider,
  makeStyles,
  Hidden,
  Theme,
  Button,
} from '@material-ui/core';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuItems from './MenuItems';
import Drawer from '@material-ui/core/Drawer';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    border: '0px',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    maxHeight: '100vh',
    overflow: 'hidden',
    '&:hover': { overflow: 'auto' },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6),
    },
    overflow: 'hidden',
    '&:hover': { width: drawerWidth, overflow: 'auto' },
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    display: 'flex',
    zIndex: theme.zIndex.drawer + 2,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 64,
    width: '100%',
    padding: 5,
    overflow: 'hidden',
    flexGrow: 1,
  },
  logoIcon: {
    height: '26px',
    width: '26px',
    verticalAlign: 'bottom',
    flexGrow: 1,
  },
  logoName: {
    maxHeight: '100%',
    maxWidth: '80%',
    objectFit: 'contain',
    verticalAlign: 'bottom',
    overflow: 'hidden',
  },
}));

interface ISidebar {
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
}

export const Sidebar = ({
  open,
  handleDrawerClose,
  handleDrawerOpen,
}: ISidebar) => {
  const classes = useStyles();
  const [hoverAndCloseSidebar, setHoverAndCloseSidebar] = useState(true);

  const handleMouseOver = () => {
    setHoverAndCloseSidebar(open || true);
  };
  const handleMouseOut = () => {
    setHoverAndCloseSidebar(open || false);
  };

  const location = useLocation();
  const isPortais = location.pathname === '/portais';
  
  var sideBarContent;
    sideBarContent = (
    <div style={{ backgroundColor: '#0288D1', height: '100vh' }}>
      <div className={classes.toolbarIcon}>
        <img
          src="/assets/logo-icon.png"
          className={classes.logoIcon}
          alt="Logo Yamaha"
        />
        <img
          src="/assets/logo-text.png"
          className={classes.logoName}
          alt="Yamaha Consórico"
        />
        <Button onClick={open ? handleDrawerClose : handleDrawerOpen}>
          {open ? (
            <ChevronLeftIcon style={{ color: '#CDCDCD', fontSize: 24 }} />
          ) : (
            <ChevronRightIcon style={{ color: '#CDCDCD', fontSize: 24 }} />
          )}
        </Button>
        <Hidden smUp implementation="js"></Hidden>
      </div>
      <Divider />
      <List style={{ padding: 0 }}>
        <MenuItems hoverAndCloseSidebar={hoverAndCloseSidebar} />
      </List>
    </div>
  );

  if(isPortais){return null}
  
  return (
    <div>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        style={{ border: '0px' }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseOut}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: false, // Better open performance on mobile.
        }}
      >
        {sideBarContent}
      </Drawer>
    </div>
  );
};
