const FETCH_LINK_ATIVACAO = "pecaEmail/FETCH_LINK_ATIVACAO";
const FETCH_LINK_ATIVACAO_FAIL = "pecaEmail/FETCH_LINK_ATIVACAO_FAIL";
const FETCH_LINK_ATIVACAO_SUCCESS = "pecaEmail/FETCH_LINK_ATIVACAO_SUCCESS";

export const defaultState = {
    loading: false,
    linkAtivacaolList: []

}
// Reducer
export default function reducer(state: any = defaultState, action: any = {}) {
    switch (action.type) {
        case FETCH_LINK_ATIVACAO:
            return {
                ...state,
                loading: true
            };
        case FETCH_LINK_ATIVACAO_FAIL:
            return {
                ...state,
                loading: false

            };
        case FETCH_LINK_ATIVACAO_SUCCESS:
            return {
                ...state,
                loading: false,
                linkAtivacaolList: action.payload.data
            };
            default:
            return state;
    }
}

export function getLinkAtivacao(index: number , size: number, orderBy: string, orderType: String, search: string) {
    return {
        type: FETCH_LINK_ATIVACAO,
        payload: {
            request: {
                method: "GET",
                url: "/EnvioEmail",
                params: {
                    index: index,
                    size: size,
                    search: search,
                    orderBy: orderBy,
                    orderType: orderType
                  }
            },
        },
    };
}