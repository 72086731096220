import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AuthenticationRequest } from '../../Models/AuthenticationRequest';
import { getMyData, getTelas, login } from '../../redux/ducks/user';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Box, CircularProgress, Drawer } from '@material-ui/core';
import { LandingPage } from '../LandingPage/LandingPage';
import { FAQModal } from './modals/FAQModal';
import { ContatoModal } from './modals/ContatoModal';
import { ResetPasswordModal } from './modals/ResetPasswordModal';
import { Link } from 'react-router-dom';

const DRAWER_WIDTH = 416;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
  },

  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    background: '#BBDEFB',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '& $parent': {
        width: '100vw',
      },
    },
  },

  paper: {
    padding: 48,
    width: DRAWER_WIDTH,
    background: '#BBDEFB',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#009ee6',
  },

  textFieldBackground: {
    background: 'white',
  },

  buttonProgress: {
    color: '#009ee6',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  authTitle: {
    border: '1px solid black',
    borderColor: 'rgb(3,155,229)',
    padding: '25px',
    fontSize: '20px',
  },
  forgotPasswordContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    color: 'rgb(3,155,229)',
  },

  contentContainer: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  contentContainerShift: {
    marginRight: DRAWER_WIDTH,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  closeButton: {
    marginTop: '20px',
    borderRadius: '50%',
    backgroundColor: '#009ee6',
    color: 'white',
    boxShadow: '0 2px 5px 0 rgb(0 0 0 / 26%)',
    width: 40,
    height: 60,
  },
}));

const SignInScreen = ({ onSignIn, loading }: any) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [passwd, setPasswd] = useState('');
  const [isHidden, setVisible] = useState(false);
  const [isContatoModalOpen, setContatoModalOpen] = useState(false);
  const [isFAQModalOpen, setFAQModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const setLoginContainerVisible = () => {
    setVisible(true);
  };

  const setLoginContainerInvisible = () => {
    setVisible(false);
  };

  const handleCloseModal = () => {
    setContatoModalOpen(false);
    setFAQModalOpen(false);
    setResetPasswordModalOpen(false);
  };

  const onSubmit = (e: any) => {
    // e.preventDefault();

    let user: AuthenticationRequest = {
      Email: email,
      Password: passwd,
    };

    onSignIn(user);

    return false;
  };

  return (
    <>
      <Box className="root">
        <CssBaseline />
        <div
          className={clsx(classes.contentContainer, {
            [classes.contentContainerShift]: isHidden,
          })}
        >
          <LandingPage
            buttonClickAction={setLoginContainerVisible}
            setContatoModalOpen={setContatoModalOpen}
            setFAQModalOpen={setFAQModalOpen}
          />
        </div>

        <Drawer
          open={isHidden}
          id="drawer"
          variant="persistent"
          anchor="right"
          className={classes.drawer}
        >
          <div className={classes.paper}>
            <div style={{ paddingBottom: '40px' }}>
              <Typography className={classes.authTitle}>
                Acessar sua conta
              </Typography>
            </div>

            <ValidatorForm
              onSubmit={onSubmit}
              style={{ paddingTop: '40px' }}
              //onError={errors => console.log(errors)}
              className={classes.form}
            >
              <TextValidator
                margin="normal"
                required
                fullWidth
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                id="username"
                label="Email"
                name="username"
                autoComplete="username"
                autoFocus
                validators={['required', 'isEmail']}
                errorMessages={[
                  'Este campo é obrigatório',
                  'Este email não parece ser válido',
                ]}
              />

              <TextValidator
                margin="normal"
                required
                fullWidth
                value={passwd}
                onChange={(e: any) => setPasswd(e.target.value)}
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="password"
                validators={['required']}
                errorMessages={['Este campo é obrigatório']}
              />
              <div className={classes.wrapper}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                >
                  Acessar
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </ValidatorForm>
            <div className={classes.forgotPasswordContainer}>
              <a
                onClick={() => setResetPasswordModalOpen(true)}
                href="javascript::"
              >
                Esqueceu sua senha?
              </a>
              <Button
                variant="outlined"
                className={classes.closeButton}
                onClick={setLoginContainerInvisible}
              >
                <CloseIcon />
              </Button>
            </div>
          </div>
        </Drawer>

        <FAQModal isOpen={isFAQModalOpen} handleCloseModal={handleCloseModal} />
        <ContatoModal
          isOpen={isContatoModalOpen}
          handleCloseModal={handleCloseModal}
        />
      </Box>
      <ResetPasswordModal
        isOpen={isResetPasswordModalOpen}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    loading: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  
  return {
    onSignIn: async (user: AuthenticationRequest) => {
      try {
        await dispatch(login(user));
        await dispatch(getMyData());
        await dispatch(getTelas());
      } catch (error) {
        console.error("An error occurred during sign in:", error);
      }
    },
  };
};

export const SignIn = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInScreen);
