import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch,
  HashRouter,
} from 'react-router-dom';

import './App.css';
import Dashboard from './base/Dashboard';
import Home from './pages/Home/Home';
import { SignIn } from './pages/SignIn/SignIn';
import { SignUp } from './pages/SignUp';
import { MyData } from './pages/Users/MyData';
import { CreateUser } from './pages/Users/CreateUser';
import { EditUser } from './pages/Users/EditUser';
import { SystemParameter } from './pages/SystemParameter/SystemParameter';
import { EmailConfiguration } from './pages/EmailConfiguration/EmailConfiguration';
import Users from './pages/Users/Users';
import { PecaEmail } from './pages/PecaEmail/PecaEmail';
import { PontoVenda } from './pages/PontoVenda/PontoVenda';
import { UserProfileList } from './pages/UserProfile/UserProfile';
import { LinkAtivacao } from './pages/LinkAtivacao/LinkAtivacao';
import { TipoProdutos } from './pages/TipoProduto/TipoProduto';
import BoletoAvulso from './pages/Vendas/BoletoAvulso/BoletoAvulso';
import { Regulamento } from './pages/Quality/Regulamento';
import { ExtratoClassificacao } from './pages/Quality/ExtratoClassificacao';
import { Hierarquia } from './pages/Hierarquia/Hierarquia';
import { InfoGerencial } from './pages/InfoGerencial/InfoGerencial';
import { GrupoTela } from './pages/GrupoTela/GrupoTela';
import ExtratoCota from './pages/Vendas/ExtratoCota/ExtratoCota';
import BoletoAdesao from './pages/Vendas/BoletoAdesao/BoletoAdesao';
import CadastroVenda from './pages/Vendas/CadastroVenda/CadastroVenda';
import OfertaLance from './pages/Lances/OfertaLance';
import Resultados from './pages/Assembleia/Resultados';
import Calendario from './pages/Assembleia/Calendario';
import ProcessoCredito from './pages/AnaliseCredito/ProcessoCredito';
import AniversarioRelatorio from './pages/AnivesarioRelatorio/AniversarioRelatorio';
import Comissoes from './pages/RelatoriosComissoes/Comissoes';
import DebitoConta from './pages/Vendas/DebitoConta/DebitoConta';
import painelVendas from './pages/PainelVendas/PainelVendas';
import TabelaDePrecos from './pages/TabelaDePrecos/TabelaDePrecos';
import PagamentoContemplacao from './pages/PagamentoContemplacao/PagamentoContemplacao';
import Activation from './pages/ActivationLink/Activation';
import Reset from './pages/ResetLink/Reset';
import { GestaoCarteira } from './pages/GestaoCarteira/GestaoCarteira';
import { Portais } from './pages/Portais/Portais';

function AppScreen({ user, loading }: any) {
  if (!user.userData.jwtToken) {
    return (
      <HashRouter>
        <Switch>
          <Redirect exact from="/" to="/signin" />
          <Route exact path="/portais" component={Portais} />
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />
          <Route path="/Activation/:codigoAtivacao" component={Activation} />
          <Route path="/Reset/:codigoRedefinicao" component={Reset} />
        </Switch>
      </HashRouter>
    );
  }
  if (user.userData.portalCobranca && user.userData.pontoVenda.bancoAtivo) {
    return (
      <HashRouter>
        <Dashboard>
          <Switch>
            <Redirect exact from="/signin" to="/portais" />
            <Redirect exact from="/signup" to="/" />
            <Route exact path="/" component={Home} />
            <Route exact path="/portais" component={Portais} />
            <Route exact path="/mydata" component={MyData} />
            <Route exact path="/Sistema/Users" component={Users} />
            <Route exact path="/Users/Create" component={CreateUser} />
            <Route exact path="/Users/Edit" component={EditUser} />
            <Route
              exact
              path="/treinamento"
              render={() => <h1>Treinamento!</h1>}
            />
            <Route
              exact
              path="/Sistema/SystemParameter"
              component={SystemParameter}
            />
            <Route exact path="/Sistema/PecaEmail" component={PecaEmail} />
            <Route
              exact
              path="/Sistema/EmailConfiguration"
              component={EmailConfiguration}
            />
            <Route exact path="/Sistema/PontoVenda" component={PontoVenda} />
            <Route
              exact
              path="/Sistema/UserProfile"
              component={UserProfileList}
            />
            <Route exact path="/Sistema/LinkAtivacao" component={LinkAtivacao} />
            <Route exact path="/Sistema/TipoProduto" component={TipoProdutos} />
            <Route exact path="/Vendas/BoletoAvulso" component={BoletoAvulso} />
            <Route exact path="/Vendas/BoletoAdesao" component={BoletoAdesao} />
            <Route exact path="/Vendas/CadastroVenda" component={CadastroVenda} />
            <Route exact path="/Vendas/DebitoConta" component={DebitoConta} />
            <Route exact path="/activation" component={Activation} />
            <Route exact path="/Vendas/ExtratoCota" component={ExtratoCota} />
            <Route exact path="/Regulamento" component={Regulamento} />
            <Route exact path="/ExtratoClassificacao" component={ExtratoClassificacao} />
            <Route
              exact
              path="/Sistema/InfoGerencial"
              component={InfoGerencial}
            />
            <Route exact path="/Sistema/Hierarquia" component={Hierarquia} />
            <Route exact path="/Sistema/GrupoTela" component={GrupoTela} />
            <Route exact path="/Lances/OfertaLance" component={OfertaLance} />
            <Route exact path="/Assembleia/Calendario" component={Calendario} />
            <Route exact path="/Assembleia/Resultados" component={Resultados} />
            <Route
              exact
              path="/AnaliseCredito/ProcessoCredito"
              component={ProcessoCredito}
            />
            <Route exact path="/Vendas/PainelVendas" component={painelVendas} />
            <Route
              exact
              path="/Relatorios/Aniversarios"
              component={AniversarioRelatorio}
            />
            <Route
              exact
              path="/PagamentoContemplacao/PagamentoContemplacao"
              component={PagamentoContemplacao}
            />
            <Route exact path="/Relatorios/Comissoes" component={Comissoes} />
            <Route
              exact
              path="/Relatorios/gestaoCarteira"
              component={GestaoCarteira}
            />
            <Route
              exact
              path="/Vendas/TabelaDePrecos"
              component={TabelaDePrecos}
            />
          </Switch>
        </Dashboard>
      </HashRouter>
    );
  }
  if (user.userData.portalCobranca == undefined) {
    return (
      <HashRouter>
        <Switch>
        <Route exact path="/portais" component={Portais} />
          <Redirect exact from="/" to="/signin" />
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />
        </Switch>
      </HashRouter>
    );
  }
  return (
    <HashRouter>
      <Dashboard>
        <Switch>
          <Redirect exact from="/signin" to="/" />
          <Redirect exact from="/signup" to="/" />
          <Route exact path="/" component={Home} />
          <Route exact path="/mydata" component={MyData} />
          <Route exact path="/Sistema/Users" component={Users} />
          <Route exact path="/Users/Create" component={CreateUser} />
          <Route exact path="/Users/Edit" component={EditUser} />
          <Route
            exact
            path="/treinamento"
            render={() => <h1>Treinamento!</h1>}
          />
          <Route
            exact
            path="/Sistema/SystemParameter"
            component={SystemParameter}
          />
          <Route exact path="/Sistema/PecaEmail" component={PecaEmail} />
          <Route
            exact
            path="/Sistema/EmailConfiguration"
            component={EmailConfiguration}
          />
          <Route exact path="/Sistema/PontoVenda" component={PontoVenda} />
          <Route
            exact
            path="/Sistema/UserProfile"
            component={UserProfileList}
          />
          <Route exact path="/Sistema/LinkAtivacao" component={LinkAtivacao} />
          <Route exact path="/Sistema/TipoProduto" component={TipoProdutos} />
          <Route exact path="/Vendas/BoletoAvulso" component={BoletoAvulso} />
          <Route exact path="/Vendas/BoletoAdesao" component={BoletoAdesao} />
          <Route exact path="/Vendas/CadastroVenda" component={CadastroVenda} />
          <Route exact path="/Vendas/DebitoConta" component={DebitoConta} />
          <Route exact path="/activation" component={Activation} />
          <Route exact path="/Vendas/ExtratoCota" component={ExtratoCota} />
          <Route exact path="/Regulamento" component={Regulamento} />
          <Route exact path="/ExtratoClassificacao" component={ExtratoClassificacao} />
          <Route
            exact
            path="/Sistema/InfoGerencial"
            component={InfoGerencial}
          />
          <Route exact path="/Sistema/Hierarquia" component={Hierarquia} />
          <Route exact path="/Sistema/GrupoTela" component={GrupoTela} />
          <Route exact path="/Lances/OfertaLance" component={OfertaLance} />
          <Route exact path="/Assembleia/Calendario" component={Calendario} />
          <Route exact path="/Assembleia/Resultados" component={Resultados} />
          <Route
            exact
            path="/AnaliseCredito/ProcessoCredito"
            component={ProcessoCredito}
          />
          <Route exact path="/Vendas/PainelVendas" component={painelVendas} />
          <Route
            exact
            path="/Relatorios/Aniversarios"
            component={AniversarioRelatorio}
          />
          <Route
            exact
            path="/PagamentoContemplacao/PagamentoContemplacao"
            component={PagamentoContemplacao}
          />
          <Route exact path="/Relatorios/Comissoes" component={Comissoes} />
          <Route
            exact
            path="/Relatorios/gestaoCarteira"
            component={GestaoCarteira}
          />
          <Route
            exact
            path="/Vendas/TabelaDePrecos"
            component={TabelaDePrecos}
          />
        </Switch>
      </Dashboard>
    </HashRouter>
  );

}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppScreen);
