import {
  Button,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "800px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(4),
  },
  closeIcon: {
    color: "white",
  },
  head: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#0288D1",
    color: "white",
    borderRadius: "0px",
    padding: theme.spacing(2),
  },

  content: { position: "relative", paddingTop: 20 },

  foot: {
    padding: theme.spacing(4, 0, 0, 0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

export const ComissoesDetalhesComponent = ({
  openDetalhes,
  handleCloseDetalhes,
  nome,
  endereco,
  telefone,
  email,
}: any) => {
  const classes = useStyles();
  return (
    <Modal
      open={openDetalhes}
      onClose={handleCloseDetalhes}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.head}>
          <Typography variant="h5">Dados Cadastrais</Typography>
          <Button>
            <CloseIcon
              className={classes.closeIcon}
              fontSize="large"
              onClick={handleCloseDetalhes}
            />
          </Button>
        </Paper>

        <Paper className={classes.paperWrap}>
          <div className={classes.content}>
            <b>
              <Typography variant="h6">{nome}</Typography>
            </b>
            <br />
            <label>
              <b>Endereços</b>
            </label>
            <br />
            <label>
              {endereco.map((value: string) => (
                <Typography style={{ display: "inline-block" }}>
                  {value}
                </Typography>
              ))}
            </label>
            <br />
            <br />
            <label>
              <b>Telefones</b>
            </label>
            <br />
            {telefone.map((value: string) => (
              <div>
                {value}
                <br />
              </div>
            ))}
            <br />
            <br />
            <label>
              <b>Emails</b>
            </label>
            <br />
            {email.map((value: string) => (
              <div>
                {value}
                <br />
              </div>
            ))}
          </div>
        </Paper>
      </Grid>
    </Modal>
  );
};
