import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { GrupoTelaCreate } from "./GrupoTelaCreate";

import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomFab from "../../ui/CustomFab";
import AddIcon from "@material-ui/icons/Add";
import { Loading } from "../../ui/Loading";
import {
  createGrupoTela,
  deletePontoVenda,
  getGrupoTela,
  updateGrupoTela,
} from "../../redux/ducks/grupoTela";
import { GrupoTelaRequest } from "../../Models/GrupoTelaRequest";
import { fetchTelasPermitidas } from "../../redux/ducks/telasPermitidas";
import { GrupoTelaDelete } from "./GrupoTelaDelete";
import { GrupoTelaEdit } from "./GrupoTelaEdit";
import { TitleCard } from "../../base/TitleCard";
import { useStyles } from "./style";
import { getTelas } from "../../redux/ducks/user";

function Search(grupList: any, searchStringCased: any) {
  var userchUsers = [];
  var grupoTela: GrupoTelaRequest;
  var searchTerm = searchStringCased.toLowerCase();
  for (var i in grupList) {
    if (
      grupList[i].nome.toLowerCase().match(searchTerm) ||
      grupList[i].ordem.toLowerCase().match(searchTerm) ||
      grupList[i].icone.match(searchTerm)
    ) {
      grupoTela = {
        id: grupList[i].id,
        icone: grupList[i].icone,
        nome: grupList[i].nome,
        ordem: grupList[i].ordem,
        tela: grupList[i].tela,
      };
      userchUsers.push(grupoTela);
    }
  }
  return userchUsers;
}

const DataTable = ({
  grupoTelaList,
  loading,
  loadGrupoTela,
  createGrupoTela,
  updateGrupoTela,
  deleteGrupoTela,
  telasPermitidas,
  grupoTelaLoading,
  loadTelas,
}: any) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [orderType, setOrderType] = useState<"asc" | "desc">("asc");
  const [order, setOrder] = useState(1);
  const [openCadastro, setOpenCadastro] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [rowId, setRowId] = React.useState(null);
  const [grupoTelaEdit, setGrupoTelaEdit] = React.useState();
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenCadastro = () => setOpenCadastro(true);
  const handleCloseCadastro = () => setOpenCadastro(false);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenEdit = (row: any) => {
    setGrupoTelaEdit(row);
    setOpenEdit(true);
  };

  const handleOpenDelete = (row: any) => {
    setRowId(row.id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  function deleteParameter() {
    deleteGrupoTela(rowId);
    setOpenDelete(false);
  }

  function sortTable(orderByAtual: string) {
    let orderTypeAtual = orderType;

    if (orderTypeAtual === "asc") {
      setOrderType("desc");
      orderTypeAtual = "desc";
      setOrder(-1);
    } else if (orderTypeAtual === "desc") {
      setOrderType("asc");
      orderTypeAtual = "asc";
      setOrder(1);
    }
    setOrderBy(orderByAtual);

    return 0;
  }

  if (orderBy === "nome") {
    grupoTelaList.sort((a: any, b: any) => [
      a.nome.toLowerCase() > b.nome.toLowerCase() ? order : -order,
    ]);
  }

  if (orderBy === "icone") {
    grupoTelaList.sort((a: any, b: any) => [
      a.icone.toLowerCase() > b.icone.toLowerCase() ? order : -order,
    ]);
  }

  if (orderBy === "ordem") {
    grupoTelaList.sort((a: any, b: any) => [
      a.ordem.toLowerCase() > b.ordem.toLowerCase() ? order : -order,
    ]);
  }

  useEffect(loadTelas, []);
  useEffect(loadGrupoTela, []);
  grupoTelaList = Search(grupoTelaList, search);
  return (
    <Box maxWidth="lg">
      <TitleCard>Grupo de Telas</TitleCard>
      <CustomFab label="Novo" icon={<AddIcon />} onClick={handleOpenCadastro} />
      <Paper className={classes.paperWrap}>
        <div className={classes.grow}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              type="text"
              placeholder="Pesquise aqui…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(e) => setSearch(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} />
        </div>
        <Loading loading={loading} />
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  Nome
                  <TableSortLabel
                    active={orderBy === "nome"}
                    direction={orderBy === "nome" ? orderType : "asc"}
                    onClick={() => sortTable("nome")}
                  >
                    {orderBy === "nome" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Icone
                  <TableSortLabel
                    active={orderBy === "icone"}
                    direction={orderBy === "icone" ? orderType : "asc"}
                    onClick={() => sortTable("icone")}
                  >
                    {orderBy === "icone" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Ordem
                  <TableSortLabel
                    active={orderBy === "ordem"}
                    direction={orderBy === "ordem" ? orderType : "asc"}
                    onClick={() => sortTable("ordem")}
                  >
                    {orderBy === "ordem" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {grupoTelaList.map((row: GrupoTelaRequest) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.nome}
                  </TableCell>
                  <TableCell>{row.icone}</TableCell>
                  <TableCell align="right">{row.ordem}</TableCell>
                  <TableCell align="right">
                    <Button
                      style={{
                        color: "#388e3c",
                        borderColor: "#388e3c",
                      }}
                      title="Editar"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenEdit(row)}
                    >
                      <EditRoundedIcon />
                    </Button>

                    <Button
                      style={{
                        color: "#b20303",
                        borderColor: "#b20303",
                      }}
                      title="Excluir"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenDelete(row)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <GrupoTelaCreate
          openCadastro={openCadastro}
          handleCloseCadastro={handleCloseCadastro}
          postGrupoTela={createGrupoTela}
          loading={loading}
          telasPermitidas={telasPermitidas}
          grupoTelaLoading={grupoTelaLoading}
        />
        <GrupoTelaEdit
          grupoTelaEdit={grupoTelaEdit}
          openEdit={openEdit}
          handleCloseEdit={handleCloseEdit}
          putGrupoTela={updateGrupoTela}
          loading={loading}
          telasPermitidas={telasPermitidas}
          grupoTelaLoading={grupoTelaLoading}
        />
        <GrupoTelaDelete
          openDelete={openDelete}
          deleteParameter={deleteParameter}
          handleCloseDelete={handleCloseDelete}
        />
      </Paper>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  grupoTelaList: state.grupoTela.grupoTelaList,
  loading: state.grupoTela.loading,
  telasPermitidas: state.telasPermitidas.telasPermitidas,
  grupoTelaLoading: state.telasPermitidas.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadGrupoTela: () => {
    dispatch(getGrupoTela());
  },
  createGrupoTela: (gt: GrupoTelaRequest) => {
    dispatch(createGrupoTela(gt)).then(() => dispatch(getGrupoTela()));
  },
  updateGrupoTela: (gt: GrupoTelaRequest) => {
    dispatch(updateGrupoTela(gt)).then(() => {
      dispatch(getGrupoTela());
      dispatch(getTelas());
    });
  },
  deleteGrupoTela: (Id: number) => {
    dispatch(deletePontoVenda(Id)).then(() => {
      dispatch(getGrupoTela());
      dispatch(getTelas());
    });
  },
  loadTelas: () => {
    dispatch(fetchTelasPermitidas());
  },
});

export const GrupoTela = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataTable);
