import DateFnsUtils from '@date-io/date-fns';
import { Paper } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useContext, useState } from 'react';
import { ComissaoContext } from '../../providers/Comissao';
import api from '../../service/api';
import { parseRelatorioParams } from '../../utils/utils';
import { FormComissoesRetidas } from './components/Forms/FormComissoesRetidas';
import ComissoesRetidasPorPendenciaTable from './components/Tables/ComissoesRetidasPorPendenciaTable';
import { useStyles } from './style';

export const ComissoesRetidasComponent = ({ handleOpenDetalhes }: any) => {
  const classes = useStyles();
  var [loading, setLoading] = useState(false);
  var [tableData, setTableData] = useState([]);
  var [tipoRelatorio, setTipoRelatorio] = useState('total');
  var [itemsAmount, setItemsAmount] = useState();
  var [currentPage, setCurrentPage] = useState(1);

  var { dataInicial, dataFinal, searchField, searchText, tipoComissao } =
    useContext(ComissaoContext);

  const fetchData = (index: number) => {
    setLoading(true);
    var params = {
      dataInicial,
      dataFinal,
      searchField,
      searchText,
      tipoConsulta: tipoRelatorio,
      index: index * 10,
      size: 10,
    };

    params = parseRelatorioParams(params);

    api
      .get('/Relatorio/ComissaoRetida', {
        params: params,
      })
      .then((response) => {
        setTableData(response.data.list);
        setItemsAmount(response.data.size);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return tipoComissao === 'Comissões Retidas por Pendência' ? (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Paper className={classes.paperWrap}>
          <FormComissoesRetidas
            tipoRelatorio={tipoRelatorio}
            setTipoRelatorio={setTipoRelatorio}
            loading={loading}
            fetchData={fetchData}
            setCurrentPage={setCurrentPage}
          />
        </Paper>
      </MuiPickersUtilsProvider>
      <ComissoesRetidasPorPendenciaTable
        updateData={fetchData}
        tableData={tableData}
        itemsAmount={itemsAmount}
        handleOpenDetalhes={handleOpenDetalhes}
        tipoConsulta={tipoRelatorio}
        setLoading={setLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  ) : (
    <></>
  );
};
