import { UserProfile } from "../../Models/UserProfile";

const FETCH_USER_PROFILE = "userProfile/FETCH_USER_PROFILE";
const FETCH_USER_PROFILE_FAIL = "userProfile/FETCH_USER_PROFILE_FAIL";
const FETCH_USER_PROFILE_SUCCESS = "userProfile/FETCH_USER_PROFILE_SUCCESS";

const CREATE_USER_PROFILE = "userProfile/CREATE_USER_PROFILE";
const CREATE_USER_PROFILE_FAIL = "userProfile/CREATE_USER_PROFILE_FAIL";
const CREATE_USER_PROFILE_SUCCESS = "userProfile/CREATE_USER_PROFILE_SUCCESS";

const UPDATE_USER_PROFILE = "userProfile/UPDATE_USER_PROFILE";
const UPDATE_USER_PROFILE_FAIL = "userProfile/UPDATE_USER_PROFILE_FAIL";
const UPDATE_USER_PROFILE_SUCCESS = "userProfile/UPDATE_USER_PROFILE_SUCCESS";

const DELETE_USER_PROFILE = "userProfile/DELETE_USER_PROFILE";
const DELETE_USER_PROFILE_FAIL = "userProfile/DELETE_USER_PROFILE_FAIL";
const DELETE_USER_PROFILE_SUCCESS = "userProfile/DELETE_USER_PROFILE_SUCCESS";

// Default state
export const defaultState = {
  loading: false,
  userProfileList: []
}

// Reducer
export default function reducer(state: any = defaultState, action: any = {}) {
  switch (action.type) {
    case FETCH_USER_PROFILE:
      return {
        ...state,
        loading: true
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfileList: action.payload.data,
      };
    case FETCH_USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false
      };
    case DELETE_USER_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case DELETE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_USER_PROFILE:
      return {
        ...state,
        loading: true
      }
    case CREATE_USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false
      }
    case CREATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        loading: true
      }
    case UPDATE_USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false
      }
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    default:
      return state;

  }
}

// Action Creators
export function getUserProfile() {
  return {
    type: FETCH_USER_PROFILE,
    payload: {
      request: {
        method: "GET",
        url: "/PerfilUsuario/Profile"
      }
    }
  }
}

export function deleteUserProfile(id: number) {
  return {
    type: DELETE_USER_PROFILE,
    payload: {
      request: {
        method: "DELETE",
        url: `/PerfilUsuario?id=${id}`,

      },
    },
  };
}

export function createUserProfile(data: UserProfile) {
  return {
    type: CREATE_USER_PROFILE,
    payload: {
      request: {
        method: "POST",
        url: `/PerfilUsuario`,
        data
      },
    },
  };
}

export function updateUserProfile(data: UserProfile) {
  return {
    type: UPDATE_USER_PROFILE,
    payload: {
      request: {
        method: "PUT",
        url: `/PerfilUsuario`,
        data
      },
    },
  };
}