import {
  Button,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { Checkbox } from "@material-ui/core";
import { useEffect } from "react";

interface ICheckBoxItem {
  title: string;
  checked: boolean;
  setChecked: (flag: boolean) => void;
}
type NameMapper = {
  [key: string]: string;
};

const nameMapperCheckbox: NameMapper = {
  cato: "Carteira Total",
  ato: "Ativos Total",
  acto: "Ativos Contemplados Total",
  acbe: "Ativos Contemplados com Bens Entregues",
  acbne: "Ativos Contemplados  com Bens a Entregar",
  anc: "Ativos Não Contemplados",
  canto: "Cancelados Total",
  can1p: "Cancelados com 1ª parcela paga - quantidade de cotas",
  conmes: "Contemplações no mês - quantidade de cotas",
  condbne: "Contemplados em dia - SEM o bem",
  condbe: "Contemplados em dia - COM o bem",
  conabne: "Contemplados Atraso - SEM o bem",
  conabe: "Contemplados Atraso - COM o bem",
  ncond: "Não Contemplados - EM DIA",
  ncona: "Não Contemplados - EM ATRASO",
  f10q: "Faltando 10% ou < pra QUITAR",
  quit: "Quitados",
  cdes: "Cancelados - Desistentes",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "600px",
    top: `50%`,
    left: `50%`,
    overflowY: "scroll",
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(4),
  },
  closeIcon: {
    color: "white",
  },
  head: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#0288D1",
    color: "white",
    borderRadius: "0px",
    padding: theme.spacing(2),
  },

  content: { position: "relative", paddingTop: 20 },

  foot: {
    padding: theme.spacing(4, 0, 0, 0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

export const ModalCheckBox = ({
  open,
  handleCloseModal,
  checkboxes,
  setCheckboxes,
}: any) => {
  var [todosChecked, setTodosChecked] = useState(true);
  const classes = useStyles();

  const todosCheckboxHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTodosChecked(event.target.checked);
    var updatedCheckboxes = { ...checkboxes };

    Object.keys(updatedCheckboxes).map((key) => {
      updatedCheckboxes[key] = event.target.checked;
    });

    setCheckboxes(updatedCheckboxes);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    var checkedElements = Object.values(checkboxes).filter(
      (value) => value === false
    );
    if (checkedElements.length > 0) {
      setTodosChecked(false);
    } else {
      setTodosChecked(true);
    }
  }, [checkboxes]);

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.head}>
          <Typography variant="h5">Dados Cadastrais</Typography>
          <Button>
            <CloseIcon
              className={classes.closeIcon}
              fontSize="large"
              onClick={handleCloseModal}
            />
          </Button>
        </Paper>

        <Paper className={classes.paperWrap}>
          <div>
            <Checkbox
              key="todos"
              checked={todosChecked}
              color="primary"
              name="todos"
              onChange={todosCheckboxHandler}
            />
            Todos
          </div>

          {Object.keys(checkboxes).map((key: string) => (
            <div>
              <Checkbox
                key={key}
                checked={checkboxes[key]}
                color="primary"
                name={key}
                onChange={handleChange}
              />

              {nameMapperCheckbox[key]}
            </div>
          ))}

          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseModal}
          >
            Selecionar
          </Button>
        </Paper>
      </Grid>
    </Modal>
  );
};
