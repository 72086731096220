import React from 'react';
import { Pie, PieChart, Tooltip, Legend } from 'recharts';
import Title from './Title';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Divider, Grid, Typography } from '@material-ui/core';
import { VendasTableItem } from '../../../Models/VendasTableItem';
import { Skeleton } from '@material-ui/lab';
let colormap = require('colormap');

const useStyles = makeStyles({
  table: {
    height: '100%',
  },
  split: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '100%',
    maxWidth: '100%',
    padding: 16,
  },
  cardData: {
    color: 'black',
    padding: 10,
    fontSize: '12px',
    height: '30%',
    display: 'flex',
    lineHeight: '20px',
    alignItems: 'right',
    justifyContent: 'end',
  },
});

interface IVendas {
  itens: VendasTableItem[];
  loadingVendas: boolean;
  data: string;
}

export default function Vendas({ itens, loadingVendas, data }: IVendas) {
  const classes = useStyles();
  const colors = colormap({
    colormap: 'jet',
    nshades: itens.length > 5 ? itens.length : 6,
    format: 'hex',
    alpha: 1,
  });
  itens.map((value, index) => {
    value.fill = colors[index];
  });

  return (
    <React.Fragment>
      <Title>
        <Grid container spacing={0} justifyContent="space-between">
          <Grid item xs={8} xl={8}>
            <span style={{ textAlign: 'center', verticalAlign: 'center' }}>
              Vendas
            </span>
          </Grid>
          <Grid item xs={4} xl={4}>
            <Typography className={classes.cardData}>{data}</Typography>
          </Grid>
        </Grid>
      </Title>
      <Divider style={{ margin: '0px 16px' }} />

      <Grid container className={classes.split}>
        <Grid item xs={12} xl={6}>
          <TableContainer>
            {!loadingVendas && (
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Formas de acesso</TableCell>
                    <TableCell align="right">Qtde</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itens.map((row, index) => (
                    <TableRow style={{ height: '120px' }} key={index}>
                      <TableCell component="th" scope="row">
                        {row.formaAcesso}
                      </TableCell>
                      <TableCell align="right">{row.qtde}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow style={{ height: '120px' }} key={'teste'}>
                    <TableCell
                      component="th"
                      style={{ fontWeight: 'bold' }}
                      scope="row"
                    >
                      Total
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      {itens.reduce((prev, current) => {
                        return prev + +current.qtde;
                      }, 0)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}

            {loadingVendas && (
              <>
                <Skeleton height="120px" width="100%">
                  <TableRow></TableRow>
                </Skeleton>
                <Skeleton height="120px" width="100%">
                  <TableRow></TableRow>
                </Skeleton>
                <Skeleton height="120px" width="100%">
                  <TableRow></TableRow>
                </Skeleton>
              </>
            )}
          </TableContainer>
        </Grid>

        <Grid
          item
          xs={12}
          xl={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loadingVendas ? (
            <Skeleton variant="circle" height="300px" width="300px" />
          ) : (
            <PieChart width={400} height={400}>
              <Tooltip cursor={{ stroke: 'red', strokeWidth: 2 }} />
              <Legend verticalAlign="top" height={36} />
              <Pie
                data={itens}
                dataKey="qtde"
                nameKey="formaAcesso"
                cx="50%"
                cy="50%"
                innerRadius={90}
                outerRadius={180}
                fill="fill"
              />
            </PieChart>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
