import React, { useState } from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';

type IComissaoContext = {
  dataInicial: any;
  setDataInicial: any;
  dataFinal: any;
  setDataFinal: any;
  tipoRelatorio: any;
  setTipoRelatorio: any;
  searchField: any;
  setSearchField: any;
  searchText: any;
  setSearchText: any;
  tipoComissao: string;
  setTipoComissao: any;
};

export const ComissaoContext = createContext<IComissaoContext>({
  dataInicial: '',
  setDataInicial: '',
  dataFinal: '',
  setDataFinal: '',
  tipoRelatorio: '',
  setTipoRelatorio: '',
  searchField: '',
  setSearchField: '',
  searchText: '',
  setSearchText: '',
  tipoComissao: '',
  setTipoComissao: '',
});

export const ComissaoProvider = (props: any) => {
  var date = new Date();
  const defaultDataInicial = new Date(
    date.getFullYear(),
    date.getMonth() - 1,
    date.getDate()
  );
  const defaultDataFinal = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  let [dataInicial, setDataInicial] = useState<Date>(defaultDataInicial);
  let [dataFinal, setDataFinal] = useState<Date>(defaultDataFinal);
  let [tipoRelatorio, setTipoRelatorio] = useState<string>('total');
  let [searchField, setSearchField] = useState<string>(' ');
  let [searchText, setSearchText] = useState<string>(' ');
  let [tipoComissao, setTipoComissao] = useState<string>(
    'Aguardando Emissão N.F.'
  );

  const data = {
    dataInicial,
    setDataInicial,
    dataFinal,
    setDataFinal,
    tipoRelatorio,
    setTipoRelatorio,
    searchField,
    setSearchField,
    searchText,
    setSearchText,
    tipoComissao,
    setTipoComissao,
  };

  return (
    <ComissaoContext.Provider value={data}>
      {props.children}
    </ComissaoContext.Provider>
  );
};
