import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSystemParm } from '../../../redux/ducks/systemParameter';
import { Loading } from '../../../ui/Loading';
import { Alert } from '@material-ui/lab';
import { TitleCard } from '../../../base/TitleCard';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paperWrap: {
    margin: 20,
    overflow: 'hidden',
  },
  innerPadding: {
    padding: 20,
  },
  spacer: {
    height: 4,
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperWrapA: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: '0px',
  },
  frame: {
    width: `100%`,
    height: `500px`,
    border: '0 solid transparent',
    borderRadius: '4px',
    marginTop: 30,
  },
}));

interface IExtratoCotaComponentProps {
  loading: boolean;
  sysParmList: any[];
  loadSysParm: any;
}

const ExtratoCotaComponent = ({
  loading,
  sysParmList,
  loadSysParm,
}: IExtratoCotaComponentProps) => {
  const classes = useStyles();

  /**
   * vao virar parametros do parametros de sistema
   */
  const actionCodigo = 'VENDAS_EXTRATO_COTA_AVULSO_ACTION_URL';
  // useEffect(loadUserData, []);
  useEffect(loadSysParm, []);

  const otherFields = [
    {
      name: 'ctl00$hdnID_Modulo',
      value: '',
    },
    {
      name: 'ctl00$Conteudo$btnLocalizar',
      value: 'Localizar',
    },
    {
      name: '__LASTFOCUS',
      value: '',
    },
    {
      name: '__EVENTTARGET',
      value: '',
    },
    {
      name: '__EVENTARGUMENT',
      value: '',
    },
    {
      name: '__VIEWSTATE',
      value: '',
    },
    {
      name: '__VSTATE',
      value:
        'H4sIAAAAAAAEAL1WW0/jRhROnBgIsM1eutlVH4LrIrUSkMtyWyKyK3CqFhUCguxq36yJZ5JM155JZ8YkvFT9L33Y9/4FfkZ/DD1jOxBCUKtVF1uyj885833f3M74Kp1/mTGLrutwpgT35Sn5LaSCnHCp9pH38Rdy4boFw3zuKb9SWaZB193zFRHojLAeuuVWhGEaEKa4+U3s1pgkxHy5K3CTK+pRJPNdbP4wET4iLHSd4+Zeq6pTbz7zuyupxfSckcZ5M7e5s1VZ39rYfp0vGMUnWIFUxQXjCfKrvx+l0qlU6gou/dbXogGP5tmFVCQoNZBCq9Z7IiTlrL5Rquh71XJCX4WC1BkJlUD+qnUStn3qQcdb/CNh9fb2Ntr0NreqO+sbpPJ6x9Tgz8Ywo8cZURnwv0js0ikJQBbrJnS5D4F/5vVIgBbAatBO5yeBgkw6/VUClORpvTkNNJOFx1/zu2+HgW+dJ5rtaqliW4R5HAN03Q5VZ626Zb9982h+dyhrMiKwKK7bTTJIlNgWIDBZtxOjNgS7p1S/Vi4PBoPSYL3ERbf8qlKplj8cHcYiR7mBxIACRIIl6HItoJ7gknfUmseDGuStxVk2qLAsrYP4RC8Di6GA3JYSZ9YOZOKp20qE5Nr/ThInFALaHnIP+SQJR8AxNFD2fTJsXfRJ4k38PU49YgWUHXteKKCLMFABGo6+QtbmIcME29fNpmltobZPquM599PeRCVsGZiUydA0goOGmyxX21KAVrchhbKboVFIdIlqQrLsI4/oabvdqfJ/YGkeuS2qQp+PkUglYM387zxnYfuhqO4O3Jcgchowdn3+QGzRTD0YW+Nn94B5fijR+HQBPmlB4f58rt3yfbsgikzfPVEo0Xezl8vXm3m066chTLSN+aP69GbGhML5R3oXQ43tilr8QsHnFLSkTQzxb21GRGvnVT1AWV3EJ4q7Lukz7hH6lQt4UaZf+yH1cc49JedUp+hDaw4uIznArpJroWB08vrcMzzcwQXDMPK4sNjRntn30BLKVg9HTjgstTdHGSOipwLffHGAYZhoh3pIWHDmSi5gqWGODTOvodL6lH2SSeUL2eKC+60uzfu6WHb11wHod+BLGVmcflxI6Xvx65VUSgvChXlojWPTMDKgcKa40ETntAtr6p3wze9+L0cHebkjAIbtKYfJsR+FEpL9YTHbIkNlvoRfDnr56fJPbmE+rrM42+Lch21yfwqO+z6uY2nG/H4KeQOypYMwgv2FqIwELM2ajyO/5V0Hlubu+oAlc5dlOWJxjhMWh58Sf5/78G+ydx76ko8onv8YUCkj7QS0twVil58Q8EwPAFn2LtlKTHYyIutDVxxBYIJhHKIhPUSwB0ec+eMOEQpp4MgPbJMurJcBrJglowfmbGIZCyMjEwVnzad7QZsCAdEtWwTWtZxsem3OJdY/Ih2p410KAAA=',
    },
    {
      name: '__EVENTVALIDATION',
      value:
        '/wEdAAqvVXD1oYELeveMr0vHCmYP3OVcUU8W753P13fN8zCqBtscIGRHClVlQbBz/IC5yxjngTMR3j/qjbFo6FF1Hu7WQIgozQF3SPv4ZzShXu69X1Gwpv+lCmiTWc5PAQ8OunH1FdY+WDytJO73YqHXDcbmwHwu694l+cuA52jajHBrzIO1CMY6IcCX3KvClzLKU51K9nrFiy3DVUru2b0o5cLzL4nSESd5gKKV7gkbiRKq2XWL8Ws=',
    },
  ];

  let [grupo, setGrupo] = useState<string | null>();
  let [cota, setCota] = useState<string | null>();
  let [versao, setVersao] = useState<string | null>();
  let [actionUrl, setActionUrl] = useState<string | undefined>();

  function LoadData(codigo: string) {
    const sysParm = sysParmList?.find((x) => x.codigo === codigo);
    sysParm ? setActionUrl(sysParm.texto) : setActionUrl('Error');
  }

  useEffect(() => LoadData(actionCodigo), []);

  return (
    <>
      <Box>
        <TitleCard>Extrato da Cota</TitleCard>
      </Box>
      <Box>
        <Paper className={classes.paperWrapA}>
          {/* <Typography variant="h6" gutterBottom>
            Identificação do Consorciado
          </Typography> */}
          <Grid>
            <Loading loading={loading} />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <form method="post" action={actionUrl} target="frame">
                <Grid container spacing={3}>
                  {/* <Grid item xs={12} md={3}>
                    <TextField
                      required
                      name="ctl00$Conteudo$edtGrupo"
                      id="grupo"
                      label="Grupo"
                      fullWidth
                      autoComplete="grupo"
                      value={grupo}
                      onChange={(e) => setGrupo(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      required
                      name="ctl00$Conteudo$edtCota"
                      id="cota"
                      label="Cota"
                      fullWidth
                      autoComplete="cota"
                      value={cota}
                      onChange={(e) => setCota(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      required
                      name="ctl00$Conteudo$edtVersao"
                      id="versao"
                      label="Versão"
                      fullWidth
                      autoComplete="versao"
                      value={versao}
                      onChange={(e) => setVersao(e.target.value)}
                    />
                  </Grid> 
                  <Grid item xs={12} md={3}>
                    <Button type="submit" variant="contained" color="primary">
                      Busca Simples
                    </Button>
                  </Grid>
                   <div style={{ display: 'none' }}>
                    {otherFields.map((x) => (
                      <input name={x.name} value={x.value} />
                    ))}
                  </div> */}
                </Grid>
              </form>
              {actionUrl !== 'Error' ? (
                <iframe
                  sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                  style={{ display: 'block' }}
                  src={actionUrl}
                  className={classes.frame}
                  name="frame"
                  title="Extrato Cota"
                ></iframe>
              ) : (
                <div>
                  <iframe
                    sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                    style={{ display: 'block' }}
                    src={actionUrl}
                    className={classes.frame}
                    name="frame"
                    title="Extrato Cota"
                  ></iframe>
                  <Alert style={{ marginTop: 30 }} severity="warning">
                    Endereço não encontrado!
                  </Alert>
                </div>
              )}
            </MuiPickersUtilsProvider>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.systemParameter.loading,
  sysParmList: state.systemParameter.sysParmList,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUserData: () => {
    // dispatch(getMyData())
  },
  loadSysParm: () => {
    dispatch(getSystemParm());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtratoCotaComponent);
