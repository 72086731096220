import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ListSelect from "../../ui/ListSelect";
import { GrupoTelaRequest, TelaRequest } from "../../Models/GrupoTelaRequest";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "800px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(4),
  },
  head: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  foot: {
    padding: theme.spacing(4, 0, 0, 0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  vstack: {
    margin: "16px 0px",
  },
}));
export const GrupoTelaEdit = (props: any) => {
  if (props.openEdit) {
    return <GrupoTelaEditComponet {...props} />;
  } else {
    return null;
  }
};

export const GrupoTelaEditComponet = ({
  openEdit,
  handleCloseEdit,
  putGrupoTela,
  grupoTelaEdit,
  loading,
  telasPermitidas,
  grupoTelaLoading,
}: any) => {
  const telasOg: any[] = grupoTelaEdit.tela?.map((x: any) => ({
    title: x.titulo,
    value: x.id,
  }));

  const handleOrdemChange = (e: any) => {
    var value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    setOrdem(value);
  };

  const [id] = useState<number>(grupoTelaEdit.id);
  const [icone, setIcone] = useState<string>(grupoTelaEdit.icone || "");
  const [ordem, setOrdem] = useState<string>(grupoTelaEdit.ordem || "");
  const [nome, setNome] = useState<string>(grupoTelaEdit.nome || "");
  const [selecao, setSelecao] = React.useState<any[]>(telasOg);
  const [tela] = React.useState<TelaRequest[]>([] || null);

  const classes = useStyles();

  const options: any[] = telasPermitidas.map((x: any) => ({
    title: x.titulo,
    value: x.id,
  }));
  const handlePost = () => {
    for (var item of selecao) {
      for (var i of telasPermitidas) {
        if (item.value === i.id) {
          let telalist: TelaRequest = {
            comando: i.comando,
            icone: i.icone,
            id: i.id,
            idGrupo: i.idGrupo,
            ordem: i.ordem,
            tipo: i.tipo,
            titulo: i.titulo,
          };
          tela.push(telalist);
          break;
        }
      }
    }
    let grupoTela: GrupoTelaRequest = {
      id: id,
      icone: icone,
      nome: nome,
      ordem: ordem?.toString(),
      tela: tela.map(
        (x: any) =>
          ({
            id: x.id,
            comando: x.comando,
            icone: x.icone,
            ordem: x.ordem,
            tipo: x.tipo,
            titulo: x.titulo,
          } as TelaRequest)
      ),
    };
    putGrupoTela(grupoTela);
    handleCloseEdit();
  };

  return (
    <Modal
      open={openEdit}
      onClose={handleCloseEdit}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.paperWrap}>
          <div className={classes.head}>
            <Typography variant="h6">Edição de Grupo de Tela</Typography>

            <IconButton onClick={handleCloseEdit}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>

          <div style={{ position: "relative", paddingTop: 40 }}>
            <TextField
              className={classes.vstack}
              required
              name="Nome"
              label="Nome"
              value={nome}
              fullWidth
              onChange={(e) => setNome(e.target.value)}
            />
            <TextField
              className={classes.vstack}
              name="Icone"
              label="Icone"
              value={icone}
              fullWidth
              onChange={(e) => setIcone(e.target.value)}
            />
            <TextField
              className={classes.vstack}
              name="Ordem"
              label="Ordem"
              value={ordem}
              inputProps={{ maxLength: 2 }}
              fullWidth
              onChange={handleOrdemChange}
            />

            <ListSelect
              className={classes.vstack}
              label="Grupo de telas"
              placeholder="Selecione as telas..."
              fullWidth
              options={options}
              value={selecao}
              setValue={setSelecao}
            />
            <div className={classes.foot}>
              <Button
                onClick={handlePost}
                color="primary"
                disabled={loading || grupoTelaLoading}
              >
                Salvar
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>
    </Modal>
  );
};
