import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Avatar,
  MenuItem,
  Menu,
  makeStyles,
} from '@material-ui/core';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { logout } from '../redux/ducks/user';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { RevokeTokenRequest } from '../Models/RevokeTokenRequest';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: '#FFFFFF',
  },
  appBar: {
    width: `calc(100% - ${theme.spacing(6)}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBar100: {
    width: `100%`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  buttonText: {
    color: '#202020',
    paddingRight: 10,
    paddingLeft: 10,
  },
  logoIcon: {
    height: '29px',
    width: '29px',
    verticalAlign: 'bottom',
    flexGrow: 1,
  },
  logoName: {
    maxHeight: '100%',
    maxWidth: '80%',
    objectFit: 'contain',
    verticalAlign: 'bottom',
    overflow: 'hidden',
  },
  toolbarIcon: {
    display: 'left',
    background: 'white',
    padding: '10px',
    ...theme.mixins.toolbar,
  },
}));

interface IHeader {
  open: boolean;
  onLogout: (revokeTokenRequest: RevokeTokenRequest) => void;
  user: any;
}

const HeaderComponent = ({ open, onLogout, user }: IHeader) => {
  const classes = useStyles();
  const [mustRedirect, setMustRedirect] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    var revokeToken: RevokeTokenRequest = {
      token: user.userData.jwtToken,
    };
    var btnDOM = btnRef.current;
    btnDOM?.click();
    setMustRedirect(true);
    onLogout(revokeToken);
    setAnchorEl(null);
  };

  const otherFields = [
    { name: '__EVENTTARGET', value: 'ctl00$Conteudo$lkbSair' },
    { name: '__EVENTARGUMENT', value: '' },
    { name: 'ctl00_Conteudo_tvwEmpresa_ExpandState', value: 'en' },
    {
      name: 'ctl00_Conteudo_tvwEmpresa_SelectedNode',
      value: 'ctl00_Conteudo_tvwEmpresat1',
    },
    { name: 'ctl00_Conteudo_tvwEmpresa_PopulateLog', value: '' },
    { name: 'ctl00_Conteudo_tvwAgenda_ExpandState', value: 'en' },
    { name: 'ctl00_Conteudo_tvwAgenda_SelectedNode', value: '' },
    { name: 'ctl00_Conteudo_tvwAgenda_PopulateLog', value: '' },
    { name: 'ctl00_Conteudo_tvwConexoesUsuario_ExpandState', value: 'en' },
    { name: 'ctl00_Conteudo_tvwConexoesUsuario_SelectedNode', value: '' },
    { name: 'ctl00_Conteudo_tvwConexoesUsuario_PopulateLog', value: '' },
    {
      name: '__VSTATE',
      value:
        'H4sIAAAAAAAEAJWU3W7bNhTHLTq05dqdmqE12hWQ2KHAugWzpMROXCMIoKXFYGzIhsXJLgVaPFaISaIryq57U6CvsdtdDNj9nsAP1pGWnSZ1N2DyhY4O/zwfPx/yvWE9rGI7DE9FVuQikb/AqxnP4Wchi+9o9NsP8CYM2xg/iorE855qFcyYeFrMX79MpzlIih9uLwUxZIziJ9sryoaFAHkhZzTnAj8oJTyNwyApIKfnkF3RW+5CxeIpZIXAX34UMM7ZqSiofMHlVGR8DlxaMcNfbMvORMEjTqV1vFdpodoD487wRbh2mui9eixm4ea3/lG3e+QddI8OLdZGE8tqIxtFbKI+EFKu1kR76pdc8nECV6x0IkN7GzzLIL8q0gS3RpBQMsx0+IShqoqzY++MYFHgR2cihfCg5/d7XY88uzwdeZ7nE0K+tusjIZIRn/6HhjFU15WpjKqTBqpY7Zp99wzmkL9cTGnGgDG7dQ4JRAWorhngxysa4YZG+OGvK3y78SOVxVBtWyDEzPt7FYSr3sDTRrvmVHF9LbXxJU1mgA3PNjeJ4ttyU8l3PV/Vuap+v9fvPz/sOSY2fNvcVBQ7jVj10FA9mMj8uBMHM6fGnPp2LTp4rRwrHdHb9BhEBRdZ85vO+RtZQNr5Fcadi6F+bQa6M8oBNIibG6qqjO0E7jE/uRTR8i+SLf8QRMUjmZgLSaiUMzV/kmSUlEU8OXb5iark8Vv39Kez4Ht3kqcqYxCXyx0qpwvbHKY0hos8wV+9dbm2M+nqyQjXk+GW6jCYcyk602xNp3mDi6G4IAdr92efdu9qy4j/D0l7mE1EntLln8u/QRIpxjkQSiJ9OFXnK8JOs7m3s83pjtr+zlCglr8nBU8FoRFIKciA+Aeu99zd9/Z94ncHPX/gHZDrJyhVQTGjyW3p4aB3NPD7H6TH45y4J6t7gMBiynP1SgfE67v+/mpPiR6cFn72L1hXtwwVN7humrmmW9esdpFxr13Rv5ZqrsLQ3RXLq/aOmtB7jLUrGu+nhPpEI4Xi8yAdc3U3AWFARqBmUKoU2CrjrE7rtWmurX8AlF3ZRHQFAAA=',
    },
    { name: '__VIEWSTATE', value: '' },
    { name: '	ctl00$hdnID_Modulo', value: '' },
    { name: '__VIEWSTATEENCRYPTED', value: '' },
    {
      name: '__EVENTVALIDATION',
      value:
        'szVJ7+4841R5tqNtyFc7uzD65AvYKSbPTrxuOT0Jh+l8B2B4UP3QY17pHKT6fuWkhUCTZlssJKkxxqRCc/n8uBkHCEy0YFs13b3fDfUdoyJQGHehrGiG7wGAqmyMjAeuuJlVqmwZTpguseIKj7VSjgqCM+NkHjYfvPiThMTdLidzw32YrIrxzd691dNR3mga++D2S46uG1qRN7BrClJI5dSEZXAZZquV6ntHVkSkkVvWpnIIZrMiksTx6GcAhj8MmsD8ZQ==',
    },
  ];

  const location = useLocation();
  const isPortais = location.pathname === '/portais';

  return (
    <AppBar
      position="absolute"
      className={isPortais ? clsx(classes.appBar100) : clsx(classes.appBar, open && classes.appBarShift)}
    >
      {isPortais ?
        <><div className={classes.toolbarIcon}>
          <img
            src="/assets/logo-icon.png"
            className={classes.logoIcon}
            alt="Logo Yamaha" />
          <img
            src="/assets/logo-text.png"
            className={classes.logoName}
            alt="Yamaha Consórico" /></div></> :
        <><Toolbar className={classes.toolbar}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          ></Typography>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Avatar alt={user.userData.nome} src="/static/images/avatar/3.jpg" />
            <span className={classes.buttonText}>{user.userData.nome}</span>
            <ExpandMoreIcon />
          </Button><Menu
            style={{ top: '39px' }}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          ><Link to="/mydata">
              <MenuItem style={{ width: '200px' }} onClick={handleClose}>
                Meus Dados
              </MenuItem>
            </Link><Link to="/">
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Link>

            <form
              id="logoutNewcon"
              method="post"
              action="https://newkey.cny.com.br/Intranet/frmMain.aspx"
              target="logoutFrame"
            >
              <div style={{ display: 'none' }}>
                {otherFields.map((x) => (
                  <input name={x.name} value={x.value} />
                ))}
              </div>
              <Button
                id="submitBtn"
                type="submit"
                style={{ width: '100%', display: 'none' }}
                ref={btnRef}
              ></Button>
            </form>
            <iframe
              id="logoutFrame"
              title="targetForm"
              name="logoutFrame"
              style={{ display: 'none' }} />
          </Menu>
        </Toolbar>
        </>
      }
    </AppBar >
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogout: (request: RevokeTokenRequest) => {
      dispatch(logout(request));
    },
  };
};

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
