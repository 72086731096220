import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  createStyles,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import { Loading } from '../../ui/Loading';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { TitleCard } from '../../base/TitleCard';
import { PainelVendasRequest } from '../../Models/PainelVendasRequest';
import { Pie, PieChart, Tooltip, Legend } from 'recharts';
import api from '../../service/api';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Title from '../Home/components/Title';
import { UserData } from '../../Models/User';
import PainelVendasDetalhes from './PainelVendasDetalhes';
import { Skeleton } from '@material-ui/lab';
import { DatePicker } from '../../base/Forms/DatePicker';
let colormap = require('colormap');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    split: {
      display: 'flex',
      flexDirection: 'row',
      maxHeight: '100%',
      maxWidth: '100%',
      padding: 16,
    },
    formPaperWrap: {
      margin: theme.spacing(4),
      padding: theme.spacing(4),
      borderRadius: '0px',
    },
    inputField: {
      marginLeft: '20px',
      marginRight: '20px',
    },
    tablePaperWrap: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      padding: theme.spacing(2),
    },
    table: {
      height: '100%',
    },
  })
);

const DataTable = ({ userData }: any) => {
  var codigoComissionado = (userData as UserData).pontoVenda?.codigo;
  var date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const [comissionado] = useState(codigoComissionado);
  const [dataInicial, setDataInicial] = useState<Date | null>(
    new Date(firstDay.toISOString().substring(0, 10))
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    new Date(date.toISOString().substring(0, 10))
  );

  const [openDetalhe, setOpenDetalhe] = useState(false);
  const [size] = useState<number>(10);
  const [totalCont, setTotalCont] = useState<number>(0);
  const [loadingVendas, setloadingVendas] = useState(false);
  const [acesso, setAcesso] = useState<string>('');
  const [painelVendasList, setPainelVendasList] = useState([]);
  const [itemPainelVendasList, setItemPainelVendasList] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [pageTotal, setPageTotal] = useState<number>(0);
  const [loadingItensVendas, setloadingItensVendas] = useState(false);

  const colors = colormap({
    colormap: 'jet',
    nshades: painelVendasList.length > 5 ? painelVendasList.length : 6,
    format: 'hex',
    alpha: 1,
  });
  var auxColor = painelVendasList as PainelVendasRequest[];
  auxColor.map((value, index) => {
    value.fill = colors[index];
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const search = async () => {
    setloadingVendas(true);
    const { data: tableData } = await api.get('/RelatorioVendas/PainelVendas', {
      params: {
        comissionado: comissionado,
        dt_inicial: dataInicial,
        dt_final: dataFinal,
      },
    });
    setPainelVendasList(tableData);
    setloadingVendas(false);
  };

  const buscarNaApiPaginated = () => {
    setloadingItensVendas(true);
    api
      .get('/RelatorioVendas/ItemPainelVenda', {
        params: {
          index: (page - 1) * size,
          size: size,
          comissionado: comissionado,
          formaDeAcesso: acesso,
          dataInicial: dataInicial,
          dataFinal: dataFinal,
        },
      })
      .then(({ data: tableData }) => {
        setItemPainelVendasList(tableData);
      })
      .finally(() => {
        setloadingItensVendas(false);
      });
  };

  const handleCloseDetalhes = () => {
    setPage(1);
    setOpenDetalhe(false);
  };

  const handleOpenDetalhes = (modo: string, total: number) => {
    setAcesso(modo);
    setTotalCont(total);
    setPageTotal(Math.ceil(total / 10));
    setOpenDetalhe(true);
  };

  const handleExportXLS = () => {
    setloadingItensVendas(true);
    api
      .get('/RelatorioVendas/GetItemPainelVendas', {
        params: {
          comissionado: comissionado,
          formaDeAcesso: acesso,
          dataInicial: dataInicial,
          sizeTotal: totalCont,
          dataFinal: dataFinal,
          reportPdf: false,
          reportXLSX: true,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'PainelVendas.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setloadingItensVendas(false);
      });
  };

  const handleExportPdf = () => {
    setloadingItensVendas(true);
    api
      .get('/RelatorioVendas/GetItemPainelVendas', {
        params: {
          comissionado: comissionado,
          formaDeAcesso: acesso,
          dataInicial: dataInicial,
          dataFinal: dataFinal,
          sizeTotal: totalCont,
          reportPdf: true,
        },
        responseType: 'blob',
      })
      .then((response) => {
        let blob = new Blob([response.data], { type: 'application/pdf' }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .finally(() => {
        setloadingItensVendas(false);
      });
  };

  useEffect(buscarNaApiPaginated, [page, acesso]);

  useEffect(() => {
    setItemPainelVendasList([]);
  }, [acesso]);

  const classes = useStyles();

  return (
    <Box>
      <TitleCard breadcrumbsText="Vendas">Painel de Vendas</TitleCard>
      <Paper className={classes.formPaperWrap}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={5}>
            <Grid item xs={12} lg={3} md={4}>
              <FormControl fullWidth>
                <DatePicker
                  disableToolbar
                  name="dataInicial"
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="DataInicial"
                  autoComplete="DataInicial"
                  label="Data Inicial"
                  value={dataInicial}
                  onChange={(date: Date | null) => setDataInicial(date)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3} md={4}>
              <FormControl fullWidth>
                <DatePicker
                  disableToolbar
                  name="dataFinal"
                  variant="inline"
                  label="Data Final"
                  format="dd/MM/yyyy"
                  id="DataFinal"
                  value={dataFinal}
                  onChange={(date: Date | null) => setDataFinal(date)}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              lg={6}
              md={4}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                disabled={loadingVendas}
                onClick={() => search()}
                color="primary"
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Paper>
      <Paper className={classes.tablePaperWrap}>
        <React.Fragment>
          <Title>Vendas</Title>
          <Divider></Divider>

          <Grid container>
            <Grid item xs={12} lg={6}>
              <TableContainer>
                {!loadingVendas && (
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Formas de acesso</TableCell>
                        <TableCell align="center">Qtde</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {painelVendasList?.map((row: PainelVendasRequest) => (
                        <TableRow style={{ height: '120px' }} key={row.qtde}>
                          <TableCell component="th" scope="row">
                            {row.formaAcesso}
                          </TableCell>
                          <TableCell align="center">{row.qtde}</TableCell>
                          <TableCell align="right">
                            <Button
                              color="primary"
                              onClick={() =>
                                handleOpenDetalhes(row.formaAcesso, row.qtde)
                              }
                            >
                              <VisibilityIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow style={{ height: '120px' }} key={'teste'}>
                        <TableCell
                          component="th"
                          style={{ fontWeight: 'bold' }}
                          scope="row"
                        >
                          Total
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          {(painelVendasList as PainelVendasRequest[])?.reduce(
                            (prev, current) => {
                              return prev + +current.qtde;
                            },
                            0
                          )}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
                {loadingVendas && (
                  <>
                    <Skeleton height="120px" width="100%">
                      <TableRow></TableRow>
                    </Skeleton>
                    <Skeleton height="120px" width="100%">
                      <TableRow></TableRow>
                    </Skeleton>
                    <Skeleton height="120px" width="100%">
                      <TableRow></TableRow>
                    </Skeleton>
                  </>
                )}
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {loadingVendas ? (
                <Skeleton
                  style={{ marginTop: '20px' }}
                  variant="circle"
                  height="400px"
                  width="400px"
                />
              ) : (
                <PieChart width={400} height={400}>
                  <Tooltip cursor={{ stroke: 'red', strokeWidth: 2 }} />
                  <Legend verticalAlign="top" height={36} />

                  <Pie
                    data={painelVendasList as PainelVendasRequest[]}
                    dataKey="qtde"
                    nameKey="formaAcesso"
                    cx="50%"
                    cy="50%"
                    innerRadius={90}
                    outerRadius={180}
                    fill="fill"
                  />
                </PieChart>
              )}
            </Grid>
          </Grid>
          <PainelVendasDetalhes
            itens={itemPainelVendasList}
            page={page}
            count={pageTotal}
            dataInicial={dataInicial}
            handleCloseDetalhes={handleCloseDetalhes}
            handleChangePage={handleChangePage}
            handleExportPdf={handleExportPdf}
            handleExportXLS={handleExportXLS}
            openDetalhe={openDetalhe}
            loading={loadingItensVendas}
          />
        </React.Fragment>
      </Paper>
    </Box>
  );
};
const mapStateToProps = (state: any) => ({
  userData: state.user.userData,
});
const mapDispatchToProps = (dispatch: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
