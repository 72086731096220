import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import api from "../../../service/api";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "800px",
    maxHeight: "600px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(4),
  },
  closeIcon: {
    color: "white",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  head: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#0288D1",
    color: "white",
    borderRadius: "0px",
    padding: theme.spacing(2),
  },

  content: { position: "relative", paddingTop: 20 },

  foot: {
    padding: theme.spacing(4, 0, 0, 0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonProgress: {
    color: "#009ee6",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#009ee6",
  },
}));

export const ResetPasswordModal = ({ isOpen, handleCloseModal}: any) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false)
  const onSubmit = (e: any) => {
    EmailReset()
  }
  const EmailReset = () => {
    setLoading(true);
    api.get(`/Users/ResetPasswordEmail?email=${email}`)
      .then((resp) => {
        setLoading(false);
        handleCloseModal()
      })
      .catch(() => {
        setLoading(false);
      })
      .finally();
  }
  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.head}>
          <Typography variant="h5">Redefinir Senha</Typography>
          <Button>
            <CloseIcon
              className={classes.closeIcon}
              fontSize="large"
              onClick={handleCloseModal}
            />
          </Button>
        </Paper>

        <Paper className={classes.paperWrap}>
        <ValidatorForm
            onSubmit={onSubmit}
            style={{ paddingTop: "40px" }}
            //onError={errors => console.log(errors)}
            className={classes.form}
          >
            <TextValidator
              margin="normal"
              required
              fullWidth
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              id="username"
              label="Email"
              name="username"
              autoComplete="username"
              autoFocus
              validators={["required", "isEmail"]}
              errorMessages={[
                "Este campo é obrigatório",
                "Este email não parece ser válido",
              ]}
            />
            <div className={classes.wrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                Enviar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </ValidatorForm>
        </Paper>
      </Grid>
    </Modal>
  );
};
