import { createMuiTheme } from '@material-ui/core';

export const yamahaTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#039be5',
    },
    secondary: {
      main: '#9e9e9e',
    },
  },
});
