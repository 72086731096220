import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, parseISO } from 'date-fns';
import React from 'react';

export const DatePicker = (props: any) => {
  return (
    <KeyboardDatePicker
      {...props}
      autoOk
      animateYearScrolling
      format="dd/MM/yyyy"
      invalidDateMessage="Data Inválida"
      maxDateMessage="Data excede data Máxima"
      minDateMessage="Data excede data Mínima"
    />
  );
};
