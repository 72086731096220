const FETCH_TELAS_PERMITIDAS = "telasPermitida/FETCH_TELAS_PERMITIDAS";
const FETCH_TELAS_PERMITIDAS_FAIL = "telasPermitida/FETCH_TELAS_PERMITIDAS_FAIL";
const FETCH_TELAS_PERMITIDAS_SUCCESS = "telasPermitida/FETCH_TELAS_PERMITIDAS_SUCCESS";

export const defaultState = {
  loading: false,
  telasPermitidas: []
}

// Reducer
export default function reducer(state: any = defaultState, action: any = {}) {
  switch (action.type) {
    case FETCH_TELAS_PERMITIDAS:
      return {
        ...state,
        loading: true
      };
    case FETCH_TELAS_PERMITIDAS_FAIL:
      return {
        ...state,
        loading: false
      };
    case FETCH_TELAS_PERMITIDAS_SUCCESS:
      return {
        ...state,
        loading: false,
        telasPermitidas: action.payload.data
      };
    default:
      return state;
  }
}

export function fetchTelasPermitidas() {
  return {
    type: FETCH_TELAS_PERMITIDAS,
    payload: {
      request: {
        method: "GET",
        url: "/Telas",
      },
    },
  };
}