import { Box, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

import { TitleCard } from "../../base/TitleCard";

import api from "../../service/api";
import { ComissoesDetalhesComponent } from "./components/ComissoesDetalhes";
import { ComissaoProvider } from "../../providers/Comissao";
import { AguardandoNFComponent } from "./AguardandoNF";
import { ComissoesFuturasComponent } from "./ComissoesFuturas";
import { ComissoesPagasComponent } from "./ComissoesPagas";
import { ComissoesRetidasComponent } from "./ComissoesRetidas";
import { ConversaoBonusComponent } from "./ConversaoBonus";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 130,
  },
  paperWrap: {
    width: "100%",
    padding: theme.spacing(2),
  },
  paperSpace: {
    marginTop: theme.spacing(1),
    width: "100%",
    padding: theme.spacing(2),
  },
  frame: {
    width: `100%`,
    height: `500px`,
    border: "0 solid transparent",
    borderRadius: "4px",
    marginTop: 30,
  },
  innerPadding: {
    padding: 5,
  },
  comissao: {
    marginTop: 20,
  },
}));

const RelatorioComissoes = () => {
  const classes = useStyles();

  const [nome, setNome] = useState("");
  const [openDetalhes, setOpenDetalhes] = useState(false);
  const [endereco, setEndereco] = useState<[]>([]);
  const [telefone, setTelefone] = useState<[]>([]);
  const [email, setEmail] = useState<[]>([]);

  const handleCloseDetalhes = () => {
    setOpenDetalhes(false);
  };

  const handleOpenDetalhes = (row: any) => {
    api
      .get("/Relatorio/GetDadosPessoa", {
        params: {
          idPessoa: row.idPessoa,
        },
      })
      .then(({ data }: any) => {
        setNome(data.nome);
        setEndereco(data.enderecos);
        setTelefone(data.telefones);
        setEmail(data.emails);
        setOpenDetalhes(true);
      });
  };

  return (
    <ComissaoProvider>
      <TitleCard>Relatório Comissões</TitleCard>
      <Box className={classes.container}>
        <AguardandoNFComponent handleOpenDetalhes={handleOpenDetalhes} />
        <ComissoesFuturasComponent handleOpenDetalhes={handleOpenDetalhes} />
        <ComissoesPagasComponent handleOpenDetalhes={handleOpenDetalhes} />
        <ComissoesRetidasComponent handleOpenDetalhes={handleOpenDetalhes} />
        <ConversaoBonusComponent handleOpenDetalhes={handleOpenDetalhes} />

        <ComissoesDetalhesComponent
          openDetalhes={openDetalhes}
          handleCloseDetalhes={handleCloseDetalhes}
          nome={nome}
          endereco={endereco}
          telefone={telefone}
          email={email}
        ></ComissoesDetalhesComponent>
      </Box>
    </ComissaoProvider>
  );
};

export default RelatorioComissoes;
