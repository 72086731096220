import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: "12px",
    },

    paperWrap: {
      padding: theme.spacing(2),
      margin: theme.spacing(4),
    },
  })
);
