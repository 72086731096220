

import { Box } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { TitleCard } from '../../../base/TitleCard'

import './DebitoConta.css'

const DebitoContaComponent = () => {

    return (<>
        <Box>
            <TitleCard>Débito em Conta</TitleCard>
        </Box>
        <div>
            <div id="forms" className="page-layout simple fullwidth doc-page"  >
                <div className="content" >
                    <div id="debito">
                        <figure className="header"><img src="http://yamaha-motor.com.br/consorcio/images/debito/bg.jpg" alt="" /></figure>
                        <div className="container">
                            <p>
                                Agora você conta com mais uma facilidade Yamaha: o Débito Automático. Um serviço exclusivo que traz velocidade e deixa o seu dia mais prático, assim como todos os nossos produtos. <br></br>
                                Sem nenhuma taxa adicional, você pode optar por realizar os pagamentos do seu consórcio de forma mais segura e, com toda a tranquilidade e comodidade que o débito automático oferece.

                            </p>

                            <p>
                                Atualmente, o banco conveniado para a inclusão do débito é o Santander.* <br></br>
                                <small>*Em breve teremos convênio com outros bancos</small>
                            </p>

                            <p>Se interessou pelo Débito Automático? Imprima o formulário de autorização abaixo, preencha e nos encaminhe ? você tem 3 maneiras de fazer isso:</p>

                            <p><strong>Email:</strong> escaneie o formulário, preencha e o envie para <a href="mailto:sac.consorcio@yamaha-motor.com.br" className="link-email">sac.consorcio@yamaha-motor.com.br</a></p>

                            <p><strong>Correio:</strong> imprima o formulário, preencha e envie para uma Concessionaria Yamaha.</p>

                            <p><strong>Pessoalmente:</strong> imprima o formulário, preencha e leve até uma de nossas Concessionarias mais próxima de você.</p>

                            <a href="http://yamaha-motor.com.br/upload/termo.pdf" target="_blank" rel="noopener noreferrer" className="btn"><span>Imprimir Formulário de Autorização</span></a>
                            <p></p>
                            <a href="http://yamaha-motor.com.br/concessionarias" target="_blank" rel="noopener noreferrer" className="btn"><span>Endereços Concessionaria Yamaha</span></a>
                            <div className="aviso">
                                <span className="erro"></span>
                                <p><strong>Atenção: a inclusão do débito automático será feita em até 7 dias úteis a contar do recebimento do requerimento.</strong></p>
                            </div>

                            <p>Acompanhe o agendamento do débito junto ao seu banco e caso não ocorra em até 3 dias úteis anteriores a data de vencimento, você poderá pagar sua parcela normalmente através do boleto bancário.</p>

                            <p>A Yamaha pensou nesse benefício para você: com mais comodidade e agilidade!</p>

                            <p>
                                <strong>Cancelamento do serviço:</strong>
                                Se você deseja cancelar o serviço, envie um email para <a href="mailto:sac.consorcio@yamaha-motor.com.br">sac.consorcio@yamaha-motor.com.br</a> com a solicitação de cancelamento. Ele será realizado no prazo de 05 dias úteis a contar do recebimento do pedido.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

const mapStateToProps = (state: any) => ({

})

const mapDispatchToProps = (dispatch: any) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(DebitoContaComponent)
