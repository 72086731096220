import { FilterRegulation } from './../../Models/FilterRegulation';


const FETCH_QUALITY_REGULAMENTO_FAIL = "quality/FETCH_QUALITY_REGULAMENTO_FAIL";
const FETCH_QUALITY_REGULAMENTO = "quality/FETCH_QUALITY_REGULAMENTO";
const FETCH_QUALITY_REGULAMENTO_SUCCESS = "quality/FETCH_QUALITY_REGULAMENTO_SUCCESS";
const CREATE_QUALITY_REGULAMENTO_FAIL = "quality/CREATE_QUALITY_REGULAMENTO_FAIL";
const CREATE_QUALITY_REGULAMENTO = "quality/CREATE_QUALITY_REGULAMENTO";
const CREATE_QUALITY_REGULAMENTO_SUCCESS = "quality/CREATE_QUALITY_REGULAMENTO_SUCCESS";
const DOWNLOAD_QUALITY_REGULAMENTO_FAIL = "quality/ DOWNLOAD_QUALITY_REGULAMENTO_FAIL";
const DOWNLOAD_QUALITY_REGULAMENTO = "quality/ DOWNLOAD_QUALITY_REGULAMENTO";
const DOWNLOAD_QUALITY_REGULAMENTO_SUCCESS = "quality/ DOWNLOAD_QUALITY_REGULAMENTO_SUCCESS";
const DELETE_QUALITY_REGULAMENTO = "quality/DELETE_QUALITY_REGULAMENTO"
const DELETE_QUALITY_REGULAMENTO_SUCCESS = "quality/DELETE_QUALITY_REGULAMENTO_SUCCESS"
const DELETE_QUALITY_REGULAMENTO_FAIL = "quality/DELETE_QUALITY_REGULAMENTO_FAIL"

// Default state
export const defaultState = {
  loading: false,
  regulamentoList: [],
  downloadUrl: {
    url: null
  }

}
// Reducer
export default function reducer(state: any = defaultState, action: any = {}) {
  switch (action.type) {
    case FETCH_QUALITY_REGULAMENTO:
      return {
        ...state,
        loading: true
      };
    case FETCH_QUALITY_REGULAMENTO_SUCCESS:
      return {
        ...state,
        loading: false,
        regulamentoList: action.payload.data.regulamentoQuality,
        totalRQ: action.payload.data.total,
      };
    case FETCH_QUALITY_REGULAMENTO_FAIL:
      return {
        ...state,
        loading: false
      };
    case CREATE_QUALITY_REGULAMENTO:
      return {
        ...state,
        loading: true
      };
    case CREATE_QUALITY_REGULAMENTO_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case CREATE_QUALITY_REGULAMENTO_FAIL:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_QUALITY_REGULAMENTO:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_QUALITY_REGULAMENTO_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadUrl: action.payload.data,
      };
    case DOWNLOAD_QUALITY_REGULAMENTO_FAIL:
      return {
        ...state,
        loading: false,
      };

    case DELETE_QUALITY_REGULAMENTO:
      return {
        ...state,
        loading: true,
      };
    case DELETE_QUALITY_REGULAMENTO_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_QUALITY_REGULAMENTO_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}


// Action Creators
export function getRegulamento(filter: FilterRegulation) {
  return {
    type: FETCH_QUALITY_REGULAMENTO,
    payload: {
      request: {
        method: "GET",
        url: "/Quality/Regulamento",
        params: {
          index: filter.index,
          size: filter.size,
          orderBy: filter.orderBy,
          orderType: filter.orderType,
        }
      }
    }
  }
};

export function PostRegulamento(file: any, titulo: string) {
  return {
    type: CREATE_QUALITY_REGULAMENTO,
    payload: {
      request: {
        method: "POST",
        url: "/Quality/Regulamento",
        data: file,
        params: {
          titulo: titulo,
        }

      }
    }
  }
}

export function DownloadRegulamento(id: number) {

  return {
    type: DOWNLOAD_QUALITY_REGULAMENTO,
    payload: {
      request: {
        method: "GET",
        url: "/Quality/DownloadRegulamento",
        params: {
          id: id,
        }

      }
    }
  }
}

export function DeleteRegulamento(id: number) {
  return {
    type: DELETE_QUALITY_REGULAMENTO,
    payload: {
      request: {
        method: "DELETE",
        url: `Quality/DeleteRegulamento?Id=${id}`,
      }
    }
  }
}
