import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Alert } from '@material-ui/lab';
import { getSystemParm } from '../../redux/ducks/systemParameter';
import { Loading } from '../../ui/Loading';
import { TitleCard } from '../../base/TitleCard';

// import { MyDataRequest } from '../../Models/MyDataRequest';
// import { UserData } from '../../Models/User';
// import { getMyData, putMyData } from '../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  paperWrap: {
    margin: 20,
    overflow: 'hidden',
  },
  innerPadding: {
    padding: 20,
  },
  spacer: {
    height: 4,
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperWrapA: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: '0px',
  },
  frame: {
    width: `100%`,
    height: `500px`,
    border: '0 solid transparent',
    borderRadius: '4px',
    marginTop: 30,
  },
}));

interface IResultadoComponentProps {
  loading: boolean;
  sysParmList: any[];
  loadSysParm: any;
}

const ResultadoComponent = ({
  loading,
  sysParmList,
  loadSysParm,
}: IResultadoComponentProps) => {
  const classes = useStyles();

  /**
   * vao virar parametros do parametros de sistema
   */
  const actionCodigo = 'ASSEMBLEIAS_RESULTADOS_ACTION_URL';
  // useEffect(loadUserData, []);
  useEffect(loadSysParm, []);

  const otherFields = [
    {
      name: 'ctl00$hdnID_Modulo',
      value: 'CP',
    },
    {
      name: 'ctl00$Conteudo$btnOK',
      value: 'Confirmar',
    },
    {
      name: 'ctl00$Conteudo$edtFilialInicial',
      value: '000000',
    },
    {
      name: 'ctl00$Conteudo$edtFilialFinal',
      value: 'ZZZZZZ',
    },
    {
      name: 'ctl00$Conteudo$edtEquipeInicial',
      value: '000000',
    },
    {
      name: 'ctl00$Conteudo$edtEquipeFinal',
      value: 'ZZZZZZ',
    },
    {
      name: 'ctl00$Conteudo$cbx_PontoVenda',
      value: '0',
    },
    {
      name: 'ctl00$Conteudo$edtComissionadoInicial',
      value: '000000',
    },
    {
      name: 'ctl00$Conteudo$edtComissionadoFinal',
      value: 'ZZZZZZ',
    },
    {
      name: 'ctl00$Conteudo$edtGrupoIncial',
      value: '000000',
    },
    {
      name: 'ctl00$Conteudo$edtGrupoFinal',
      value: 'ZZZZZZ',
    },
    {
      name: 'ctl00$Conteudo$edtDT_Contemplacao_Inicial',
      value: '',
    },
    {
      name: 'ctl00$Conteudo$edtDT_Contemplacao_Final',
      value: '31/12/2050',
    },
    {
      name: 'ctl00$Conteudo$cbxST_Situacao',
      value: 'C',
    },
  ];

  let [grupo, setGrupo] = useState<number | null>();
  let [actionUrl, setActionUrl] = useState<string | undefined>();

  function LoadData(codigo: string) {
    const sysParm = sysParmList?.find((x) => x.codigo === codigo);
    sysParm ? setActionUrl(sysParm.texto) : setActionUrl('Error');
  }

  useEffect(() => LoadData(actionCodigo), []);

  return (
    <>
      <Box>
        <TitleCard>Resultados das Assembleias</TitleCard>
      </Box>
      <Box>
        <Paper className={classes.paperWrapA}>
          <Loading loading={loading} />
          <Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <form method="post" action={actionUrl} target="frame">
                <Grid container spacing={3}>
                  {/* <Grid item xs={12} md={3}>
                    <TextField
                      required
                      name="ctl00$Conteudo$edtGrupo"
                      id="grupo"
                      label="Grupo"
                      fullWidth
                      autoComplete="grupo"
                      type="number"
                      // variant="outlined"
                      value={grupo}
                      onChange={(e) => setGrupo(parseInt(e.target.value))}
                    />
                  </Grid> 
                  <Grid item xs={12} md={3}>
                    <Button type="submit" variant="contained" color="primary">
                      Busca Simples
                    </Button>
                  </Grid>
                   <div style={{ display: 'none' }}>
                    {otherFields.map((x) => (
                      <input name={x.name} value={x.value} />
                    ))}
                  </div> */}
                </Grid>
              </form>
              {actionUrl !== 'Error' ? (
                <iframe
                  sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                  src={actionUrl}
                  style={{ display: 'block' }}
                  className={classes.frame}
                  name="frame"
                  title="Resultado"
                ></iframe>
              ) : (
                <div>
                  <iframe
                    sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                    style={{ display: 'block' }}
                    src={actionUrl}
                    className={classes.frame}
                    name="frame"
                    title="Resultado"
                  ></iframe>
                  <Alert style={{ marginTop: 30 }} severity="warning">
                    Endereço não encontrado!
                  </Alert>
                </div>
              )}
            </MuiPickersUtilsProvider>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.systemParameter.loading,
  sysParmList: state.systemParameter.sysParmList,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUserData: () => {
    // dispatch(getMyData())
  },
  loadSysParm: () => {
    dispatch(getSystemParm());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultadoComponent);
