import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import {
  fetchTipoProdutos,
  updateTipoProdutos,
  deleteProduct,
  postTipoProduto,
} from "../../redux/ducks/tipoProduto";
import SearchIcon from "@material-ui/icons/Search";
import { Loading } from "../../ui/Loading";
import { TipoProdutoRequest } from "../../Models/TipoProdutoRequest";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { TipoProdutoEdit } from "./TipoProdutoEdit";
import CustomFab from "../../ui/CustomFab";
import AddIcon from "@material-ui/icons/Add";
import { TipoProdutoCreate } from "./TipoProdutoCreate";
import DeleteIcon from "@material-ui/icons/Delete";
import { ProdutoDelete } from "./ProdutoDelete";
import { TitleCard } from "../../base/TitleCard";
import { useStyles } from "./style";

function Search(tipoP: any, search2: any) {
  var userchUsers = [];
  var tipoProdutos: TipoProdutoRequest;

  for (var i in tipoP) {
    if (tipoP[i].sigla.match(search2) || tipoP[i].nome.match(search2)) {
      tipoProdutos = {
        id: tipoP[i].id,
        nome: tipoP[i].nome,
        sigla: tipoP[i].sigla,
      };
      userchUsers.push(tipoProdutos);
    }
  }
  return userchUsers;
}

const DataTable = ({
  handlePut,
  postTipoProduto,
  tipoProdutoList,
  tipoProdutoLoading,
  loadTipoProduto,
  loading,
  requestDeleteProduct,
  tipoProdutos,
}: any) => {
  const [orderBy, setOrderBy] = useState("id");
  const [orderType, setOrderType] = useState<"asc" | "desc">("asc");
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState(1);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [rowId, setRowId] = React.useState<number | null>(null);
  const [produto, setProduto] = useState<string | null>("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const classes = useStyles();
  const [tipoProduto, setTipoProdutoEdit] = React.useState();

  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenEdit = (row: any) => {
    setTipoProdutoEdit(row);
    setOpenEdit(true);
  };
  const [openCadastro, setOpenCadastro] = React.useState(false);

  const handleOpenCadastro = () => {
    setOpenCadastro(true);
  };

  const handleCloseCadastro = () => {
    setOpenCadastro(false);
  };

  useEffect(loadTipoProduto, []);

  const handleOpenDelete = (row: any) => {
    setRowId(row.id);
    setProduto(row.nome);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  function deleteProduct() {
    requestDeleteProduct(rowId);
    setOpenDelete(false);
  }

  function sortTable(orderByAtual: string) {
    let orderTypeAtual = orderType;

    if (orderTypeAtual === "asc") {
      setOrderType("desc");
      orderTypeAtual = "desc";
      setOrder(-1);
    } else if (orderTypeAtual === "desc") {
      setOrderType("asc");
      orderTypeAtual = "asc";
      setOrder(1);
    }

    setOrderBy(orderByAtual);

    return 0;
  }

  if (orderBy === "nome") {
    tipoProdutos.sort((a: any, b: any) => [
      a.nome.toLowerCase() > b.nome.toLowerCase() ? order : -order,
    ]);
  }

  if (orderBy === "sigla") {
    tipoProdutos.sort((a: any, b: any) => [
      a.sigla.toLowerCase() > b.sigla.toLowerCase() ? order : -order,
    ]);
  }

  tipoProdutos = Search(tipoProdutos, search);

  return (
    <Box>
      <TitleCard>Tipo Produto</TitleCard>

      <CustomFab label="Novo" icon={<AddIcon />} onClick={handleOpenCadastro} />
      <Paper className={classes.paperWrap}>
        <div className={classes.grow}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              type="text"
              placeholder="Pesquise aqui…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(e) => setSearch(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} />
        </div>
        <Loading loading={loading} />

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  Sigla
                  <TableSortLabel
                    active={orderBy === "sigla"}
                    direction={orderBy === "sigla" ? orderType : "asc"}
                    onClick={() => sortTable("sigla")}
                  >
                    {orderBy === "sigla" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Nome
                  <TableSortLabel
                    active={orderBy === "nome"}
                    direction={orderBy === "nome" ? orderType : "asc"}
                    onClick={() => sortTable("nome")}
                  >
                    {orderBy === "nome" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tipoProdutos.map((row: TipoProdutoRequest) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.sigla}
                  </TableCell>
                  <TableCell align="left">{row.nome}</TableCell>
                  <TableCell align="left">
                    <Button
                      style={{
                        color: "#388e3c",
                        borderColor: "#388e3c",
                      }}
                      title="Editar"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenEdit(row)}
                    >
                      <EditRoundedIcon />
                    </Button>
                    <Button
                      style={{
                        color: "#b20303",
                        borderColor: "#b20303",
                      }}
                      title="Excluir"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenDelete(row)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TipoProdutoEdit
          tipoProdutoEdit={tipoProduto}
          openEdit={openEdit}
          handleCloseEdit={handleCloseEdit}
          putTipoProduto={handlePut}
          loading={loading}
        />
        <TipoProdutoCreate
          openCadastro={openCadastro}
          handleCloseCadastro={handleCloseCadastro}
          postPontoVenda={postTipoProduto}
          loading={loading}
          tipoProdutoList={tipoProdutoList}
          tipoProdutoLoading={tipoProdutoLoading}
        />
        <ProdutoDelete
          openDelete={openDelete}
          handleDelete={deleteProduct}
          handleCloseDelete={handleCloseDelete}
          produto={produto}
        />
      </Paper>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  tipoProdutos: state.tipoProduto.tipoProdutos,
  loading: state.tipoProduto.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadTipoProduto: () => {
    dispatch(fetchTipoProdutos());
  },
  handlePut: (tp: TipoProdutoRequest) => {
    dispatch(updateTipoProdutos(tp)).then(() => dispatch(fetchTipoProdutos()));
  },
  postTipoProduto: (tipoProdutos: TipoProdutoRequest) => {
    dispatch(postTipoProduto(tipoProdutos)).then(() =>
      dispatch(fetchTipoProdutos())
    );
  },
  requestDeleteProduct: (id: number) => {
    dispatch(deleteProduct(id)).then(() => dispatch(fetchTipoProdutos()));
  },
});

export const TipoProdutos = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataTable);
