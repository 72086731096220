import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    titlePage: {
      position: "relative",
      left: 10,
      top: 5,
    },
    button: {
      margin: theme.spacing(1),
    },
    paperModal: {
      position: "absolute",
      width: 320,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "50%",
      left: "50%",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paperWrap: {
      width: "100%",
      padding: theme.spacing(2),
    },
    tableCellTitle: {
      color: "#808080",
      fontWeight: "bold",
    },
    tableCellData: {
      height: "53px",
    },
    boxContainer: {
      margin: "0px 32px",
      paddingTop: "32px",
    },
  })
);
