import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  createStyles,
  InputBase,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { PontoVendaRequest } from "../../Models/PontoVendaRequest";
import PrintIcon from "@material-ui/icons/Print";
import { Loading } from "../../ui/Loading";
import { HeaderCell } from "../../ui/HeaderCell";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import { PontoVendaDelete } from "./PontoVendaDelete";
import { PontoVendaCreate } from "./PontoVendaCreate";
import { fetchTipoProdutos } from "../../redux/ducks/tipoProduto";
import { PontoVendaEdit } from "./PontoVendaEdit";
import { TitleCard } from "../../base/TitleCard";
import { ThemePagination } from "../../base/Pagination";
import api from "../../service/api";
import { TableHeader } from "../../base/TableHeader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    search: {
      position: "relative",
      backgroundColor: "#33333311",
      padding: theme.spacing(0, 2),
      margin: theme.spacing(2, 0),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      width: "100%",
      display: "flex",
    },
    searchIcon: {
      padding: theme.spacing(2, 0),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      backgroundColor: "transparent",
      color: "inherit",
      flexGrow: 1,
    },
    inputInput: {
      backgroundColor: "transparent",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      flexGrow: 1,
      display: "flex",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    titlePage: {
      position: "relative",
      left: 10,
      top: 5,
    },
    button: {
      margin: theme.spacing(1),
    },
    paperModal: {
      position: "absolute",
      width: 320,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "50%",
      left: "50%",
    },
    buttonNovo: {
      margin: theme.spacing(1),
      paddingRight: 5,
      position: "absolute",
      left: "90%",
      width: 65,
      height: 38,
    },
    paperdelete: {
      position: "absolute",
      width: 365,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "38%",
      left: "38%",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paperWrap: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      padding: theme.spacing(2),
    },

    buttonDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },

    icon: {
      color: "#808080",
    },
  })
);

const DataTable = ({
  handleDelete,
  loadTipoProdutos,
  tipoProdutoList,
  tipoProdutoLoading,
}: any) => {
  const classes = useStyles();

  const [search, setSearch] = useState("");
  const [index, setIndex] = useState<number>(0);
  const [size] = useState<number>(10);
  const [orderBy, setOrderBy] = useState("Id");
  const [orderType, setOrderType] = useState<"asc" | "desc">("asc");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [listSize, setListSize] = React.useState(0);
  const [rowId, setRowId] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [openCadastro, setOpenCadastro] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [pontoVendaEdit, setPontoVendaEdit] = React.useState();

  const [tableData, setTableData] = React.useState([]);

  const handleOpenCadastro = () => setOpenCadastro(true);
  const handleCloseCadastro = () => setOpenCadastro(false);

  const handleOpenEdit = (row: any) => {
    setPontoVendaEdit(row);
    setOpenEdit(true);
  };

  const handleExportPdf = () => {
    setLoading(true);
    api
      .get("/PontoVendas", {
        params: {
          orderBy,
          orderType,
          search,
          reportPdf: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        let blob = new Blob([response.data], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);

        window.open(url);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportXLS = () => {
    setLoading(true);
    api
      .get("/PontoVendas", {
        params: {
          orderBy,
          orderType,
          search,
          reportXLSX: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Ponto de Venda.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenDelete = (row: any) => {
    setRowId(row.id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  function deleteParameter() {
    setOpenDelete(false);
    setLoading(true);
    api
      .delete("/PontoVendas", {
        params: {
          id: rowId,
        },
      })
      .then(() => {
        loadData();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const loadData = () => {
    getData();
  };

  const getData = async () => {
    setLoading(true);
    const { data } = await api.get("/PontoVendas", {
      params: {
        index,
        size,
        orderBy,
        orderType,
        search,
      },
    });
    setListSize(data.size);
    setTableData(data.list);
    setLoading(false);
  };

  const handlePut = (pv: PontoVendaRequest) => {
     loadData();
    
  };

  const handlePost = (pv: PontoVendaRequest) => {
    api.post("/PontoVendas", { ...pv }).then(() => loadData());
  };

  useEffect(loadData, [index, size, orderBy, orderType, search]);

  function sortTable(_orderBy: string) {
    if (_orderBy !== orderBy) {
      setOrderType(orderType === "asc" ? "desc" : "asc");
    }

    setOrderBy(_orderBy);

    getData();
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    let indexPage = (newPage - 1) * size;

    setIndex(indexPage);
    loadTipoProdutos();
    setPage(newPage);
  };

  function inputSearch(inputSearch: string) {
    setSearch(inputSearch);
    getData();
  }
  useEffect(() => {
    setNumberOfPages(
      Math.ceil(listSize / 10)
    );
  }, [listSize]);

  useEffect(() => {
    setPage(page > numberOfPages ? numberOfPages : page);
  }, [numberOfPages]);

  return (
    <Box>
      <TitleCard>Concessionária</TitleCard>

      <div className={classes.buttonDiv}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenCadastro}
          style={{ marginRight: "24px" }}
        >
          CADASTRAR
        </Button>
      </div>

      <Paper className={classes.paperWrap}>
        <TableHeader
          title="Ponto de Venda"
          onClickPdfButton={handleExportPdf}
          onClickXlsButton={handleExportXLS}
        />
        <div className={classes.grow}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              type="text"
              placeholder="Pesquise aqui…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(e) => inputSearch(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} />
        </div>

        <Loading loading={loading} />

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <HeaderCell
                  orderBy={orderBy}
                  orderType={orderType}
                  fieldName="Codigo"
                  sortTable={sortTable}
                >
                  Código
                </HeaderCell>
                <HeaderCell
                  orderBy={orderBy}
                  orderType={orderType}
                  fieldName="Nome"
                  sortTable={sortTable}
                >
                  Nome
                </HeaderCell>
                <HeaderCell
                  orderBy={orderBy}
                  orderType={orderType}
                  fieldName="Cnpj"
                  sortTable={sortTable}
                >
                  CNPJ
                </HeaderCell>
                <HeaderCell
                  orderBy={orderBy}
                  orderType={orderType}
                  fieldName="GrupoEconomico"
                  sortTable={sortTable}
                >
                  Grupo Economico
                </HeaderCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row: PontoVendaRequest) => (
                <TableRow key={row.id}>
                  <TableCell>{row.codigo}</TableCell>
                  <TableCell>{row.nome}</TableCell>
                  <TableCell>{row.cnpj}</TableCell>
                  <TableCell>{row.grupoEconomico}</TableCell>
                  <TableCell>
                    <Button
                      style={{
                        color: "#388e3c",
                        borderColor: "#388e3c",
                      }}
                      title="Editar"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenEdit(row)}
                    >
                      <EditRoundedIcon className={classes.icon} />
                    </Button>

                    <Button
                      style={{
                        color: "#b20303",
                        borderColor: "#b20303",
                      }}
                      title="Excluir"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenDelete(row)}
                    >
                      <DeleteIcon className={classes.icon} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <PontoVendaDelete
          openDelete={openDelete}
          deleteParameter={deleteParameter}
          handleCloseDelete={handleCloseDelete}
        />

        <PontoVendaCreate
          openCadastro={openCadastro}
          handleCloseCadastro={handleCloseCadastro}
          postPontoVenda={handlePost}
          loading={loading}
          tipoProdutoList={tipoProdutoList}
          tipoProdutoLoading={tipoProdutoLoading}
        />

        <PontoVendaEdit
          pontoVendaEdit={pontoVendaEdit}
          openEdit={openEdit}
          handleCloseEdit={handleCloseEdit}
          putPontoVenda={handlePut}
          loading={loading}
          tipoProdutoList={tipoProdutoList}
          tipoProdutoLoading={tipoProdutoLoading}
        />
      </Paper>

      <ThemePagination
        marginLeft="32px"
        pagesCount={numberOfPages}
        currentPage={page}
        handlePageChange={handleChangePage}
      />
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  tipoProdutoList: state.tipoProduto.tipoProdutos,
  tipoProdutoLoading: state.tipoProduto.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadTipoProdutos: () => {
    dispatch(fetchTipoProdutos());
  }
});

export const PontoVenda = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataTable);
