import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Loading } from "../../ui/Loading";
import { TitleCard } from "../../base/TitleCard";
import { HierarquiaRequest } from "../../Models/HierarquiaRequest";
import api from "../../service/api";
import { ThemePagination } from "../../base/Pagination";
import { Box } from "@material-ui/core";
import { useStyles } from "./style";

const DataTable = () => {
  const [orderBy, setOrderBy] = useState("Descricao");
  const [orderType, setOrderType] = useState<"asc" | "desc">("asc");
  const [size] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = React.useState(1);
  const [hierarquiasList, setHierarquiasList] = useState([]);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const buscarNaApiCount = async () => {
    const { data: contador } = await api.get("/Hierarquia/CountHierarquia");
    setCount(contador);
  };

  const buscarNaApiSorted = async () => {
    const { data: tableData } = await api.get(
      "/Hierarquia/GetHierarquiaPaginatedSorted",
      {
        params: {
          page: page - 1,
          size: size,
          orderBy: orderBy,
          sortOrder: orderType,
        },
      }
    );
    setHierarquiasList(tableData);
  };

  let loadData = () => {
    setLoading(true);
    buscarNaApiSorted();
    buscarNaApiCount();
    setLoading(false);
  };

  let loadNewData = () => {
    buscarNaApiSorted();
  };

  useEffect(loadData, []);
  useEffect(loadNewData, [orderBy, orderType, page]);

  function sortTable(orderByAtual: string) {
    let orderTypeAtual = orderType;

    if (orderTypeAtual === "asc") {
      setOrderType("desc");
      orderTypeAtual = "desc";
    } else if (orderTypeAtual === "desc") {
      setOrderType("asc");
      orderTypeAtual = "asc";
    }
    setOrderBy(orderByAtual);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Box>
        <TitleCard>HIERARQUIA</TitleCard>
      </Box>
      <Box className={classes.boxContainer}>
        <Paper>
          <Loading loading={loading} />
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>
                    Descrição
                    <TableSortLabel
                      active={orderBy === "Descricao"}
                      direction={orderBy === "Descricao" ? orderType : "asc"}
                      onClick={() => sortTable("Descricao")}
                    >
                      {orderBy === "Descricao" ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellTitle}>
                    Cargo
                    <TableSortLabel
                      active={orderBy === "Cargo"}
                      direction={orderBy === "Cargo" ? orderType : "asc"}
                      onClick={() => sortTable("Cargo")}
                    >
                      {orderBy === "Cargo" ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableCellTitle} align="center">
                    Nível
                    <TableSortLabel
                      active={orderBy === "Nivel"}
                      direction={orderBy === "Nivel" ? orderType : "asc"}
                      onClick={() => sortTable("Nivel")}
                    >
                      {orderBy === "Nivel" ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableCellTitle} align="center">
                    Próximo Nível
                    <TableSortLabel
                      active={orderBy === "ProximoNivel"}
                      direction={orderBy === "ProximoNivel" ? orderType : "asc"}
                      onClick={() => sortTable("ProximoNivel")}
                    >
                      {orderBy === "ProximoNivel" ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hierarquiasList?.map((row: HierarquiaRequest) => (
                  <TableRow key={row.id} className={classes.tableCellData}>
                    <TableCell align="left">{row.descricao}</TableCell>
                    <TableCell align="center">{row.cargo}</TableCell>
                    <TableCell align="center">{row.nivel}</TableCell>
                    <TableCell align="center">{row.proximoNivel}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <ThemePagination
          marginLeft="0px"
          pagesCount={count}
          currentPage={page}
          handlePageChange={handleChangePage}
        />
      </Box>
    </>
  );
};

export const Hierarquia = DataTable;
