import React from "react";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import HelpIcon from "@material-ui/icons/Help";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

import { Button, CssBaseline, Grid, Link } from "@material-ui/core";
import { GridItem } from "./components/GridItem";
import { Typography } from "@material-ui/core";
import { useStyles } from "./style";

export const LandingPage = (props: any) => {
  const classes = useStyles();

  const cardsData = [
    {
      avatar: <AccountBoxIcon fontSize="large" className={classes.icon} />,
      title: "Portal",
      description: "Informações sobre acesso",
      backgroundColor: "#00a7f5",
      onClick: null,
    },
    {
      avatar: <HelpIcon fontSize="large" className={classes.icon} />,
      title: "Dúvidas Frequentes",
      description: "Verifique se aqui já constam suas dúvidas",
      backgroundColor: "#2fb7f7",
      onClick: props.setFAQModalOpen,
    },
    {
      avatar: <PhoneInTalkIcon fontSize="large" className={classes.icon} />,
      title: "Contatos",
      description: "Temos várias formas de contato",
      backgroundColor: "#afdffc",
      onClick: props.setContatoModalOpen,
    },
  ];

  const cards = cardsData.map((data) => (
    <Link
      onClick={data.onClick}
      underline="none"
      style={{ cursor: data.onClick ? "pointer" : "auto" }}
    >
      <Grid key={data.title} item className={classes.gridItem}>
        <GridItem
          avatar={data.avatar}
          title={data.title}
          description={data.description}
          backgroundColor={data.backgroundColor}
        />
      </Grid>
    </Link>
  ));

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <CssBaseline />

        <img
          className={classes.logo}
          src="/assets/logo.png"
          alt="Yamaha Consórico"
        />

        <Grid className={classes.grid} container spacing={0} justify="center">
          {cards}
        </Grid>

        <Typography className={classes.bottomTextTitle} variant="h3">
          Bem vindo ao Portal do Consórcio
        </Typography>

        <Typography className={classes.bottomTextDescription} variant="h5">
          Aqui você encontra todas as informações necessárias para ter boas
          vendas.
        </Typography>

        <Button
          variant="outlined"
          size="large"
          onClick={props.buttonClickAction}
          className={classes.buttonBottomText}
        >
          Acessar
        </Button>
      </div>
    </div>
  );
};
