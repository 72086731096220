import { makeStyles } from "@material-ui/core";
import BackgroundImg from "./assets/background.png";

export const useStyles = makeStyles((theme) => ({
  main: {
    backgroundImage: "url(/assets/bg.jpg)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    minHeight: "100vh",
  },

  grid: {
    height: "100%",
    paddingTop: "40px",
    paddingBottom: "40px",
  },

  container: {
    padding: "128px",
  },

  logo: {
    width: "200px",
  },

  paper: {
    height: 200,
    borderRadius: 0,
    width: 300,
  },

  icon: {
    color: "white",
    fontSize: "45px",
  },

  bottomTextTitle: {
    fontSize: "42px",
    color: "white",
  },

  bottomTextDescription: {
    color: "white",
    fontSize: "14px",
    paddingTop: "8px",
  },

  buttonBottomText: {
    fontWeight: "bold",
    width: "200px",
    height: "40px",
    marginTop: "30px",
    color: "white",
    borderColor: "white",
    borderRadius: "0px",
  },

  gridItem: {
    width: 230,
    height: 180,
  },

  itemContainer: {
    height: "100%",
    padding: "20px",
    textAlign: "center",
  },

  itemIcon: {
    height: "45%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  cardTitle: {
    color: "rgba(255,255,255,0.87)",
    fontSize: "25px",
    height: "35%",
    display: "flex",
    lineHeight: "25px",
    alignItems: "center",
    justifyContent: "center",
  },

  cardDescription: {
    color: "rgba(0,0,0,0.54)",
    fontSize: "14px",
    height: "30%",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
