import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { TitleCard } from '../../base/TitleCard';

export const InfoGerencial = () => {
  const useStyles = makeStyles((theme) => ({
  }));

  const classes = useStyles();

  return (
    <Box>
      <TitleCard breadcrumbsText="Sistema">
        Informações Gerenciais
      </TitleCard>
    </Box>
  );
};
