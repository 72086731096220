import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { FAQData } from "../data/FAQData";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "800px",
    maxHeight: "600px",
    overflowY: "scroll",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(4),
  },
  closeIcon: {
    color: "white",
  },
  head: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#0288D1",
    color: "white",
    borderRadius: "0px",
    padding: theme.spacing(2),
  },

  content: { position: "relative", paddingTop: 20 },

  foot: {
    padding: theme.spacing(4, 0, 0, 0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

export const FAQModal = ({ isOpen, handleCloseModal }: any) => {
  const classes = useStyles();

  let faqItems = FAQData;

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.head}>
          <Typography variant="h5">Dúvidas Frequentes</Typography>
          <Button>
            <CloseIcon
              className={classes.closeIcon}
              fontSize="large"
              onClick={handleCloseModal}
            />
          </Button>
        </Paper>

        <Paper className={classes.paperWrap}>
          <div className={classes.content}>
            {faqItems.map((item) => (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    {item.descriptionItems.map((descriptionItem) => (
                      <>
                        <Typography
                          style={{ fontWeight: "bold", marginBottom: "16px" }}
                        >
                          {descriptionItem.title}
                        </Typography>

                        {descriptionItem.description && (
                          <Typography style={{ marginBottom: "20px" }}>
                            {descriptionItem.description}
                          </Typography>
                        )}

                        {descriptionItem.descriptionJsx && (
                          <Typography style={{ marginBottom: "20px" }}>
                            {descriptionItem.descriptionJsx}
                          </Typography>
                        )}
                      </>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Paper>
      </Grid>
    </Modal>
  );
};
