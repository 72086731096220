import { GrupoTelaRequest } from './../../Models/GrupoTelaRequest';

const FETCH_GRUPO_TELA = "grupoTela/FETCH_GRUPO_TELA";
const FETCH_GRUPO_TELA_SUCCESS = "grupoTela/FETCH_GRUPO_TELA_SUCCESS";
const FETCH_GRUPO_TELA_FAIL = "grupoTela/FETCH_GRUPO_TELA_FAIL";

const CREATE_GRUPO_TELA = "grupoTela/CREATE_GRUPO_TELA";
const CREATE_GRUPO_TELA_SUCCESS = "grupoTela/CREATE_GRUPO_TELA_SUCCESS";
const CREATE_GRUPO_TELA_FAIL = "grupoTela/CREATE_GRUPO_TELA_FAIL";

const UPDATE_GRUPO_TELA = "grupoTela/UPDATE_GRUPO_TELA";
const UPDATE_GRUPO_TELA_SUCCESS = "grupoTela/UPDATE_GRUPO_TELA_SUCCESS";
const UPDATE_GRUPO_TELA_FAIL = "grupoTela/UPDATE_GRUPO_TELA_FAIL";

const DELETE_GRUPO_TELA = "grupoTela/DELETE_GRUPO_TELA";
const DELETE_GRUPO_TELA_SUCCESS = "grupoTela/DELETE_GRUPO_TELA_SUCCESS";
const DELETE_GRUPO_TELA_FAIL = "grupoTela/DELETE_GRUPO_TELA_FAIL";


export const defaultState = {
    loading: false,
    grupoTelaList: []
}

export default function reducer(state: any = defaultState, action: any = {}) {
    switch (action.type) {
        case FETCH_GRUPO_TELA:
            return {
                ...state,
                loading: true,
            };
        case FETCH_GRUPO_TELA_SUCCESS:
            return {
                ...state,
                loading: false,
                grupoTelaList: action.payload.data
            };
        case FETCH_GRUPO_TELA_FAIL:
            return {
                ...state,
                loading: false,
            };
        case CREATE_GRUPO_TELA:
            return {
                ...state,
                loading: true,
            };
        case CREATE_GRUPO_TELA_FAIL:
            return {
                ...state,
                loading: false,
            };
        case CREATE_GRUPO_TELA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_GRUPO_TELA:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_GRUPO_TELA_FAIL:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_GRUPO_TELA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_GRUPO_TELA:
            return {
                ...state,
                loading: true,
            };
        case DELETE_GRUPO_TELA_FAIL:
            return {
                ...state,
                loading: false,
            };
        case DELETE_GRUPO_TELA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}

export function getGrupoTela() {
    return {
        type: FETCH_GRUPO_TELA,
        payload: {
            request: {
                method: "GET",
                url: "/GrupoDeTelas"
            },
        },
    };
}

export function createGrupoTela(data: GrupoTelaRequest) {
    return {
        type: CREATE_GRUPO_TELA,
        payload: {
            request: {
                method: "POST",
                url: "/GrupoDeTelas",
                data
            }
        }
    }
}

export function updateGrupoTela(data: GrupoTelaRequest) {
    return {
        type: UPDATE_GRUPO_TELA,
        payload: {
            request: {
                method: "PUT",
                url: "/GrupoDeTelas",
                data
            }
        }
    }
}

export function deletePontoVenda(id: number) {
    return {
        type: DELETE_GRUPO_TELA,
        payload: {
            request: {
                method: "DELETE",
                url: "/GrupoDeTelas",
                params: {
                    id
                }
            }
        }
    }
}