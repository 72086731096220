import { PecaEmailRequest } from "../../Models/PecaEmailRequest";


const FETCH_PECA_EMAIL = "pecaEmail/FETCH_PECA_EMAIL";
const FETCH_PECA_EMAIL_FAIL = "pecaEmail/FETCH_PECA_EMAIL_FAIL";
const FETCH_PECA_EMAIL_SUCCESS = "pecaEmail/FETCH_PECA_EMAIL_SUCCESS";
const UPDATE_PECA_MAIL = "pecaEmail/UPDATE_PECA_MAIL";
const UPDATE_PECA_MAIL_FAIL = "pecaEmail/UPDATE_PECA_MAIL_FAIL";
const UPDATE_PECA_MAIL_SUCCESS = "pecaEmail/UPDATE_PECA_MAIL_SUCCESS";
const POST_PECA_MAIL = "pecaEmail/POST_PECA_MAIL";
const POST_PECA_MAIL_FAIL = "pecaEmail/POST_PECA_MAIL_FAIL";
const POST_PECA_MAIL_SUCCESS = "pecaEmail/POST_PECA_MAIL_SUCCESS";
const DELETE_PECA_MAIL = "pecaEmail/DELETE_PECA_MAIL";
const DELETE_PECA_MAIL_FAIL = "pecaEmail/DELETE_PECA_MAIL_FAIL";
const DELETE_PECA_MAIL_SUCESS = "pecaEmail/DELETE_PECA_MAIL_SUCESS";


// Default state
export const defaultState = {
    loading: false,
    pecaEmailList: []

}
// Reducer
export default function reducer(state: any = defaultState, action: any = {}) {
    switch (action.type) {
        case FETCH_PECA_EMAIL:
            return {
                ...state,
                loading: true
            };
        case FETCH_PECA_EMAIL_FAIL:
            return {
                ...state,
                loading: false

            };
        case FETCH_PECA_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                pecaEmailList: action.payload.data
            };
        case UPDATE_PECA_MAIL:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_PECA_MAIL_FAIL:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_PECA_MAIL_SUCCESS:
            return {
                ...state,
                loading: true,
            };
        case POST_PECA_MAIL:
            return {
                ...state,
                loading: true,
            };
        case POST_PECA_MAIL_FAIL:
            return {
                ...state,
                loading: false,
            };
        case POST_PECA_MAIL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_PECA_MAIL:
            return {
                ...state,
                loading: true,
            };
        case DELETE_PECA_MAIL_FAIL:
            return {
                ...state,
                loading: false,
            };
        case DELETE_PECA_MAIL_SUCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}

// Action Creators
export function getPecaEmail() {
    return {
        type: FETCH_PECA_EMAIL,
        payload: {
            request: {
                method: "GET",
                url: "/PecaEmail"
            },
        },
    };
}
export function putPegaEmail(pecaEmail: PecaEmailRequest) {
    return {
        type: UPDATE_PECA_MAIL,
        payload: {
            request: {
                method: "PUT",
                url: "/PecaEmail",
                data: pecaEmail
            },
        },
    };
}
export function postPegaEmail(pecaEmail: PecaEmailRequest) {
    return {
        type: POST_PECA_MAIL,
        payload: {
            request: {
                method: "POST",
                url: "/PecaEmail",
                data: pecaEmail
            },
        },
    };
}
export function deletePegaEmail(id: number) {
    return {
        type: DELETE_PECA_MAIL,
        payload: {
            request: {
                method: "DELETE",
                url: `/pecaEmail?id=${id}`,

            },
        },
    };
}
