import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Loading } from '../../ui/Loading';
import { getSystemParm } from '../../redux/ducks/systemParameter';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { TitleCard } from '../../base/TitleCard';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperWrapA: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: '0px',
  },
  frame: {
    width: `100%`,
    height: ``,
    border: '0 solid transparent',
    borderRadius: '4px',
    marginTop: 30,
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  link: {
    textDecoration: 'none',
    color: '#333',
    fontSize: 14,
    transform: 'scale(0.5)',
  },
}));

function resizeIframe(obj: any) {
  obj.target.style.height =
    obj.target.ownerDocument.scrollingElement.offsetHeight + 'px';
}

interface IProcessoCreditoComponentProps {
  loading: boolean;
  sysParmList: any[];
  loadSysParm: any;
}

const ProcessoCreditoComponent = ({
  loading,
  sysParmList,
  loadSysParm,
}: IProcessoCreditoComponentProps) => {
  const classes = useStyles();

  /**
   * vao virar parametros do parametros de sistema
   */
  const NewKey = 'ANALISE_DE_CREDITO_PROCESSO_CREDITO_CONTEMPLACAO_URL';
  const watchDoc = 'ANALISE_DE_CREDITO_PROCESSO_CREDITO_DOCUMENTOS_URL';
  // useEffect(loadUserData, []);
  useEffect(loadSysParm, []);

  let [urlNewKey, setUrlNewKey] = useState<string | undefined>();
  let [watchDocUrl, setWatchDocUrl] = useState<string | undefined>();
  const [value, setValue] = React.useState(0);

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const urlPage = () => {
    const sysParm = sysParmList?.find((x) => x.codigo === NewKey);
    sysParm ? setUrlNewKey(sysParm.texto) : setUrlNewKey('Error');
  };

  useEffect(urlPage, []);

  function buscaAvancada(codigo: string) {
    const sysParm = sysParmList?.find((x) => x.codigo === codigo);
    if (sysParm) {
      setWatchDocUrl(sysParm.texto);
    } else {
      setWatchDocUrl('Error');
    }
  }

  return (
    <>
      <Box>
        <TitleCard>Processo de Crédito</TitleCard>
      </Box>
      <Box>
        <Paper className={classes.paperWrapA}>
          <Loading loading={loading} />
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Processo de Contemplação" {...a11yProps(0)} />
                <Tab label="Digitalização de Documentos" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              {urlNewKey !== 'Error' ? (
                <iframe
                  sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                  src={urlNewKey}
                  className={classes.frame}
                  onLoad={(e) => resizeIframe(e)}
                  name="frame"
                  title="Processo Contemplacao"
                ></iframe>
              ) : (
                <div>
                  <iframe
                    sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                    style={{ display: 'none' }}
                    src={urlNewKey}
                    className={classes.frame}
                    onLoad={(e) => resizeIframe(e)}
                    name="frame"
                    title="Processo Contemplacao"
                  ></iframe>
                  <Alert style={{ marginTop: 30 }} severity="warning">
                    Endereço não encontrado!
                  </Alert>
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="contained"
                  href="https://yamaha.watchdoc.com.br/"
                  color="primary"
                  target="_blank"
                >
                  Abrir WATCHDOC
                </Button>
              </Grid>
            </TabPanel>
          </div>
        </Paper>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.systemParameter.loading,
  sysParmList: state.systemParameter.sysParmList,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUserData: () => {
    // dispatch(getMyData())
  },
  loadSysParm: () => {
    dispatch(getSystemParm());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessoCreditoComponent);
