import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { useStyles } from "../style";

export const GridItem = (props: any) => {
  const classes = useStyles();

  return (
    <div
      className={classes.itemContainer}
      style={{ backgroundColor: props.backgroundColor }}
    >
      <div className={classes.itemIcon}>{props.avatar}</div>

      <Typography className={classes.cardTitle}>{props.title}</Typography>

      <Typography className={classes.cardDescription}>
        {props.description}
      </Typography>
    </div>
  );
};
