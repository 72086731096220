import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSystemParm } from '../../../redux/ducks/systemParameter';
import { Loading } from '../../../ui/Loading';
import { Alert } from '@material-ui/lab';
import { TitleCard } from '../../../base/TitleCard';

const useStyles = makeStyles((theme) => ({
  paperWrap: {
    margin: 20,
    overflow: 'hidden',
  },
  innerPadding: {
    padding: 5,
  },
  relatorio: {
    marginTop: 20,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperWrapA: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: '0px',
  },
  frame: {
    width: `100%`,
    height: `500px`,
    border: '0 solid transparent',
    borderRadius: '4px',
    marginTop: 30,
  },
}));

interface IBoletoAdesaoComponentProps {
  loading: boolean;
  sysParmList: any[];
  loadSysParm: any;
}

function resizeIframe(obj: any) {
  obj.target.style.height =
    obj.target.ownerDocument.scrollingElement.offsetHeight + 'px';
}

const BoletoAdesaoComponent = ({
  loading,
  sysParmList,
  loadSysParm,
}: IBoletoAdesaoComponentProps) => {
  const classes = useStyles();

  /**
   * vao virar parametros do parametros de sistema
   */
  const advancedCodigo = 'VENDAS_BOLETO_ADESAO_ADVANCED_URL';
  const actionCodigo = 'VENDAS_BOLETO_ADSAO_ACTION_URL';
  // useEffect(loadUserData, []);
  useEffect(loadSysParm, []);

  let [tipoRelatorio, setTipoRelatorio] = useState<string>('Grupo_Cota');
  const otherFields = [
    {
      name: 'ctl00$Conteudo$cbxTipoImpresso',
      value: 'P',
    },
    {
      name: 'ctl00$hdnID_Modulo',
      value: '',
    },
    {
      name: 'ctl00$Conteudo$hdn_SN_Boleto_Vencido',
      value: 'N',
    },
    {
      name: 'ctl00$Conteudo$hdn_ID_Forma_Recebimento',
      value: '',
    },
    {
      name: 'ctl00$Conteudo$btnLocalizar',
      value: 'Localizar',
    },
    {
      name: '__LASTFOCUS',
      value: '',
    },
    {
      name: '__EVENTTARGET',
      value: '',
    },
    {
      name: '__EVENTARGUMENT',
      value: '',
    },
    {
      name: '__VSTATE',
      value:
        tipoRelatorio === 'Grupo_Cota'
          ? 'H4sIAAAAAAAEAF2RUW/TMBDHqVuvK5oahLRoD1OoBOKNNstgzdoHNIq0TSCEyoDHKMldFgvHzuLr0vFt+Cx8MIjTTRVYsnT63f3vf2f/6TgHHe5F0UIrqrQ0S7xZiQo/a0Pv4vTHB7yLIrfL91OSvv9CFNfRmSSs4i+o8vgfTKhAFKhI8+EWX6AsHT4IguPpyfHJNHRc5j2+NYv30Xm1KjXf8dvjssxx3J7HUsi8va/Lj60w+o4Jf1vX9VhhnWpldEZ1XOE41cU4qSYbOsltqe+/bm4w+bRhQgGuxzkVElzGmAPuXma9+9+EEYnEHFo4bOFAKIWVLeaHSxRFWaExL58HwXSuR4Cj9nFi0lbzBMDtZMB4I3zWzZvm26jbZh8B29kgYH3H2vcd69K7wjXx4UJTPLpUt79/SQExANu91/eaTvc6K7RKjxu6k8iPfr5qF5qFYTiXmNHs6DQs1/NSG0FCq1mcGC1XhHPS5exNkwE2eJih23bl/OlZkYjmf9CudIWG0AD852nD6+1M8BfdAH4NIAIAAA=='
          : 'H4sIAAAAAAAEAFWQUUvDMBSFbbY4B2MRweKD1IHiY1eqbIIPMn0RFJE59bG0vbdrsE1mEun89Zq2juFD4PDx5Ry4Pw47cqgXRXdSGCULPcfPL67wWWpzG6cfD/gdRW6HHqamCIIzXi6jWWFQxS8o8vgfNiiAlyiMpMMtvsdixWg/DC+mk4vJ9IqBSzLG3K5HUsi8wev8sXGid0zoTVVVvsAqlULLzFSxQj+VpZ+ocUvHea0GwaV94fipZVwArv3clIXtJsQuDDLmEq/3xjVPCsyhgcMG9rkQqGqZHs+RlyuFWp+fhuH0Wo4AR80dYiPrP/sArkMcIF3784TYHtqEpZ1pU25Tp/F2gOxurL1N6G9Ch9Xb3QWuDT2YlQm3Z8J6boHaoIa6+c8F0mvjErZNv4f5NXanAQAA',
    },
    {
      name: '__VIEWSTATE',
      value: '',
    },
    {
      name: '__EVENTVALIDATION',
      value:
        tipoRelatorio === 'Grupo_Cota'
          ? '/wEdABKvVXD1oYELeveMr0vHCmYP3OVcUU8W753P13fN8zCqBssFfHfmnzrP0RGMMbdGj3oL7hOvF24I3vRsEEOoZPCzPHxL1lQkwghNeXKI72i2ZohNP9NUW0CoulQoSD3oSxiFB4yNQbsEHROYRvszFuq02xwgZEcKVWVBsHP8gLnLGOeBMxHeP+qNsWjoUXUe7tZAiCjNAXdI+/hnNKFe7r1fUbCm/6UKaJNZzk8BDw66cfUV1j5YPK0k7vdiodcNxubgIg0TDHe//+eyE4Oq7cBJvJhHK/mvPu10O67NRdCw3+Ie3UFHvG6/9HEcgBWRWOGDtQjGOiHAl9yrwpcyylOdSvZ6xYstw1VK7tm9KOXC82SLrCoQxLUlUpXKvRMEc+YGveMB5rxJqRXDvia2e4/Xg5zRjg=='
          : '/wEdABCvVXD1oYELeveMr0vHCmYP3OVcUU8W753P13fN8zCqBssFfHfmnzrP0RGMMbdGj3oL7hOvF24I3vRsEEOoZPCzPHxL1lQkwghNeXKI72i2ZohNP9NUW0CoulQoSD3oSxiFB4yNQbsEHROYRvszFuq0nHj0oTv+3EXRNPIG4RM8mVGwpv+lCmiTWc5PAQ8OunH1FdY+WDytJO73YqHXDcbm4CINEwx3v//nshODqu3ASbyYRyv5rz7tdDuuzUXQsN/iHt1BR7xuv/RxHIAVkVjhg7UIxjohwJfcq8KXMspTnUr2esWLLcNVSu7ZvSjlwvNki6wqEMS1JVKVyr0TBHPm6nv9rP0J5LBurAM6sChbLqXTMx0=',
    },
  ];

  let [grupo, setGrupo] = useState<string | null>();
  let [cota, setCota] = useState<string | null>();
  let [versao, setVersao] = useState<string | null>();
  let [contrato, setContrato] = useState<string | null>();
  let [advancedUrl, setAdvancedUrl] = useState<string | undefined>();
  let [actionUrl, setActionUrl] = useState('');
  let [frameBltAdesao, setFrameBltAdesao] = useState('');

  function buscaAvancada(codigo: string) {
    const sysParm = sysParmList?.find((x) => x.codigo === advancedCodigo);
    if (sysParm) {
      console.log(sysParm.texto);
      setAdvancedUrl(sysParm.texto);
      setFrameBltAdesao(sysParm.texto);
    } else {
      setFrameBltAdesao('Error');
    }
  }

  function setFrame(codigo: string) {
    const sysParm = sysParmList?.find((x) => x.codigo === actionCodigo);
    if (sysParm) {
      console.log(sysParm.texto);
      setActionUrl(sysParm.texto);
      setFrameBltAdesao(sysParm.texto);
    } else {
      setFrameBltAdesao('Error');
    }
  }
  useEffect(() => setFrame(actionCodigo), []);
  return (
    <>
      <Box>
        <TitleCard>Boleto Adesão</TitleCard>
      </Box>

      <Box>
        <Paper className={classes.paperWrapA}>
          <Typography variant="h6" gutterBottom>
            Tipo de Busca
          </Typography>
          <Loading loading={loading} />

          <Grid>
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={12}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-label" required>
                      Tipo Relatório
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={tipoRelatorio}
                      onChange={(e) =>
                        setTipoRelatorio(e.target.value as string)
                      }
                      label="Tipo Pessoa *"
                    >
                      <MenuItem value={'Grupo_Cota'}>Grupo/Cota</MenuItem>
                      <MenuItem value={'NO_Contrato'}>Contrato</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider> */}
            <div className={classes.innerPadding}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                  {/* {tipoRelatorio === 'Grupo_Cota' && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          required
                          name="ctl00$Conteudo$edtGrupo"
                          id="grupo"
                          label="Grupo"
                          fullWidth
                          autoComplete="grupo"
                          value={grupo}
                          onChange={(e) => setGrupo(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          required
                          name="ctl00$Conteudo$edtCota"
                          id="cota"
                          label="Cota"
                          fullWidth
                          autoComplete="cota"
                          value={cota}
                          onChange={(e) => setCota(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          required
                          name="ctl00$Conteudo$edtVersao"
                          id="versao"
                          label="Versão"
                          fullWidth
                          autoComplete="versao"
                          value={versao}
                          onChange={(e) => setVersao(e.target.value)}
                        />
                      </Grid> */}
                  <form method="post" action={actionUrl} target="frame">
                    <Grid item xs={12} style={{ padding: '5px' }}>
                      <Button type="submit" variant="contained" color="primary">
                        Busca Simples
                      </Button>
                    </Grid>
                    {/* <div style={{ display: 'none' }}>
                        <input />
                        <input
                          name="ctl00$Conteudo$rblTipoPesquisa"
                          value={tipoRelatorio}
                        />
                        {otherFields.map((x) => (
                          <input name={x.name} value={x.value} />
                        ))}
                      </div> */}
                    {/* )} */}
                    {/* {tipoRelatorio === 'NO_Contrato' && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          name="ctl00$Conteudo$edtNO_Contrato"
                          id="contarto"
                          label="Contrato"
                          fullWidth
                          autoComplete="Contrato"
                          value={contrato}
                          onChange={(e) => setContrato(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Localizar
                        </Button>
                      </Grid>
                      <div style={{ display: 'none' }}>
                        <input
                          name="ctl00$Conteudo$rblTipoPesquisa"
                          value={tipoRelatorio}
                        />
                        {otherFields.map((x) => (
                          <input name={x.name} value={x.value} />
                        ))}
                      </div>
                    </Grid>
                  )} */}
                  </form>
                  <form method="post" action={advancedUrl} target="frame">
                    <Grid item xs={12} style={{ padding: '5px' }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => buscaAvancada(advancedCodigo)}
                      >
                        Busca Avançada
                      </Button>
                    </Grid>
                  </form>
                </Grid>
                {frameBltAdesao !== 'Error' ? (
                  <iframe
                    src={frameBltAdesao}
                    sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                    className={classes.frame}
                    style={{ display: 'block' }}
                    name="frame"
                    onLoad={(e) => resizeIframe(e)}
                    title="Boleto Adesao"
                  ></iframe>
                ) : (
                  <div>
                    <iframe
                      src={frameBltAdesao}
                      className={classes.frame}
                      style={{ display: 'block' }}
                      name="frame"
                      sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                      onLoad={(e) => resizeIframe(e)}
                      title="Boleto Adesao"
                    ></iframe>
                    <Alert style={{ marginTop: 30 }} severity="warning">
                      Endereco não encontrado!
                    </Alert>
                  </div>
                )}
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.systemParameter.loading,
  sysParmList: state.systemParameter.sysParmList,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUserData: () => {
    // dispatch(getMyData())
  },
  loadSysParm: () => {
    dispatch(getSystemParm());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoletoAdesaoComponent);
