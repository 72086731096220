import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FilterUsersRequst } from '../../Models/FilterUsersRequest';
import {
  getPerfilApp,
  getPontoVendasTitle,
  getUnidadeNegocio,
  getUsersData,
  deleteUser,
} from '../../redux/ducks/user';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { Box, Button, IconButton, Paper } from '@material-ui/core';
import { EditUserRequest } from '../../Models/EditUserRequest';
import { Loading } from '../../ui/Loading';
import { UserDelete } from './UserDelete';
import { TitleCard } from '../../base/TitleCard';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { ThemePagination } from '../../base/Pagination';
import api from '../../service/api';
import { useStyles } from './style';
import { maskCnpj, maskCpf } from '../../utils/masks';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HomeIcon from '@material-ui/icons/Home';
import PrintIcon from '@material-ui/icons/Print';

interface IEditUserComponentProps {
  userList: any;
  businessUnitList: any;
  loadUsersData: any;
  loadPontoVenda: any;
  loadBusinessUnit: any;
  loadGetPerfilUsuario: any;
  appProfileList: any;
  pontoVendaTitleList: any;
  loadUserDelete: (id: any, filter: FilterUsersRequst) => void;
  loading: boolean;
  updateEditUserRequest: (
    user: EditUserRequest,
    filter: FilterUsersRequst
  ) => void;
}

const DataTable = ({
  loadUsersData,
  userList,
  loadGetPerfilUsuario,
  appProfileList,
  loadUserDelete,
  loading,
  updateEditUserRequest,
  loadBusinessUnit,
  businessUnitList,
  loadPontoVenda,
  pontoVendaTitleList,
}: IEditUserComponentProps) => {
  const classes = useStyles();

  const [index, setIndex] = useState<number>(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [usersCount, setUsersCount] = useState(0);
  const [size] = useState<number>(10);
  const [orderBy, setOrderBy] = useState('Id');
  const [orderType, setOrderType] = useState<'asc' | 'desc'>('asc');
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [search, setSearch] = useState<string>('');
  const [rowid, setRowId] = useState(null);
  const [page, setPage] = React.useState(1);

  let loadUsers = () => {
    var filter: FilterUsersRequst;
    filter = {
      index: index,
      size: size,
      orderBy: orderBy,
      orderType: orderType,
      search: search,
    };
    loadUsersData(filter);
  };

  function inputSearch(inputSearch: string) {
    setSearch(inputSearch);
    setPage(1);
    setIndex(0);
  }

  const filterPontoVenda = () => {
    loadPontoVenda();
  };

  useEffect(() => {
    const loadUsersCount = async () => {
      const { data: numberOfUsers } = await api.get('Users/UsersCount', {
        params: {
          search: search,
        },
      });
      setUsersCount(numberOfUsers);

      const pages = Math.ceil(numberOfUsers / 10);
      setIndex(index >= pages * 10 ? index - 10 : index);
      setNumberOfPages(pages);
    };

    loadUsersCount();
  }, [userList, search, setUsersCount]);

  useEffect(loadUsers, [search, page, orderBy, orderType, numberOfPages]);
  useEffect(filterPontoVenda, []);
  useEffect(loadBusinessUnit, []);
  useEffect(loadGetPerfilUsuario, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    let indexPage = (newPage - 1) * size;
    setIndex(indexPage);
    setPage(newPage);
  };

  function sortTable(orderByAtual: string) {
    let orderTypeAtual = orderType;

    if (orderTypeAtual === 'asc') {
      setOrderType('desc');
      orderTypeAtual = 'desc';
    } else if (orderTypeAtual === 'desc') {
      setOrderType('asc');
      orderTypeAtual = 'asc';
    }

    setOrderBy(orderByAtual);

    loadUsersData({
      index: index,
      size: size,
      orderBy: orderByAtual,
      orderType: orderTypeAtual,
      search: search ?? null,
    });

    return 0;
  }

  const handleExportXLS = () => {
    api
      .get('/Users/PagedUserQuery', {
        params: {
          index: 0,
          size: usersCount,
          search,
          orderBy,
          orderType,
          report: true,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'usuarios.xlsx');
        document.body.appendChild(link);
        link.click();
      });
  };

  function handleOpenDeleteModal(nome: any) {
    setRowId(nome.id);
    setOpenModalDelete(true);
  }

  function handleCloseDeleteModal() {
    setOpenModalDelete(false);
  }

  function handleDelete(id: any) {
    setOpenModalDelete(false);
    var filter = {
      index,
      size,
      search,
      orderBy,
      orderType,
    };

    loadUserDelete(id, filter);
  }

  return (
    <Box>
      <TitleCard breadcrumbsText="Usuários" breadcrumbsIcon={<HomeIcon />}>
        <FileCopyIcon /> Usuários
      </TitleCard>

      <Box style={{ margin: '32px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>Total ({usersCount})</div>

          <div>
            <Link
              to={{
                pathname: '/Users/Create',
                state: {
                  pontoVendaTitleList: pontoVendaTitleList,
                  appProfileList: appProfileList,
                  businessUnitList: businessUnitList,
                },
              }}
            >
              <Button variant="contained" color="primary">
                CADASTRAR
              </Button>
            </Link>
            <IconButton
              onClick={handleExportXLS}
              color="primary"
              style={{
                width: '40px',
                height: '40px',
                color: '#707070',
                margin: '0px 6px',
              }}
            >
              <PrintIcon />
            </IconButton>
          </div>
        </div>

        <Paper>
          <div className={classes.grow}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                type="text"
                placeholder="Pesquise aqui…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                style={{ height: '50px' }}
                onChange={(e) => inputSearch(e.target.value)}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}></div>
          </div>
          <Loading loading={loading} />

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableTitleCell} align="left">
                    E-Mail
                    <TableSortLabel
                      active={orderBy === 'Email'}
                      direction={orderBy === 'Email' ? orderType : 'asc'}
                      onClick={() => sortTable('Email')}
                    >
                      {orderBy === 'Email' ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.tableTitleCell} align="left">
                    CPF/CNPJ
                    <TableSortLabel
                      active={orderBy === 'Cpf'}
                      direction={orderBy === 'Cpf' ? orderType : 'asc'}
                      onClick={() => sortTable('Cpf')}
                    >
                      {orderBy === 'Cpf' ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.tableTitleCell} align="left">
                    Nome
                    <TableSortLabel
                      active={orderBy === 'Nome'}
                      direction={orderBy === 'Nome' ? orderType : 'asc'}
                      onClick={() => sortTable('Nome')}
                    >
                      {orderBy === 'Nome' ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="left" className={classes.tableTitleCell}>
                    Data Cadastro
                    <TableSortLabel
                      active={orderBy === 'DataCadastro'}
                      direction={orderBy === 'DataCadastro' ? orderType : 'asc'}
                      onClick={() => sortTable('DataCadastro')}
                    >
                      {orderBy === 'DataCadastro' ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.tableTitleCell} align="left">
                    Perfil APP
                    <TableSortLabel
                      active={orderBy === 'Perfil'}
                      direction={orderBy === 'Perfil' ? orderType : 'asc'}
                      onClick={() => sortTable('Perfil')}
                    >
                      {orderBy === 'Perfil' ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.tableTitleCell} align="left">
                    Ativo
                    <TableSortLabel
                      active={orderBy === 'Ativo'}
                      direction={orderBy === 'Ativo' ? orderType : 'asc'}
                      onClick={() => sortTable('Ativo')}
                    >
                      {orderBy === 'Ativo' ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList?.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">
                      {row.cpf ? maskCpf(row.cpf) : ''}
                      {row.cnpj ? maskCnpj(row.cnpj) : ''}
                    </TableCell>

                    <TableCell align="left" component="th" scope="row">
                      {row.nome}
                    </TableCell>

                    <TableCell align="left">
                      {row.dataCadastro
                        ? format(parseISO(row.dataCadastro), 'dd/MM/yy')
                        : ''}
                    </TableCell>

                    <TableCell align="left">
                      {row.perfilApp &&
                        `${row.perfilApp.nome} - ${row.perfilApp.tipoPerfil}`}
                    </TableCell>

                    <TableCell align="left">{row.ativo}</TableCell>
                    <TableCell>
                      <div className={classes.cellButtom}>
                        <Link
                          to={{
                            pathname: '/Users/Edit',
                            state: {
                              userRegistration: row,
                              pontoVendaTitleList: pontoVendaTitleList,
                              appProfileList: appProfileList,
                              businessUnitList: businessUnitList,
                            },
                          }}
                        >
                          <Button
                            style={{
                              color: '#388e3c',
                              borderColor: '#388e3c',
                            }}
                            title="Editar"
                            variant="text"
                            className={classes.button}
                          >
                            <EditRoundedIcon className={classes.icon} />
                          </Button>
                        </Link>

                        <Button
                          style={{
                            color: '#b20303',
                            borderColor: '#b20303',
                          }}
                          title="Excluir"
                          variant="text"
                          className={classes.button}
                          onClick={() => handleOpenDeleteModal(row)}
                        >
                          <DeleteIcon className={classes.icon} />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <UserDelete
            openDelete={openModalDelete}
            deleteParameter={() => handleDelete(rowid)}
            handleCloseDelete={handleCloseDeleteModal}
          />
        </Paper>
        <ThemePagination
          currentPage={page}
          handlePageChange={handleChangePage}
          marginLeft="0px"
          pagesCount={numberOfPages}
        />
      </Box>
    </Box>
  );
};
const mapStateToProps = (state: any) => ({
  userData: state.user.userData,
  loading: state.user.loading,
  userList: state.user.userList,
  search: state.user.search,
  index: state.user.index,
  size: state.user.size,
  orderBy: state.user.orderBy,
  orderType: state.user.orderType,
  businessUnitList: state.user.businessUnitList,
  appProfileList: state.user.appProfileList,
  pontoVendaTitleList: state.user.pontoVendaTitleList,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUsersData: (filter: FilterUsersRequst) => {
    dispatch(getUsersData(filter));
  },
  loadUserDelete: (id: any, filter: FilterUsersRequst) => {
    dispatch(deleteUser(id)).then(() => dispatch(getUsersData(filter)));
  },
  loadBusinessUnit: () => {
    dispatch(getUnidadeNegocio());
  },
  loadGetPerfilUsuario: () => {
    dispatch(getPerfilApp());
  },
  loadPontoVenda: () => {
    dispatch(getPontoVendasTitle());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
