import { createStyles, Divider, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React, { useRef } from 'react'
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      maxWidth: "1100px",
      width: "100%",
      maxHeight: "800px",
      height: "100%",
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
      overflow: "hidden",
      display: "flex",
      flexDirection: "column"
    },
    scrollEl: {
        flexGrow: 1,
        overflow: "scroll"
    },
    container: {
      padding: theme.spacing(4),
      overflow: "scroll"
    },
    head: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    }
  }),
);

export const ViewLink = ({modalData, handleClose}: any) => {
    const modalStyles = useStyles();
    const inputRef = useRef(null);

    return (
        <Paper className={modalStyles.paper} ref={inputRef}>
            <div className={modalStyles.head}>
                <Typography >
                    Emails de Ativação enviados
                </Typography>

                <CancelIcon
                    style={{ color: "#ff0000", cursor: "pointer" }}
                    fontSize="large"
                    onClick={handleClose}
                />
            </div>
            <div className={modalStyles.scrollEl}>
                {modalData.envioEmail.map((el: any, i: number) => (
                    <div className={modalStyles.container} key={i}>
                        <div dangerouslySetInnerHTML={{__html: el.conteudo}} />
                        <Divider />
                    </div>
                ))}
            </div>
        </Paper>
    )
}
