import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack'
import React from 'react'

interface IProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
  props.setUseSnackbarRef(useSnackbar())
  return null
}

let useSnackbarRef: WithSnackbarProps
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp
}

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
}

export default {
  success(msg: string) {
    this.toast(msg, 'success')
  },
  warning(msg: string) {
    this.toast(msg, 'warning')
  },
  info(msg: string) {
    this.toast(msg, 'info')
  },
  error(msg: string) {
    this.toast(msg, 'error')
  },
  toast(msg: string, variant: VariantType = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, { variant })
  }
}

export const interpolateColors = (
  initialColor: string, 
  finalColor: string, 
  numberOfColors: number
  ) => {
    const initialColors = [
      parseInt("0x" + initialColor.substring(1, 3)),
      parseInt("0x" + initialColor.substring(3, 5)),
      parseInt("0x" + initialColor.substring(5, 7)),
    ]

    const finalColors = [
      parseInt("0x" + finalColor.substring(1, 3)),
      parseInt("0x" + finalColor.substring(3, 5)),
      parseInt("0x" + finalColor.substring(5, 7)),
    ]

    const finalArr = []

    const delta = finalColors.map((x, i) => (finalColors[i] - initialColors[i]))

    //console.log(delta)
    
    for (let i = 0; i < numberOfColors; i++) {
      finalArr.push(
        initialColors.map((x, i) => Math.round(x + ((delta[i]/numberOfColors) * i)))
      )
    }

    //console.log(finalArr);

    let w = finalArr.map(x => (
      "#" + x.map(y => y.toString(16).padStart(2, "0")).join("")
    ))

    //console.log(w)

    return w
}