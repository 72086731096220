import { combineReducers } from "redux";
import Axios from "axios";
import { createStore, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import axiosMiddleware from "redux-axios-middleware";
import { throttle } from "lodash";
import * as reducers from "./ducks";
import snackbarUtils from "./../utils/toast";

const rootReducer = combineReducers(reducers);

const client = Axios.create({
  //all axios can be used, shown in axios documentation
  baseURL: process.env.REACT_APP_API,
  responseType: "json",
});

export const mapErrorsToString = (error: any) => {
  try {
    if (error?.response?.data?.errors) {
      let fieldsWithError = Object.values(error.response.data.errors);
      let errors = fieldsWithError.map((x: any) => x.join(", "));
      return errors.join(", ");
    } else if (error?.response?.data?.message) {
      return error.response.data.message;
    } else if (error?.response?.status === 401) {
      return "Usuário não autorizado";
    } else if (error.message) {
      return error.message;
    } else {
      return "Erro Desconhecido";
    }
  } catch (e) {
    console.error(e);
    return "Falha ao mapear o erro";
  }
};

const middlewareConfig = {
  interceptors: {
    request: [
      {
        success: function (
          { getState, dispatch, getSourceAction }: any,
          req: any
        ) {
          if (getState().user?.userData?.jwtToken) {
            req.headers["Authorization"] = `Bearer ${
              getState().user.userData.jwtToken
            }`;
          }

          return req;
        },
      },
    ],
    response: [
      {
        success: function (
          { getState, dispatch, getSourceAction }: any,
          req: any
        ) {
          if (req?.data?.message) {
            snackbarUtils.success(req.data.message);
          }

          return req;
        },
        error: function (
          { getState, dispatch, getSourceAction }: any,
          error: any
        ) {
          if (error.response) {
            let message = mapErrorsToString(error);

            if (error.response.status >= 400 && error.response.status < 500) {
              // Erro 400 === erro do cliente
              snackbarUtils.warning(message);
            } else {
              snackbarUtils.error(message);
            }
          } else {
            snackbarUtils.error(
              "Falha ao se conectar com o servidor. Você está conectado a internet?"
            );
          }

          return Promise.reject(error);
        },
      },
    ],
  },
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    
    let loadedState = JSON.parse(serializedState);
    if (loadedState && loadedState.user && loadedState.user.userData &&
        loadedState.user.userData.email === null && loadedState.user.userData.id === null) {
      loadedState.user.loading = false;
    }

    return loadedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }
};

const persistedState = loadState();

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: [axiosMiddleware(client, middlewareConfig)], //second parameter options can optionally contain onSuccess, onError, onComplete, successSuffix, errorSuffix]
//   preloadedState: persistedState,
//   devTools: true
// });

const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(
    axiosMiddleware(client, middlewareConfig) //second parameter options can optionally contain onSuccess, onError, onComplete, successSuffix, errorSuffix
  )
);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

export default store;
