import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Loading } from '../../ui/Loading';
import { getSystemParm } from '../../redux/ducks/systemParameter';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TitleCard } from '../../base/TitleCard';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperWrapA: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: '0px',
  },
  frame: {
    width: `100%`,
    height: `500px`,
    border: '0 solid transparent',
    borderRadius: '4px',
    marginTop: 30,
  },
}));

function resizeIframe(obj: any) {
  obj.target.style.height =
    obj.target.ownerDocument.scrollingElement.offsetHeight + 'px';
}

interface IOfertaLanceComponentProps {
  loading: boolean;
  sysParmList: any[];
  loadSysParm: any;
}

const OfertaLanceComponent = ({
  loading,
  sysParmList,
  loadSysParm,
}: IOfertaLanceComponentProps) => {
  const classes = useStyles();
  const otherFields = [
    {
      name: 'ctl00$hdnID_Modulo',
      value: '',
    },
    {
      name: 'ctl00$Conteudo$btnLocalizar',
      value: 'Localizar',
    },
    {
      name: '__LASTFOCUS',
      value: '',
    },
    {
      name: '__EVENTTARGET',
      value: '',
    },
    {
      name: '__EVENTARGUMENT',
      value: '',
    },
    {
      name: '__VSTATE',
      value:
        'H4sIAAAAAAAEAFWMQYvCMBSE6VuDKwqBBcue1INXoYJQe1SPLigqXkNsXm0wTdnkCe6vX5uysHj75pthfiP+GbGREJvakquNP+D3XTvc157WMr9t8UeIGNgwJ5MkU11dxcoQOnlEW8oXTWiVrtBSzVl/Nl+k6TJL0izjKoaC8xhGkKuiCQCNGhTBdM/a64vBUrUSIAq2p61FV1Jl2HgjbY5Gtr8ThZNdgY5koK/QKHhrrzsnfBD7WFUX3Swx9Cf0hF4pYGExjsoGu//4/kdPjCiBSAIBAAA=',
    },
    {
      name: '__VIEWSTATE',
      value: '',
    },
    {
      name: '__EVENTVALIDATION',
      value:
        '/wEdAAqvVXD1oYELeveMr0vHCmYP3OVcUU8W753P13fN8zCqBtscIGRHClVlQbBz/IC5yxjngTMR3j/qjbFo6FF1Hu7WQIgozQF3SPv4ZzShXu69X1Gwpv+lCmiTWc5PAQ8OunH1FdY+WDytJO73YqHXDcbmwHwu694l+cuA52jajHBrzIO1CMY6IcCX3KvClzLKU51K9nrFiy3DVUru2b0o5cLztfmNflsyaluIk4cKYArQRWmWWJM=',
    },
  ];
  /**
   * vao virar parametros do parametros de sistema
   */
  const actionCodigo = 'LANCES_OFERTA_LANCE_URL';
  // useEffect(loadUserData, []);
  useEffect(loadSysParm, []);

  let [actionUrl, setActionUrl] = useState<string | undefined>();
  let [grupo, setGrupo] = useState<string | null>();
  let [cota, setCota] = useState<string | null>();
  let [versao, setVersao] = useState<string | null>();

  function LoadData(codigo: string) {
    const sysParm = sysParmList?.find((x) => x.codigo === codigo);
    sysParm ? setActionUrl(sysParm.texto) : setActionUrl('Error');
  }

  useEffect(() => LoadData(actionCodigo), []);

  return (
    <>
      <Box>
        <TitleCard>Oferta de Lance</TitleCard>
      </Box>
      <Box>
        <Paper className={classes.paperWrapA}>
          <Loading loading={loading} />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <form method="post" action={actionUrl} target="frame">
              <Grid container spacing={3}>
                {/* <Grid item xs={12} md={3}>
                  <TextField
                    required
                    name="ctl00$Conteudo$edtGrupo"
                    id="grupo"
                    label="Grupo"
                    fullWidth
                    autoComplete="grupo"
                    value={grupo}
                    onChange={(e) => setGrupo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    name="ctl00$Conteudo$edtCota"
                    id="cota"
                    label="Cota"
                    fullWidth
                    autoComplete="cota"
                    value={cota}
                    onChange={(e) => setCota(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    name="ctl00$Conteudo$edtVersao"
                    id="versao"
                    label="Versão"
                    fullWidth
                    autoComplete="versao"
                    value={versao}
                    onChange={(e) => setVersao(e.target.value)}
                  />
                </Grid> 
                <Grid item xs={12} md={3}>
                  <Button type="submit" variant="contained" color="primary">
                    Busca Simples
                  </Button>
                </Grid>
                <div style={{ display: 'none' }}>
                  <input />
                  {otherFields.map((x) => (
                    <input name={x.name} value={x.value} />
                  ))}
                </div> */}
              </Grid>
            </form>

            {actionUrl !== 'Error' ? (
              <iframe
                sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                src={actionUrl}
                style={{ display: 'block' }}
                className={classes.frame}
                name="frame"
                title="Oferta Lance"
              ></iframe>
            ) : (
              <div>
                <iframe
                  sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                  style={{ display: 'block' }}
                  src={actionUrl}
                  onLoad={(e) => resizeIframe(e)}
                  className={classes.frame}
                  name="frame"
                  title="Oferta Lance"
                ></iframe>
                <Alert style={{ marginTop: 30 }} severity="warning">
                  Endereço não encontrado!
                </Alert>
              </div>
            )}
          </MuiPickersUtilsProvider>
        </Paper>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.systemParameter.loading,
  sysParmList: state.systemParameter.sysParmList,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUserData: () => {
    // dispatch(getMyData())
  },
  loadSysParm: () => {
    dispatch(getSystemParm());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfertaLanceComponent);
