import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'

export const GrupoTelaDelete = ({openDelete, deleteParameter, handleCloseDelete}: any) => {
  return (
    <Dialog
      open={openDelete}
      onClose={handleCloseDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deseja mesmo excluir esse Grupo de Telas?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Essa ação não pode ser desfeita.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDelete} color="primary" autoFocus>
          Cancelar
        </Button>
        <Button onClick={deleteParameter} color="primary" >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}
