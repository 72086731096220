import {
  AppBar,
  Box,
  Button,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { connect } from "react-redux";
import { TitleCard } from "../../base/TitleCard";
import {
  getDownloadTabelaPreco,
  getTabelaPreco,
  postTabelaPreco,
  getTabelaPrecoPaginated,
  DeleteTabela
} from "../../redux/ducks/tabelaDePrecos";
import AddIcon from "@material-ui/icons/Add";
import CustomFab from "../../ui/CustomFab";
import { TabelaDePrecoCreate } from "./TabelaDePrecoCreate";
import { TabelaDePrecoRequest } from "../../Models/TabelaDePrecoRequest";
import { ThemePagination } from "../../base/Pagination";
import api from "../../service/api";
import DeleteIcon from "@material-ui/icons/Delete";
import { Loading } from "../../ui/Loading";
import { format, parseISO } from "date-fns";
import { isJSDocVariadicType } from "typescript";
import { TabelaPrecoDelete } from "./TabelaPrecoDelete";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function fileS3(url: string) {
  window.open(url);
}

const DataTable = ({
  tipoProdutoPontoVendaList,
  uploadTabelaPreco,
  loading,
  idPontoVenda,
  listas,
  getTabelasPreco,
  downloadTabelaPreco,
  listaTipo,
  deleteTabelaPreco
}: any) => {
  const [loadings, setLoadings] = useState(false);
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [idTabelaDelete, setIdTabelaDelete] = useState(0)
  const [orderBy, setOrderBy] = useState("Id");
  const [orderType, setOrderType] = useState<"asc" | "desc">("asc");
  const [tipoAtual, setTipoAtual] = useState(
    tipoProdutoPontoVendaList[0].tipoProduto.sigla
  );
  const [lista, setLista] = useState(listas);
  const [openCadastro, setOpenCadastro] = React.useState(false);
  const handleOpenCadastro = () => setOpenCadastro(true);
  const handleCloseCadastro = () => setOpenCadastro(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const handleCloseModalDelete = () => setOpenModalDelete(false);

  const handleOpenModalDelete = (id: number) => {
    setIdTabelaDelete(id)
    setOpenModalDelete(true);
  }
  const handleChangeTab = (event: any, newValue: any) => {
    setValue(newValue);
    setTipoAtual(tipoProdutoPontoVendaList[newValue].tipoProduto.sigla);
    setOrderBy("Id");
    setOrderType("asc");
    setPage(1);
  };

  const a11yProps = (index: any) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  };

  const buscarNaApiSorted = async () => {
    setLoadings(true);
    const { data: tableData } = await api.get(
      "/TabelaPreco/GetByTipo",
      {
        params: {
          index: page - 1,
          size: 10,
          orderBy: orderBy,
          orderType: orderType,
          tipo: tipoAtual,
        },
      }
    );
    lista[tipoAtual] = tableData;
    setLoadings(false);
    setLista(lista);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const BuscaNaApi = () => {
    buscarNaApiSorted();
  };
  const ReloadOnUpload = () => {
    var novalista = { ...lista };
    novalista[tipoAtual] = listaTipo
    setLista({ ...novalista })
  };

  const LoadData = () => {
    getTabelasPreco(idPontoVenda);
  };

  function onDownload(row: any) {
    downloadTabelaPreco(row.id);
  }

  function onDelete() {
    deleteTabelaPreco(idTabelaDelete, tipoAtual, page - 1, 10, orderBy, orderType);
    handleCloseModalDelete();
  }
  useEffect(LoadData, []);
  useEffect(BuscaNaApi, [page, orderBy, orderType, value]);
  useEffect(ReloadOnUpload, [listaTipo]);

  return (
    <>
      <Box>
        <TitleCard>Tabelas de Preço</TitleCard>
      </Box>
      <Box>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {tipoProdutoPontoVendaList?.map((row: any, index: number) => (
              <Tab label={row.tipoProduto.nome} {...a11yProps(index)} />
            ))}
          </Tabs>
        </AppBar>
      </Box>
      <Box>
        {tipoProdutoPontoVendaList?.map((row: any, index: number) => (
          <TabPanel value={value} index={index}>
            <Paper>
              <Loading loading={loadings||loading}></Loading>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome Arquivo</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lista[row.tipoProduto.sigla] &&
                      lista[row.tipoProduto.sigla].tabelaPreco?.map(
                        (row: TabelaDePrecoRequest) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">{row.titulo}</TableCell>
                            <TableCell align="left">
                              {format(
                                parseISO(row.data ?? "01-01-0001"),
                                "dd/MM/yyyy"
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                color="secondary"
                                title="download"
                                variant="text"
                                onClick={() => onDownload(row)}
                              >
                                <GetAppIcon />
                              </Button>
                              <Button
                                color="secondary"
                                title="delete"
                                variant="text"
                                onClick={() => handleOpenModalDelete(row.id)}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            {lista[row.tipoProduto.sigla] && (
              <ThemePagination
                marginLeft="0px"
                pagesCount={Math.ceil(lista[row.tipoProduto.sigla].total / 10)}
                currentPage={page}
                handlePageChange={handleChangePage}
              />
            )}
          </TabPanel>
        ))}
      </Box>
      <CustomFab label="Novo" icon={<AddIcon />} onClick={handleOpenCadastro} />
      <TabelaDePrecoCreate
        openCadastro={openCadastro}
        handleCloseCadastro={handleCloseCadastro}
        loading={loading}
        uploadTabelaPreco={uploadTabelaPreco}
        listaTipos={tipoProdutoPontoVendaList}
        index={page - 1}
        size={10}
        orderBy={orderBy}
        orderType={orderType}
      />
      <TabelaPrecoDelete
        openDelete={openModalDelete}
        handleCloseDelete={handleCloseModalDelete}
        loading={loading}
        deleteTabelaPreco={onDelete}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  downloadUrl: state.tabelaDePrecos.downloadUrl,
  loading: state.tabelaDePrecos.loading,
  request: state.tabelaDePrecos.request,
  tipoProdutoPontoVendaList:
    state.user.userData.pontoVenda.tipoProdutoPontoVenda,
  idPontoVenda: state.user.userData.idPontoVenda,
  listas: state.tabelaDePrecos.listas,
  listaTipo: state.tabelaDePrecos.listaTipo,
});
const mapDispatchToProps = (dispatch: any) => ({
  uploadTabelaPreco: (file: any, titulo: string, tipo: string, index: any, size: any, orderBy: any, orderType: any) => {
    dispatch(postTabelaPreco(file, titulo, tipo)).then(() => {
      dispatch(getTabelaPrecoPaginated({ index, size, orderBy, orderType, tipo }))
    });
  },
  downloadTabelaPreco: (id: number) => {
    dispatch(getDownloadTabelaPreco(id)).then((res: any) =>
      fileS3(res.payload.data.url)
    );
  },
  getTabelasPreco: (idPontoVenda: number) => {
    dispatch(getTabelaPreco(idPontoVenda));
  },
  deleteTabelaPreco: (id: any, tipo: string, index: any, size: any, orderBy: any, orderType: any) => {
    dispatch(DeleteTabela(id)).then(() => {
      dispatch(getTabelaPrecoPaginated({ index, size, orderBy, orderType, tipo }))
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
