import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  PontoVendaRequest,
  TipoProdutoPontoVendaRequest,
} from "../../Models/PontoVendaRequest";
import ListSelect from "../../ui/ListSelect";
import { maskCnpj } from "../../utils/masks";
import { validarCNPJ } from "../../utils/utils";
import { Pages } from "@material-ui/icons";
import { Loading } from "../../ui/Loading";
import { Alert } from "@material-ui/lab";
import api from "../../service/api";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    overflowY: "auto",
    width: "100%",
    maxWidth: "800px",
    height: "100%",
    maxHeight: "800px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(2),
  },
  alert: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignItems: "center",
  },
  head: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    alignItems: "rigth",
    justifyContent: "flex-end",
  },
  foot: {
    padding: theme.spacing(3, 0, 0, 0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  input: {
    margin: "6px",
  },
  cancelIcon: {
    color: "#ff0000",
    cursor: "pointer",
  },
}));

export const PontoVendaCreate = ({
  openCadastro,
  handleCloseCadastro,
  tipoProdutoList,
}: any) => {
  let [cnpj, setCnpj] = useState<string>("");
  let [classificacao, setClassificacao] = useState<string>("");
  let [codigo, setCodigo] = useState<string>("");
  let [grupoEconomico, setGrupoEconomico] = useState<string>("");
  let [nome, setNome] = useState<string>("");
  let [tipo, setTipo] = useState<string>("");
  let [percTreinamento, setPercTreinamento] = useState<number | null>(0.0);
  let [tipoProduto, setTipoProduto] = React.useState<any[]>([]);
  let [bancoAtivo, setBancoAtivo] = useState<boolean>(false);
  let [errorAlert, setErrorAlert] = useState(true);
  let [msgError, setMsgError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const options = tipoProdutoList.map((x: any) => ({
    title: x.nome,
    value: x.id,
  }));

  const handlePost = async () => {
    try {
      let pontoVenda: PontoVendaRequest = {
        id: null,
        cnpj: cnpj,
        classificacao: classificacao,
        codigo: codigo,
        grupoEconomico: grupoEconomico,
        nome: nome,
        visualizaPortal: "",
        tipo: tipo,
        bancoAtivo: bancoAtivo,
        percTreinamento: percTreinamento,
        tipoProdutoPontoVendaRequest: tipoProduto.map(
          (x: any) =>
            ({
              IdTipoProduto: x.value,
            } as TipoProdutoPontoVendaRequest)
        ),
      };
      if (
        codigo === "" ||
        cnpj === "" ||
        grupoEconomico === "" ||
        classificacao === "" ||
        tipo === "" ||
        nome === ""
      ) {
        setMsgError("Campo com * é de preenchimento obrigatório");
        setErrorAlert(false);
      } else {
        if (validarCNPJ(cnpj)) {
          setLoading(true);
          try {
            api.post("/PontoVendas", pontoVenda).then((response) => {
              if (response.status === 200) {
                setCnpj("");
                setNome("");
                setClassificacao("");
                setCodigo("");
                setGrupoEconomico("");
                setTipo("");
                setPercTreinamento(0);
                setCodigo("");
                setTipoProduto([]);
                setBancoAtivo(false);
                setErrorAlert(true);
              }
              handleCloseCadastro();
              setLoading(false);
            });
          } catch (error) {
            setLoading(false);
          }

          setLoading(false);
        } else {
          setMsgError("O CNPJ inserido é inválido");
          setErrorAlert(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBancoAtivo(event.target.checked);
  };

  useEffect(() => {
    setCnpj(maskCnpj(cnpj));
  }, [cnpj]);

  return (
    <Modal
      open={openCadastro}
      onClose={handleCloseCadastro}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.paperWrap}>
          <div className={classes.head}>
            <Typography variant="h6">Cadastro de Ponto de Venda</Typography>
            <CancelIcon
              fontSize="large"
              onClick={handleCloseCadastro}
              className={classes.cancelIcon}
            />
          </div>
          <Loading loading={loading}></Loading>

          <div style={{ position: "relative", paddingTop: 16 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="selector-de-tipo-de-pv">
                    Tipo do Ponto de Venda *
                  </InputLabel>
                  <Select
                    labelId="selector-de-tipo-de-pv"
                    label="Tipo do Ponto de Venda"
                    required
                    name="tipo"
                    value={tipo}
                    fullWidth
                    onChange={(e) => setTipo(e.target.value as string)}
                  >
                    <MenuItem value="CCY">Concessionária</MenuItem>
                    <MenuItem value="REP">Representante</MenuItem>
                    <MenuItem value="FAB">Fabricante</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.input}
                    required
                    name="codigo"
                    label="Código"
                    value={codigo}
                    fullWidth
                    onChange={(e) => setCodigo(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.input}
                    required
                    name="nome"
                    label="Nome"
                    value={nome}
                    fullWidth
                    onChange={(e) => setNome(e.target.value)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.input}
                    required
                    name="cnpj"
                    label="CNPJ"
                    value={cnpj}
                    fullWidth
                    onChange={(e) => setCnpj(e.target.value)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    name="grupoEconomico"
                    label="Grupo Econômico"
                    value={grupoEconomico}
                    fullWidth
                    onChange={(e) => setGrupoEconomico(e.target.value)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.input}
                    required
                    name="classificacao"
                    label="Classificação"
                    value={classificacao}
                    fullWidth
                    onChange={(e) => setClassificacao(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    required
                    name="percTreinamento"
                    label="Percentual de Treinamento"
                    value={percTreinamento}
                    fullWidth
                    onChange={(e) =>
                      setPercTreinamento(parseFloat(e.target.value))
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <ListSelect
                    label="Tipos de Produto"
                    placeholder="Selecione os tipos..."
                    fullWidth
                    options={options}
                    value={tipoProduto}
                    setValue={setTipoProduto}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="bancoAtivo"
                    checked={bancoAtivo}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                  BANCO ATIVO
                  </label>
                </div>
                </FormControl>
              </Grid>
              
              <Grid item xs={6}>
                <div className={classes.alert} hidden={errorAlert}>
                  <Alert severity="error">{msgError}</Alert>
                </div>
              </Grid>

              <Grid item xs={6} className={classes.button}>
                <Button
                  onClick={handlePost}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    </Modal>
  );
};
