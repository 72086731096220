import { Typography } from "@material-ui/core";
import React from "react";

interface IDescriptionItems {
  title: string;
  description?: string | null;
  descriptionJsx?: React.ReactFragment | null;
}

interface IFAQData {
  title: string;
  descriptionItems: IDescriptionItems[];
}

export let FAQData: IFAQData[] = [
  {
    title: "CONSÓRCIO",
    descriptionItems: [
      {
        title: "O que é um consórcio?",
        description:
          "Consórcio é um grupo de pessoas físicas ou jurídicas, com prazo de duração e número de cotas previamente determinados, promovido por uma administradora, com a finalidade de propiciar a seus integrantes a aquisição de bens móveis duráveis e imóveis por meio de autofinanciamento com taxa diferenciada, acessível e sem juros.",
        descriptionJsx: null,
      },
      {
        title: "Quem regulamenta o consórcio?",
        description:
          "O BACEN - Banco Central do Brasil tem a função de coordenar, supervisionar e fiscalizar as atividades do consórcio, conforme disposto atualmente na Lei 11.795/08 (nova lei do consórcio).",
        descriptionJsx: <></>,
      },
      {
        title: "Por que devo escolher o Consórcio Yamaha?",
        description:
          "O Consórcio Yamaha possui mais de 30 anos no mercado e conta com um suporte da Rede de Concessionárias Autorizadas espalhadas por todo território Nacional. Existem diversos planos para pagamento, garantia de entrega da fábrica Yamaha Motor do Brasil, crédito de fácil aprovação, facilidade para autônomos, sem juros e sem taxa de adesão.",
        descriptionJsx: null,
      },
      {
        title: "Qualquer pessoa pode participar do Consórcio Yamaha?",
        description:
          "A aquisição de cotas de consórcio só é permitida para pessoas com idade superior a 18 anos, sendo que para os consorciados com idade inferior a 18 anos é necessário assinatura em conjunto com pai, mãe ou responsável legal.",
        descriptionJsx: null,
      },
      {
        title: "Existe algum prazo para a formação do Grupo?",
        description:
          "Sim. O grupo deve ser formado no prazo de até 90 dias após a assinatura do contrato de adesão. Se não ocorrer a formação do grupo, os valores pagos são devolvidos.",
        descriptionJsx: null,
      },
    ],
  },
  {
    title: "COMPRAR COTA",
    descriptionItems: [
      {
        title: "O que é um consórcio?",
        description: null,
        descriptionJsx: (
          <>
            Basta{" "}
            <a
              href="http://www.yamaha-motor.com.br/consorcio/faca-sua-compra"
              style={{ color: "#3399cc" }}
            >
              CLICAR AQUI
            </a>{" "}
            para realizar a compra de sua Motocicleta ou Motor de Popa Yamaha ou
            ir até uma{" "}
            <a
              href="http://www.yamaha-motor.com.br/consorcio/concessionarias"
              style={{ color: "#3399cc" }}
            >
              CONCESSIONÁRIA AUTORIZADA
            </a>{" "}
            mais próxima e fazer um dos planos que melhor se encaixam no seu
            orçamento, sem juros e sem taxa de adesão.
          </>
        ),
      },
    ],
  },
  {
    title: "WEB ATENDIMENTO",
    descriptionItems: [
      {
        title: "Como faço para acessar o Web Atendimento?",
        description:
          "Na parte superior deste site ou nos menus laterais, você encontra o Acesso ao Cliente Consorciado. Basta preencher os campos solicitados para ter acesso a 2º Via de Boleto, Oferta de Lances e Resultados de Assembleias, sem sair de casa.",
        descriptionJsx: null,
      },
      {
        title: "O que mais pode ser feito através do Web Atendimento?",
        description:
          "Poderão ser consultados os dados do grupo, os dados da cota, extrato, resultado de assembleias, serviços como oferta de lances, antecipação de parcelas, alteração de senha entre outros serviços e consultas.",
        descriptionJsx: null,
      },
    ],
  },
  {
    title: "ASSEMBLÉIA",
    descriptionItems: [
      {
        title: "O que é assembleia?",
        description:
          "É uma reunião que ocorre mensalmente entre os consorciados onde ocorrem os sorteios e apuração dos lances.",
        descriptionJsx: null,
      },
    ],
  },
  {
    title: "2ª VIA DO BOLETO",
    descriptionItems: [
      {
        title: "Caso não receba o boleto para pagamento, como devo proceder?",
        description:
          "Se não houver o recebimento do boleto até a data de vencimento, poderá ser solicitado a 2ª via em uma Concessionária Autorizada Yamaha ou através de nossa Central de Relacionamento. Você ainda tem a opção de efetuar o pagamento com o número de seu contrato em uma Casa Lotérica ou também por internet, acessando o link Web Atendimento, na qual você poderá imprimir a 2ª via do boleto e obter mais informações sobre a sua cota e grupo.",
        descriptionJsx: null,
      },
    ],
  },
  {
    title: "PAGAMENTO DE PARCELA",
    descriptionItems: [
      {
        title: "Caso não receba o boleto para pagamento, como devo proceder?",
        description: null,
        descriptionJsx: (
          <>
            Após a realização da 1ª assembleia, o boleto para pagamento será
            encaminhado mensalmente no endereço informado no contrato.
            <ul>
              <li>
                <b>Boleto</b> Efetuar o pagamento da parcela até a data do
                vencimento em qualquer agência bancaria. Depois de vencida
                poderá ser paga no prazo de 15 dias somente no banco emissor do
                documento.
              </li>

              <li>
                <b>Casa Lotérica</b> A parcela do mês poderá ser paga na Casa
                Lotérica, apenas informando o número do Contrato de Adesão.
              </li>
            </ul>
          </>
        ),
      },
      {
        title:
          "O que acontece se os pagamentos não forem efetuados até o dia do vencimento?",
        description: null,
        descriptionJsx: (
          <>
            <ul>
              <li>
                <b>Não contemplado</b> - O consorciado não contemplado ficará
                impedido de participar do sorteio e/ou lance no mês em que
                ocorrer o atraso.
              </li>

              <li>
                <b>Contemplado</b> - O cliente já contemplado fica sujeito às
                penalidades contratuais.
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    title: "LANCE",
    descriptionItems: [
      {
        title: "De que forma posso ofertar um lance?",
        description:
          "Os lances poderão ser ofertados através do sistema Web Atendimento, na Concessionária que adquiriu o Consórcio, em nossa Central de Relacionamento até uma hora antes da realização da assembleia e também através do nosso atendimento eletrônico.",
        descriptionJsx: null,
      },
      {
        title: "Como ocorre a oferta de lance em uma cota?",
        description:
          "A oferta de lance poderá ser feita por todos os participantes ativos e em dia com seus pagamentos. A oferta pode ser de duas formas: Lance Fixo ou Lance Livre, no fixo se o grupo possuir essa modalidade.",
        descriptionJsx: null,
      },
      {
        title: "O que é lance fixo?",
        description:
          "É o lance pré-definido pelo grupo (que possui essa modalidade) onde concorrem os consorciados que ofertarem lance fixo ou um lance livre igual ou acima do percentual estipulado no Lance Fixo.",
        descriptionJsx: null,
      },
    ],
  },
  {
    title: "CONTEMPLAÇÃO",
    descriptionItems: [
      {
        title: "Quais são as formas de contemplação?",
        description: "Através de lances ou por sorteio da Loteria Federal.",
        descriptionJsx: null,
      },
      {
        title: "Como ocorre a contemplação por sorteio?",
        description:
          "A contemplação ocorre através de sorteio efetuado pelo aproveitamento da extração da Loteria Federal imediatamente anterior à data da assembleia ordinária.",
        descriptionJsx: null,
      },
      {
        title: "Como identifico uma cota contemplada por lance?",
        description:
          "No caso do Lance Livre é através do maior percentual ofertado. E do Lance Fixo segue o mesmo parâmetro das cotas de sorteio.",
        descriptionJsx: null,
      },
      {
        title:
          "Como posso utilizar o valor do lance ofertado, caso seja contemplado?",
        description:
          "Para redução de prazo, diminuindo a quantidade de parcelas na ordem inversa do plano ou para redução no valor da parcela.",
        descriptionJsx: null,
      },
      {
        title: "Qual o prazo para o pagamento do lance após a contemplação?",
        description:
          "O pagamento do lance poderá ser efetuado em até 05 dias úteis após contemplação.",
        descriptionJsx: null,
      },
      {
        title: "Eu posso perder a minha contemplação?",
        description:
          "Sim. O consorciado terá sua contemplação cancelada quando não utilizar o respectivo crédito, e/ou ficar inadimplente por 60 (sessenta) dias, consecutivos ou não, totalizando a duas prestações.",
        descriptionJsx: null,
      },
      {
        title: "Qual documento apresentar para retirar o bem?",
        description:
          "Apresentar na concessionária, cópias do RG, CPF, comprovantes de Residência e renda atualizados, onde também serão assinados documentos necessários para a retirada do bem.",
        descriptionJsx: null,
      },
    ],
  },
  {
    title: "TRANSFERÊNCIA",
    descriptionItems: [
      {
        title: "Posso transferir meu consórcio para outra pessoa?",
        description:
          "Sim, a transferência poderá ser realizada a qualquer momento mediante documentação necessária, sendo que os valores já pagos devem ser negociados entre as partes, não havendo intervenção da Administradora. A concessionária auxiliará no processo de transferência.",
        descriptionJsx: null,
      },
    ],
  },
  {
    title: "SUBSTITUIÇÃO DE GARANTIA",
    descriptionItems: [
      {
        title:
          "Depois que já retirei o bem posso apresentar outro para substituir a alienação existente?",
        description:
          "Sim, é possível substituir a alienação respeitando as normas do contrato de adesão. A concessionária auxiliará no processo de substituição.",
        descriptionJsx: null,
      },
    ],
  },
  {
    title: "ANTECIPAÇÃO DE PARCELA",
    descriptionItems: [
      {
        title: "Como antecipar parcelas?",
        description:
          "Existem duas opções para antecipar parcelas, sendo para reduzir o prazo abatendo prestações na ordem inversa ou reduzindo o valor das parcelas mensais.",
        descriptionJsx: null,
      },
    ],
  },
  {
    title: "BAIXA DE ALIENAÇÃO",
    descriptionItems: [
      {
        title: "Quando ocorre?",
        description:
          "A baixa de alienação ocorre após a quitação de 100% do saldo devedor, desde que o documento do veículo (CRV), esteja emitido com a informação de Alienação a Yamaha.",
        descriptionJsx: null,
      },
    ],
  },
  {
    title: "CANCELAMENTO",
    descriptionItems: [
      {
        title: "Posso desistir do Consórcio?",
        description:
          "Sim. O consorciado não contemplado poderá formalizar seu pedido de desistência do plano a qualquer momento, respeitando as normas do contrato de adesão.",
        descriptionJsx: null,
      },
      {
        title: "Minha cota poderá ser cancelada em algum momento?",
        description:
          "Sim. O consorciado poderá sujeitar-se à exclusão do grupo por inadimplência quanto deixar de cumprir suas obrigações financeiras por 3 (três) meses consecutivos ou não, totalizando a três prestações.",
        descriptionJsx: null,
      },
      {
        title: "Quando posso receber os valores pagos em caso de cancelamento?",
        description: "",
        descriptionJsx: (
          <ul>
            <li>
              Para grupo formado antes de 06 de fevereiro de 2009, a restituição
              de valor será efetuada no prazo de até 60 (sessenta) dias após a
              realização da última assembleia, e de acordo com os redutores
              previstos em contrato.
            </li>
            <li>
              Para grupos formados após 06 de fevereiro de 2009, a devolução de
              valor ocorrerá quando a cota for sorteada através da loteria
              federal, e de acordo com os redutores previstos em contrato.
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    title: "FUSÃO DE GRUPOS",
    descriptionItems: [
      {
        title: "O que é fusão de Grupos?",
        description:
          "É a junção de dois grupos com as mesmas características a fim de melhorar a saúde financeira do plano, aumentando assim a quantidade de contemplação.",
        descriptionJsx: null,
      },
    ],
  },
  {
    title: "ENCERRAMENTO",
    descriptionItems: [
      {
        title: "Como ocorre o encerramento de Grupo?",
        description: null,
        descriptionJsx: (
          <>
            Dentro de 60 (sessenta) dias, contados da data de realização da
            última assembleia ordinária do grupo, a administradora deverá
            colocar à disposição os créditos na seguinte ordem:
            <ul>
              <li>
                Consorciados contemplados - que não tenham utilizado o crédito
                para adquirir sua motocicleta;
              </li>
              <li>
                Excluídos e desistentes - valores relativos à devolução das
                quantias pagas, aplicando-se as regras estabelecidas pelo Banco
                Central;
              </li>
              <li>Demais consorciados.</li>
            </ul>
          </>
        ),
      },
    ],
  },
];
