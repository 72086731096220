import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react';
import { deletePegaEmail, getPecaEmail, postPegaEmail, putPegaEmail } from '../../redux/ducks/pecaEmail';
import { Box, Button, Container, createStyles, Grid, InputBase, makeStyles, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Theme } from '@material-ui/core';
import { PecaEmailRequest } from '../../Models/PecaEmailRequest';
import SearchIcon from "@material-ui/icons/Search";

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomFab from '../../ui/CustomFab';
import AddIcon from '@material-ui/icons/Add';
import { Loading } from '../../ui/Loading';
import { PecaDelete } from './PecaDelete';
import { TitleCard } from '../../base/TitleCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    search: {
      position: "relative",
      backgroundColor: "#33333311",
      padding: theme.spacing(0, 2),
      margin: theme.spacing(2, 0),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      width: "100%",
      display: "flex",
    },
    searchIcon: {
      padding: theme.spacing(2, 0),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: {
      backgroundColor: "transparent",
      color: "inherit",
      flexGrow: 1
    },
    inputInput: {
      backgroundColor: "transparent",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      flexGrow: 1,
      display: "flex",
      [theme.breakpoints.up("md")]: {
        width: "20ch"
      }
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    titlePage: {
      position: 'relative',
      left: 10,
      top: 5
    },
    button: {
      margin: theme.spacing(1),
    },
    paperModal: {
      position: 'absolute',
      width: 320,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "50%",
      left: "50%",
    },
    buttonNovo: {
      margin: theme.spacing(1),
      paddingRight: 5,
      position: "absolute",
      left: "90%",
      width: 65,
      height: 38
    },
    paperdelete: {
      position: 'absolute',
      width: 365,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "38%",
      left: "38%",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paperWrap: {
      width: '100%',
      padding: theme.spacing(2),
    },
  })
);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Search(parmList: any, search2: any) {
  var userchUsers = []
  var pecaEmailList: PecaEmailRequest

  for (var i in parmList) {
    if (parmList[i].codigo.match(search2)) {
      pecaEmailList = {
        id: parmList[i].id,
        codigo: parmList[i].codigo,
        descricao: parmList[i].descricao,
        assunto: parmList[i].assunto,
        conteudo: parmList[i].conteudo
      }
      userchUsers.push(pecaEmailList)
    }
  }
  return userchUsers;
}

const useStylesModal = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "80%",
    height: "90%",
    paddingBottom: 200,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 5),
  },
  margemGrid: {
    marginBottom: 20,
  },
  marginButton: {
    marginLeft: 2.5,
    marginRight: 2.5,
  },
  icon: {
    alignItems: "flex-end",
    cursor: "pointer",
  },
  botao: {
    position: "relative",
    top: 0
  },
}));

const DataTable = ({ pecaEmailList, loadPecaEmail, updatePecaEmail, deletePecaEmail, loading, postPecaEmail }: any) => {
  const [modalStyle] = React.useState(getModalStyle);
  const [orderType, setOrderType] = useState<"asc" | "desc">('asc');
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState(1);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [rowId, setRowId] = React.useState<number | null>(null);
  const [codigo, setCodigo] = useState<string | null>('');
  const [descricao, setDesricao] = useState<string | null>('');
  const [assunto, setAssunto] = useState<String | null>('');
  const [conteudo, setConteudo] = useState<String | null>('');
  const [openCadastro, setOpenCadastro] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const classesModal = useStylesModal();
  const classes = useStyles();

  const handleUpdate = () => {
    let PegaEmailEdit: PecaEmailRequest = {
      id: rowId,
      codigo: codigo ?? "",
      descricao: descricao ?? "",
      assunto: assunto ?? "",
      conteudo: conteudo ?? "",
    }

    updatePecaEmail(PegaEmailEdit);
    setOpenEdit(false);
  }

  const handlePost = () => {
    let PegaEmailcadastro: PecaEmailRequest = {
      id: rowId,
      codigo: codigo ?? "",
      descricao: descricao ?? "",
      assunto: assunto ?? "",
      conteudo: conteudo ?? "",
    }
    postPecaEmail(PegaEmailcadastro);
    setOpenCadastro(false);
  }

  const handleOpenCadastro = () => {
    setOpenCadastro(true);
  };

  const handleCloseCadastro = () => {
    setOpenCadastro(false);
  };


  const handleOpenEditEmail = (row: any) => {
    setOpenEdit(true);
    setRowId(row.id);
    setCodigo(row.codigo);
    setDesricao(row.descricao);
    setAssunto(row.assunto);
    setConteudo(row.conteudo);
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenDelete = (row: any) => {
    setRowId(row.id);
    setCodigo(row.codigo);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  function deleteEmail() {
    deletePecaEmail(rowId)
    setOpenDelete(false)
  }

  useEffect(loadPecaEmail, []);

  function sortTable(orderByAtual: string) {
    let orderTypeAtual = orderType

    if (orderTypeAtual === 'asc') {
      setOrderType('desc');
      orderTypeAtual = 'desc'
      setOrder(-1)
    }
    else if (orderTypeAtual === 'desc') {
      setOrderType('asc');
      orderTypeAtual = 'asc'
      setOrder(1)
    }

    setOrderBy(orderByAtual)


    return 0;
  }

  if (orderBy === "codigo") {
    pecaEmailList.sort((a: any, b: any) => [
      a.codigo.toLowerCase() > b.codigo.toLowerCase() ? order : -order
    ])
  }

  if (orderBy === "descricao") {
    pecaEmailList.sort((a: any, b: any) => [
      a.descricao.toLowerCase() > b.descricao.toLowerCase() ? order : -order
    ])
  }

  const ModalCadastro = (
    <Grid style={modalStyle} className={classesModal.paper}>

      <div style={{ position: "relative" }}>

        <h2 style={{ position: "absolute", marginTop: 5 }}> Cadastro Peça de Email</h2>

        <div style={{ position: "absolute", left: "97%" }} className={classesModal.icon}>
          <CancelIcon
            style={{
              color: "#ff0000",
            }}
            fontSize="large"
            onClick={handleCloseCadastro}
          ></CancelIcon>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid style={{ marginTop: 55 }} item xs={12} sm={12}>
          <TextField
            id="codigo"
            name="codigo"
            label="Codigo"
            fullWidth
            autoComplete="codigo"
            onChange={e => setCodigo(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="assunto"
            id="assunto"
            fullWidth
            autoComplete="assunto"
            label="assunto"
            onChange={e => setAssunto(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} >
          <TextField
            id="descricao"
            name="descricao"
            label="Descrição"
            fullWidth
            autoComplete="descricao"
            onChange={e => setDesricao(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} >
          <TextField
            multiline
            rows={12}
            id="conteudo"
            name="conteudo"
            label="conteudo"
            fullWidth
            autoComplete="conteudo"
            variant="outlined"
            onChange={e => setConteudo(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePost}
          >
            Salvar
              </Button>

        </Grid>
      </Grid>

    </Grid>
  );

  const ModalEdit = (
    <Grid style={modalStyle} className={classesModal.paper}>

      <div style={{ position: "relative" }}>

        <h2 style={{ position: "absolute", marginTop: 5 }}> Editar Peça de Email</h2>

        <div style={{ position: "absolute", left: "97%" }} className={classesModal.icon}>
          <CancelIcon
            style={{
              color: "#ff0000",
            }}
            fontSize="large"
            onClick={handleCloseEdit}
          ></CancelIcon>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid style={{ marginTop: 55 }} item xs={12} sm={12}>
          <TextField
            id="codigo"
            name="codigo"
            label="Codigo"
            fullWidth
            autoComplete="codigo"
            value={codigo}
            onChange={e => setCodigo(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="assunto"
            id="assunto"
            fullWidth
            autoComplete="assunto"
            label="assunto"

            value={assunto}
            onChange={e => setAssunto(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} >
          <TextField
            id="descricao"
            name="descricao"
            label="Descrição"
            fullWidth
            autoComplete="descricao"

            value={descricao}
            onChange={e => setDesricao(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} >
          <TextField
            multiline
            rows={12}
            id="conteudo"
            name="conteudo"
            label="conteudo"
            fullWidth
            autoComplete="conteudo"
            variant="outlined"
            value={conteudo}
            onChange={e => setConteudo(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdate}

          >
            Salvar
              </Button>
        </Grid>
      </Grid>

    </Grid>
  );

  pecaEmailList = Search(pecaEmailList, search);

  return (
    <Box>
      <TitleCard>Peça de Email</TitleCard>

      <CustomFab label="Novo" icon={<AddIcon />} onClick={handleOpenCadastro} />
      <Paper className={classes.paperWrap}>

        <div className={classes.grow}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              type="text"
              placeholder="Pesquise aqui…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}

              onChange={e => setSearch(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} />
        </div>
        <Loading loading={loading} />

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Código
                <TableSortLabel active={orderBy === 'codigo'}
                    direction={orderBy === 'codigo' ? orderType : 'asc'}
                    onClick={() => sortTable('codigo')}
                  >
                    {orderBy === 'codigo' ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">Descricao
                <TableSortLabel active={orderBy === 'descricao'}
                    direction={orderBy === 'descricao' ? orderType : 'asc'}
                    onClick={() => sortTable('descricao')}
                  >
                    {orderBy === 'descricao' ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pecaEmailList.map((row: PecaEmailRequest) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.codigo}
                  </TableCell>
                  <TableCell style={{ width: '50%' }} align="left">{row.descricao}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      style={{
                        color: "#388e3c",
                        borderColor: "#388e3c"
                      }}
                      title="Editar"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenEditEmail(row)}
                    >
                      <EditRoundedIcon />
                    </Button>

                    <Button
                      style={{
                        color: "#b20303",
                        borderColor: "#b20303"
                      }}
                      title="Excluir"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenDelete(row)}
                    >
                      <DeleteIcon />
                    </Button>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <PecaDelete
          openDelete={openDelete}
          handleDelete={deleteEmail}
          handleCloseDelete={handleCloseDelete}
        />

        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {ModalEdit}
        </Modal>
        <Modal
          open={openCadastro}
          onClose={handleCloseCadastro}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {ModalCadastro}
        </Modal>
      </Paper>
    </Box>
  )

}

const mapStateToProps = (state: any) => ({
  pecaEmailList: state.pecaEmail.pecaEmailList,
  loading: state.pecaEmail.loading,
})

const mapDispatchToProps = (dispatch: any) => ({
  loadPecaEmail: () => {
    dispatch(getPecaEmail())
  },
  updatePecaEmail: (pecaemail: PecaEmailRequest) => {
    dispatch(putPegaEmail(pecaemail)).then(() => dispatch(getPecaEmail()))
  },
  postPecaEmail: (pecaemail: PecaEmailRequest) => {
    dispatch(postPegaEmail(pecaemail)).then(() => dispatch(getPecaEmail()))
  },
  deletePecaEmail: (id: number) => {
    dispatch(deletePegaEmail(id)).then(() => dispatch(getPecaEmail()))
  }
})

export const PecaEmail = connect(mapStateToProps, mapDispatchToProps)(DataTable)