import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import { Divider, Grid } from '@material-ui/core';
import { GridItem } from './GridItem';
import api from '../../../service/api';
import { formataData, getCurrentMedia } from '../../../utils/utils';
import { QualityInfo } from '../../../Models/QualityInfo';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});

var today = new Date();

var dataInicialMedia = new Date(2024, 0);
var dataFinalMedia = new Date(today.getFullYear(), today.getMonth(), 0);

var dataFinalRetencao = new Date(today.getFullYear(), today.getMonth() - 1, 0);
var dataInicialRetencao = new Date(
  dataFinalRetencao.getFullYear(),
  dataFinalRetencao.getMonth() - 11
);

var periodoMediaTostring =
  dataInicialMedia.toLocaleDateString() +
  ' a ' +
  dataFinalMedia.toLocaleDateString();

var periodoRetencaoTostring =
  dataInicialRetencao.toLocaleDateString() +
  ' a ' +
  dataFinalRetencao.toLocaleDateString();

export default function QualityHome({ classificacao }: any) {
  const classes = useStyles();

  const [qualityInfo, setQualityInfo] = useState<QualityInfo>();
  const [loading, setLoading] = useState(false);

  const itemsCount = [
    {
      data: periodoMediaTostring,
      valor: (qualityInfo?.mediaVendas ?? 0).toFixed(0),
      descricao: 'MÉDIA DE VENDAS',
      backgroundColor: '#03A9F4',
      tipoConsulta: 'venda',
    },
    {
      data: periodoRetencaoTostring,
      valor: ((qualityInfo?.percentualRetencao ?? 0) * 100).toFixed(2),
      descricao: '% RETENÇÃO',
      backgroundColor: '#4CAF50',
      tipoConsulta: 'retencao',
    },
    {
      data: periodoMediaTostring,
      valor: '-',
      descricao: '% EQUIPE TREINADA',
      backgroundColor: '#673AB7',
      tipoConsulta: '',
    },
  ];

  const getDadosQuality = () => {
    setLoading(true);
    api
      .get('/Quality/GetQualityInfo', {
        params: {
          dataInicialMedia: dataInicialMedia.toISOString().substring(0, 10),
          dataFinalMedia: dataFinalMedia.toISOString().substring(0, 10),
          dataInicialRetencao: dataInicialRetencao
            .toISOString()
            .substring(0, 10),
          dataFinalRetencao: dataFinalRetencao.toISOString().substring(0, 10),
        },
      })
      .then((response) => {
        var info = response.data as QualityInfo;
        setQualityInfo(info);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDadosQuality();
  }, []);

  return (
    <React.Fragment>
      <Title>
        <img
          alt="logoQuality"
          src="/assets/logo-quality.png"
          height="62px"
        ></img>
      </Title>
      <Divider />
      <Grid container>
        <Grid item className={classes.itemRow} xl={6} xs={12}>
          <Typography style={{ padding: 15, color: 'GrayText' }}>
            Classificação Atual:
          </Typography>
          <span
            style={{
              background: qualityInfo?.classificacaoAtual?.cor ?? '#db961d',
              color: 'white',
              padding: 10,
              fontWeight: 'bold',
            }}
          >
            {loading ? (
              <Skeleton height="100%" width="50px" />
            ) : (
              qualityInfo?.classificacaoAtual?.nome
            )}
          </span>
        </Grid>
        <Grid item className={classes.itemRow} xl={6} xs={12}>
          <Typography style={{ padding: 15, color: 'GrayText' }}>
            Classificação para o próximo período:
          </Typography>
          <span
            style={{
              background: qualityInfo?.proximaClassificacao?.cor ?? '#db961d',
              color: 'white',
              padding: 10,
              fontWeight: 'bold',
            }}
          >
            {loading ? (
              <Skeleton height="100%" width="50px" />
            ) : (
              qualityInfo?.proximaClassificacao?.nome
            )}
          </span>
        </Grid>
      </Grid>

      <Divider />
      <Grid
        container
        style={{
          height: '100%',
          padding: 8,
          alignItems: 'center',
        }}
      >
        {itemsCount.map((x: any) => (
          <GridItem
            data={x.data}
            valor={x.valor}
            descricao={x.descricao}
            backgroundColor={x.backgroundColor}
            loading={loading}
          />
        ))}
      </Grid>
    </React.Fragment>
  );
}
