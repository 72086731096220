import { connect } from "react-redux";
import React, { useEffect } from "react";
import {
  Box,
  Button,
  InputBase,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ViewLink } from "./ViewLink";
import { Loading } from "../../ui/Loading";
import { TitleCard } from "../../base/TitleCard";
import { useStyles } from "./style";
import { ThemePagination } from "../../base/Pagination";
import api from "../../service/api";
import { format, parseISO } from "date-fns";

const DataTable = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");

  const [viewModal, setViewModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState(null);

  const [linkAtivacaolList, setLinkAtivacaolList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [totalItemSize, settotalItemSize] = useState(0);
  const [index, setIndex] = useState<number>(0);
  const [size] = useState<number>(10);
  const [orderBy, setOrderBy] = useState("Id");
  const [orderType, setOrderType] = useState<"asc" | "desc">("asc");
  const [page, setPage] = React.useState(1);

  function handleOpenModal(data: any) {
    setModalData(data);
    setViewModal(true);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    let indexPage = (newPage - 1) * size;
    setIndex(indexPage);
    setPage(newPage);
  };

  function sortTable(orderByAtual: string) {
    let orderTypeAtual = orderType;

    if (orderTypeAtual === "asc") {
      setOrderType("desc");
      orderTypeAtual = "desc";
    } else if (orderTypeAtual === "desc") {
      setOrderType("asc");
      orderTypeAtual = "asc";
    }
    setOrderBy(orderByAtual);
  }

  useEffect(() => {
    setLoading(true);
    api
      .get("/EnvioEmail", {
        params: {
          index: index,
          size: size,
          search: search,
          orderBy: orderBy,
          orderType: orderType,
        },
      })
      .then(({ data }) => {
        setLinkAtivacaolList(data.list);

        const numberOfPages = Math.floor(
          data.size % 10 === 0 ? data.size / 10 : data.size / 10 + 1
        );
        settotalItemSize(numberOfPages);

        setLoading(false);
      });
  }, [index, orderBy, orderType, search, size]);

  function inputSearch(inputSearch: string) {
    setSearch(inputSearch);
  }

  return (
    <Box>
      <TitleCard breadcrumbsText="Sistema">
        <LinkIcon /> Link Ativação
      </TitleCard>
      <Paper className={classes.paperWrap}>
        <div className={classes.grow}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              type="text"
              placeholder="Pesquise aqui…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(e) => inputSearch(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}></div>
        </div>
        <Loading loading={loading} />

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.tableCellTitle}>
                  Nome
                  <TableSortLabel
                    active={orderBy === "Nome"}
                    direction={orderBy === "Nome" ? orderType : "asc"}
                    onClick={() => sortTable("Nome")}
                  >
                    {orderBy === "Nome" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell align="left" className={classes.tableCellTitle}>
                  E-Mail
                  <TableSortLabel
                    active={orderBy === "Email"}
                    direction={orderBy === "Email" ? orderType : "asc"}
                    onClick={() => sortTable("Email")}
                  >
                    {orderBy === "Email" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell align="left" className={classes.tableCellTitle}>
                  CPF
                  <TableSortLabel
                    active={orderBy === "Cpf"}
                    direction={orderBy === "Cpf" ? orderType : "asc"}
                    onClick={() => sortTable("Cpf")}
                  >
                    {orderBy === "Cpf" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell align="left" className={classes.tableCellTitle}>
                  Perfil
                  <TableSortLabel
                    active={orderBy === "Perfil"}
                    direction={orderBy === "Perfil" ? orderType : "asc"}
                    onClick={() => sortTable("Perfil")}
                  >
                    {orderBy === "Perfil" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell align="left" className={classes.tableCellTitle}>
                  Data Cadastro
                  <TableSortLabel
                    active={orderBy === "DataCadastro"}
                    direction={orderBy === "DataCadastro" ? orderType : "asc"}
                    onClick={() => sortTable("DataCadastro")}
                  >
                    {orderBy === "DataCadastro" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={classes.tableCellTitle}>
                  Ativo
                  <TableSortLabel
                    active={orderBy === "Ativo"}
                    direction={orderBy === "Ativo" ? orderType : "asc"}
                    onClick={() => sortTable("Ativo")}
                  >
                    {orderBy === "Ativo" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {linkAtivacaolList?.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell align="left" component="th" scope="row">
                    {row.nome}
                  </TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.cpf}</TableCell>
                  <TableCell align="left">{row.perfilUsuario?.nome}</TableCell>
                  <TableCell align="left">
                    {row.dataCadastro
                      ? format(parseISO(row.dataCadastro), "dd/MM/yyyy")
                      : ""}
                  </TableCell>
                  <TableCell align="left">{row.ativo}</TableCell>
                  <TableCell align="left">
                    {row.envioEmail && row.envioEmail.length ? (
                      <Button
                        color="secondary"
                        onClick={() => handleOpenModal(row)}
                      >
                        <VisibilityIcon className={classes.eyeIcon} />
                      </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={viewModal}
          onClose={() => setViewModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ViewLink
            modalData={modalData}
            handleClose={() => setViewModal(false)}
          />
        </Modal>
      </Paper>

      <ThemePagination
        marginLeft="32px"
        pagesCount={totalItemSize}
        currentPage={page}
        handlePageChange={handleChangePage}
      />
    </Box>
  );
};

export const LinkAtivacao = DataTable;
