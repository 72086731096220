import { createStyles, makeStyles, TableCell, TableSortLabel, Theme } from '@material-ui/core'
import React from 'react'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
    })
)

export const HeaderCell = ({orderBy, orderType, fieldName, sortTable, children}: any) => {
  const classes = useStyles();
  
  return (
    <TableCell align="left" onClick={() => sortTable(fieldName)}>
      <TableSortLabel
        active={orderBy === fieldName}
        direction={orderBy === fieldName ? orderType : 'asc'}
      >
      <span>{children}</span>
        {orderBy === fieldName ? (
          <span className={classes.visuallyHidden}>
            {orderType === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  )
}
