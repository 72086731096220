import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  coolbg: {
    background: "url(/assets/header-home.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    margin: 0,
    minHeight: "200px",
    height: "200px",
    padding: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    alignContent: "space-between",
    flexDirection: "row",
    color: "white",
    textShadow: "0 0 32px #333333DD",
    paddingBottom: "32px",
  },

  top: {
    background: "linear-gradient(#fafafa33, #fafafaff)",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },

  breadcrumbsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "13px",
  },
  iconWrapper: {
    fontSize: "10px",
  },
  breadcrumbsText: {
    fontSize: "12px",
  },

  arrowIcon: {
    fontSize: 12,
    marginRight: "15px",
    marginLeft: "15px",
  },
});

interface ITitleCard {
  breadcrumbsText?: string;
  breadcrumbsIcon?: any;
  children: any;
  buttonLink?: string | null;
  onClickButton?: (event: any) => void;
}

export const TitleCard = ({
  children,
  breadcrumbsText,
  breadcrumbsIcon,
  buttonLink,
  onClickButton,
}: ITitleCard) => {
  const classes = useStyles();

  return (
    <div className={classes.coolbg}>
      <div>
        <div className={classes.breadcrumbsWrapper}>
          <div className={classes.iconWrapper}>{breadcrumbsIcon}</div>
          {breadcrumbsIcon ? (
            <ArrowForwardIosIcon className={classes.arrowIcon} />
          ) : null}

          <Typography className={classes.breadcrumbsText}>
            {breadcrumbsText}
          </Typography>
        </div>
        <Typography variant="h4">{children} </Typography>
      </div>

      {buttonLink && (
        <div>
          <Link to={buttonLink}>
            <Button
              variant="contained"
              onClick={onClickButton}
              style={{ color: "#000", backgroundColor: "#FAFAFA" }}
            >
              <ArrowBackIosIcon style={{ fontSize: "14px" }} /> VOLTAR PARA
              LISTA
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};
