import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { getMyData, getPerfilApp } from '../../redux/ducks/user';
import { UserDto } from '../../Models/UserDto';
import api from '../../service/api';
import { maskCpf, maskPhone, maskRg, unmask } from '../../utils/masks';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: '1200px',
    maxHeight: '800px',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    overflowY: 'scroll',
  },
  paperWrap: {
    padding: theme.spacing(4),
  },
  closeIcon: {
    color: 'white',
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#0288D1',
    color: 'white',
    borderRadius: '0px',
    padding: theme.spacing(2),
  },

  content: { position: 'relative', paddingTop: 20 },

  foot: {
    padding: theme.spacing(4, 0, 0, 0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

interface IAdditionalInformationModal {
  isOpen: boolean;
  handleCloseModal: () => void;
  userData?: any;
  appProfileList?: any;
  loadGetPerfilUsuario: () => void;
  loadUserData: () => void;
}

const AdditionalInformationModalComponent = ({
  isOpen,
  handleCloseModal,
  userData,
  appProfileList,
  loadGetPerfilUsuario,
  loadUserData,
}: IAdditionalInformationModal) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [editedUserData, setEditedUserData] = useState<UserDto>(userData);

  const [profileList, setProfileList] = useState(appProfileList ?? []);

  useEffect(() => {
    setProfileList(appProfileList ?? []);
  }, [appProfileList]);
  useEffect(loadGetPerfilUsuario, []);

  const elevation = 6;

  const putData = async () => {
    setLoading(true);
    var campanha = editedUserData.campanha;
    var editedCampanha = {
      rg: campanha?.rg,
      cpf: campanha?.cpf,
      cpf_Gerencia: campanha?.cpf_Gerencia,
      sexo: campanha?.sexo,
    };
    // Campos que são adicionados somente ao checar o checkbox (termos)
    var termsEditedCampanha = {
      cep: campanha?.cep,
      logradouro: campanha?.logradouro,
      numero: campanha?.numero,
      complemento: campanha?.complemento,
      bairro: campanha?.bairro,
      uf: campanha?.uf,
      cidade: campanha?.cidade,
      ponto_Referencia: campanha?.ponto_Referencia,
      email_Primario: campanha?.email_Primario,
      email_Secundario: campanha?.email_Secundario,
      telefone_Fixo: campanha?.telefone_Fixo,
      telefone_Celular: campanha?.telefone_Celular,
      telefone_Outros: campanha?.telefone_Outros,
    };

    editedCampanha = termsChecked
      ? { ...editedCampanha, ...termsEditedCampanha }
      : editedCampanha;

    await api
      .put('/users/EditUserDataAdditionalInformation', {
        campanha: editedCampanha,
        termsAccepted: termsChecked,
      })
      .finally(() => {
        setLoading(false);
      });

    loadUserData();
  };

  useEffect(() => {
    setEditedUserData(userData);
  }, [userData]);

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.head}>
          <Typography variant="h5">Dados Cadastrais</Typography>
          <Button>
            <CloseIcon
              className={classes.closeIcon}
              fontSize="large"
              onClick={handleCloseModal}
            />
          </Button>
        </Paper>

        <Paper className={classes.paperWrap}>
          <Paper className={classes.paperWrap} elevation={elevation}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    value={editedUserData.nome}
                    disabled
                    required
                    name="nome"
                    label="Nome"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    disabled={loading}
                    InputLabelProps={{ shrink: true }}
                    value={editedUserData.campanha?.rg ?? ''}
                    onChange={(e) => {
                      setEditedUserData({
                        ...editedUserData,
                        campanha: {
                          ...editedUserData.campanha,
                          rg: maskRg(e.target.value),
                        },
                      });
                    }}
                    name="rg"
                    label="RG"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    disabled={loading}
                    InputLabelProps={{ shrink: true }}
                    value={editedUserData.campanha?.cpf ?? ''}
                    onChange={(e) => {
                      setEditedUserData({
                        ...editedUserData,
                        campanha: {
                          ...editedUserData.campanha,
                          cpf: maskCpf(e.target.value),
                        },
                      });
                    }}
                    name="cpf"
                    label="CPF"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel required id="LabelPU">
                    Perfil APP
                  </InputLabel>
                  <Select
                    disabled
                    id="idPerfilUsuario"
                    value={editedUserData.idPerfilUsuario}
                    onChange={(e) =>
                      setEditedUserData({
                        ...editedUserData,
                        idPerfilUsuario: e.target.value as number,
                      })
                    }
                    name="idPerfilUsuario"
                    label="Perfil APP *"
                  >
                    {profileList.map((row: any) => (
                      <MenuItem
                        value={row.id}
                      >{`${row.nome} - ${row.tipoPerfil}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    disabled={loading}
                    InputLabelProps={{ shrink: true }}
                    value={editedUserData.campanha?.cpf_Gerencia}
                    onChange={(e) => {
                      setEditedUserData({
                        ...editedUserData,
                        campanha: {
                          ...editedUserData.campanha,
                          cpf_Gerencia: maskCpf(e.target.value),
                        },
                      });
                    }}
                    required
                    name="cpfGerente"
                    label="CPF Gerente"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                  <Select
                    disabled={loading}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="sexo"
                    value={editedUserData.campanha?.sexo}
                    onChange={(e) => {
                      setEditedUserData({
                        ...editedUserData,
                        campanha: {
                          ...editedUserData.campanha,
                          sexo: e.target.value as string,
                        },
                      });
                    }}
                    label="sexo"
                    style={{ width: '200px', marginRight: '20px' }}
                  >
                    <MenuItem value={'M'}>Masculino</MenuItem>
                    <MenuItem value={'F'}>Feminino</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            style={{ marginTop: 32 }}
            className={classes.paperWrap}
            elevation={elevation}
          >
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="checkedB"
                      checked={termsChecked}
                      onChange={(e) => setTermsChecked(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Estou ciente que as informações contidas neste formulário são
                  verdadeiras e estão atualizadas. Sendo assim, isento a Yamaha
                  Consórcio e demais empresas do grupo Yamaha por qualquer
                  responsabilidade devido as informações por mim preenchidas.
                  Autorizo o envio de informações e/ou materiais no endereço
                  eletrônico (e-mail), telefone e endereço de correspondência
                  por mim informados."
                />
              </Grid>
            </Grid>
          </Paper>

          {termsChecked && (
            <Paper
              style={{ marginTop: 32 }}
              className={classes.paperWrap}
              elevation={elevation}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={editedUserData.campanha?.cep}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            cep: e.target.value,
                          },
                        });
                      }}
                      name="cep"
                      label="CEP"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={editedUserData.campanha?.logradouro}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            logradouro: e.target.value,
                          },
                        });
                      }}
                      name="logradouro"
                      label="Logradouro"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={editedUserData.campanha?.numero}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            numero: e.target.value,
                          },
                        });
                      }}
                      name="numero"
                      label="Número"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      value={editedUserData.campanha?.complemento}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            complemento: e.target.value,
                          },
                        });
                      }}
                      name="complemento"
                      label="Complemento"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={editedUserData.campanha?.bairro}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            bairro: e.target.value,
                          },
                        });
                      }}
                      name="bairro"
                      label="Bairro"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={editedUserData.campanha?.uf}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            uf: e.target.value,
                          },
                        });
                      }}
                      name="estado"
                      label="Estado"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={editedUserData.campanha?.cidade}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            cidade: e.target.value,
                          },
                        });
                      }}
                      name="cidade"
                      label="Cidade"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={editedUserData.campanha?.ponto_Referencia}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            ponto_Referencia: e.target.value,
                          },
                        });
                      }}
                      name="referencia"
                      label="Ponto de Referência/Observações"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={editedUserData.campanha?.email_Primario}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            email_Primario: e.target.value,
                          },
                        });
                      }}
                      name="email"
                      label="Email"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={editedUserData.campanha?.email_Secundario}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            email_Secundario: e.target.value,
                          },
                        });
                      }}
                      name="emailSecundario"
                      label="Email Secundário"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={maskPhone(
                        editedUserData.campanha?.telefone_Outros
                      )}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            telefone_Outros: unmask(e.target.value),
                          },
                        });
                      }}
                      name="telefone"
                      label="Telefone"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={maskPhone(
                        editedUserData.campanha?.telefone_Celular
                      )}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            telefone_Celular: unmask(e.target.value),
                          },
                        });
                      }}
                      name="telefoneCelular"
                      label="Telefone (Celular)"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      value={maskPhone(editedUserData.campanha?.telefone_Fixo)}
                      onChange={(e) => {
                        setEditedUserData({
                          ...editedUserData,
                          campanha: {
                            ...editedUserData.campanha,
                            telefone_Fixo: unmask(e.target.value),
                          },
                        });
                      }}
                      name="telefoneFixo"
                      label="Telefone (Fixo)"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          )}

          <Button
            style={{ marginTop: '16px', marginRight: '16px' }}
            variant="contained"
            color="primary"
            onClick={putData}
            disabled={loading}
          >
            Salvar
          </Button>
          <Button
            style={{ marginTop: '16px' }}
            variant="contained"
            color="secondary"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Fechar
          </Button>
        </Paper>
      </Grid>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.user.userData,
  appProfileList: state.user.appProfileList,
});
const mapDispatchToProps = (dispatch: any) => ({
  loadGetPerfilUsuario: () => {
    dispatch(getPerfilApp());
  },
  loadUserData: () => {
    dispatch(getMyData());
  },
});
export const AdditionalInformationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalInformationModalComponent);
