import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import {
  getSystemParm,
  putSystemParm,
  postSystemParm,
  deleteSystemParm,
} from "../../redux/ducks/systemParameter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { SysParameterRequest } from "../../Models/SysParameterRequest";
import AddIcon from "@material-ui/icons/Add";

import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Box, Button, Grid, Modal, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import CustomFab from "../../ui/CustomFab";
import { Loading } from "../../ui/Loading";
import { ParameterDelete } from "./ParameterDelete";
import { ParameterCreate } from "./ParameterCreate";
import { TitleCard } from "../../base/TitleCard";
import { useStyles, useStylesModal } from "./style";

function Search(parmList: any, search2: any) {
  var userchUsers = [];
  var sysParmLista: SysParameterRequest;

  for (var i in parmList) {
    if (parmList[i].codigo.match(search2)) {
      sysParmLista = {
        id: parmList[i].id,
        codigo: parmList[i].codigo,
        descricao: parmList[i].descricao,
        inteiro: parmList[i].inteiro,
        numerico: parmList[i].numerico,
        texto: parmList[i].texto,
      };
      userchUsers.push(sysParmLista);
    }
  }
  return userchUsers;
}

interface ISystemParameterProps {
  loading: boolean;
  sysParmList: any;
  loadSysParm: any;
  updateSysParm: any;
  postSysParm: (user: SysParameterRequest) => void;
  deleteSysParm: any;
}

const DataTable = ({
  postSysParm,
  sysParmList,
  deleteSysParm,
  updateSysParm,
  loadSysParm,
  loading,
}: ISystemParameterProps) => {
  const classesModal = useStylesModal();
  useEffect(loadSysParm, []);

  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  const [orderType, setOrderType] = useState<"asc" | "desc">("asc");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCadastro, setOpenCadastro] = React.useState(false);
  const [rowId, setRowId] = React.useState(null);
  const [order, setOrder] = useState(1);

  const [codigo, setCodigo] = useState<string | null>("");
  const [descricao, setDescricao] = useState<string | null>("");
  const [texto, setTexto] = useState<string | null>("");
  const [inteiro, setInteiro] = useState<number | null>(null);
  const [numerico, setNumerico] = useState<number | null>(null);

  const handleUpdate = () => {
    let sysParam: SysParameterRequest = {
      id: rowId,
      codigo: codigo ?? "",
      descricao: descricao ?? "",
      texto: texto ?? "",
      inteiro: inteiro ?? null,
      numerico: numerico ?? null,
    };
    updateSysParm(sysParam);
    setOpenEdit(false);
  };

  const handleOpenDelete = (row: any) => {
    setRowId(row.id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenEdit = (row: any) => {
    setRowId(row.id);
    setCodigo(row.codigo);
    setDescricao(row.descricao);
    setTexto(row.texto);
    setInteiro(row.inteiro);
    setNumerico(row.numerico);

    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenCadastro = () => {
    setOpenCadastro(true);
  };

  const handleCloseCadastro = () => {
    setOpenCadastro(false);
  };

  function sortTable(orderByAtual: string) {
    let orderTypeAtual = orderType;

    if (orderTypeAtual === "asc") {
      setOrderType("desc");
      orderTypeAtual = "desc";
      setOrder(-1);
    } else if (orderTypeAtual === "desc") {
      setOrderType("asc");
      orderTypeAtual = "asc";
      setOrder(1);
    }

    setOrderBy(orderByAtual);

    return 0;
  }

  if (orderBy === "codigo") {
    sysParmList.sort((a: any, b: any) => [
      a.codigo.toLowerCase() > b.codigo.toLowerCase() ? order : -order,
    ]);
  }

  if (orderBy === "descricao") {
    sysParmList.sort((a: any, b: any) => [
      a.descricao.toLowerCase() > b.descricao.toLowerCase() ? order : -order,
    ]);
  }

  const classes = useStyles();

  sysParmList = Search(sysParmList, search);

  function deleteParameter() {
    deleteSysParm(rowId);
    setOpenDelete(false);
  }

  const ModalEdit = (
    <Grid className={classesModal.paper}>
      <div style={{ position: "relative" }}>
        <h2 style={{ position: "absolute", marginTop: 5 }}>
          {" "}
          Editar parâmetros do sistema
        </h2>
        <div
          style={{ position: "absolute", left: "97%" }}
          className={classesModal.icon}
        >
          <CancelIcon
            style={{
              color: "#ff0000",
            }}
            fontSize="large"
            onClick={handleCloseEdit}
          ></CancelIcon>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid style={{ marginTop: 55 }} item xs={12} sm={12}>
          <TextField
            id="codigo"
            name="codigo"
            label="Codigo"
            fullWidth
            autoComplete="codigo"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="descricao"
            name="descricao"
            label="Descrição"
            fullWidth
            autoComplete="descricao"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="texto"
            name="texto"
            label="Valor Texto"
            fullWidth
            autoComplete="texto"
            value={texto}
            onChange={(e) => setTexto(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="inteiro"
            name="inteiro"
            label="Valor Inteiro"
            autoComplete="inteiro"
            variant="outlined"
            type="number"
            value={inteiro}
            onChange={(e) => setInteiro(parseInt(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="double"
            id="double"
            autoComplete="double"
            label="Valor Double"
            type="number"
            variant="outlined"
            value={numerico}
            onChange={(e) => setNumerico(parseFloat(e.target.value))}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box>
      <TitleCard>Parâmetros do Sistema</TitleCard>

      <CustomFab label="Novo" icon={<AddIcon />} onClick={handleOpenCadastro} />
      <Paper className={classes.paperWrap}>
        <div className={classes.grow}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              type="text"
              placeholder="Pesquise aqui…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(e) => setSearch(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} />
        </div>
        <Loading loading={loading} />

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  Código
                  <TableSortLabel
                    active={orderBy === "codigo"}
                    direction={orderBy === "codigo" ? orderType : "asc"}
                    onClick={() => sortTable("codigo")}
                  >
                    {orderBy === "codigo" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  Descrição
                  <TableSortLabel
                    active={orderBy === "descricao"}
                    direction={orderBy === "descricao" ? orderType : "asc"}
                    onClick={() => sortTable("descricao")}
                  >
                    {orderBy === "descricao" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sysParmList.map((row: SysParameterRequest) => (
                <TableRow key={row.id}>
                  <TableCell scope="row">{row.codigo}</TableCell>
                  <TableCell align="center">{row.descricao}</TableCell>
                  <TableCell align="right">
                    <Button
                      style={{
                        color: "#388e3c",
                        borderColor: "#388e3c",
                      }}
                      title="Editar"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenEdit(row)}
                    >
                      <EditRoundedIcon />
                    </Button>

                    <Button
                      style={{
                        color: "#b20303",
                        borderColor: "#b20303",
                      }}
                      title="Excluir"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenDelete(row)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <ParameterDelete
          openDelete={openDelete}
          deleteParameter={deleteParameter}
          handleCloseDelete={handleCloseDelete}
        />

        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {ModalEdit}
        </Modal>

        <ParameterCreate
          openCadastro={openCadastro}
          handleCloseCadastro={handleCloseCadastro}
          postSysParm={postSysParm}
          loading={loading}
        />
      </Paper>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  sysParmList: state.systemParameter.sysParmList,
  loading: state.systemParameter.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadSysParm: () => {
    dispatch(getSystemParm());
  },
  deleteSysParm: (id: any) => {
    dispatch(deleteSystemParm(id)).then(() => dispatch(getSystemParm()));
  },
  updateSysParm: (sysParam: SysParameterRequest) => {
    dispatch(putSystemParm(sysParam)).then(() => dispatch(getSystemParm()));
  },
  postSysParm: (sysParam: SysParameterRequest) => {
    dispatch(postSystemParm(sysParam)).then(() => dispatch(getSystemParm()));
  },
});

export const SystemParameter = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataTable);
