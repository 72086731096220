import Axios from "axios";
import store, { mapErrorsToString } from "../redux";
import snackbarUtils from "../utils/toast";

const api = Axios.create({
  baseURL: process.env.REACT_APP_API,
  responseType: "json",
});

api.interceptors.request.use((config) => {
  const token = store.getState().user.userData.jwtToken;
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    // want to use the toast notification here
    if (response?.data?.message) {
      snackbarUtils.success(response.data.message);
    }
    return response;
  },
  (error) => {
    let message = mapErrorsToString(error);

    if (error.status >= 400 && error.status < 500) {
      snackbarUtils.warning(message);
    } else {
      snackbarUtils.error(message);
    }

    return Promise.reject(error);
  }
);

export default api;
