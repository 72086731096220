import { connect } from 'react-redux';
import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Loading } from '../../ui/Loading';

import { TitleCard } from '../../base/TitleCard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { TableHeader } from '../../base/TableHeader';
import api from '../../service/api';
import { useStyles } from './style';
import { ThemePagination } from '../../base/Pagination';
import { useEffect } from 'react';
import { DatePicker } from '../../base/Forms/DatePicker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { maskCurrency } from '../../utils/masks';

const PagamentoContemplacao = ({ userData }: any) => {
  var date = new Date();

  var codigoComissionado = userData.pontoVenda?.codigo;
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const [comissionado] = useState(codigoComissionado);

  const [dataInicial, setDataInicial] = useState<Date | null>(
    new Date(firstDay.toISOString().substring(0, 10))
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    new Date(date.toISOString().substring(0, 10))
  );

  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [size] = useState(10);

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleExportPdf = () => {
    setLoading(true);
    api
      .get('/Relatorio/PagamentoContemplacao', {
        params: {
          comissionado,
          dataInicial: dataInicial?.toISOString().substring(0, 10),
          dataFinal: dataFinal?.toISOString().substring(0, 10),
          reportPdf: true,
        },
        responseType: 'blob',
      })
      .then((response) => {
        setLoading(false);
        let blob = new Blob([response.data], { type: 'application/pdf' }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportXLS = () => {
    setLoading(true);
    api
      .get('/Relatorio/PagamentoContemplacao', {
        params: {
          comissionado,
          dataInicial: dataInicial?.toISOString().substring(0, 10),
          dataFinal: dataFinal?.toISOString().substring(0, 10),
          reportXLSX: true,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'PagamentoContemplacao.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePage = (event: any, newPage: number) => {
    setCurrentPage(newPage);
  };

  const loadData = () => {
    search();
  };

  const search = async () => {
    setLoading(true);
    try {
      const { data: numberOfPages } = await api.get(
        '/Relatorio/PagamentoContemplacao',
        {
          params: {
            comissionado,
            dataInicial: dataInicial?.toISOString().substring(0, 10),
            dataFinal: dataFinal?.toISOString().substring(0, 10),
            count: true,
          },
        }
      );
      setNumberOfPages(Math.ceil(numberOfPages / size));

      const { data: response } = await api.get(
        '/Relatorio/PagamentoContemplacao',
        {
          params: {
            comissionado,
            dataInicial: dataInicial?.toISOString().substring(0, 10),
            dataFinal: dataFinal?.toISOString().substring(0, 10),
            index: (currentPage - 1) * size,
            size,
          },
        }
      );
      setTableData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(loadData, [currentPage]);

  return (
    <Box>
      <TitleCard>
        {' '}
        <FileCopyIcon></FileCopyIcon> Pagamento de Contemplação
      </TitleCard>

      <Paper elevation={1} className={classes.paperWrap}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container>
            <Grid item xs={12} md={9}>
              <DatePicker
                required
                variant="inline"
                format="dd/MM/yyyy"
                name="dataInicial"
                id="dataInicial"
                label="Data Inicial"
                value={dataInicial}
                onChange={(date: Date | null) => setDataInicial(date)}
                style={{ marginRight: '24px' }}
              />
              <DatePicker
                required
                variant="inline"
                format="dd/MM/yyyy"
                name="dataFinal"
                id="dataFinal"
                label="Data Final"
                value={dataFinal}
                onChange={(date: Date | null) => setDataFinal(date)}
                style={{ marginRight: '24px' }}
              />
            </Grid>

            <Grid
              xs={12}
              md={3}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => search()}
                color="primary"
              >
                Consultar
              </Button>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Paper>
      <Paper elevation={1} className={classes.paperWrap}>
        <Loading loading={loading} />
        <TableHeader
          title="Pagamento de Contemplação"
          onClickPdfButton={handleExportPdf}
          onClickXlsButton={handleExportXLS}
        ></TableHeader>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.titleGrid}>Grupo</TableCell>
                <TableCell className={classes.titleGrid}>Cota</TableCell>
                <TableCell className={classes.titleGrid}>Versão</TableCell>
                <TableCell className={classes.titleGrid}>Consorciado</TableCell>
                <TableCell className={classes.titleGrid}>Favorecido</TableCell>
                <TableCell className={classes.titleGrid}>
                  CPF/CNPJ Favorecido
                </TableCell>
                <TableCell className={classes.titleGrid}>Situação</TableCell>
                <TableCell className={classes.titleGrid}>
                  Data Situação
                </TableCell>
                <TableCell className={classes.titleGrid}>Forma Pagto</TableCell>
                <TableCell className={classes.titleGrid}>Banco</TableCell>
                <TableCell className={classes.titleGrid}>Agência</TableCell>
                <TableCell className={classes.titleGrid}>Conta</TableCell>
                <TableCell className={classes.titleGrid}>Valor Pago</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{row.grupo}</TableCell>
                  <TableCell>{row.cota}</TableCell>
                  <TableCell>{row.versao}</TableCell>
                  <TableCell>{row.consorciado}</TableCell>
                  <TableCell>{row.favorecido}</TableCell>
                  <TableCell>{row.documento}</TableCell>
                  <TableCell>{row.situacao}</TableCell>
                  <TableCell>
                    {row.dataSituacao?.replace('T00:00:00', '')}
                  </TableCell>
                  <TableCell>{row.formaPagamento}</TableCell>
                  <TableCell>{row.banco}</TableCell>
                  <TableCell>{row.agencia}</TableCell>
                  <TableCell>{row.contaCorrente}</TableCell>
                  <TableCell>{maskCurrency(row.valorPago)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ThemePagination
        marginLeft="32px"
        pagesCount={numberOfPages}
        currentPage={currentPage}
        handlePageChange={handleChangePage}
      />
    </Box>
  );
};
const mapStateToProps = (state: any) => ({
  userData: state.user.userData,
});
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PagamentoContemplacao);
