import React, { Dispatch, SetStateAction, useContext } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { SelectComissao } from '../SelectComissao';
import { useStyles } from '../../style';
import { Loading } from '../../../../ui/Loading';
import { ComissaoContext } from '../../../../providers/Comissao';
import { DatePicker } from '../../../../base/Forms/DatePicker';

interface IFormComissoesFuturas {
  loading: boolean;
  fetchData: (index: number, count?: boolean) => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const FormComissoesFuturas = ({
  loading,
  fetchData,
  setCurrentPage,
}: IFormComissoesFuturas) => {
  const classes = useStyles();
  var {
    dataInicial,
    setDataInicial,
    dataFinal,
    setDataFinal,
    searchField,
    setSearchField,
    searchText,
    setSearchText,
  } = useContext(ComissaoContext);
  return (
    <>
      <Grid container spacing={3}>
        {/* Start container da esquerda */}
        <Grid container item xs={12} md={9}>
          {/* Start primeira linha do container da esquerda */}
          <Grid container spacing={5}>
            <Grid item xs={12} lg={4}>
              <SelectComissao loading={loading} />
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  disabled={loading}
                  required
                  disableToolbar
                  name="dataInicial"
                  variant="inline"
                  id="DataInicial"
                  autoComplete="DataInicial"
                  label="Data de Venda Inicial"
                  value={dataInicial}
                  onChange={(date: Date | null) => setDataInicial(date)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  disabled={loading}
                  required
                  disableToolbar
                  name="dataInicial"
                  variant="inline"
                  id="DataInicial"
                  autoComplete="Data de Venda Final"
                  label="Data de Venda Final"
                  value={dataFinal}
                  onChange={(date: Date | undefined) => setDataFinal(date)}
                />
              </FormControl>
            </Grid>

            {/* End primeira linha do container da esquerda */}
            {/* Start segunda linha do container da esquerda */}
          </Grid>
          <Grid container spacing={5} style={{ marginTop: '16px' }}>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="Filtro" required>
                  Filtrar por
                </InputLabel>

                <Select
                  disabled={loading}
                  style={{ minWidth: 180 }}
                  labelId="Filtro"
                  id="TipoConsulta"
                  value={searchField}
                  onChange={(e) => setSearchField(e.target.value as string)}
                  label="Tipo Comissão"
                >
                  <MenuItem value={' '}>Sem Filtro</MenuItem>
                  <MenuItem value={'contrato'}>Nr. Contrato</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {searchField === 'contrato' && (
              <Grid item xs={12} lg={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled={loading}
                    id="busca"
                    label="Busca"
                    fullWidth
                    autoComplete="Busca"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          {/* End container da esquerda */}
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              setCurrentPage(1);
              fetchData(0, true);
            }}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <div className={classes.loadingContainer}>
        <Loading loading={loading} />
      </div>
    </>
  );
};
