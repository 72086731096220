export const maskCnpj = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1/$2')
    .replace(/(\d{4})(\d{2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const maskCpf = (value?: string) => {
  if (!value) return '';
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const maskCurrency = (value?: string) => {
  if (!value) return 'R$ 0,00';
  return parseFloat(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

export const maskRg = (value?: string) => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d*)$/, '$1.$2.$3-$4');
  return value;
};

export const maskPhone = (value?: string) => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  if (value.length === 10)
    value = value.replace(/(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
  else value = value.replace(/(\d{2})(\d{5})(\d{4})(\d*)$/, '($1) $2-$3');

  return value;
};

export const unmask = (value?: string) => {
  if (!value) return '';
  return value.replace(/\D/g, '');
};
