import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "../style";

export const ZeroResultados = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paperZeroResultados} elevation={0}>
      <Typography className={classes.paperZeroResultadosTitle}>
        Nada foi encontrado!
      </Typography>

      <Typography className={classes.paperZeroResultadosSubtitle}>
        Não foi encontrado nenhum resultado com os termos ou filtros utilizados,
        por favor tente novamente com outros dados.
      </Typography>
    </Paper>
  );
};
