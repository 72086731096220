import { EmailConfigRequest } from '../../Models/EmailConfigRequest';

// Actions
const FETCH_EMAIL_CONFIG = "users/FETCH_EMAIL_CONFIG";
const FETCH_EMAIL_CONFIG_SUCCESS = "users/FETCH_EMAIL_CONFIG_SUCCESS";
const FETCH_EMAIL_CONFIG_FAIL = "users/FETCH_EMAIL_CONFIG_FAIL";
const PUT_EMAIL_CONFIG = "users/PUT_EMAIL_CONFIG";
const PUT_EMAIL_CONFIG_SUCCESS = "users/PUT_EMAIL_CONFIG_SUCCESS";
const PUT_EMAIL_CONFIG_FAIL = "users/PUT_EMAIL_CONFIG_FAIL";

// Default state
export const defaultState = {
  loading: false,

  emailConfigList: {
    id: 1,
    emailRemetente: '',
    emailReposta: '',
    fallback: '',
    necessitaAutenticacao: '',
    porta: '',
    senha: '', 
    servidor: '',
    tipo: '',
    usuario: ''
  },
}

// Reducer
export default function reducer(state: any = defaultState, action: any = {}) {
  switch (action.type) {

    case FETCH_EMAIL_CONFIG:
      return {
        ...state,
        loading: true
      }
    case FETCH_EMAIL_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        emailConfigList: {
          ...state.emailConfigList,
          ...action.payload.data
        }
      }
    case FETCH_EMAIL_CONFIG_FAIL:
      return {
        ...state,
        loading: false
      }


      case PUT_EMAIL_CONFIG:
        return {
          ...state,
          loading: true
        }
      case PUT_EMAIL_CONFIG_SUCCESS:
        return {
          ...state,
          loading: false
        }
      case PUT_EMAIL_CONFIG_FAIL:
        return {
          ...state,
          loading: false
        }
        default:
          return state;
  }
}

// Action Creators

export function getEmailConfig() {

  return {
    type: FETCH_EMAIL_CONFIG,
    payload: {
      request: {
        method: "GET",
        url: "/ConfiguracaoEmail",
      },
    },
  };
}

export function updateEmailConfig(emailConfig: EmailConfigRequest) {
  return {
    type: PUT_EMAIL_CONFIG,
    payload: {
      request: {
        method: "PUT",
        url: "/ConfiguracaoEmail",
        data: emailConfig
      },
    },
  };
}
