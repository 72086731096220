import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  PontoVendaRequest,
  TipoProdutoPontoVendaRequest,
} from "../../Models/PontoVendaRequest";
import ListSelect from "../../ui/ListSelect";
import { Loading } from "../../ui/Loading";
import { Alert } from "@material-ui/lab";
import { validarCNPJ } from "../../utils/utils";
import api from "../../service/api";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    overflowY: "auto",
    width: "100%",
    maxWidth: "800px",
    height: "100%",
    maxHeight: "800px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(2),
  },
  alert: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignItems: "center",
  },
  head: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    alignItems: "rigth",
    justifyContent: "flex-end",
  },
  foot: {
    padding: theme.spacing(3, 0, 0, 0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  input: {
    margin: "6px",
  },
  cancelIcon: {
    color: "#ff0000",
    cursor: "pointer",
  },
 
}));

export const PontoVendaEdit = (props: any) => {
  if (props.openEdit) {
    return <PontoVendaEditComponent {...props} />;
  } else {
    return null;
  }
};

export const PontoVendaEditComponent = ({
  openEdit,
  handleCloseEdit,
  putPontoVenda,
  tipoProdutoList,
  tipoProdutoLoading,
  pontoVendaEdit,
}: any) => {
  const tipoProdutoOg: any[] =
    pontoVendaEdit.tipoProdutoPontoVenda?.map((x: any) => ({
      title: x.tipoProduto.nome,
      value: x.tipoProduto.id,
    })) || [];

  const [id] = useState<number>(pontoVendaEdit.id);
  const [cnpj, setCnpj] = useState<string>(pontoVendaEdit.cnpj || "");
  const [classificacao, setClassificacao] = useState<string>(
    pontoVendaEdit.classificacao || ""
  );
  const [codigo, setCodigo] = useState<string>(pontoVendaEdit.codigo || "");
  const [grupoEconomico, setGrupoEconomico] = useState<string>(
    pontoVendaEdit.grupoEconomico || ""
  );
  const [nome, setNome] = useState<string>(pontoVendaEdit.nome || "");
  const [visualizaPortal] = useState<string>(
    pontoVendaEdit.visualizaPortal || ""
  );
  const [tipo, setTipo] = useState<string>(pontoVendaEdit.tipo || "");
  const [percTreinamento, setPercTreinamento] = useState<number>(
    pontoVendaEdit.percTreinamento || 0
  );

  const [bancoAtivo, setBancoAtivo] = React.useState<boolean>(pontoVendaEdit.bancoAtivo);
  const [tipoProduto, setTipoProduto] = React.useState<any[]>(tipoProdutoOg);

  const classes = useStyles();
  let [errorAlert, setErrorAlert] = useState(true);
  let [msgError, setMsgError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const options = tipoProdutoList.map((x: any) => ({
    title: x.nome,
    value: x.id,
  }));
  

  const handlePut = async () => {
    
    try {
      let pontoVenda: PontoVendaRequest = {
        id: id,
        cnpj: cnpj,
        classificacao: classificacao,
        codigo: codigo,
        grupoEconomico: grupoEconomico,
        nome: nome,
        visualizaPortal: visualizaPortal,
        tipo: tipo,
        bancoAtivo: bancoAtivo,
        percTreinamento: percTreinamento,
        tipoProdutoPontoVendaRequest: tipoProduto.map(
          (x: any) =>
            ({
              IdTipoProduto: x.value,
            } as TipoProdutoPontoVendaRequest)
        ),
      };
      if (codigo === "" || cnpj === "" || grupoEconomico === "" || classificacao === "" || tipo === "" || nome === "") {
        setMsgError("Campo com * é de preenchimento obrigatório");
        setErrorAlert(false)
      } else {
        if (validarCNPJ(cnpj)) {
          setLoading(true);
          api.put("/PontoVendas", pontoVenda).then((response) => {
            if(response.status === 200){
              setCnpj("");
              setNome("");
              setClassificacao("");
              setCodigo("");
              setGrupoEconomico("");
              setTipo("");
              setPercTreinamento(0);
              setCodigo("");
              setBancoAtivo(false);
              setTipoProduto([]);
              setErrorAlert(true);
              putPontoVenda(pontoVenda);
            }
            handleCloseEdit();
            setLoading(false);
          });
        }else {
          setMsgError("O CNPJ inserido é inválido");
          setErrorAlert(false)
        }
      }
    }catch (error) {
        setLoading(false);
    }
    
};

const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setBancoAtivo(event.target.checked);
};

  return (
    <Modal
      open={openEdit}
      onClose={handleCloseEdit}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.paperWrap}>
          <div className={classes.head}>
            <Typography variant="h6">Cadastro de Ponto de Venda</Typography>
            <CancelIcon
              style={{
                color: "#ff0000",
              }}
              fontSize="large"
              onClick={handleCloseEdit}
            />
          </div>
          <Loading loading={loading}></Loading>

          <div style={{ position: "relative", paddingTop: 16 }}>
            <Grid container spacing={3}>

              <Grid item xs={6}  >
                <FormControl fullWidth>
                  <InputLabel id="selector-de-tipo-de-pv" >
                    Tipo do Ponto de Venda *
                  </InputLabel>
                  <Select
                    labelId="selector-de-tipo-de-pv"
                    label="Tipo do Ponto de Venda"
                    required
                    name="tipo"
                    value={tipo}
                    fullWidth
                    onChange={(e) => setTipo(e.target.value as string)}
                  >
                    <MenuItem value="CCY">Concessionária</MenuItem>
                    <MenuItem value="REP">Representante</MenuItem>
                    <MenuItem value="FAB">Fabricante</MenuItem>
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={6}  >
                <FormControl fullWidth>

                  <TextField
                    className={classes.input}
                    required
                    name="codigo"
                    label="Código"
                    value={codigo}
                    fullWidth
                    onChange={e => setCodigo(e.target.value)}
                  />

                </FormControl>
              </Grid>
              <Grid item xs={6}  >
                <FormControl fullWidth>
                  <TextField
                    className={classes.input}
                    required
                    name="nome"
                    label="Nome"
                    value={nome}
                    fullWidth
                    onChange={e => setNome(e.target.value)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}  >
                <FormControl fullWidth>
                  <TextField
                    className={classes.input}
                    required
                    name="cnpj"
                    label="CNPJ"
                    value={cnpj}
                    fullWidth
                    onChange={e => setCnpj(e.target.value)}
                  />

                </FormControl>
              </Grid>


              <Grid item xs={6}  >
                <FormControl fullWidth>
                  <TextField
                    required
                    name="grupoEconomico"
                    label="Grupo Econômico"
                    value={grupoEconomico}
                    fullWidth
                    onChange={e => setGrupoEconomico(e.target.value)}
                  />
                </FormControl>

              </Grid>

              <Grid item xs={6}  >
                <FormControl fullWidth>
                  <TextField
                    className={classes.input}
                    required
                    name="classificacao"
                    label="Classificação"
                    value={classificacao}
                    fullWidth
                    onChange={e => setClassificacao(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}  >
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    required
                    name="percTreinamento"
                    label="Percentual de Treinamento"
                    value={percTreinamento}
                    fullWidth
                    onChange={(e) => setPercTreinamento(parseFloat(e.target.value))}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}  >
                <FormControl fullWidth>
                  <ListSelect

                    label="Tipos de Produto"
                    placeholder="Selecione os tipos..."
                    fullWidth
                    options={options}
                    value={tipoProduto}
                    setValue={setTipoProduto}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="bancoAtivo"
                    checked={bancoAtivo}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                  BANCO ATIVO
                  </label>
                </div>
                </FormControl>
              </Grid>

              <Grid item xs={6}  >
                <div className={classes.alert} hidden={errorAlert} >
                  <Alert severity="error">{msgError}</Alert>
                </div>
              </Grid>

              <Grid item xs={6} className={classes.button}>
                <Button
                  onClick={handlePut}
                  variant="contained"
                  color="primary"
                  disabled={loading}

                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    </Modal>
  );
};
