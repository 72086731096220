
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Modal, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react'

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: "auto",
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    },
    paperWrap: {
        padding: theme.spacing(4),
    },
    head: {
        // padding: theme.spacing(4),
        display: 'flex',
        flexDirection: "row",
        justifyContent: "start"
    },
    foot: {
        padding: theme.spacing(4, 0, 0, 0),
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "end"
    },
}));

export const TabelaPrecoDelete = ({
    openDelete,
    handleCloseDelete,
    loading,
    deleteTabelaPreco
}: any) => {

    const [bttColor] = useState<boolean>(false);

    const classes = useStyles();

    return (
        <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <DialogTitle id="alert-dialog-title">{"Deseja Realmente Remover a Tabela de Preço?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Todos os arquivos serão excluidos
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDelete} color="primary">
                    NÃO
                </Button>
                <Button onClick={deleteTabelaPreco} color="primary" autoFocus>
                    SIM
                </Button>
            </DialogActions>
        </Dialog>
    )

}