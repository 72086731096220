import { AuthenticationRequest } from "../../Models/AuthenticationRequest";
import { MyDataRequest } from "../../Models/MyDataRequest";
import { RevokeTokenRequest } from "../../Models/RevokeTokenRequest";
import { DeactivateUserRequest } from "../../Models/DeactivateUserRequest";
import { FilterUsersRequst } from "../../Models/FilterUsersRequest";
import { encryptData } from "../../utils/utils";

// Actions
const LOGIN = "users/LOGIN";
const LOGIN_SUCCESS = "users/LOGIN_SUCCESS";
const LOGIN_FAIL = "users/LOGIN_FAIL";
const LOGOUT = "users/LOGOUT";
const LOGOUT_SUCCESS = "users/LOGOUT_SUCCESS";
const LOGOUT_FAIL = "users/LOGOUT_FAIL";
const UPDATE_MY_DATA = "users/UPDATE_MY_DATA";
const UPDATE_MY_DATA_SUCCESS = "users/UPDATE_MY_DATA_SUCCESS";
const UPDATE_MY_DATA_FAIL = "users/UPDATE_MY_DATA_FAIL";
const FETCH_MY_DATA = "users/FETCH_MY_DATA";
const FETCH_MY_DATA_SUCCESS = "users/FETCH_MY_DATA_SUCCESS";
const FETCH_MY_DATA_FAIL = "users/FETCH_MY_DATA_FAIL";
const FETCH_MY_TELAS = "users/FETCH_MY_TELAS";
const FETCH_MY_TELAS_SUCCESS = "users/FETCH_MY_TELAS_SUCCESS";
const FETCH_MY_TELAS_FAIL = "users/FETCH_MY_TELAS_FAIL";
const PUT_DEACTIVE_USER = "users/PUT_REACTIVE_USER";
const PUT_DEACTIVE_USER_SUCESS = "users/PUT_REACTIVE_USER";
const PUT_DEACTIVE_USER_FAIL = "users/PUT_REACTIVE_USER";
const FETCH_USERS_DATA = "users/FETCH_USERS_DATA";
const FETCH_USERS_DATA_SUCCESS = "users/FETCH_USERS_DATA_SUCCESS";
const FETCH_USERS_DATA_FAIL = "users/FETCH_USERS_DATA_FAIL";
const SET_SEARCH = '"users/SET_SEARCH";';
const FETCH_BUSINESS_UNIT = "users/FETCH_BUSINESS_UNIT";
const FETCH_BUSINESS_UNIT_SUCCESS = "users/FETCH_BUSINESS_UNIT_SUCCESS";
const FETCH_BUSINESS_UNIT_FAIL = "users/FETCH_BUSINESS_UNIT_FAIL";
const FETCH_PERFIL_APP = "users/FETCH_PERFIL_APP";
const FETCH_PERFIL_APP_SUCCESS = "users/FETCH_PERFIL_APP_SUCCESS";
const FETCH_PERFIL_APP_FAIL = "users/FETCH_PERFIL_APP_FAIL";
const FETCH_PONTO_VENDAS_TITLE = "users/FETCH_PONTO_VENDAS_TITLE";
const FETCH_PONTO_VENDAS_TITLE_SUCCESS = "users/FETCH_PONTO_VENDAS_TITLE_SUCCESS";
const FETCH_PONTO_VENDAS_TITLE_FAIL = "users/FETCH_PONTO_VENDAS_TITLE_FAIL";
const DELETE_USER = "users/DELETE_USER";
const DELETE_USER_SUCCESS = "users/DELETE_USER_SUCCESS";
const DELETE_USER_FAIL = "users/DELETE_USER_FAIL";

// Default state
export const defaultState = {
  loading: false,
  userData: {
    email: null,
    id: null,
    jwtToken: null,
    nome: null,
  },
  grupoTela: [],
  userRegistration: {
    tipoPessoa: "",
    nome: "",
    email: "",
    idPerfilUsuario: "",
    cnpj: "",
    telefone: "",
    idUnidadeNegocio: "",
    idPontoVenda: "",
  },

  DeactiveUser: {
    idPerfilUsuario: "",
  },
  filterUserRequst: {
    index: null,
    size: null,
    search: "",
    orderBy: "",
    orderType: "",
  },

  EditUser: {
    tipoPessoa: "",
    nome: "",
    email: "",
    cnpj: "",
    idPerfilUsuario: "",
    celular: "",
    idUnidadeNegocio: "",
    idPontoVenda: "",
  },
};

// Reducer
export default function reducer(state: any = defaultState, action: any = {}) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload.data,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_MY_DATA:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_MY_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };

    case FETCH_PERFIL_APP:
      return {
        ...state,
        loading: true,
      };

    case FETCH_PERFIL_APP_SUCCESS:
      return {
        ...state,
        loading: false,
        appProfileList: action.payload.data,
      };
    case FETCH_PERFIL_APP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_PONTO_VENDAS_TITLE_SUCCESS:
      return {
        ...state,
        loading: false,
        pontoVendaTitleList: action.payload.data,
      };
    case FETCH_PONTO_VENDAS_TITLE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case FETCH_PONTO_VENDAS_TITLE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MY_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: {
          ...state.userData,
          ...action.payload.data,
        },
      };
    case FETCH_MY_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_MY_TELAS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MY_TELAS_SUCCESS:
      return {
        ...state,
        loading: false,
        grupoTela: action.payload.data,
      };
    case FETCH_MY_TELAS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case PUT_DEACTIVE_USER:
      return {
        ...state,
        loading: true,
      };
    case PUT_DEACTIVE_USER_SUCESS:
      return {
        ...state,
        loading: false,
      };
    case PUT_DEACTIVE_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case LOGOUT:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    case LOGOUT_FAIL:
      return defaultState;
    case FETCH_BUSINESS_UNIT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        businessUnitList: action.payload.data,
      };
    case FETCH_BUSINESS_UNIT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_USERS_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USERS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.payload.data,
      };
    case FETCH_USERS_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search,
      };

    default:
      return state;
  }
}

// Action Creators
export function login(authRequest: AuthenticationRequest) {
  return {
    type: LOGIN,
    payload: {
      request: {
        method: "POST",
        url: "/Users/authenticate",
        data: encryptData(authRequest),
      },
    },
  };
}

export function putMyData(user: MyDataRequest) {
  return {
    type: UPDATE_MY_DATA,
    payload: {
      request: {
        method: "PUT",
        url: "/Users/EditUserData",
        data: user,
      },
    },
  };
}

export function getMyData() {
  return {
    type: FETCH_MY_DATA,
    payload: {
      request: {
        method: "GET",
        url: "/Users/UserData",
      },
    },
  };
}

export function getTelas() {
  return {
    type: FETCH_MY_TELAS,
    payload: {
      request: {
        method: "GET",
        url: "/Users/UserScreen",
      },
    },
  };
}

export function getPontoVendasTitle() {
  return {
    type: FETCH_PONTO_VENDAS_TITLE,
    payload: {
      request: {
        method: "GET",
        url: "/PontoVendas/Title",
      },
    },
  };
}

export function getUnidadeNegocio() {
  return {
    type: FETCH_BUSINESS_UNIT,
    payload: {
      request: {
        method: "GET",
        url: "/UnidadeNegocio",
      },
    },
  };
}

export function getPerfilApp() {
  return {
    type: FETCH_PERFIL_APP,
    payload: {
      request: {
        method: "GET",
        url: "/PerfilUsuario",
      },
    },
  };
}

export function logout(data: RevokeTokenRequest) {
  return {
    type: LOGOUT,
    payload: {
      request: {
        method: "POST",
        url: "/Users/RevokeToken",
        data: data,
      },
    },
  };
}
export function putDeactive(user: DeactivateUserRequest) {
  return {
    type: PUT_DEACTIVE_USER,
    payload: {
      request: {
        method: "PUT",
        url: "/Users/Deactivate",
        params: {
          id: user,
        },
      },
    },
  };
}

export function deleteUser(id: number) {
  return {
    type: DELETE_USER,
    payload: {
      request: {
        method: "DELETE",
        url: "/Users",
        params: {
          id: id,
        },
      },
    },
  };
}

export function getUsersData(filter: FilterUsersRequst) {
  return {
    type: FETCH_USERS_DATA,
    payload: {
      request: {
        method: "GET",
        url: "/Users/PagedUserQuery",
        params: {
          index: filter.index,
          size: filter.size,
          search: filter.search,
          orderBy: filter.orderBy,
          orderType: filter.orderType,
        },
      },
    },
  };
}
