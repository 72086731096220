import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useStyles } from "../style";
import React from "react";
import { GestaoCarteira } from "../../../Models/GestaoCarteira";
import { format, parseISO } from "date-fns";
import { maskCpf, maskCurrency } from "../../../utils/masks";

export const TableContent = ({ tableData }: any) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellTitle}>Contrato</TableCell>
            <TableCell className={classes.tableCellTitle}>Grupo</TableCell>
            <TableCell className={classes.tableCellTitle}>Cota</TableCell>
            <TableCell className={classes.tableCellTitle}>Versão</TableCell>
            <TableCell className={classes.tableCellTitle}>Data Venda</TableCell>
            <TableCell className={classes.tableCellTitle}>Data Vencimento</TableCell>
            <TableCell className={classes.tableCellTitle}>CPF. Vendedor</TableCell>
            <TableCell className={classes.tableCellTitle}>
              Data Cancelamento
            </TableCell>
            <TableCell className={classes.tableCellTitle}>Bem</TableCell>
            <TableCell className={classes.tableCellTitle}>Vlr. Bem</TableCell>
            <TableCell className={classes.tableCellTitle}>
              Contemplação
            </TableCell>
            <TableCell className={classes.tableCellTitle}>
              Tipo Contemplação
            </TableCell>
            <TableCell className={classes.tableCellTitle}>Entrega</TableCell>
            <TableCell className={classes.tableCellTitle}>Fornecedor</TableCell>
            <TableCell className={classes.tableCellTitle}>% Pago</TableCell>
            <TableCell className={classes.tableCellTitle}>Vlr. Pago</TableCell>
            <TableCell className={classes.tableCellTitle}>% a Pagar</TableCell>
            <TableCell className={classes.tableCellTitle}>
              Vlr. a Pagar
            </TableCell>
            <TableCell className={classes.tableCellTitle}>
              Parcelas Pagas
            </TableCell>
            <TableCell className={classes.tableCellTitle}>
              Parcelas a Pagar
            </TableCell>
            <TableCell className={classes.tableCellTitle}>
              Parcelas em atraso
            </TableCell>
            <TableCell className={classes.tableCellTitle}>Enc. Grupo</TableCell>
            <TableCell className={classes.tableCellTitle}>
              Cota Transf.
            </TableCell>
            <TableCell className={classes.tableCellTitle}>
              Nome Cliente
            </TableCell>
            <TableCell className={classes.tableCellTitle}>DDD</TableCell>
            <TableCell className={classes.tableCellTitle}>Fone</TableCell>
            <TableCell className={classes.tableCellTitle}>Email</TableCell>
            <TableCell className={classes.tableCellTitle}>Situação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map((row: GestaoCarteira) => (
            <TableRow key={row.contrato}>
              <TableCell>{row.contrato}</TableCell>
              <TableCell>{row.grupo}</TableCell>
              <TableCell>{row.cota}</TableCell>
              <TableCell>{row.versao}</TableCell>
              <TableCell>
                {row.datavenda &&
                  format(parseISO(row.datavenda.toString()), "dd/MM/yyyy")}
              </TableCell>
              <TableCell>
                {row.datavencimento &&
                  format(parseISO(row.datavencimento.toString()), "dd/MM/yyyy")}
              </TableCell>
              <TableCell>{maskCpf(row.cpfvendedor)}</TableCell>
              <TableCell>
                {row.dt_datacancelamento &&
                  format(
                    parseISO(row.dt_datacancelamento.toString()),
                    "dd/MM/yyyy"
                  )}
              </TableCell>
              <TableCell>{row.bemEntrega}</TableCell>
              <TableCell>{maskCurrency(row.vlbem.toString())}</TableCell>
              <TableCell>
                {row.datacontemplacao &&
                  format(
                    parseISO(row.datacontemplacao.toString()),
                    "dd/MM/yyyy"
                  )}
              </TableCell>
              <TableCell>{row.tipocontemplacao}</TableCell>
              <TableCell>
                {row.dataentregabem &&
                  format(parseISO(row.dataentregabem.toString()), "dd/MM/yyyy")}
              </TableCell>
              <TableCell>{row.fornecedor}</TableCell>
              <TableCell>{row.percpago}</TableCell>
              <TableCell>{maskCurrency(row.valorpago.toString())}</TableCell>
              <TableCell>{row.percapagar}</TableCell>
              <TableCell>{maskCurrency(row.valorapagar.toString())}</TableCell>
              <TableCell>{row.qtdeparcelapaga}</TableCell>
              <TableCell>
                {row.qtdeparcelaapagar ? 0 : row.qtdeparcelaapagar}
              </TableCell>
              <TableCell>{row.qtdeparcelaatraso}</TableCell>
              <TableCell>
                {row.dataencerramentogrupo &&
                  format(
                    parseISO(row.dataencerramentogrupo.toString()),
                    "dd/MM/yyyy"
                  )}
              </TableCell>
              <TableCell>{row.cotatransferida}</TableCell>
              <TableCell>{row.nomecliente}</TableCell>
              <TableCell>{row.ddd}</TableCell>
              <TableCell>{row.telefone}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
