import { Button, Grid, makeStyles, Modal, Paper, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import { TipoProdutoRequest } from '../../Models/TipoProdutoRequest'


const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "800px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(4),
  },
  head: {
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  foot: {
    padding: theme.spacing(4, 0, 0, 0),
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
}));

export const TipoProdutoEdit = (props: any) => {
  if (props.openEdit) {
    return <TipoProdutoditComponent {...props} />;
  } else {
    return null
  }
}

export const TipoProdutoditComponent = ({
  openEdit,
  handleCloseEdit,
  putTipoProduto,
  loading,
  tipoProdutoEdit

}: any) => {
  const [id] = useState<number>(tipoProdutoEdit.id || null);
  const [nome, setNome] = useState<string>(tipoProdutoEdit.nome || "");
  const [sigla, setSigla] = useState<string>(tipoProdutoEdit.sigla || "");

  const classes = useStyles();

  const handlePut = () => {
    let tipoProdutoEdit: TipoProdutoRequest = {
      id: id,
      nome: nome,
      sigla: sigla
    }
    putTipoProduto(tipoProdutoEdit);
    handleCloseEdit();
  }

  return (
    <Modal
      open={openEdit}
      onClose={handleCloseEdit}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.paperWrap}>
          <div className={classes.head}>
            <Typography variant="h6" >
              Cadastro de Ponto de Venda
            </Typography>
            <CancelIcon
              style={{
                color: "#ff0000",
              }}
              fontSize="large"
              onClick={handleCloseEdit}
            />
          </div>

          <div style={{ position: "relative", paddingTop: 40 }}>

            <TextField
              required
              name="sigla"
              label="Sigla"
              value={sigla}
              fullWidth
              onChange={e => setSigla(e.target.value)}
            />
            <TextField
              required
              name="nome"
              label="Nome"
              value={nome}
              fullWidth
              onChange={e => setNome(e.target.value)}
            />

            <div className={classes.foot}>
              <Button
                onClick={handlePut}
                color="primary"
                disabled={loading}
              >
                Sim
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>
    </Modal>
  )
}