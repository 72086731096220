import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tablePaperWrap: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      padding: theme.spacing(2),
    },
    gridTableTitle: {
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    tableTitle: { color: '#808080', fontWeight: 'bold' },

    exportButton: {
      background: '#FAFAFA',
      marginLeft: '4px',
      marginRight: '4px',
    },

    backButtonTableHeader: {
      marginRight: '4px',
    },

    buttonIcon: {
      paddingRight: theme.spacing(1),
    },
    paginationItem: {
      height: '56px',
    },
    paginationPaper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px 8px',
    },
    paginationWrapper: {
      display: 'flex',
      height: '56px',
      marginTop: 16,
    },
  })
);
