import CryptoJS from 'crypto-js';
import { EncryptedDataRequest } from '../Models/EncryptedDataRequest';
export function getCurrentDate(separator = '') {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
}

export function formataData(data: any, separator = '', begin = 0) {
  var mes = data.getMonth();
  var ano = data.getFullYear();

  var daysInMonth = new Date(ano, mes, begin).getDate();

  if (mes.toString().length == 1) mes = '0' + mes;

  if (separator === '-') {
    return ano + separator + mes + separator + daysInMonth;
  } else if (separator === '/')
    return daysInMonth + separator + mes + separator + ano;
}

export function getCurrentMedia(qtdMonth: number, allRegister: number) {
  var result = allRegister / qtdMonth;
  return result;
}

export function parseRelatorioParams(params: any) {
  var parsedObject = params;

  parsedObject.dataInicial =
    parsedObject.dataInicial.toLocaleDateString('fr-CA');
  parsedObject.dataFinal = parsedObject.dataFinal.toLocaleDateString('fr-CA');

  return parsedObject;
}

export function validarCNPJ(cnpj: string) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho: any = cnpj.length - 2;
  let numeros: any = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i: any = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado: any = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i: any = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

export function encryptData(data: any) {
  var message = JSON.stringify(data);
  var encrypted = window.btoa(message).toString();
  var dataEncrypted: EncryptedDataRequest = {
    Data: encrypted,
  };
  return dataEncrypted;
}
