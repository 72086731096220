import React from 'react';
import { Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { useStylesExtrato } from '../styleExtrato';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface GridItem {
  data?: any;
  valor?: any;
  descricao?: any;
  loading?: any;
  backgroundColor?: any;
  tipoConsulta?: any;
  onClickStatus?: () => void;
  onClickPdfButton?: () => void;
  onClickXlsButton?: () => void;
}

export const GridItem = ({
  data,
  valor,
  descricao,
  backgroundColor,
  loading,
  onClickStatus,
}: GridItem) => {
  const classes = useStylesExtrato();
  return (
    <Grid item xs={12} lg={4}>
      <Paper style={{ backgroundColor: backgroundColor, margin: '20px' }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography className={classes.cardData}>{data}</Typography>
          <IconButton onClick={onClickStatus} className={classes.button}>
            <VisibilityIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Typography className={classes.cardValor}>
          {loading ? (
            <>
              <Skeleton style={{ width: '60%' }}></Skeleton>
            </>
          ) : (
            valor
          )}
        </Typography>

        <Typography className={classes.cardDescription}>{descricao}</Typography>
      </Paper>
    </Grid>
  );
};
