import {
  Box,
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ThemePagination } from "../../../../base/Pagination";
import { TableHeader } from "../../../../base/TableHeader";
import { ZeroResultados } from "../ZeroResultados";
import { useStyles } from "../../style";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { format, parseISO } from "date-fns";
import api from "../../../../service/api";
import { ComissaoPagaDetalhes } from "../../../../Models/ComissaoPagaDetalhes";
import { maskCpf, maskCurrency } from "../../../../utils/masks";
import { useContext } from "react";
import { ComissaoContext } from "../../../../providers/Comissao";

export default ({
  updateData,
  tableData,
  itemsAmount,
  setLoading,
  handleOpenDetalhes,
  overviewTableActive,
  setOverviewTableActive,
  currentPage,
  setCurrentPage,
  currentPageOverview,
  setCurrentPageOverview,
}: any) => {
  var { dataInicial, dataFinal, searchField, searchText } =
    useContext(ComissaoContext);
  const classes = useStyles();

  const [overviewTableSize, setOverviewTableSize] = useState(itemsAmount);
  const [idNotaFiscal, setIdNotaFiscal] = useState("");

  const [dataDetailsTable, setDataDetailsTable] = useState([]);
  const [dataDetailsTableSize, setDataDetailsTableSize] = useState(0);

  const handleChangePage = async (event: any, newPage: number) => {
    if (!overviewTableActive) {
      setCurrentPage(newPage);
    } else {
      await updateData(newPage - 1);
      setCurrentPageOverview(newPage);
    }
  };

  const handleBackButtonPress = () => {
    setOverviewTableActive(true);
  };

  const handleExportPdf = () => {
    setLoading(true);
    api
      .get("/Relatorio/comissaoPaga", {
        params: {
          dataInicial,
          dataFinal,
          searchField,
          searchText,
          reportPdf: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        let blob = new Blob([response.data], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);

        window.open(url);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const handleExportPdfDetails = () => {
    setLoading(true);
    api
      .get("/Relatorio/comissaoPagaDetails", {
        params: {
          codigoNf: idNotaFiscal,
          reportPdf: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        let blob = new Blob([response.data], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);

        window.open(url);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleExportXLS = () => {
    setLoading(true);
    api
      .get("/Relatorio/comissaoPaga", {
        params: {
          dataInicial,
          dataFinal,
          searchField,
          searchText,
          reportXLSX: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ComissaoPaga.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportXLSDetails = () => {
    setLoading(true);
    api
      .get("/Relatorio/comissaoPagaDetails", {
        params: {
          codigoNf: idNotaFiscal,
          reportXLSX: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ComissaoPaga.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDetailsData = () => {
    if (overviewTableActive) return;

    setLoading(true);
    api
      .get("Relatorio/comissaoPagaDetails", {
        params: {
          codigoNf: idNotaFiscal,
          size: 10,
          index: (currentPage - 1) * 10,
        },
      })
      .then(({ data }: any) => {
        setDataDetailsTable(data);
        setOverviewTableActive(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDetailsDataSize = () => {
    if (overviewTableActive) return;
    setLoading(true);
    api
      .get("Relatorio/comissaoPagaDetailsCount", {
        params: {
          codigoNf: idNotaFiscal,
        },
      })
      .then(({ data }: any) => {
        setDataDetailsTableSize(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEyeClick = (nf: string) => {
    setIdNotaFiscal(nf);
    setCurrentPage(1);
    setOverviewTableActive(false);
  };

  useEffect(fetchDetailsDataSize, [idNotaFiscal]);

  useEffect(fetchDetailsData, [currentPage, idNotaFiscal]);

  useEffect(() => {
    setOverviewTableSize(itemsAmount);
  }, [itemsAmount]);

  const DataTable = () => {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellTitle}>Contrato</TableCell>
              <TableCell className={classes.tableCellTitle}>Grupo</TableCell>
              <TableCell className={classes.tableCellTitle}>Cota</TableCell>
              <TableCell className={classes.tableCellTitle}>Versão</TableCell>
              <TableCell className={classes.tableCellTitle}>
                CPF Vendedor
              </TableCell>
              <TableCell className={classes.tableCellTitle}>Vlr. Bem</TableCell>
              <TableCell className={classes.tableCellTitle}>
                Data Venda
              </TableCell>
              <TableCell className={classes.tableCellTitle}>Período</TableCell>
              <TableCell className={classes.tableCellTitle}>
                Parcela Comissão
              </TableCell>
              <TableCell className={classes.tableCellTitle}>
                Comissão (%)
              </TableCell>
              <TableCell className={classes.tableCellTitle}>Estorno</TableCell>
              <TableCell className={classes.tableCellTitle}>
                Vlr. Total
              </TableCell>
              <TableCell className={classes.tableCellTitle}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataDetailsTable?.map((row: ComissaoPagaDetalhes) => (
              <TableRow key={row.id_documento}>
                <TableCell>{row.id_documento}</TableCell>
                <TableCell>{row.cd_grupo}</TableCell>
                <TableCell>{row.cd_cota}</TableCell>
                <TableCell>{row.versao}</TableCell>
                <TableCell>{maskCpf(row.cpfVendedor)}</TableCell>
                <TableCell>{maskCurrency(row.vl_bem.toString())}</TableCell>
                <TableCell>
                  {row.dt_venda
                    ? format(parseISO(row.dt_venda.toString()), "dd/MM/yyyy")
                    : ""}
                </TableCell>
                <TableCell>{row.periodo}</TableCell>
                <TableCell>{row.no_parcela}</TableCell>
                <TableCell>{row.pe_comissao}</TableCell>
                <TableCell>{maskCurrency(row.vl_estorno.toString())}</TableCell>
                <TableCell>{maskCurrency(row.vl_total.toString())}</TableCell>
                <TableCell className={classes.eyeIcon}>
                  <Button
                    color="primary"
                    onClick={() => handleOpenDetalhes(row)}
                  >
                    <VisibilityIcon className={classes.eyeIcon} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const OverviewTable = (row: any) => {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellTitle}>
                Nota Fiscal
              </TableCell>
              <TableCell className={classes.tableCellTitle}>
                Data Pagamento
              </TableCell>
              <TableCell className={classes.tableCellTitle}>Período</TableCell>
              <TableCell className={classes.tableCellTitle}>
                Valor Pago
              </TableCell>
              <TableCell className={classes.tableCellTitle}>Detalhes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((row: any) => (
              <TableRow key={row.id}>
                <TableCell>{row.notaFiscal}</TableCell>
                <TableCell>
                  {row.dataPagamento
                    ? format(parseISO(row.dataPagamento), "dd/MM/yyyy")
                    : ""}
                </TableCell>
                <TableCell>{row.periodo}</TableCell>
                <TableCell>{maskCurrency(row.valor)}</TableCell>
                <TableCell className={classes.eyeIcon}>
                  <Button
                    color="primary"
                    onClick={() => handleEyeClick(row.id)}
                  >
                    <VisibilityIcon className={classes.eyeIcon} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const TableContent = () => {
    return (
      <>
        <TableHeader
          title="Comissões Pagas"
          onClickBackButton={
            overviewTableActive ? undefined : handleBackButtonPress
          }
          onClickPdfButton={
            overviewTableActive ? handleExportPdf : handleExportPdfDetails
          }
          onClickXlsButton={
            overviewTableActive ? handleExportXLS : handleExportXLSDetails
          }
        />
        <Divider />
      </>
    );
  };

  return (
    <>
      <Box className={classes.container}>
        {itemsAmount != null && (
          <Paper className={classes.paperSpace}>
            {itemsAmount > 0 && <TableContent />}
            {itemsAmount === 0 && <ZeroResultados />}
            {overviewTableActive && itemsAmount > 0 && <OverviewTable />}
            {!overviewTableActive && itemsAmount > 0 && <DataTable />}
          </Paper>
        )}
      </Box>
      {itemsAmount > 0 && (
        <ThemePagination
          marginLeft="0px"
          pagesCount={
            overviewTableActive
              ? Math.ceil(overviewTableSize / 10)
              : Math.ceil(dataDetailsTableSize / 10)
          }
          currentPage={overviewTableActive ? currentPageOverview : currentPage}
          handlePageChange={handleChangePage}
        />
      )}
    </>
  );
};
