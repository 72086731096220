import React, { useEffect, useState } from "react";
import { AppBar, Box, Button, CircularProgress, Divider, Drawer, Grid, Tab, Tabs, TextField, Typography, } from '@material-ui/core'
import { connect } from 'react-redux'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import api from "../../service/api";
import { AuthenticationRequest } from "../../Models/AuthenticationRequest";
import { getMyData, login } from "../../redux/ducks/user";
import Alert from "@material-ui/lab/Alert";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ActivationRequest } from "../../Models/ActivationRequest";
import { Loading } from "../../ui/Loading";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    alert: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      alignItems: "center"
    },
    gridMargin: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),

    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    progressValid: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "25px",
    },

    main: {
      backgroundImage: "url(/assets/bg.jpg)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100%",
      minHeight: "100vh",
    },
    divInterna: {
      left: "50%",
      top: "50%",
      marginLeft: "-300px",
      marginTop: "-150px",
      position: "absolute",
      width: "600px",
      heigth: "500px",
      background: "#FFFFFF",

    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },

  }),
);

function getSteps() {
  return ['ATIVAÇÃO', 'DEFINIR SENHA'];
}

export default function ActivationScreen(data: any, props: any) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const steps = getSteps();
  const [email, setEmail] = React.useState<string | undefined>();
  const [senha, setSenha] = React.useState<string | undefined>("");
  const [senhaTemporaria, setSenhaTemporaria] = React.useState<string | undefined>();
  const [confirmaSenha, setConfirmaSenha] = React.useState<string | undefined>("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [errorAlert, setErrorAlert] = useState(true);
  const [open] = React.useState(true);
  const [valid, setValid] = React.useState(false);
  const parametros: any = useParams();
  const codigoAtivacao = parametros['codigoAtivacao'];
  const history = useHistory();
  const [errorMessageTabTwo, setErrorMessageTabTwo] = useState<string>("")

  const ValidateActivation = () => {
    setLoading(true);
    api.get(`/Users/Activation?codigoAtivacao=${codigoAtivacao}`)
      .then(() => {
        setLoading(false);
        setValid(true)
      })
      .catch(() => {
        setLoading(false);
        setTimeout(function () {
          history.push("/signin")
        }, 1000)
      })
      .finally();
  }

  useEffect(ValidateActivation, [])
  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return tabSlideOne();
      case 1:
        return tabSlideTwo();;
      default:
        return 'Unknown step';
    }
  }

  const tabValidation = () => {
    return (
      <div>
        <Divider></Divider>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.progressValid} alignContent="center">
            {loading ?
              (<CircularProgress />) :
              (<Alert severity="error">Código de Ativação Inválido</Alert>)
            }
          </Grid>
        </Grid>
      </div>
    );

  };

  const getContentSuccess = () => {
    return <>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "50px", marginBottom: "50px" }}>
        <div className={classes.alert}>
          <Alert severity="success">Usuário ativado com Sucesso!</Alert>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "50px", marginBottom: "30px", marginRight: "50px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleReset}
          className={classes.button}
        >
          Ir para Login
        </Button>
      </div>
    </>
  }


  const tabSlideOne = () => {
    return (
      <div>
        <Divider></Divider>
        <ValidatorForm
          onSubmit={handleNext}
          style={{ paddingTop: "40px" }}
          className={classes.form}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.gridMargin} >
              <TextValidator
                margin="normal"
                required
                fullWidth
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                validators={["required", "isEmail"]}
                errorMessages={[
                  "Este campo é obrigatório",
                  "Este email não parece ser válido",
                ]}
              />

            </Grid>
            <Grid item xs={12} className={classes.gridMargin}>
              <TextValidator
                margin="normal"
                required
                fullWidth
                value={senhaTemporaria}
                onChange={(e: any) => setSenhaTemporaria(e.target.value)}
                name="senhaTemporaria"
                label="Senha Temporária"
                type="password"
                id="senhaTemporaria"
                autoComplete="senhaTemporaria"
                validators={["required"]}
                errorMessages={["Este campo é obrigatório"]}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridMargin}>
              <Loading loading={loading}></Loading>
              <div className={classes.alert} hidden={errorAlert} >
                <Alert severity="error">Senha e/ou Email incorreto(s)</Alert>
              </div>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );

  };

  const tabSlideTwo = () => {

    return (
      <div>
        <Divider></Divider>
        <ValidatorForm
          onSubmit={handleNext}
          style={{ paddingTop: "40px" }}
          className={classes.form}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.gridMargin} >

              <TextValidator
                margin="normal"
                required
                fullWidth
                value={senha}
                onChange={(e: any) => setSenha(e.target.value)}
                name="senha"
                label="Nova Senha"
                type="password"
                id="senha"
                autoComplete="senha"
                validators={["required"]}
                errorMessages={["Este campo é obrigatório"]}
              />

            </Grid>
            <Grid item xs={12} style={{ marginTop: "30px", marginLeft: "20px", marginRight: "50px" }}>
              <TextValidator
                margin="normal"
                required
                fullWidth
                value={confirmaSenha}
                onChange={(e: any) => setConfirmaSenha(e.target.value)}
                name="confirmaSenha"
                label="Confirma Nova Senha"
                type="password"
                id="confirmaSenha"
                autoComplete="confirmaSenha"
                validators={["required"]}
                errorMessages={["Este campo é obrigatório"]}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridMargin}>
              <Loading loading={loading}></Loading>
              <div className={classes.alert} hidden={errorAlert} >
                <Alert severity="error">{errorMessageTabTwo}</Alert>

              </div>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div >
    );

  };

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep == 0)
      activation();
    else
      handleValue();

  };

  const handleReset = () => {
    history.push("/signin")
  };

  const handleValue = () => {
    if ((confirmaSenha?.length ?? 0) < 6) {
      setErrorAlert(false)
      setErrorMessageTabTwo("As senhas devem ter no mínimo 6 caracteres")
    }
    else if (senha === confirmaSenha) {
      activationNewPWD();
      setErrorAlert(true)
    } else {
      setErrorAlert(false)
      setErrorMessageTabTwo("As senhas devem ser iguais!")
    }
  };

  const activationNewPWD = async () => {
    setLoading(true);
    try {
      let user: ActivationRequest = {
        Password: senhaTemporaria ?? "",
        NovoPassword: senha ?? "",
        Email: email ?? "",
        CodigoAtivacao: codigoAtivacao ?? ""
      };

      api.post("/Users/ActivationSetPassword", user).then(
        () => {
          handleNextNew();
          setRedirect(true);
        })
        .catch((error) => {
          setLoading(false);
          setErrorAlert(false)
          setErrorMessageTabTwo(error.request.response['message'])
        });

    } catch (error) {
      setLoading(false);
      setErrorAlert(false)
      setErrorMessageTabTwo(error.request.response['message'])
    }
  };


  const handleNextNew = () => {

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

  };

  const activation = async () => {
    setLoading(true);
    try {
      let user: ActivationRequest = {
        Password: senhaTemporaria ?? "",
        NovoPassword: "",
        Email: email ?? "",
        CodigoAtivacao: codigoAtivacao ?? ""
      };

      api.post("/Users/Activation", user).then((response) => {
        if (response.data) {
          setRedirect(true);
          setErrorAlert(true);
          handleNextNew()
        } else {
          setErrorAlert(false)
          setRedirect(false);
        }

        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Box>
        <Dialog fullScreen open={open} >
          <div className={classes.main}>
            <Box className={classes.box}>
              <img style={{ marginTop: "100px", width: "200px", height: "auto" }}
                src="/assets/logo.png"
                alt="Yamaha Consórico"
              />
              <Grid className={classes.divInterna}>
                <div className={classes.root}>
                  <Stepper activeStep={activeStep} style={{ width: "80%" }}>
                    {valid &&
                      steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: React.ReactNode } = {};
                        if (isStepOptional(index)) {
                          labelProps.optional = <Typography variant="caption"></Typography>;
                        }
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })
                    }
                    {!valid &&
                      <Step key={0} >
                        <StepLabel >Validando Codigo</StepLabel>
                      </Step>
                    }
                  </Stepper>
                  <div>
                    {activeStep === steps.length ? (
                      getContentSuccess()

                    ) : (
                      <div>

                        {valid ?
                          (
                            getStepContent(activeStep)
                          ) :
                          (
                            tabValidation()
                          )
                        }
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "50px", marginBottom: "30px", marginRight: "50px" }}>
                          {valid &&
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              disabled={(!email || !senhaTemporaria) || ((activeStep === steps.length - 1) && (!senha || !confirmaSenha))}
                              className={classes.button}
                            >
                              {activeStep === steps.length - 1 ? 'CONCLUIR' : 'PRÓXIMO'}
                            </Button>
                          }
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Box>
          </div>
        </Dialog>
      </Box >
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    loading: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSignIn: (user: AuthenticationRequest) => {
      dispatch(login(user)).then(() => dispatch(getMyData()));
    },
  };
};

export const SignIn = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivationScreen);

