import DateFnsUtils from "@date-io/date-fns";
import {
  Avatar,
  Box,
  Button,
  LinearProgress,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TitleCard } from "../../base/TitleCard";
import { MyDataRequest } from "../../Models/MyDataRequest";
import { UserData } from "../../Models/User";
import { getMyData, putMyData } from "../../redux/ducks/user";
import { AdditionalInformationModal } from "./AdditionalInformationModal";

const useStyles = makeStyles((theme) => ({
  innerPadding: {
    padding: 20,
  },
  spacer: {
    height: 4,
    width: "100%",
  },
  vstack: {
    margin: "16px 0px",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  paperWrap: {
    width: "100%",
    padding: theme.spacing(2),
  },
}));

interface IMyDataComponentProps {
  userData: UserData;
  loading: boolean;
  loadUserData: () => void;
  updateUserData: (user: MyDataRequest) => void;
}

const MyDataComponent = ({
  userData,
  loading,
  loadUserData,
  updateUserData,
}: IMyDataComponentProps) => {
  const classes = useStyles();

  useEffect(loadUserData, []);

  let [date, setDate] = useState<Date | null>(
    new Date(userData.dataNascimento ?? "")
  );
  let [name, setName] = useState<string | null>(userData.nome);
  let [cnpj, setCnpj] = useState<string | null>(userData.cnpj);
  let [phone, setPhone] = useState<string | null>(userData.celular);
  let [newconPassword, setNewconPassword] = useState<string>("");
  const [isModalOpen, setModalOpen] = useState(false);

  const handleUpdate = () => {
    let user: MyDataRequest = {
      Nome: name ?? "",
      DataNascimento: date?.toISOString() ?? "",
      Cnpj: cnpj ?? "",
      Celular: phone ?? "",
      senhaNewcon: newconPassword ?? "",
    };

    updateUserData(user);
  };

  return (
    <>
      <TitleCard>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Avatar
            style={{ marginRight: 16 }}
            alt={userData.nome ? userData.nome : ""}
            src="/static/images/avatar/3.jpg"
          />
          {userData.nome}
        </div>
      </TitleCard>
      <Box className={classes.container}>
        <Paper className={classes.paperWrap}>
          {loading ? <LinearProgress /> : <div className={classes.spacer} />}

          <div className={classes.innerPadding}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TextField
                className={classes.vstack}
                disabled={loading}
                required
                id="name"
                label="Nome"
                fullWidth
                autoComplete="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <KeyboardDatePicker
                helperText="Data de Nascimento inválida"
                className={classes.vstack}
                disabled={loading}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id="birthday"
                autoComplete="birthday"
                label="Data de Nascimento"
                value={date}
                onChange={(date: Date | null) => setDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />

              <TextField
                className={classes.vstack}
                disabled={loading}
                required
                id="cnpj"
                label="CNPJ"
                fullWidth
                autoComplete="cnpj"
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
              />

              <TextField
                className={classes.vstack}
                disabled={loading}
                required
                id="celular"
                label="Celular"
                fullWidth
                autoComplete="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />

              <TextField
                className={classes.vstack}
                disabled={loading}
                required
                id="newconPassword"
                label="Senha Newcon"
                type="password"
                fullWidth
                autoComplete="newconPassword"
                value={newconPassword}
                onChange={(e) => setNewconPassword(e.target.value)}
              />

              <div className={classes.vstack}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  DADOS COMPLEMENTARES
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdate}
                  disabled={loading}
                >
                  Salvar
                </Button>
              </div>
            </MuiPickersUtilsProvider>
          </div>
        </Paper>
      </Box>
      <AdditionalInformationModal
        isOpen={isModalOpen}
        handleCloseModal={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.user.userData,
  loading: state.user.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUserData: (user: MyDataRequest) => {
    dispatch(putMyData(user)).then(() => {
      dispatch(getMyData());
    });
  },
  loadUserData: () => {
    dispatch(getMyData());
  },
});

export const MyData = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDataComponent);
