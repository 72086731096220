import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Loading } from "../../ui/Loading";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { TitleCard } from "../../base/TitleCard";
import { useStyles } from "./style";
import api from "../../service/api";
import { TableHeader } from "../../base/TableHeader";
import { ModalCheckBox } from "./components/ModalCheckbox";
import { TableContent } from "./components/TableContent";
import { ThemePagination } from "../../base/Pagination";

const DataTable = () => {
  const classes = useStyles();

  type Filter = {
    [key: string]: boolean;
  };

  const [checkboxes, setCheckboxes] = useState<Filter>({
    cato: true,
    ato: true,
    acto: true,
    acbe: true,
    acbne: true,
    anc: true,
    canto: true,
    can1p: true,
    conmes: true,
    condbne: true,
    condbe: true,
    conabne: true,
    conabe: true,
    ncond: true,
    ncona: true,
    f10q: true,
    quit: true,
    cdes: true,
  });

  interface IGestaoCarteiraRequest {
    dataInicial?: string;
    dataFinal?: string;
    tipoPeriodo: string;
    codigo: string;
    index?: number;
    size?: number;
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [tableCategoriesActive, setTableCategoriesActive] = useState(true);
  const [page, setPage] = useState(0);
  const [filterCode, setFilterCode] = useState("");
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [gestaoCarteiraData, setGestaoCarteiraData] = useState([]);
  const [tipoPeriodo, setTipoPeriodo] = useState<string>("informado");
  const [loading, setLoading] = useState(false);

  var date = new Date();
  const defaultDataInicial = new Date(
    date.getFullYear() - 4,
    date.getMonth(),
    date.getDate()
  );
  const defaultDataFinal = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  type TableContent = {
    codigo: string;
    qtde: number;
  };

  const [dataInicial, setDataInicial] = useState<string>(
    defaultDataInicial.toISOString().substring(0, 10)
  );
  const [dataFinal, setDataFinal] = useState<string>(
    defaultDataFinal.toISOString().substring(0, 10)
  );

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    loadGestaoCarteiraDataFiltered({
      dataInicial,
      dataFinal,
      tipoPeriodo,
      codigo: filterCode,
      index: (newPage - 1) * 10,
      size: 10,
    });
  };

  const loadGestaoCarteiraDataFiltered = ({
    dataInicial,
    dataFinal,
    codigo,
    tipoPeriodo,
    index,
    size,
  }: IGestaoCarteiraRequest) => {
    setLoading(true);
    api
      .get("/Relatorio/GestaoCarteira", {
        params: {
          dataInicial,
          dataFinal,
          tipoPeriodo,
          codigo,
          index,
          size,
        },
      })
      .then(({ data }: any) => {
        setGestaoCarteiraData(data["list"]);
        setTableCategoriesActive(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEyeButtonClick = ({ codigo, qtde }: TableContent) => {
    console.log(tipoPeriodo);
    setPage(1);
    setNumberOfPages(Math.ceil(qtde / 10));
    console.log(Math.ceil(qtde / 10));
    setFilterCode(codigo);
    loadGestaoCarteiraDataFiltered({
      dataInicial,
      dataFinal,
      tipoPeriodo,
      codigo,
      index: 0,
      size: 10,
    });
  };

  const handleOpenCloseModal = () => {
    modalOpen ? setModalOpen(false) : setModalOpen(true);
  };

  const requestData = () => {
    setTableCategoriesActive(true);
    var filterList = Object.keys(checkboxes).filter((key: string) => {
      return checkboxes[key];
    });
    var filter = filterList.join(",");
    setLoading(true);
    api
      .get("/Relatorio/GestaoCarteiraOverview", {
        params: {
          dataInicial,
          dataFinal,
          tipoPeriodo,
          filter,
        },
      })
      .then((response) => {
        setTableData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportPdf = () => {
    setLoading(true);
    api
      .get("/Relatorio/GestaoCarteira", {
        params: {
          dataInicial,
          dataFinal,
          tipoPeriodo,
          codigo: filterCode,
          reportPdf: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        setLoading(false);
        let blob = new Blob([response.data], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportXLS = () => {
    setLoading(true);
    api
      .get("/Relatorio/GestaoCarteira", {
        params: {
          dataInicial,
          dataFinal,
          tipoPeriodo,
          codigo: filterCode,
          reportXLSX: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "GestaoCarteira.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const backButtonTitleCard = () => {
    setTableCategoriesActive(true);
  };

  return (
    <Box>
      <TitleCard
        breadcrumbsText=""
        buttonLink={tableCategoriesActive ? null : "/Relatorios/gestaoCarteira"}
        onClickButton={backButtonTitleCard}
      >
        Gestão de carteira
      </TitleCard>

      <Paper className={classes.formPaperWrap}>
        <Grid container spacing={3}>
          <Grid container item xs={12} md={9}>
            <Grid container spacing={5}>
              <Grid item xs={12} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="tipoPeriodoLabel">Tipo Período</InputLabel>

                  <Select
                    disabled={loading || !tableCategoriesActive}
                    labelId="tipoPeriodoLabel"
                    id="tipoPeriodoSelect"
                    value={tipoPeriodo}
                    name="tipoPeriodo"
                    label="tipoPeriodo"
                    onChange={(e) => setTipoPeriodo(e.target.value as string)}
                  >
                    <MenuItem value={"total"}>Total</MenuItem>
                    <MenuItem value={"informado"}>Informado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {tipoPeriodo === "informado" && (
                <>
                  <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={loading || !tableCategoriesActive}
                        InputLabelProps={{ shrink: true }}
                        required
                        name="dataInicial"
                        label="Data Inicial"
                        type="date"
                        value={dataInicial}
                        onChange={(e) => setDataInicial(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={loading || !tableCategoriesActive}
                        InputLabelProps={{ shrink: true }}
                        required
                        name="dataFinal"
                        label="Data Final"
                        type="date"
                        value={dataFinal}
                        onChange={(e) => setDataFinal(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} lg={4}>
                <Button
                  variant="contained"
                  onClick={handleOpenCloseModal}
                  disabled={loading || !tableCategoriesActive}
                >
                  <AddCircleIcon className={classes.plusIcon} /> Opções
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              disabled={loading || !tableCategoriesActive}
              onClick={requestData}
              color="primary"
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.tablePaperWrap}>
        <Loading loading={loading} />
        <Grid container className={classes.gridTableTitle}>
          {!tableCategoriesActive && (
            <TableHeader
              title="Gestão de carteira"
              onClickPdfButton={handleExportPdf}
              onClickXlsButton={handleExportXLS}
            />
          )}
        </Grid>

        <Divider />

        {tableCategoriesActive && (
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {tableData.map((row: any) => (
                  <TableRow key={row.codigo}>
                    <TableCell>{row.descricao}</TableCell>
                    <TableCell>{row.qtde}</TableCell>

                    <TableCell>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleEyeButtonClick({
                            codigo: row.codigo,
                            qtde: row.qtde,
                          });
                        }}
                      >
                        <VisibilityIcon className={classes.eyeIcon} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {!tableCategoriesActive && (
          <TableContent tableData={gestaoCarteiraData} />
        )}
      </Paper>

      {!tableCategoriesActive && (
        <ThemePagination
          currentPage={page}
          pagesCount={numberOfPages}
          handlePageChange={handleChangePage}
          marginLeft={"32px"}
        />
      )}

      <ModalCheckBox
        open={modalOpen}
        handleCloseModal={handleOpenCloseModal}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
      />
    </Box>
  );
};

export const GestaoCarteira = DataTable;
