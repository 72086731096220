const FETCH_ANIVESARIO_RELATORIO = "AnivesarioRelatorio/FETCH_ANIVESARIO_RELATORIO";
const FETCH_ANIVESARIO_RELATORIO_SUCCESS = "AnivesarioRelatorio/FETCH_ANIVESARIO_RELATORIO_SUCCESS";
const FETCH_ANIVESARIO_RELATORIO_FAIL = "AnivesarioRelatorio/FETCH_ANIVESARIO_RELATORIO_FAIL";

export const defaultState = {
    loading: false,
    AniverarioRelatorioList: []
}

export default function reducer(state: any = defaultState, action: any = {}) {
    switch (action.type) {
        case FETCH_ANIVESARIO_RELATORIO:
            return {
                ...state,
                loading: true
            };
        case FETCH_ANIVESARIO_RELATORIO_FAIL:
            return {
                ...state,
                loading: false

            };
        case FETCH_ANIVESARIO_RELATORIO_SUCCESS:
            return {
                ...state,
                loading: false,
                AniverarioRelatorioList: action.payload.data
            };
        default:
            return state;
    }

}

export function getAniversarioRelatori(
    comissionado: string,
    dataInicial: string,
    dataFinal: string,
    mes: string
){
    return {
        type: FETCH_ANIVESARIO_RELATORIO,
        payload: {
            request: {
                method: "GET",
                url:"/Relatorio/Aniversario",
                params:{
                    comissionado:comissionado,
                    dataInicial:dataInicial,
                    dataFinal:dataFinal,
                    mes:mes
                },
            },
        },
    };
}

