import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputInput: {
      backgroundColor: "transparent",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      flexGrow: 1,
      display: "flex",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },

    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    titlePage: {
      position: "relative",
      left: 10,
      top: 5,
    },

    button: {
      margin: theme.spacing(1),
    },

    boxContainer: {
      margin: "20px",
    },

    content: {
      marginTop: 15,
      marginBottom: 15,
    },

    paperWrap: {
      width: "100%",
      padding: theme.spacing(2),
    },

    paginationItem: {
      height: "56px",
    },
    paginationPaper: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
    tableCellTitle: {
      color: "#808080",
      fontWeight: "bold",
    },
  })
);
