import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Loading } from "../../ui/Loading";
import { AniversarioRelatorioRequest } from "../../Models/AniversarioRelatorioRequest";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { AniversarioRelatorioDetalheComponet } from "./AniversarioRelatorioDetalhe";
import { TitleCard } from "../../base/TitleCard";
import { format, parseISO } from "date-fns";
import { useStyles } from "./style";
import api from "../../service/api";
import { TableHeader } from "../../base/TableHeader";
import { connect } from "react-redux";
import { UserData } from "../../Models/User";
import { ThemePagination } from "../../base/Pagination";

interface IAniversarioRelatorio {
  userData: UserData;
}

const DataTable = ({ userData }: IAniversarioRelatorio) => {
  const classes = useStyles();
  var date = new Date();
  const defaultDataInicial = new Date(
    date.getFullYear() - 1,
    date.getMonth(),
    date.getDate()
  );
  const defaultDataFinal = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [comissionado] = useState(userData.pontoVenda?.codigo);

  const [tableData, setTableData] = useState([]);
  const [dataInicial, setDataInicial] = useState<string | undefined>(
    defaultDataInicial.toISOString().substring(0, 10)
  );
  const [dataFinal, setDataFinal] = useState<string | undefined>(
    defaultDataFinal.toISOString().substring(0, 10)
  );
  const [mes, setMes] = useState<string>("01");
  const [loading, setLoading] = useState(false);
  const [aniversarioNome, setAniversarioNome] = React.useState();
  const [aniversarioEndereco, setAniversarioEndereco] = React.useState();
  const [aniversarioCidade, setAniversarioCidade] = React.useState();
  const [aniversarioUf, setAniversarioUf] = React.useState();
  const [aniversarioTelefone, setAniversarioTelefone] = React.useState();
  const [aniversarioEmail, setAniversarioEmail] = React.useState();
  const [aniversarioOpen, setAniversarioOpen] = React.useState(false);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleOpenAniversario = (row: any) => {
    setAniversarioNome(row.nomeCliente);
    setAniversarioEndereco(row.endereco);
    setAniversarioTelefone(row.telefone);
    setAniversarioCidade(row.cidade);
    setAniversarioUf(row.uf);
    setAniversarioEmail(row.email);
    setAniversarioOpen(true);
  };

  const requestData = () => {
    setLoading(true);

    api
      .get("/Relatorio/Aniversario", {
        params: {
          comissionado,
          dataInicial,
          dataFinal,
          mes,
        },
      })
      .then((response) => {
        setTableData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportPdf = () => {
    setLoading(true);
    api
      .get("/Relatorio/Aniversario", {
        params: {
          comissionado,
          mes,
          dataInicial,
          dataFinal,
          reportPdf: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        setLoading(false);
        let blob = new Blob([response.data], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportXLS = () => {
    setLoading(true);
    api
      .get("/Relatorio/Aniversario", {
        params: {
          comissionado,
          dataInicial,
          dataFinal,
          mes,
          reportXLSX: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "aniversariantes.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCloseAniversario = () => setAniversarioOpen(false);

  return (
    <Box>
      <TitleCard breadcrumbsText="">Aniversariantes</TitleCard>

      <Paper className={classes.formPaperWrap}>
        <Grid container spacing={3}>
          <Grid container item xs={12} md={9}>
            <Grid container spacing={5}>
              <Grid item xs={12} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Mês do Aniversário
                  </InputLabel>

                  <Select
                    disabled={loading}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={mes}
                    name="mes"
                    label="Mês"
                    onChange={(e) => setMes(e.target.value as string)}
                  >
                    <MenuItem value={"01"}>Janeiro</MenuItem>
                    <MenuItem value={"02"}>Fevereiro</MenuItem>
                    <MenuItem value={"03"}>Março</MenuItem>
                    <MenuItem value={"04"}>Abril</MenuItem>
                    <MenuItem value={"05"}>Maio</MenuItem>
                    <MenuItem value={"06"}>Junho</MenuItem>
                    <MenuItem value={"07"}>Julho</MenuItem>
                    <MenuItem value={"08"}>Agosto</MenuItem>
                    <MenuItem value={"09"}>Setembro</MenuItem>
                    <MenuItem value={"10"}>Outubro</MenuItem>
                    <MenuItem value={"11"}>Novembro</MenuItem>
                    <MenuItem value={"12"}>Dezembro</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={3}>
                <FormControl fullWidth>
                  <TextField
                    disabled={loading}
                    InputLabelProps={{ shrink: true }}
                    required
                    name="dataInicial"
                    label="Data Inicial"
                    type="date"
                    value={dataInicial}
                    onChange={(e) => setDataInicial(e.target.value)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={3}>
                <FormControl fullWidth>
                  <TextField
                    disabled={loading}
                    InputLabelProps={{ shrink: true }}
                    required
                    name="dataFinal"
                    label="Data Final"
                    type="date"
                    value={dataFinal}
                    onChange={(e) => setDataFinal(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              disabled={loading}
              onClick={requestData}
              color="primary"
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.tablePaperWrap}>
        <Loading loading={loading} />
        <Grid container className={classes.gridTableTitle}>
          <TableHeader
            title="Aniversariantes"
            onClickPdfButton={handleExportPdf}
            onClickXlsButton={handleExportXLS}
          />
        </Grid>

        <Divider />

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>
                  Data Nasc.
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Contrato
                </TableCell>
                <TableCell className={classes.tableCellTitle}>Grupo</TableCell>
                <TableCell className={classes.tableCellTitle}>Cota</TableCell>
                <TableCell className={classes.tableCellTitle}>Versão</TableCell>
                <TableCell className={classes.tableCellTitle}>Venda</TableCell>
                <TableCell className={classes.tableCellTitle}>
                  CPF Vendedor
                </TableCell>
                <TableCell className={classes.tableCellTitle}>Bem</TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Crédito
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Contemplação
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Nome Cliente
                </TableCell>
                <TableCell className={classes.tableCellTitle}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                ?.slice(
                  (page - 1) * itemsPerPage,
                  (page - 1) * itemsPerPage + itemsPerPage
                )
                .map((row: AniversarioRelatorioRequest) => (
                  <TableRow key={row.idPessoa}>
                    <TableCell>
                      {format(
                        parseISO(row.dataNascimento as string),
                        "dd/MM/yyyy"
                      )}
                    </TableCell>
                    <TableCell>{row.contrato}</TableCell>
                    <TableCell>{row.grupo}</TableCell>
                    <TableCell>{row.cota}</TableCell>
                    <TableCell>{row.versao}</TableCell>
                    <TableCell>
                      {row.dataVenda
                        ? format(parseISO(row.dataVenda), "dd/MM/yyyy")
                        : ""}
                    </TableCell>

                    <TableCell>{row.cpfVendedor}</TableCell>
                    <TableCell>{row.nomeBem}</TableCell>
                    <TableCell>{row.credito.toFixed(2)}</TableCell>
                    <TableCell>
                      {row.dataContemplacao
                        ? format(parseISO(row.dataContemplacao), "dd/MM/yyyy")
                        : ""}
                    </TableCell>
                    <TableCell>{row.nomeCliente}</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        onClick={() => handleOpenAniversario(row)}
                      >
                        <VisibilityIcon className={classes.eyeIcon} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <AniversarioRelatorioDetalheComponet
          aniversarioRelatorioNome={aniversarioNome}
          aniversarioRelatorioEndereco={aniversarioEndereco}
          aniversarioRelatorioCidade={aniversarioCidade}
          aniversarioRelatorioUf={aniversarioUf}
          aniversarioRelatorioTelefone={aniversarioTelefone}
          aniversarioRelatorioEmail={aniversarioEmail}
          openAniversario={aniversarioOpen}
          handleCloseAniversario={handleCloseAniversario}
        />
      </Paper>

      <ThemePagination
        currentPage={page}
        pagesCount={Math.ceil(tableData.length / 10)}
        handlePageChange={handleChangePage}
        marginLeft={"32px"}
      />
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.user.userData,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUserData: () => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
