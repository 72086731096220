import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getSystemParm } from '../../../redux/ducks/systemParameter';
import { Loading } from '../../../ui/Loading';
import { Alert } from '@material-ui/lab';
import { TitleCard } from '../../../base/TitleCard';

const useStyles = makeStyles((theme) => ({
  paperWrap: {
    margin: 20,
    overflow: 'hidden',
  },
  innerPadding: {
    padding: 20,
  },
  spacer: {
    height: 4,
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperWrapA: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: '0px',
  },
  frame: {
    width: `100%`,
    height: `500px`,
    border: '0 solid transparent',
    borderRadius: '4px',
    marginTop: 30,
  },
}));

interface IBoletoAvulsoComponentProps {
  loading: boolean;
  sysParmList: any[];
  loadSysParm: any;
}

const BoletoAvulsoComponent = ({
  loading,
  sysParmList,
  loadSysParm,
}: IBoletoAvulsoComponentProps) => {
  const classes = useStyles();

  /**
   * vao virar parametros do parametros de sistema
   */
  const advancedCodigo = 'VENDAS_BOLETO_AVULSO_ADVANCED_URL';
  const actionCodigo = 'VENDAS_BOLETO_AVULSO_ACTION_URL';
  // useEffect(loadUserData, []);
  useEffect(loadSysParm, []);

  const otherFields = [
    {
      name: 'ctl00$hdnID_Modulo',
      value: 'CO',
    },
    {
      name: 'ctl00$Conteudo$identificacao_cota$btnLocaliza',
      value: 'Localizar',
    },
    {
      name: '__EVENTTARGET',
      value: '',
    },
    {
      name: '__EVENTARGUMENT',
      value: '',
    },
    {
      name: '__VSTATE',
      value:
        'H4sIAAAAAAAEAFWOQUvEMBBGaXbDyiIGBIunugevtlFEvcnqRVBE1lWPoU2mm2CaaBLJ+rMF75paQWQYeDw+Zr6vjOxmuGDs0prgrPYLeH1TDu6sDxc1f76Gd8byEd7hQVO6r7oVm+sArr4HI+t/OoARqgMTLN7601egXwieHpzRk8M0p5SIHLWE5OMCcYE+P7Ji82Fx85NjT9Dg8xhjaSBya7xtQ6wdlNx2ZeOqwVayj1J6nPaouh2cMgLWpQydTucRSk+mLclRMXlUXjUapEAj0ovxEtYBb8+7RqWqMBMwW4IP4IVAuE/sIZlwMuAq4cav/Abia1OtKwEAAA==',
    },
    {
      name: '__VIEWSTATE',
      value: '',
    },
    {
      name: '__EVENTVALIDATION',
      value:
        '/wEdAAuvVXD1oYELeveMr0vHCmYP3OVcUU8W753P13fN8zCqBj8MKpSiiMv/cLd8kS58dt2pAzy1smKnaevGosfUdDzEJinHLT+2k4uubKWTPtJqloZ/ANMmoaIDGG9zTqOouBLHEiRzOAgrruzBzEismdxnqztePLC8ax1rtjGJ+oK9jYO1CMY6IcCX3KvClzLKU51K9nrFiy3DVUru2b0o5cLzZIusKhDEtSVSlcq9EwRz5gdbyF027IlnIfm3HORzqxefoNvt',
    },
  ];

  let [grupo, setGrupo] = useState<string | null>();
  let [cota, setCota] = useState<string | null>();
  let [versao, setVersao] = useState<string | null>();
  let [advancedUrl, setAdvancedUrl] = useState<string | undefined>();
  let [actionUrl, setActionUrl] = useState<string | undefined>();
  let [frameBltAvulso, setFrameBltAvulso] = useState<string>();

  function buscaAvancada(codigo: string) {
    const sysParm = sysParmList?.find((x) => x.codigo === codigo);
    if (sysParm) {
      setAdvancedUrl(sysParm.texto);
      setFrameBltAvulso(sysParm.texto);
    } else {
      setFrameBltAvulso('Error');
    }
  }
  function setFrame(codigo: string) {
    const sysParm = sysParmList?.find((x) => x.codigo === actionCodigo);
    if (sysParm) {
      console.log(sysParm.texto);
      setActionUrl(sysParm.texto);
      setFrameBltAvulso(sysParm.texto);
    } else {
      setFrameBltAvulso('Error');
    }
  }
  useEffect(() => setFrame(actionCodigo), []);

  return (
    <>
      <Box>
        <TitleCard>Boleto Avulso</TitleCard>
      </Box>

      <Box>
        <Paper className={classes.paperWrapA}>
          <Typography variant="h6" gutterBottom>
            Tipo de Busca
          </Typography>
          <Loading loading={loading} />
          <Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} md={3}>
                    <TextField
                    required
                    name="ctl00$Conteudo$identificacao_cota$edtGrupo"
                    id="grupo"
                    label="Grupo"
                    fullWidth
                    autoComplete="grupo"
                    value={grupo}
                    onChange={(e) => setGrupo(e.target.value)}
                    />
                    </Grid>
                    <Grid item xs={12} md={3}>
                    <TextField
                    required
                    name="ctl00$Conteudo$identificacao_cota$edtCota"
                    id="cota"
                    label="Cota"
                    fullWidth
                    autoComplete="cota"
                    value={cota}
                    onChange={(e) => setCota(e.target.value)}
                    />
                    </Grid>
                    <Grid item xs={12} md={3}>
                    <TextField
                    required
                    name="ctl00$Conteudo$identificacao_cota$edtVersao"
                    id="versao"
                    label="Versão"
                    fullWidth
                    autoComplete="versao"
                    value={versao}
                    onChange={(e) => setVersao(e.target.value)}
                    />
                  </Grid> */}
                <form method="post" action={actionUrl} target="frame">
                  <Grid item xs={12} style={{padding:"5px"}}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => setFrame(actionCodigo)}
                    >
                      Busca Simples
                    </Button>
                  </Grid>
                </form>
                {/* <div style={{ display: 'none' }}>
                    {otherFields.map((x) => (
                      <input name={x.name} value={x.value} />
                    ))}
                  </div> */}
                <form method="post" action={advancedUrl} target="frame">
                    <Grid item xs={12} style={{padding:"5px"}}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => buscaAvancada(advancedCodigo)}
                      >
                        Busca Avançada
                      </Button>
                  </Grid>
                </form>
              </Grid>
              {frameBltAvulso !== 'Error' ? (
                <iframe
                  sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                  src={frameBltAvulso}
                  className={classes.frame}
                  name="frame"
                  title="Boleto Avulso"
                ></iframe>
              ) : (
                <div>
                  <iframe
                    sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                    style={{ display: 'none' }}
                    src={frameBltAvulso}
                    className={classes.frame}
                    name="frame"
                    title="Boleto Avulso"
                  ></iframe>
                  <Alert style={{ marginTop: 30 }} severity="warning">
                    Endereço não encontrado!
                  </Alert>
                </div>
              )}
            </MuiPickersUtilsProvider>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.systemParameter.loading,
  sysParmList: state.systemParameter.sysParmList,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUserData: () => {
    // dispatch(getMyData())
  },
  loadSysParm: () => {
    dispatch(getSystemParm());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoletoAvulsoComponent);
