import { TipoProdutoRequest } from './../../Models/TipoProdutoRequest';

const FETCH_TIPO_PRODUTO = "tipoProduto/FETCH_TIPO_PRODUTO";
const FETCH_TIPO_PRODUTO_FAIL = "tipoProduto/FETCH_TIPO_PRODUTO_FAIL";
const FETCH_TIPO_PRODUTO_SUCCESS = "tipoProduto/FETCH_TIPO_PRODUTO_SUCCESS";
const POST_TIPO_PRODUTO = "tipoProduto/POST_TIPO_PRODUTO";
const POST_TIPO_PRODUTO_FAIL = "tipoProduto/POST_TIPO_PRODUTO_FAIL";
const POST_TIPO_PRODUTO_SUCCESS = "tipoProduto/POST_TIPO_PRODUTO_SUCCESS";
const DELETE_TIPO_PRODUTO = "tipoProduto/DELETE_TIPO_PRODUTO";
const DELETE_TIPO_PRODUTO_FAIL = "tipoProduto/DELETE_TIPO_PRODUTO_FAIL";
const DELETE_TIPO_PRODUTO_SUCESS = "tipoProduto/DELETE_TIPO_PRODUTO_SUCESS";

const UPDATE_TIPO_PRODUTO = "tipoProduto/UPDATE_TIPO_PRODUTO";
const UPDATE_TIPO_PRODUTO_FAIL = "tipoProduto/UPDATE_TIPO_PRODUTO_FAIL";
const UPDATE_TIPO_PRODUTO_SUCCESS = "tipoProduto/UPDATE_TIPO_PRODUTO_SUCCESS";

export const defaultState = {
  loading: false,
  tipoProdutos: []
}

// Reducer
export default function reducer(state: any = defaultState, action: any = {}) {
  switch (action.type) {
    case FETCH_TIPO_PRODUTO:
      return {
        ...state,
        loading: true
      };
    case FETCH_TIPO_PRODUTO_FAIL:
      return {
        ...state,
        loading: false
      };
    case FETCH_TIPO_PRODUTO_SUCCESS:
      return {
        ...state,
        loading: false,
        tipoProdutos: action.payload.data
      };
    case UPDATE_TIPO_PRODUTO:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_TIPO_PRODUTO_FAIL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_TIPO_PRODUTO_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case POST_TIPO_PRODUTO:
      return {
        ...state,
        loading: true
      };
    case POST_TIPO_PRODUTO_FAIL:
      return {
        ...state,
        loading: false
      };
    case POST_TIPO_PRODUTO_SUCCESS:
      return {
        ...state,
        loading: false
      };
      case DELETE_TIPO_PRODUTO:
        return {
            ...state,
            loading: true,
        };
    case DELETE_TIPO_PRODUTO_FAIL:
        return {
            ...state,
            loading: false,
        };
    case DELETE_TIPO_PRODUTO_SUCESS:
        return {
            ...state,
            loading: false,
        };
    default:
      return state;
  }
}

export function fetchTipoProdutos() {
  return {
    type: FETCH_TIPO_PRODUTO,
    payload: {
      request: {
        method: "GET",
        url: "/TipoProduto",
      },
    },
  };
}
export function postTipoProduto(tipoProduto: TipoProdutoRequest){
  return {
    type: POST_TIPO_PRODUTO,
    payload:{
      request:{
        method: "POST",
        url: "/TipoProduto",
        data: tipoProduto
      }
    }
  }

}

export function updateTipoProdutos(data: TipoProdutoRequest) {
  return {
    type: UPDATE_TIPO_PRODUTO ,
    payload: {
      request:{
        method: "PUT",
        url: "/TipoProduto",
        data
        },
      },
    };
  }
export function deleteProduct(id: number) {
  return {
      type: DELETE_TIPO_PRODUTO,
      payload: {
          request: {
              method: "DELETE",
              url: "/TipoProduto",
              params: {
                id: id
              }
          },
      },
  };
}
