import { connect } from "react-redux";
import {
  createUserProfile,
  deleteUserProfile,
  getUserProfile,
  updateUserProfile,
} from "../../redux/ducks/userProfile";
import React, { useEffect } from "react";
import {
  Box,
  Button,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import CustomFab from "../../ui/CustomFab";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { useState } from "react";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import { Loading } from "../../ui/Loading";
import { UserProfileDelete } from "./UserProfileDelete";
import { UserProfileEdit } from "./UserProfileEdit";
import { UserProfileCreate } from "./UserProfileCreate";
import { fetchTelasPermitidas } from "../../redux/ducks/telasPermitidas";
import { UserProfile } from "../../Models/UserProfile";
import { TitleCard } from "../../base/TitleCard";
import { useStyles } from "./style";
import { getTelas } from "../../redux/ducks/user";

interface IUserProfileProps {
  loading: boolean;
  userProfileList: any[];
  loadUserProfile: any;
  deleteUserProfile: (id: number) => void;
  telasPermitidas: any[];
  loadingTelasPermitidas: boolean;
  handlePost: (data: UserProfile) => void;
  handlePut: (data: UserProfile) => void;
  loadTelasPermitidas: () => void;
}

const DataTable = ({
  userProfileList,
  loadUserProfile,
  loading,
  deleteUserProfile,
  telasPermitidas,
  loadingTelasPermitidas,
  handlePost,
  handlePut,
  loadTelasPermitidas,
}: IUserProfileProps) => {
  const [userProfile, setUserProfile] = useState(userProfileList);
  const [orderBy, setOrderBy] = useState("id");
  const [orderType, setOrderType] = useState<"asc" | "desc">("asc");
  const [order, setOrder] = useState(1);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [rowId, setRowId] = React.useState<number>(0);
  // const [nomePerfil, setNomePerfil] = useState<string | null>('');

  const [openCadastro, setOpenCadastro] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [userProfileEdit, setUserProfileEdit] = React.useState();

  const handleOpenCadastro = () => setOpenCadastro(true);
  const handleCloseCadastro = () => setOpenCadastro(false);

  const handleOpenEdit = (row: any) => {
    setUserProfileEdit(row);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => setOpenEdit(false);

  const getData = () => {
    loadUserProfile();
    loadTelasPermitidas();
  };

  useEffect(getData, []);
  const classes = useStyles();

  function Search(inputSearch: string) {
    var userchUsers = [];

    for (var i in userProfileList) {
      if (userProfileList[i].nome.match(inputSearch)) {
        userchUsers.push(userProfileList[i]);
      }
    }

    setUserProfile(userchUsers);
  }

  function sortTable(orderByAtual: string) {
    let orderTypeAtual = orderType;

    if (orderTypeAtual === "asc") {
      setOrderType("desc");
      orderTypeAtual = "desc";
      setOrder(-1);
    } else if (orderTypeAtual === "desc") {
      setOrderType("asc");
      orderTypeAtual = "asc";
      setOrder(1);
    }

    setOrderBy(orderByAtual);

    return 0;
  }

  if (orderBy === "nome") {
    userProfile.sort((a: any, b: any) =>
      a.nome.toLowerCase() > b.nome.toLowerCase() ? order : -order
    );
  }

  if (orderBy === "administradorSistema") {
    userProfile.sort((a: any, b: any) =>
      a.administradorSistema.toLowerCase() >
      b.administradorSistema.toLowerCase()
        ? order
        : -order
    );
  }

  if (orderBy === "tipoPerfil") {
    userProfile.sort((a: any, b: any) =>
      a.tipoPerfil.toLowerCase() > b.tipoPerfil.toLowerCase() ? order : -order
    );
  }

  const handleOpenDelete = (row: any) => {
    setRowId(row.id);
    // setNomePerfil(row.nome);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  function deleteProfile() {
    deleteUserProfile(rowId);
    setOpenDelete(false);
  }

  useEffect(() => {
    setUserProfile(userProfileList);
  }, [userProfileList]);

  return (
    <Box>
      <TitleCard>Perfil Usuário</TitleCard>

      <CustomFab label="Novo" icon={<AddIcon />} onClick={handleOpenCadastro} />
      <Paper className={classes.paperWrap}>
        <div className={classes.grow}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              type="text"
              placeholder="Pesquise aqui…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(e) => Search(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}></div>
        </div>
        <Loading loading={loading} />
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  Nome
                  <TableSortLabel
                    active={orderBy === "nome"}
                    direction={orderBy === "nome" ? orderType : "asc"}
                    onClick={() => sortTable("nome")}
                  >
                    {orderBy === "nome" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  Adm.Sist
                  <TableSortLabel
                    active={orderBy === "administradorSistema"}
                    direction={
                      orderBy === "administradorSistema" ? orderType : "asc"
                    }
                    onClick={() => sortTable("administradorSistema")}
                  >
                    {orderBy === "administradorSistema" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  Nivel Perfil
                  <TableSortLabel
                    active={orderBy === "tipoPerfil"}
                    direction={orderBy === "tipoPerfil" ? orderType : "asc"}
                    onClick={() => sortTable("tipoPerfil")}
                  >
                    {orderBy === "tipoPerfil" ? (
                      <span className={classes.visuallyHidden}>
                        {orderType === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userProfile.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell scope="row">{row.nome}</TableCell>
                  <TableCell align="center">
                    {row.administradorSistema}
                  </TableCell>
                  <TableCell align="center">{row.tipoPerfil}</TableCell>
                  <TableCell align="right">
                    <Button
                      style={{
                        color: "#388e3c",
                        borderColor: "#388e3c",
                      }}
                      title="Editar"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenEdit(row)}
                    >
                      <EditRoundedIcon />
                    </Button>

                    <Button
                      style={{
                        color: "#b20303",
                        borderColor: "#b20303",
                      }}
                      title="Excluir"
                      variant="text"
                      className={classes.button}
                      onClick={() => handleOpenDelete(row)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <UserProfileDelete
          openDelete={openDelete}
          handleDelete={deleteProfile}
          handleCloseDelete={handleCloseDelete}
        />

        <UserProfileCreate
          openCadastro={openCadastro}
          handleCloseCadastro={handleCloseCadastro}
          postPerfilUsuario={handlePost}
          loading={loading}
          telasPermitidasList={telasPermitidas}
          telasPermitidasLoading={loadingTelasPermitidas}
        />

        <UserProfileEdit
          userProfileEdit={userProfileEdit}
          openEdit={openEdit}
          handleCloseEdit={handleCloseEdit}
          putPerfilUsuario={handlePut}
          loading={loading}
          telasPermitidasList={telasPermitidas}
          telasPermitidasLoading={loadingTelasPermitidas}
        />
      </Paper>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  userProfileList: state.userProfile.userProfileList,
  loading: state.userProfile.loading,
  telasPermitidas: state.telasPermitidas.telasPermitidas,
  loadingTelasPermitidas: state.telasPermitidas.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUserProfile: () => {
    dispatch(getUserProfile());
  },
  deleteUserProfile: (id: number) => {
    dispatch(deleteUserProfile(id)).then(() => {
      dispatch(getUserProfile());
      dispatch(getTelas());
    });
  },
  loadTelasPermitidas: () => {
    dispatch(fetchTelasPermitidas());
  },
  handlePost: (data: UserProfile) => {
    dispatch(createUserProfile(data)).then(() => {
      dispatch(getUserProfile());
      dispatch(getTelas());
    });
  },
  handlePut: (data: UserProfile) => {
    dispatch(updateUserProfile(data)).then(() => {
      dispatch(getUserProfile());
      dispatch(getTelas());
    });
  },
});

export const UserProfileList = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataTable);
