import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { maskCnpj, maskCpf } from '../../utils/masks';

import { Autocomplete } from '@material-ui/lab';
import { EditUserRequest } from '../../Models/EditUserRequest';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HomeIcon from '@material-ui/icons/Home';
import { TitleCard } from '../../base/TitleCard';
import api from '../../service/api';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paperWrap: {
    padding: theme.spacing(2),
    margin: theme.spacing(4),
  },
  form: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
}));

interface IUserRegistrationComponentProps {
  location?: any;
}

const UserEditorComponent = ({ location }: IUserRegistrationComponentProps) => {
  let [ativo, setAtivo] = useState<string | undefined>(
    location.state.userRegistration.ativo ?? ''
  );
  let [tipoPessoa, setTipoPessoa] = useState<string>(
    location.state.userRegistration.tipoPessoa
  );
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  let [nome, setName] = useState<string | undefined>(
    location.state.userRegistration.nome ?? ''
  );
  let [email, setEmail] = useState<string | undefined>(
    location.state.userRegistration.email ?? ''
  );
  let [cnpj, setCnpj] = useState<string>(
    location.state.userRegistration.cnpj ?? ''
  );
  let [idPerfilUsuario, setIdPerfilUsuario] = useState<number>(
    location.state.userRegistration.idPerfilUsuario ?? ''
  );
  let [celular, setPhone] = useState<string | undefined>(
    location.state.userRegistration.celular ?? ''
  );
  let [idUnidadeNegocio, setIdUnidadeNegocio] = useState<number>(
    location.state.userRegistration.idUnidadeNegocio ?? ''
  );
  let [userNewcon, setuserNewcon] = useState<string | undefined>(
    location.state.userRegistration.dadosNewcon ?? ''
  );
  let [senhaNewcon, setSenhaNewcon] = useState<string | undefined>(
    location.state.userRegistration.senhaNewcon ?? ''
  );
  let [loginNewcon, setLoginNewcon] = useState<string | undefined>(
    location.state.userRegistration.loginNewcon ?? ''
  );
  let [id] = useState(location.state.userRegistration.id);
  let [dataNascimento, setDataNascimento] = useState(
    location.state.userRegistration.dataNascimento
      ? format(
          parseISO(location.state.userRegistration.dataNascimento),
          'yyyy-MM-dd'
        )
      : ''
  );
  let [loginWatchDoc, setLoginWatchDoc] = useState<string | undefined>(
    location.state.userRegistration.loginWatchDoc
  );
  let [cpf, setCpf] = useState<string>(
    location.state.userRegistration.cpf ?? ''
  );

  const [pontoVendaTitleList] = useState<any[]>(
    location.state.pontoVendaTitleList ?? []
  );
  let [inputValue, setInputValue] = React.useState<string | null>('');
  const [appProfileList] = useState<any[]>(location.state.appProfileList ?? []);
  const [businessUnitList] = useState<any[]>(
    location.state.businessUnitList ?? []
  );

  const classes = useStyles();

  const initializePontoDeVendaTitle = () => {
    const pontoDeVenda = pontoVendaTitleList.find(
      (entry) => entry.id === location.state.userRegistration.idPontoVenda
    );
    setInputValue(pontoDeVenda.titulo);
  };

  useEffect(() => {
    setCpf(maskCpf(cpf));
  }, [cpf]);

  useEffect(() => {
    setCnpj(maskCnpj(cnpj));
  }, [cnpj, location]);

  useEffect(() => {
    tipoPessoa === 'F' ? setCnpj('') : setCpf('');
  }, [tipoPessoa]);

  const handleUpdate = async () => {
    setLoading(true);
    var idpv = null;
    for (var item of pontoVendaTitleList) {
      if (item.titulo === inputValue) {
        idpv = item.id;
        break;
      }
    }

    let user: EditUserRequest = {
      id: id,
      tipoPessoa: tipoPessoa ?? null,
      nome: nome ?? '',
      email: email ?? '',
      cnpj: cnpj ? cnpj : null,
      cpf: cpf ? cpf : null,
      dataNascimento: dataNascimento ? dataNascimento : null,
      idPerfilUsuario: idPerfilUsuario ?? null,
      celular: celular ?? '',
      idUnidadeNegocio: idUnidadeNegocio ?? null,
      idPontoVenda: idpv,
      usuarioNewcon: userNewcon ?? '',
      senhaNewcon: senhaNewcon ?? '',
      loginNewcon: loginNewcon ?? '',
      loginWatchDoc: loginWatchDoc ?? '',
      ativo: ativo ?? 'N',
    };

    await api.put('/Users/EditUser', user);
    setLoading(false);
    history.push('/Sistema/Users');
  };

  useEffect(initializePontoDeVendaTitle, []);

  return (
    <Box>
      <TitleCard
        buttonLink="/Sistema/Users"
        breadcrumbsIcon={<HomeIcon />}
        breadcrumbsText="Usuários"
      >
        <FileCopyIcon /> Cadastro de Usuários
      </TitleCard>

      <Paper className={classes.paperWrap}>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <FormControl className={classes.form}>
              <InputLabel id="demo-simple-select-label" required>
                Tipo Pessoa
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tipoPessoa}
                onChange={(e) => setTipoPessoa(e.target.value as string)}
                label="Tipo Pessoa *"
              >
                <MenuItem value={'F'}>Pessoa Física</MenuItem>
                <MenuItem value={'J'}>Pessoa Jurídica</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl className={classes.form}>
              <InputLabel id="select-status-label">
                Status do Usuário
              </InputLabel>
              <Select
                labelId="select-status-label"
                id="select-status"
                onChange={(e) => setAtivo(e.target.value as string)}
                value={ativo}
                label="Ativo"
              >
                <MenuItem value={'S'}>Ativo</MenuItem>
                <MenuItem value={'N'}>Inativo</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="name"
              name="lastName"
              label="Nome"
              fullWidth
              autoComplete="Name"
              value={nome}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="e-mail"
              name="e-mail"
              label="E-mail"
              fullWidth
              autoComplete="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          {tipoPessoa === 'F' && (
            <>
              <Grid item xs={6}>
                <TextField
                  required
                  id="CPF"
                  name="CPF"
                  label="CPF"
                  fullWidth
                  autoComplete="CPF"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  name="dataDeNascimento"
                  label="Data de Nascimento"
                  type="date"
                  value={dataNascimento}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setDataNascimento(e.target.value);
                  }}
                />
              </Grid>
            </>
          )}

          {tipoPessoa === 'J' && (
            <Grid item xs={12}>
              <TextField
                required
                id="CPNJ"
                name="CPNJ"
                label="CPNJ"
                fullWidth
                autoComplete="CPNJ"
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl className={classes.form}>
              <InputLabel required id="LabelPU">
                Perfil APP
              </InputLabel>
              <Select
                id="idPerfilUsuario"
                value={idPerfilUsuario}
                onChange={(e) => setIdPerfilUsuario(e.target.value as number)}
                name="idPerfilUsuario"
                label="Perfil APP *"
              >
                {appProfileList.map((row: any) => (
                  <MenuItem
                    value={row.id}
                  >{`${row.nome} - ${row.tipoPerfil}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="telefone"
              name="telefone"
              label="Telefone"
              fullWidth
              autoComplete="Telefone"
              value={celular}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.form}>
              <InputLabel required id="LabelUD">
                Unidade Negócio
              </InputLabel>
              <Select
                labelId="Unidade Negócio"
                id="unidadeNegocio"
                value={idUnidadeNegocio}
                onChange={(e) => setIdUnidadeNegocio(e.target.value as number)}
                label="Unidade Negócio *"
              >
                {businessUnitList.map((row: any) => (
                  <MenuItem value={row.id}>{row.nomeFantasia}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              value={inputValue}
              onChange={(event: any, newValue: string | null) => {
                setInputValue(newValue);
              }}
              id="controllable-states-dem"
              options={pontoVendaTitleList.map((option: any) => option.titulo)}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label="Ponto Venda" required />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="UserNewcon"
              name="userNewcon"
              label="Usuário Newcon"
              fullWidth
              autoComplete="userNewcon"
              value={userNewcon}
              onChange={(e) => setuserNewcon(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="LoginNewcon"
              name="LoginNewcon"
              label="Login Newcon"
              fullWidth
              autoComplete="loginNewcon"
              value={loginNewcon}
              onChange={(e) => setLoginNewcon(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="password"
              id="SenhaNewcon"
              name="senhaNewcon"
              label="Senha Newcon"
              fullWidth
              autoComplete="senhaNewcon"
              value={senhaNewcon}
              onChange={(e) => setSenhaNewcon(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="loginWatchDoc"
              name="loginWatchDoc"
              label="Login WatchDoc"
              fullWidth
              autoComplete="loginNewcon"
              value={loginWatchDoc}
              onChange={(e) => setLoginWatchDoc(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              disabled={loading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export const EditUser = UserEditorComponent;
