import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    search: {
      position: "relative",
      backgroundColor: "#33333311",
      padding: theme.spacing(0, 2),
      margin: theme.spacing(2, 0),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      width: "100%",
      display: "flex",
    },
    searchIcon: {
      padding: theme.spacing(2, 0),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      backgroundColor: "transparent",
      color: "inherit",
      flexGrow: 1,
    },
    inputInput: {
      backgroundColor: "transparent",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      flexGrow: 1,
      display: "flex",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonNovo: {
      margin: theme.spacing(1),
      paddingRight: 5,
      position: "absolute",
      left: "90%",
      width: 65,
      height: 38,
    },
    buttonCadastro: {
      marginLeft: 285,
      marginTop: 15,
    },
    paperModal: {
      position: "absolute",
      width: 320,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "38%",
      left: "38%",
    },

    cadastroModal: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "20%",
      left: "33%",
      width: 440,
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paperWrap: {
      margin: theme.spacing(4),
      padding: theme.spacing(2),
    },
  })
);

export const useStylesModal = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "600px",
    height: "100%",
    maxHeight: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 5),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  icon: {
    alignItems: "flex-end",
    cursor: "pointer",
  },
  buttonCadastro: {
    marginLeft: 285,
    marginTop: 15,
  },
}));
