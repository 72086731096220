import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  gridItem: {
    width: 200,
    height: 380,
  },

  itemContainer: {
    height: "100%",
    padding: "5px",
    textAlign: "center",
  },

  cardValor: {
    color: "white",
    fontSize: "50px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  cardData: {
    color: "white",
    padding: 10,
    fontSize: "12px",
    fontWeight: "bold",
    height: "30%",
    display: "flex",
    lineHeight: "20px",
    alignItems: "left",
    justifyContent: "start",
  },

  cardDescription: {
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
    padding: 10,
    height: "30%",
    lineHeight: "20px",
    display: "flex",
    alignItems: "top",
    justifyContent: "center",
  },
}));
