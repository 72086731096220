
import { Button, Grid, InputLabel, makeStyles, MenuItem, Modal, Paper, Select, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import DescriptionIcon from '@material-ui/icons/Description';
import { FilterTabelaDePreco } from '../../Models/FilterTabelaDePreco';
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: "30%",
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    },
    paperWrap: {
        padding: theme.spacing(2),
    },
    head: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#0288D1",
        color: "white",
        borderRadius: "0px",
        padding: theme.spacing(2),
    },
    foot: {
        padding: theme.spacing(4, 0, 0, 0),
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    closeIcon: {
        color: "white",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const TabelaDePrecoCreate = ({
    openCadastro,
    handleCloseCadastro,
    loading,
    uploadTabelaPreco,
    listaTipos,
    index,
    size,
    orderBy,
    orderType
}: any) => {

    const [titulo, setTitulo] = useState<string | undefined>();
    const [file, setFile] = useState<any>(null);
    const [bttColor] = useState<boolean>(false);
    const [tipo, setTipo] = React.useState('');

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTipo(event.target.value as string);
    };
    const setFileCheck = (e: any) => {
        if(e[0].type == "application/pdf")
            setFile(e);
    };

    const uploadFile = () => {
        let data = new FormData();
        data.append('file', file[0])
        let preco: FilterTabelaDePreco;
        uploadTabelaPreco(data, titulo, tipo, index, size, orderBy, orderType);
        handleCloseCadastro();

    }
    const classes = useStyles();

    return (
        <Modal
            open={openCadastro}
            onClose={handleCloseCadastro}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Grid className={classes.paper}>
                <Paper className={classes.head}>
                    <Typography variant="h6" >
                        Upload de Tabela de Preço
                    </Typography>
                    <Button>
                        <CloseIcon
                            className={classes.closeIcon}
                            fontSize="large"
                            onClick={handleCloseCadastro}
                        />
                    </Button>
                </Paper>
                <Paper className={classes.paperWrap}>
                    <InputLabel htmlFor="demo-simple-select-helper">Tipo</InputLabel>
                    <Select
                        className={classes.selectEmpty}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={tipo}
                        fullWidth
                        onChange={handleChange}
                    >
                        {listaTipos?.map((row: any) =>
                            <MenuItem value={row.tipoProduto.sigla}>{row.tipoProduto.nome}</MenuItem>
                        )
                        }
                    </Select>

                    <TextField
                        className={classes.selectEmpty}
                        required
                        name="Titulo"
                        label="Titulo"
                        value={titulo}
                        fullWidth
                        onChange={e => setTitulo(e.target.value)}
                    />

                    <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        {file === null &&
                            <Button
                                color="primary"
                                variant="contained"
                                component="label"
                                startIcon={<DescriptionIcon />}
                            >

                                ANEXAR ARQUIVO
                                <input
                                    type="file"
                                    hidden
                                    accept="application/pdf"
                                    onChange={e => setFileCheck(e.target.files)}
                                />
                            </Button>
                        }
                        {file !== null &&
                            <Button
                                style={{
                                    background: "#388e3c",
                                    color: "#ffffff"
                                }}
                                variant="contained"
                                component="label"
                                startIcon={<DescriptionIcon />}
                            >

                                ANEXAR ARQUIVO
                                <input
                                    type="file"
                                    hidden
                                    accept="application/pdf"
                                    onChange={e => setFileCheck(e.target.files)}
                                />
                            </Button>
                        }
                    </Grid>

                    <div className={classes.foot}>
                        <Grid item xs={12}>
                            {bttColor === false &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={uploadFile}
                                    disabled={!titulo || !file}
                                >
                                    SALVAR
                                </Button>
                            }
                        </Grid>
                    </div>
                </Paper>
            </Grid>
        </Modal>
    )

}