import { Button, Grid, makeStyles, Modal, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import { SysParameterRequest } from '../../Models/SysParameterRequest';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "600px",
    height: "100%",
    maxHeight: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 5),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  icon: {
    alignItems: "flex-end",
    cursor: "pointer",
  },
  buttonCadastro: {
    marginLeft: 285,
    marginTop: 15
  },
}));

export const ParameterCreate = ({
  openCadastro, 
  handleCloseCadastro,
  postSysParm,
  loading
}: any) => {
  const [codigo, setCodigo] = useState<string | null>('');
  const [descricao, setDescricao] = useState<string | null>('');
  const [texto, setTexto] = useState<string | null>('');
  const [inteiro, setInteiro] = useState<number | null>(null);
  const [numerico, setNumerico] = useState<number | null>(null);

  const classes = useStyles();

  const handlePost = () => {
    let sysParam: SysParameterRequest = {
      id: null,
      codigo: codigo ?? "",
      descricao: descricao ?? "",
      texto: texto ?? "",
      inteiro: inteiro ?? null,
      numerico: numerico ?? null
    }
    postSysParm(sysParam);
    handleCloseCadastro();
  }
  
  return (
    <Modal
      open={openCadastro}
      onClose={handleCloseCadastro}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <div style={{ position: "relative" }}>
          <h2 style={{ position: "absolute", marginTop: 5 }}> Cadastro de Parâmetro de Sistema</h2>

          <div style={{ position: "absolute", left: "97%" }} className={classes.icon}>
            <CancelIcon
              style={{
                color: "#ff0000",
              }}
              fontSize="large"
              onClick={handleCloseCadastro}
            ></CancelIcon>
          </div>
        </div>

        <div style={{ position: "relative", paddingTop: 40 }}>
          <TextField
            required
            id="codigo"
            name="codigo"
            label="Codigo"
            fullWidth
            autoComplete="Codigos"
            onChange={e => setCodigo(e.target.value)}
          />

          <TextField
            required
            id="inteiro"
            name="inteiro"
            label="Inteiro"
            fullWidth
            autoComplete="Inteiro"
            type="number"
            onChange={e => setInteiro(parseInt(e.target.value))}
          />

          <TextField
            required
            id="texto"
            name="texto"
            label="Texto"
            fullWidth
            autoComplete="Texto"
            onChange={e => setTexto(e.target.value)}
          />
          <TextField
            required
            id="descricao"
            name="descricao"
            label="Descricao"
            fullWidth
            autoComplete="Descricao"
            onChange={e => setDescricao(e.target.value)}
          />
          <TextField
            required
            id="numerico"
            name="numerico"
            label="Numerico"
            fullWidth
            autoComplete="Numerico"
            type="number"
            onChange={e => setNumerico(parseFloat(e.target.value))}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handlePost}
            disabled={loading}
            className={classes.buttonCadastro}
          >
            Salvar
        </Button>
        </div>
      </Grid>
    </Modal>
  )
}
