import { Button, Grid, TextField } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Loading } from '../../../../ui/Loading';
import { SelectComissao } from '../SelectComissao';
import { useStyles } from '../../style';
import { ComissaoContext } from '../../../../providers/Comissao';
import { DatePicker } from '../../../../base/Forms/DatePicker';

interface IFormEmissaoNF {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  fetchData: (index: number) => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setCurrentPageDetails: Dispatch<SetStateAction<number>>;
}

export const FormEmissaoNF = ({
  loading,
  fetchData,
  setCurrentPageDetails,
  setCurrentPage,
}: IFormEmissaoNF) => {
  var { dataInicial, setDataInicial, dataFinal, setDataFinal } =
    useContext(ComissaoContext);
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          container
          item
          xs={12}
          md={9}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <Grid container spacing={5}>
            <Grid item xs={12} lg={3}>
              <SelectComissao loading={loading} />
            </Grid>

            <Grid item xs={12} lg={3}>
              <DatePicker
                disableToolbar
                name="dataInicial"
                variant="inline"
                id="DataInicial"
                autoComplete="DataInicial"
                label="Data do Cálculo Inicial"
                value={dataInicial}
                onChange={(date: Date | null) => setDataInicial(date)}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <DatePicker
                fullWidth
                disabled={loading}
                required
                disableToolbar
                name="dataFinal"
                variant="inline"
                label="Data do Cálculo Final"
                id="DataFinal"
                value={dataFinal}
                onChange={(date: Date | null) => setDataFinal(date)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}
        >
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              setCurrentPage(1);
              setCurrentPageDetails(1);
              fetchData(0);
            }}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <div className={classes.loadingContainer}>
        <Loading loading={loading} />
      </div>
    </>
  );
};
