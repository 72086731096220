import { tipoProduto } from '.';
import { FilterTabelaDePreco } from './../../Models/FilterTabelaDePreco';


const FETCH_TABELA_DE_PRECO = "tabelaDePrecos/FETCH_TABELA_DE_PRECO";
const FETCH_TABELA_DE_PRECO_SUCCESS = "tabelaDePrecos/FETCH_TABELA_DE_PRECO_SUCCESS";
const FETCH_TABELA_DE_PRECO_FAIL = "tabelaDePrecos/FETCH_TABELA_DE_PRECO_FAIL";
const FETCH_TABELA_DE_PRECO_TIPO = "tabelaDePrecos/FETCH_TABELA_DE_PRECO_TIPO";
const FETCH_TABELA_DE_PRECO_TIPO_SUCCESS = "tabelaDePrecos/FETCH_TABELA_DE_PRECO_TIPO_SUCCESS";
const FETCH_TABELA_DE_PRECO_TIPO_FAIL = "tabelaDePrecos/FETCH_TABELA_DE_PRECO_TIPO_FAIL";
const CREATE_TABELA_DE_PRECO = "tabelaDePrecos/CREATE_TABELA_DE_PRECO";
const CREATE_TABELA_DE_PRECO_SUCCESS = "tabelaDePrecos/CREATE_TABELA_DE_PRECO_SUCCESS";
const CREATE_TABELA_DE_PRECO_FAIL = "tabelaDePrecos/CREATE_TABELA_DE_PRECO_FAIL";
const DOWNLOAD_TABELA_DE_PRECO = "tabelaDePrecos/DOWNLOAD_TABELA_DE_PRECO"
const DOWNLOAD_TABELA_DE_PRECO_SUCCESS = "tabelaDePrecos/DOWNLOAD_TABELA_DE_PRECO_SUCCESS"
const DOWNLOAD_TABELA_DE_PRECO_FAIL = "tabelaDePrecos/DOWNLOAD_TABELA_DE_PRECO_FAIL"
const DELETE_TABELA_DE_PRECO = "tabelaDePrecos/DELETE_TABELA_DE_PRECO"
const DELETE_TABELA_DE_PRECO_SUCCESS = "tabelaDePrecos/DELETE_TABELA_DE_PRECO_SUCCESS"
const DELETE_TABELA_DE_PRECO_FAIL = "tabelaDePrecos/DELETE_TABELA_DE_PRECO_FAIL"

export const defaultState = {
  loading: false,
  downloadUrl: {
    url: null
  },
  listas: {},
  listaTipo: {},

}
export default function reducer(state: any = defaultState, action: any = {}) {
  switch (action.type) {
    case FETCH_TABELA_DE_PRECO:
      return {
        ...state,
        loading: true
      };
    case FETCH_TABELA_DE_PRECO_SUCCESS:
      return {
        ...state,
        loading: false,
        listas: action.payload.data

      };
    case FETCH_TABELA_DE_PRECO_FAIL:
      return {
        ...state,
        loading: false
      };
    case FETCH_TABELA_DE_PRECO_TIPO:
      return {
        ...state,
        loading: true
      };
    case FETCH_TABELA_DE_PRECO_TIPO_SUCCESS:
      return {
        ...state,
        loading: false,
        listaTipo: action.payload.data

      };
    case FETCH_TABELA_DE_PRECO_TIPO_FAIL:
      return {
        ...state,
        loading: false
      };
    case CREATE_TABELA_DE_PRECO:
      return {
        ...state,
        loading: true
      };
    case CREATE_TABELA_DE_PRECO_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case CREATE_TABELA_DE_PRECO_FAIL:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_TABELA_DE_PRECO:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_TABELA_DE_PRECO_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadUrl: action.payload.data,
      };
    case DOWNLOAD_TABELA_DE_PRECO_FAIL:
      return {
        ...state,
        loading: false,
      };
    case DELETE_TABELA_DE_PRECO:
      return {
        ...state,
        loading: true,
      };
    case DELETE_TABELA_DE_PRECO_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_TABELA_DE_PRECO_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export function getTabelaPreco(idPontoVenda: any) {
  return {
    type: FETCH_TABELA_DE_PRECO,
    payload: {
      request: {
        method: "GET",
        url: "/TabelaPreco/GetAll",
        params: {
          idPontoVenda: idPontoVenda
        }
      }
    }
  }
};

export function getTabelaPrecoPaginated({ index, size, orderBy, orderType, tipo }: any) {
  return {
    type: FETCH_TABELA_DE_PRECO_TIPO,
    payload: {
      request: {
        method: "GET",
        url: "/TabelaPreco/GetByTipo",
        params: {
          index,
          size,
          orderBy,
          orderType,
          tipo,
        },
      }
    }
  }
};

export function postTabelaPreco(
  file: any,
  titulo: string,
  tipo: string,
) {
  return {
    type: CREATE_TABELA_DE_PRECO,
    payload: {
      request: {
        method: "POST",
        url: "/TabelaPreco/",
        data: file,
        params: {
          titulo: titulo,
          tipo: tipo,
        }

      }
    }
  }
};
export function getDownloadTabelaPreco(
  id: number,
) {
  return {
    type: DOWNLOAD_TABELA_DE_PRECO,
    payload: {
      request: {
        method: "GET",
        url: "/TabelaPreco/Download",
        params: {
          id: id,
        }

      }
    }
  }
};

export function DeleteTabela(id: number) {
  return {
    type: DELETE_TABELA_DE_PRECO,
    payload: {
      request: {
        method: "DELETE",
        url: `TabelaPreco?Id=${id}`,
      }
    }
  }
}
