import { Button, CssBaseline, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { useStyles } from "./style";
import { GridItem } from "./components/GridItem";

interface IPortais {
  user: any;
}

const PortaisScreen = ({ user }: IPortais) => {
  var urlPortal = process.env.REACT_APP_PORTAL_COBRANCA_URL;

  const classes = useStyles();
  const cardsData = [
    {
      avatar: <AccountBoxIcon fontSize="large" className={classes.icon} />,
      title: "Portal Consórcio",
      backgroundColor: "#00a7f5",
      onClick: "/",
    },
    {
      avatar: <AccountBoxIcon fontSize="large" className={classes.icon} />,
      title: "Portal Banco",
      backgroundColor: "#2fb7f7",
      onClick: urlPortal + user.userData.portalCobranca,
    },
  ];
  const cards = cardsData.map((data) => {
    const isExternal = data.onClick;
    return isExternal ? (
      <a
        href={data.onClick}
      >
        <Grid item className={classes.gridItem}>
          <GridItem
            avatar={data.avatar}
            title={data.title}
            backgroundColor={data.backgroundColor}
          />
        </Grid>
      </a>
    )  :
    (
      <Link
        to={data.onClick}
      >
        <Grid item className={classes.gridItem}>
          <GridItem
            avatar={data.avatar}
            title={data.title}
            backgroundColor={data.backgroundColor}
          />
        </Grid>
      </Link>
    );
  });
  
  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <CssBaseline />
        <Grid className={classes.grid} container spacing={0} justify="center">
          {cards}
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export const Portais = connect(
  mapStateToProps,
)(PortaisScreen);