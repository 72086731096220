import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FilterRegulation } from "../../Models/FilterRegulation";
import {
  DownloadRegulamento,
  getRegulamento,
  PostRegulamento,
  DeleteRegulamento,
} from "../../redux/ducks/quality";

import { RegulamentoCreate } from "./RegulamentoCreate";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import { TitleCard } from "../../base/TitleCard";
import { format, parseISO } from "date-fns";
import { useStyles } from "./style";
import { RegulamentoDelete } from "./RegulamentoDelete";
import { Loading } from "../../ui/Loading";
import { ThemePagination } from "../../base/Pagination";

function fileS3(url: string) {
  window.open(url);
}

const DataTable = ({
  loadRegulamento,
  regulamentoList,
  loading,
  uploadRegulamento,
  totalRQ,
  fileDownload,
  deleteRegulamento,
}: any) => {
  const [orderBy, setOrderBy] = useState("Id");
  const [orderType, setOrderType] = useState<"asc" | "desc">("asc");
  const [openCadastro, setOpenCadastro] = React.useState(false);
  const [idRegulamentoDelete, setIdRegulamentoDelete] = useState(0);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [size] = useState<number>(10);
  const [index, setIndex] = useState<number>(0);
  const [page, setPage] = React.useState(1);
  const handleOpenCadastro = () => setOpenCadastro(true);
  const handleCloseCadastro = () => setOpenCadastro(false);
  const handleCloseModalDelete = () => setOpenModalDelete(false);

  const handleOpenModalDelete = (id: number) => {
    setIdRegulamentoDelete(id);
    setOpenModalDelete(true);
  };

  const classes = useStyles();

  let loadData = () => {
    let regulamento: FilterRegulation;
    regulamento = {
      index: index,
      size: size,
      orderBy: orderBy,
      orderType: orderType,
    };
    loadRegulamento(regulamento);
  };

  useEffect(loadData, [orderBy, orderType]);

  function sortTable(orderByAtual: string) {
    let orderTypeAtual = orderType;

    if (orderTypeAtual === "asc") {
      setOrderType("desc");
      orderTypeAtual = "desc";
    } else if (orderTypeAtual === "desc") {
      setOrderType("asc");
      orderTypeAtual = "asc";
    }

    setOrderBy(orderByAtual);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    let indexPage = (newPage - 1) * size;
    setIndex(indexPage);
    setPage(newPage);

    let regulamento: FilterRegulation;
    regulamento = {
      index: indexPage,
      size: size,
      orderBy: orderBy,
      orderType: orderType,
    };
    loadRegulamento(regulamento);
  };

  let totalPages = Math.floor(totalRQ / size) + 1;

  function onDownload(row: any) {
    fileDownload(row.id);
  }

  function onDeleteRegulamento() {
    let regulamento: FilterRegulation;
    regulamento = {
      index: index,
      size: size,
      orderBy: orderBy,
      orderType: orderType,
    };
    deleteRegulamento(idRegulamentoDelete, regulamento);
    handleCloseModalDelete();
  }

  return (
    <>
      <TitleCard breadcrumbsText="Quality Performance">Regulamento</TitleCard>

      <Box className={classes.boxContainer}>
        <Box className={classes.buttonWrapper}>
          <Button
            variant="contained"
            onClick={handleOpenCadastro}
            color="primary"
          >
            ENVIAR ARQUIVO
          </Button>
        </Box>
        <Paper className={classes.content}>
          <Loading loading={loading}></Loading>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>
                    Nome Arquivo
                    <TableSortLabel
                      active={orderBy === "Titulo"}
                      direction={orderBy === "Titulo" ? orderType : "asc"}
                      onClick={() => sortTable("Titulo")}
                    >
                      {orderBy === "sigla" ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableCellTitle}>
                    Data
                    <TableSortLabel
                      active={orderBy === "Data"}
                      direction={orderBy === "Data" ? orderType : "asc"}
                      onClick={() => sortTable("Data")}
                    >
                      {orderBy === "Data" ? (
                        <span className={classes.visuallyHidden}>
                          {orderType === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {regulamentoList?.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.titulo}</TableCell>
                    <TableCell align="left">
                      {format(parseISO(row.data), "dd/MM/yyyy")}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="secondary"
                        title="download"
                        variant="text"
                        className={classes.button}
                        onClick={() => onDownload(row)}
                      >
                        <GetAppIcon />
                      </Button>
                      <Button
                        color="secondary"
                        title="delete"
                        variant="text"
                        className={classes.button}
                        onClick={() => handleOpenModalDelete(row.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <ThemePagination
          marginLeft="0px"
          handlePageChange={handleChangePage}
          currentPage={page}
          pagesCount={totalPages}
        />
      </Box>
      <RegulamentoCreate
        openCadastro={openCadastro}
        handleCloseCadastro={handleCloseCadastro}
        loading={loading}
        uploadRegulamento={uploadRegulamento}
        loadData={loadData}
      />
      <RegulamentoDelete
        openDelete={openModalDelete}
        handleCloseDelete={handleCloseModalDelete}
        loading={loading}
        deleteRegulamento={onDeleteRegulamento}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  regulamentoList: state.quality.regulamentoList,
  loading: state.quality.loading,
  downloadUrl: state.quality.downloadUrl,
  request: state.quality.request,
  totalRQ: state.quality.totalRQ,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadRegulamento: (filter: FilterRegulation) => {
    dispatch(getRegulamento(filter));
  },
  uploadRegulamento: (file: any, titulo: string, filter: FilterRegulation) => {
    dispatch(PostRegulamento(file, titulo)).then(() =>
      dispatch(getRegulamento(filter))
    );
  },
  fileDownload: (id: number) => {
    dispatch(DownloadRegulamento(id)).then((res: any) =>
      fileS3(res.payload.data.url)
    );
  },
  deleteRegulamento: (id: number, filter: FilterRegulation) => {
    dispatch(DeleteRegulamento(id)).then(() =>
      dispatch(getRegulamento(filter))
    );
  },
});

export const Regulamento = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataTable);
