import {
  Box,
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { ThemePagination } from "../../../../base/Pagination";
import { TableHeader } from "../../../../base/TableHeader";
import { ZeroResultados } from "../ZeroResultados";
import { useStyles } from "../../style";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { format, parseISO } from "date-fns";
import api from "../../../../service/api";
import { maskCpf, maskCurrency } from "../../../../utils/masks";
import { ComissaoContext } from "../../../../providers/Comissao";
export default ({
  updateData,
  tableData,
  itemsAmount,
  setLoading,
  handleOpenDetalhes,
  currentPage,
  setCurrentPage,
}: any) => {
  var { dataInicial, dataFinal, searchField, searchText } =
    useContext(ComissaoContext);
  const classes = useStyles();

  const numberOfPages =
    Math.floor(itemsAmount % 10) === 0
      ? Math.floor(itemsAmount / 10)
      : Math.floor(itemsAmount / 10) + 1;

  const handleChangePage = (event: any, newPage: number) => {
    updateData(newPage - 1);
    setCurrentPage(newPage);
  };

  const handleExportPdf = () => {
    setLoading(true);
    api
      .get("/Relatorio/BonusConversao", {
        params: {
          dataInicial,
          dataFinal,
          searchField,
          searchText,
          reportPdf: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        let blob = new Blob([response.data], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleExportXLS = () => {
    setLoading(true);
    api
      .get("/Relatorio/BonusConversao", {
        params: {
          dataInicial,
          dataFinal,
          searchField,
          searchText,
          reportXLSX: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BonusConversao.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function TableContent() {
    return (
      <>
        <TableHeader
          title="Bônus Conversão"
          onClickPdfButton={handleExportPdf}
          onClickXlsButton={handleExportXLS}
        />
        <Divider />

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>
                  Contrato
                </TableCell>
                <TableCell className={classes.tableCellTitle}>Grupo</TableCell>
                <TableCell className={classes.tableCellTitle}>Cota</TableCell>
                <TableCell className={classes.tableCellTitle}>Versão</TableCell>
                <TableCell className={classes.tableCellTitle}>
                  CPF. Vendedor
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Vlr. Bem
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Data Venda
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Parcela Comissão
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Comissão (%)
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Estorno
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Valor Total
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell>{row.contrato}</TableCell>
                  <TableCell>{row.grupo}</TableCell>
                  <TableCell>{row.cota}</TableCell>
                  <TableCell>{row.versao}</TableCell>
                  <TableCell>{maskCpf(row.cpfVendedor)}</TableCell>
                  <TableCell>{maskCurrency(row.valorBem)}</TableCell>
                  <TableCell>
                    {row.dataVenda
                      ? format(parseISO(row.dataVenda), "dd/MM/yyyy")
                      : ""}
                  </TableCell>
                  <TableCell>{row.parcela}</TableCell>
                  <TableCell>{row.percComissao}</TableCell>
                  <TableCell>{maskCurrency(row.valorEstorno)}</TableCell>
                  <TableCell>{maskCurrency(row.valorTotal)}</TableCell>
                  <TableCell className={classes.eyeIcon}>
                    <Button
                      color="primary"
                      onClick={() => handleOpenDetalhes(row)}
                    >
                      <VisibilityIcon className={classes.eyeIcon} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <Box className={classes.container}>
        {itemsAmount != null && (
          <Paper className={classes.paperSpace}>
            {itemsAmount > 0 && <TableContent />}
            {itemsAmount === 0 && <ZeroResultados />}
          </Paper>
        )}
      </Box>

      {itemsAmount > 0 && (
        <ThemePagination
          marginLeft="0px"
          pagesCount={numberOfPages}
          currentPage={currentPage}
          handlePageChange={handleChangePage}
        />
      )}
    </>
  );
};
