import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    grow: {
      flexGrow: 1,
    },
    search: {
      position: "relative",
      backgroundColor: "#33333311",
      padding: theme.spacing(0, 2),
      margin: theme.spacing(2, 0),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      width: "100%",
      display: "flex",
    },
    searchIcon: {
      padding: theme.spacing(2, 0),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      backgroundColor: "transparent",
      color: "inherit",
      flexGrow: 1,
    },
    inputInput: {
      backgroundColor: "transparent",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      flexGrow: 1,
      display: "flex",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    dataGrid: {
      width: "1109px",
      height: "683px",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    titlePage: {
      position: "relative",
      left: 10,
      top: 5,
    },
    paperWrap: {
      width: "100%",
      padding: theme.spacing(2),
      margin: theme.spacing(4),
      borderRadius: "0px",
    },
    cellButtom: {
      display: "flex",
      flexDirection: "row",
    },
    form: {
      margin: theme.spacing(0),
      minWidth: "100%",
    },

    tableTitleCell: {
      fontWeight: "bold",
      color: "#808080",
    },

    icon: {
      color: "#808080",
    },
  })
);
