import { Grid, Paper } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';
import { useEffect } from 'react';
import { useStyles } from './style';

interface IPagination {
  marginLeft: string;
  pagesCount: number;
  currentPage: number;
  handlePageChange: (event: any, newPage: number) => void;
}

export const ThemePagination = ({
  marginLeft,
  pagesCount,
  currentPage,
  handlePageChange,
}: IPagination) => {
  const classes = useStyles();

  useEffect(() => {
    console.log(currentPage);
  }, [currentPage]);

  return (
    <div
      className={classes.paginationWrapper}
      style={{ marginLeft: marginLeft }}
    >
      <Paper className={classes.paginationPaper}>
        <Pagination
          count={pagesCount}
          page={currentPage}
          showFirstButton
          showLastButton
          onChange={handlePageChange}
        />
      </Paper>
    </div>
  );
};
