import DateFnsUtils from '@date-io/date-fns';
import { Paper } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useContext, useState } from 'react';
import { ComissaoContext } from '../../providers/Comissao';
import api from '../../service/api';
import { parseRelatorioParams } from '../../utils/utils';
import { FormComissoesPagas } from './components/Forms/FormComissoesPagas';
import RelatorioComissoesPagas from './components/Tables/ComissoesPagasTable';
import { useStyles } from './style';

export const ComissoesPagasComponent = ({ handleOpenDetalhes }: any) => {
  var [loading, setLoading] = useState(false);
  var [overviewTableActive, setOverviewTableActive] = useState(true);
  var [tableData, setTableData] = useState([]);
  var [itemsAmount, setItemsAmount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageOverview, setCurrentPageOverview] = useState(1);

  const classes = useStyles();

  var { dataInicial, dataFinal, searchField, searchText, tipoComissao } =
    useContext(ComissaoContext);

  const fetchData = (index: number) => {
    setLoading(true);

    var params = {
      dataInicial,
      dataFinal,
      searchField,
      searchText,
      index: index * 10,
      size: 10,
    };

    params = parseRelatorioParams(params);

    api
      .get('/Relatorio/comissaoPaga', {
        params: params,
      })
      .then((response) => {
        setTableData(response.data.list);
        setItemsAmount(response.data.size);
        setOverviewTableActive(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return tipoComissao === 'Comissões Pagas' ? (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Paper className={classes.paperWrap}>
          <FormComissoesPagas
            loading={loading}
            fetchData={fetchData}
            setCurrentPage={setCurrentPage}
            setCurrentPageOverview={setCurrentPageOverview}
          />
        </Paper>
        <RelatorioComissoesPagas
          tableData={tableData}
          overviewTableActive={overviewTableActive}
          setOverviewTableActive={setOverviewTableActive}
          updateData={fetchData}
          setLoading={setLoading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          currentPageOverview={currentPageOverview}
          setCurrentPageOverview={setCurrentPageOverview}
          itemsAmount={itemsAmount}
          handleOpenDetalhes={handleOpenDetalhes}
        />
      </MuiPickersUtilsProvider>
    </>
  ) : (
    <></>
  );
};
