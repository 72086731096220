const FETCH_PAINEL_VENDAS = "painelVendas/FETCH_PAINEL_VENDAS";
const FETCH_PAINEL_VENDAS_SUCCESS = "painelVendas/FETCH_PAINEL_VENDAS_SUCCESS";
const FETCH_PAINEL_VENDAS_FAIL = "painelVendas/FETCH_PAINEL_VENDAS_FAIL";
const FETCH_PAINEL_VENDAS_ITEM = "painelVendas/FETCH_PAINEL_VENDAS_ITEM";
const FETCH_PAINEL_VENDAS_ITEM_SUCCESS = "painelVendas/FETCH_PAINEL_VENDAS_ITEM_SUCCESS";
const FETCH_PAINEL_VENDAS_ITEM_FAIL = "painelVendas/FETCH_PAINEL_VENDAS_ITEM_FAIL";

export const defaultState = {
    loading: false,
    painelVendasList: [],
    painelVendasItemList:[],
    colors:[]
}

export default function reducer(state: any = defaultState, action: any = {}) {
    switch (action.type) {
        case FETCH_PAINEL_VENDAS:
            return {
                ...state,
                loading: true
            };
        case FETCH_PAINEL_VENDAS_FAIL:
            return {
                ...state,
                loading: false
            };
        case FETCH_PAINEL_VENDAS_SUCCESS:
            return {
                ...state,
                loading: false,
                painelVendasList: action.payload.data
            };
        case FETCH_PAINEL_VENDAS_ITEM:
            return {
                ...state,
                loading: true
            };
        case FETCH_PAINEL_VENDAS_ITEM_FAIL:
            return {
                ...state,
                loading: false
            };

        case FETCH_PAINEL_VENDAS_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                painelVendasItemList:action.payload.data
            };

        default:
            return state;
    }
}

export function GetPainelVendas(
    comissionado: string,
    dtInicial: string,
    dtFinal: string
) {
    return {
        type: FETCH_PAINEL_VENDAS,
        payload: {
            request: {
                method: "GET",
                url: "/RelatorioVendas/PainelVendas",
                params: {
                    comissionado: comissionado,
                    dt_inicial: dtInicial,
                    dt_final: dtFinal
                },
            },
        },
    }
}

export function GetPainelVendasItem(
    Comissionado: string,
    formaDeAcesso:string,
    dataInical: string,
    dataFinal: string,
    index: number,
    size:number
){
    return{
        type:FETCH_PAINEL_VENDAS_ITEM,
        payload: {
            request: {
                method: "GET",
                url: "RelatorioVendas/ItemPainelVenda",
                params: {
                    Comissionado:Comissionado,
                    formaDeAcesso:formaDeAcesso,
                    dataInical:dataInical,
                    dataFinal:dataFinal,
                    index:index,
                    size:size
                },
            },
        },
    }
}