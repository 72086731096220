import { LinearProgress } from '@material-ui/core'
import React from 'react'

interface ILoading {
    loading: boolean
}

export const Loading = ({loading}: ILoading) => {
    if (loading)
        return <LinearProgress />
    else
        return <div style={{width: "100%", height: "4px"}} />
}
