import DateFnsUtils from '@date-io/date-fns';
import { Paper } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useContext, useState } from 'react';
import { ComissaoContext } from '../../providers/Comissao';
import api from '../../service/api';
import { parseRelatorioParams } from '../../utils/utils';
import { useStyles } from '../GestaoCarteira/style';
import { FormBonusConversao } from './components/Forms/FormBonusConversao';
import BonusConversaoTable from './components/Tables/BonusConversaoTable';

export const ConversaoBonusComponent = ({ handleOpenDetalhes }: any) => {
  var { dataInicial, dataFinal, searchField, searchText, tipoComissao } =
    useContext(ComissaoContext);
  var [loading, setLoading] = useState(false);
  var [tableData, setTableData] = useState([]);
  var [itemsAmount, setItemsAmount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = (index: number, count?: boolean) => {
    setLoading(true);

    var countParams = { dataInicial, dataFinal, searchField, searchText };
    countParams = parseRelatorioParams(countParams);

    if (count) {
      api
        .get('/Relatorio/BonusConversaoCount', {
          params: countParams,
        })
        .then((response) => {
          setItemsAmount(response.data);
        });
    }

    var params = {
      dataInicial,
      dataFinal,
      searchField,
      searchText,
      index: index * 10,
      size: 10,
    };

    params = parseRelatorioParams(params);

    api
      .get('/Relatorio/BonusConversao', {
        params: params,
      })
      .then((response) => {
        setTableData(response.data.list);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const classes = useStyles();
  return tipoComissao === 'Bônus Conversão' ? (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Paper className={classes.paperWrap}>
          <FormBonusConversao
            loading={loading}
            fetchData={fetchData}
            setCurrentPage={setCurrentPage}
          />
        </Paper>
      </MuiPickersUtilsProvider>
      <BonusConversaoTable
        updateData={fetchData}
        tableData={tableData}
        itemsAmount={itemsAmount}
        handleOpenDetalhes={handleOpenDetalhes}
        setLoading={setLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  ) : (
    <></>
  );
};
