import React, { useContext, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Paper } from '@material-ui/core';
import { FormEmissaoNF } from './components/Forms/FormEmissaoNF';
import { ComissaoContext } from '../../providers/Comissao';
import api from '../../service/api';
import ComissaoAguardandoEmissaoNF from './components/Tables/ComissoesAguardandoEmissaoNFTable';
import { useStyles } from './style';
import { parseRelatorioParams } from '../../utils/utils';

export const AguardandoNFComponent = ({ handleOpenDetalhes }: any) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [itemsAmount, setItemsAmount] = useState();
  const [overviewTableActive, setOverviewTableActive] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageDetails, setCurrentPageDetails] = useState(1);
  const { dataInicial, dataFinal, searchField, searchText, tipoComissao } =
    useContext(ComissaoContext);

  const fetchData = (index: number) => {
    var params = {
      dataInicial,
      dataFinal,
      searchField,
      searchText,
      index: index * 10,
      size: 10,
    };

    params = parseRelatorioParams(params);

    setLoading(true);
    api
      .get('/Relatorio/ExtratoComissaoNF', {
        params: params,
      })
      .then((response) => {
        setTableData(response.data.list);
        setItemsAmount(response.data.size);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return tipoComissao === 'Aguardando Emissão N.F.' ? (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Paper className={classes.paperWrap}>
          <FormEmissaoNF
            loading={loading}
            setLoading={setLoading}
            fetchData={fetchData}
            setCurrentPage={setCurrentPage}
            setCurrentPageDetails={setCurrentPageDetails}
          />
        </Paper>
      </MuiPickersUtilsProvider>
      <ComissaoAguardandoEmissaoNF
        overviewTableActive={overviewTableActive}
        setOverviewTableActive={setOverviewTableActive}
        updateData={fetchData}
        tableData={tableData}
        handleOpenDetalhes={handleOpenDetalhes}
        itemsAmount={itemsAmount}
        loading={loading}
        setLoading={setLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        currentPageDetails={currentPageDetails}
        setCurrentPageDetails={setCurrentPageDetails}
      />
    </>
  ) : (
    <></>
  );
};
