import React from 'react';
import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import { useStylesExtrato } from '../styleExtrato';
import CheckIcon from '@material-ui/icons/Check';

export const GridItemMeta = (props: any) => {
  const classes = useStylesExtrato();

  return (
    <Grid item xs={12} md={6} lg>
      <Paper style={{ margin: '15px' }}>
        <Divider></Divider>
        <Grid container style={{ backgroundColor: props.backgroundColor }}>
          <Grid item className={classes.itemRow} xs={6}>
            <Typography
              style={{
                padding: 15,
                color: 'White',
                fontSize: 20,
                fontWeight: 'bold',
              }}
            >
              {props.descricao}
            </Typography>
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              style={{ paddingLeft: 15, paddingTop: 15, fontSize: 14 }}
            >
              Venda Média a Realizar
            </Typography>
            <Typography
              style={{ paddingLeft: 15, paddingBottom: 15, fontSize: 11 }}
            >
              Meses Restantes
            </Typography>
          </Grid>
          <Grid item className={classes.itemRowValor} xs={6}>
            <Typography style={{ padding: 15, fontSize: 35 }}>
              {props.vendaMediaRealizar <= 0 ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                props.vendaMediaRealizar
              )}
            </Typography>
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid container>
          <Grid
            item
            className={classes.itemRow}
            style={{ backgroundColor: '#f1f1f1' }}
            xs={12}
          >
            <Typography
              align="right"
              style={{ padding: 15, color: 'GrayText' }}
            >
              Resultado Mês Atual
            </Typography>
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid container>
          <Grid item className={classes.itemRow} xs={6}>
            <Typography style={{ padding: 15, fontSize: 14 }}>
              Venda Média Realizada
            </Typography>
          </Grid>
          <Grid item className={classes.itemRowValor} xs={6}>
            <Typography style={{ padding: 15, fontSize: 35 }}>
              {props.vendaRealizada >= props.vendaMediaRealizar ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                props.vendaRealizada.toFixed(0)
              )}
            </Typography>
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid container>
          <Grid item className={classes.itemRow} xs={6}>
            <Typography style={{ padding: 15, fontSize: 14 }}>
              Faltam Vender
            </Typography>
          </Grid>
          <Grid item className={classes.itemRowValor} xs={6}>
            <Typography style={{ padding: 15, fontSize: 35 }}>
              {props.faltaVender <= 0 ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                props.faltaVender
              )}
            </Typography>
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid container>
          <Grid
            item
            className={classes.itemRow}
            xs={12}
            style={{ backgroundColor: '#f1f1f1' }}
          >
            <Typography style={{ padding: 15, color: 'GrayText' }}>
              Retenção
            </Typography>
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid container>
          <Grid item className={classes.itemRow} xs={6}>
            <Typography style={{ padding: 15, fontSize: 14 }}>
              Aumentar
            </Typography>
          </Grid>
          <Grid item className={classes.itemRowValor} xs={6}>
            <Typography style={{ padding: 15, fontSize: 35 }}>
              {props.aumentar <= 0 ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                (props.aumentar * 100).toFixed(2).toString() + '%'
              )}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
