import React from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox, TextField } from '@material-ui/core';

export default function ListSelect({
  options,
  value,
  setValue,
  label,
  placeholder
}: any) {
  return (
    <Autocomplete
      multiple
      options={options}
      value={value}
      onChange={(event, newValue) => {
        const obj:any = {};

        newValue.forEach(x => {
          if (obj[x.value]) {
            delete obj[x.value];
          } else {
            obj[x.value] = x;
          }
        });

        setValue([
          ...Object.values(obj)
        ])
      }}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.title}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={!!value.filter((x: any) => {
              return (x.value === option.value)
            }).length}
          />
          {option.title}
        </>
      )}
      fullWidth
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label} placeholder={placeholder} />
      )}
    />
  );
}
