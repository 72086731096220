import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
} from '@material-ui/core';
import Title from './components/Title';
import React, { useEffect, useState } from 'react';
import { TitleCard } from '../../base/TitleCard';
import { GridItem } from './components/GridItem';
import { GridItemMeta } from './components/GridItemMeta';
import { Loading } from '../../ui/Loading';
import { TableHeader } from '../../base/TableHeader';
import { ExtratoClassificacaoRequest } from '../../Models/ExtratoClassificacaoRequest';
import api from '../../service/api';
import moment from 'moment';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { formataData, getCurrentMedia } from '../../utils/utils';
import { ThemePagination } from '../../base/Pagination';
import { QualityInfo } from '../../Models/QualityInfo';
import { Skeleton } from '@material-ui/lab';

export const ExtratoClassificacao = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    container: {
      paddingBottom: theme.spacing(4),
    },
    paper: {
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    icon: {
      color: 'white',
    },
    itemRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    formPaperWrap: {
      margin: theme.spacing(4),
      padding: theme.spacing(2),
      borderRadius: '0px',
      height: '100px',
    },

    tableTitle: { color: '#808080', fontWeight: 'bold' },
    gridTableTitle: {
      padding: theme.spacing(1),
      height: '70px',
      display: 'flex',
      alignItems: 'center',
    },

    tableCellTitle: {
      color: '#808080',
      fontWeight: 'bold',
    },
    paginationWrapper: {
      marginTop: 15,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    paginationItem: {
      height: '56px',
    },
    paginationPaper: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paperWrap: {
      width: '97%',
      padding: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [extratoGrid, setExtratoGrid] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [tableVenda, setTableVenda] = useState([]);
  const [tableVendaCancelamento, setTableVendaCancelamento] = useState([]);
  const [tableVendaAtraso, setTableVendaAtraso] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [value, setValue] = useState(0);
  const [tipoConsulta, setTipoConsulta] = useState('venda');
  const [tipoConsultaRelatorio, settipoConsultaRelatorio] = useState('venda');
  const dataAtual = ' - Extração:' + moment().format('DD/MM/YYYY');
  const [totalMediaVenda, setTotalMediaVenda] = useState(true);
  const [total, setTotal] = useState<number>(0);
  const [qualityInfo, setQualityInfo] = useState<QualityInfo>();

  var today = new Date();
  var dataInicialMedia = new Date(2024, 0);
  var dataFinalMedia = new Date(today.getFullYear(), today.getMonth(), 0);

  var dataFinalRetencao = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    0
  );
  var dataInicialRetencao = new Date(
    dataFinalRetencao.getFullYear(),
    dataFinalRetencao.getMonth() - 11
  );

  var periodoMediaTostring =
    dataInicialMedia.toLocaleDateString() +
    ' a ' +
    dataFinalMedia.toLocaleDateString();

  var periodoRetencaoTostring =
    dataInicialRetencao.toLocaleDateString() +
    ' a ' +
    dataFinalRetencao.toLocaleDateString();

  const getDadosQuality = () => {
    setLoadingInfo(true);
    api
      .get('/Quality/GetQualityInfo', {
        params: {
          dataInicialMedia: dataInicialMedia.toISOString().substring(0, 10),
          dataFinalMedia: dataFinalMedia.toISOString().substring(0, 10),
          dataInicialRetencao: dataInicialRetencao
            .toISOString()
            .substring(0, 10),
          dataFinalRetencao: dataFinalRetencao.toISOString().substring(0, 10),
        },
      })
      .then((response) => {
        var info = response.data as QualityInfo;
        setQualityInfo(info);
        setLoadingInfo(false);
      })
      .catch(() => {
        setLoadingInfo(false);
      })
      .finally(() => {
        setLoadingInfo(false);
      });
  };

  useEffect(() => {
    getDadosQuality();
  }, []);

  const itemsCount = [
    {
      data: periodoMediaTostring,
      valor: (qualityInfo?.mediaVendas ?? 0).toFixed(0),
      descricao: 'MÉDIA DE VENDAS',
      backgroundColor: '#03A9F4',
      tipoConsulta: 'venda',
    },
    {
      data: periodoRetencaoTostring,
      valor: ((qualityInfo?.percentualRetencao ?? 0) * 100).toFixed(2),
      descricao: '% RETENÇÃO',
      backgroundColor: '#4CAF50',
      tipoConsulta: 'retencao',
    },
    {
      data: periodoMediaTostring,
      valor: '-',
      descricao: '% EQUIPE TREINADA',
      backgroundColor: '#673AB7',
      tipoConsulta: '',
    },
  ];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const viewTab = (tipoConsulta: any) => {
    if (tipoConsulta === 'venda') {
      hiddenExtratoGrid();
      setTipoConsulta(tipoConsulta);
    } else if (tipoConsulta === 'retencao') {
      setTotalMediaVenda(false);
      hiddenExtractionRetentionGrid();
      setTipoConsulta(tipoConsulta);
    } else {
      //inserir reqisição da nova api.
      setTipoConsulta(tipoConsulta);
    }
  };

  function handleClick(value: string) {
    viewTab(value);
  }

  var today = new Date();
  var anoC = today.getFullYear();
  var mesC = today.getMonth();

  const hiddenExtratoGrid = () => {
    setLoading(true);
    api
      .get('/Quality/MediaVenda', {
        params: {
          dataInicial: dataInicialMedia.toISOString().substring(0, 10),
          dataFinal: dataFinalMedia.toISOString().substring(0, 10),
          tipoConsulta: tipoConsulta,
          count: totalMediaVenda,
        },
      })
      .then((response) => {
        Object.keys(response.data).forEach(function (keyName, keyIndex) {
          if (keyName === 'venda') {
            setTableData(response.data[keyName]);
            setExtratoGrid(false);
          } else if (keyName === 'count') {
            setTotal(response.data[keyName]);
            setTotalMediaVenda(false);
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    hiddenExtratoGrid();
  }, [total]);

  var d1Retencao = new Date(anoC, mesC - 12, 1);
  var d2Retencao = new Date(anoC, mesC, 0);

  const hiddenExtractionRetentionGrid = () => {
    setLoading(true);
    api
      .get('/Quality/ItemExtratoRetencao', {
        params: {
          dataInicial: dataInicialRetencao.toISOString().substring(0, 10),
          dataFinal: dataFinalRetencao.toISOString().substring(0, 10),
          tipoConsulta: tipoConsulta,
        },
      })
      .then(async (response) => {
        Object.keys(response.data).forEach(function (keyName, keyIndex) {
          if (keyName === 'venda') {
            setTableVenda(response.data[keyName]);
          } else if (keyName === 'cancelado') {
            setTableVendaCancelamento(response.data[keyName]);
          } else if (keyName === 'atraso') {
            setTableVendaAtraso(response.data[keyName]);
          }
        });
      })
      .finally(() => {
        setLoading(false);
        setExtratoGrid(false);
      });
  };

  var d1EquipeTreinada = new Date(anoC, mesC - 6, 1);
  var d2EquipeTreinada = new Date(anoC, mesC, 1);

  const handleExportPdf = () => {
    setLoading(true);
    api
      .get('/Quality/ItemExtratoRetencao', {
        params: {
          dataInicial: dataInicialRetencao.toISOString().substring(0, 10),
          dataFinal: dataFinalRetencao.toISOString().substring(0, 10),
          tipoConsulta: tipoConsultaRelatorio,
          reportPdf: true,
        },
        responseType: 'blob',
      })
      .then((response) => {
        setLoading(false);
        let blob = new Blob([response.data], { type: 'application/pdf' }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportXLS = () => {
    setLoading(true);
    api
      .get('/Quality/ItemExtratoRetencao', {
        params: {
          dataInicial: dataInicialRetencao.toISOString().substring(0, 10),
          dataFinal: dataFinalRetencao.toISOString().substring(0, 10),
          tipoConsulta: tipoConsultaRelatorio,
          reportXLSX: true,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', tipoConsultaRelatorio + '.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportPdfMediaVenda = () => {
    setLoading(true);
    api
      .get('/Quality/MediaVenda', {
        params: {
          dataInicial: dataInicialMedia.toISOString().substring(0, 10),
          dataFinal: dataFinalMedia.toISOString().substring(0, 10),
          tipoConsulta: tipoConsultaRelatorio,
          reportPdf: true,
        },
        responseType: 'blob',
      })
      .then((response) => {
        setLoading(false);
        let blob = new Blob([response.data], { type: 'application/pdf' }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportXLSMediaVenda = () => {
    setLoading(true);
    api
      .get('/Quality/MediaVenda', {
        params: {
          dataInicial: dataInicialMedia.toISOString().substring(0, 10),
          dataFinal: dataFinalMedia.toISOString().substring(0, 10),
          tipoConsulta: tipoConsultaRelatorio,
          reportXLSX: true,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', tipoConsultaRelatorio + '.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    if (value === 0) {
      settipoConsultaRelatorio('venda');
    } else if (value === 1) {
      settipoConsultaRelatorio('cancelado');
    } else if (value === 2) {
      settipoConsultaRelatorio('atraso');
    }
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <TitleCard breadcrumbsText="Extrato Classificação">
        Extrato Classificação
      </TitleCard>
      <React.Fragment>
        <Typography
          align="right"
          style={{ fontSize: '0.8rem', padding: 15, color: 'GrayText' }}
        >
          As vendas do LIBERACRED serão consideradas para o índice de retenção e
          média de vendas do Programa Quality (conforme regulamento), porém
          ainda não estão sendo demonstradas neste extrato.
          <br></br> Em breve faremos a inclusão destes indicadores e
          comunicaremos a todos.{' '}
        </Typography>
        <Title>
          <img
            alt="logoQuality"
            src="/assets/logo-quality.png"
            height="136px"
            width="334px"
          ></img>
        </Title>
        <Divider></Divider>
        <Grid container>
          <Grid item className={classes.itemRow} xs={12} md={6}>
            <Typography style={{ padding: 15, color: 'GrayText' }}>
              Classificação Atual:
            </Typography>
            <span
              style={{
                background: qualityInfo?.classificacaoAtual?.cor ?? '#db961d',
                color: 'white',
                padding: 10,
                fontWeight: 'bold',
              }}
            >
              {loadingInfo ? (
                <Skeleton height="100%" width="50px" />
              ) : (
                qualityInfo?.classificacaoAtual?.nome
              )}
            </span>
          </Grid>
          <Grid item className={classes.itemRow} xs={6} md={6}>
            <Typography style={{ padding: 15, color: 'GrayText' }}>
              Classificação para o próximo período:
            </Typography>
            <span
              style={{
                background: qualityInfo?.proximaClassificacao?.cor ?? '#db961d',
                color: 'white',
                padding: 10,
                fontWeight: 'bold',
              }}
            >
              {loadingInfo ? (
                <Skeleton height="100%" width="50px" />
              ) : (
                qualityInfo?.proximaClassificacao?.nome
              )}
            </span>
          </Grid>
        </Grid>
        <Divider></Divider>

        <Grid
          container
          style={{ paddingLeft: 2, marginTop: 2, marginBottom: 2 }}
        >
          {itemsCount.map((x) => (
            <GridItem
              data={x.data}
              valor={x.valor}
              descricao={x.descricao}
              tipoConsulta={x.tipoConsulta}
              onClickStatus={() => {
                handleClick(x.tipoConsulta);
              }}
              backgroundColor={x.backgroundColor}
              loading={loadingInfo}
            />
          ))}
        </Grid>
      </React.Fragment>
      <Divider></Divider>
      <Grid container>
        <Grid item className={classes.itemRow}>
          <Typography style={{ padding: 15, color: 'GrayText' }}>
            Veja abaixo os resultados que você deve alcançar para atingir a
            classificação desejada!{' '}
          </Typography>
        </Grid>
      </Grid>
      <Divider></Divider>
      <Grid container style={{ paddingLeft: 2, marginTop: 2, marginBottom: 2 }}>
        {qualityInfo?.metricas?.map((x) => (
          <GridItemMeta
            aumentar={x.aumentarRetencao}
            vendaMediaRealizar={x.mediaVendas}
            vendaRealizada={qualityInfo.mediaVendas}
            faltaVender={x.faltaVender}
            descricao={x.nome}
            backgroundColor={x.cor}
          />
        ))}
      </Grid>

      <Paper elevation={2} className={classes.paperWrap} hidden={extratoGrid}>
        <Loading loading={loading} />
        <Box hidden={tipoConsulta === 'venda' ? true : false}>
          <Grid container className={classes.gridTableTitle}>
            <TableHeader
              title={'% Retenção' + dataAtual}
              onClickPdfButton={handleExportPdf}
              onClickXlsButton={handleExportXLS}
            />
          </Grid>
          <Divider />
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="VENDAS" {...a11yProps(0)} />
              <Tab label="CANCELADOS" {...a11yProps(1)} />
              <Tab label="ATIVOS N. CONTEMPLADOS ATRASO" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Typography className={classes.tableTitle}>VENDAS</Typography>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellTitle}>
                      CCY
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Nome Cliente
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      DDD
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Fone
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Cpf Vendedor
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Contrato
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Grupo
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Cota
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Versão
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Venda
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Vlr. Bem
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Situação
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableVenda
                    ?.slice(
                      (page - 1) * itemsPerPage,
                      (page - 1) * itemsPerPage + itemsPerPage
                    )
                    .map((row: ExtratoClassificacaoRequest, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.ccy}</TableCell>
                        <TableCell>{row.nome_cliente}</TableCell>
                        <TableCell>{row.ddd}</TableCell>
                        <TableCell>{row.telefone}</TableCell>
                        <TableCell>{row.cpf_vendedor}</TableCell>
                        <TableCell>{row.contrato}</TableCell>
                        <TableCell>{row.grupo}</TableCell>
                        <TableCell>{row.cota}</TableCell>
                        <TableCell>{row.versao}</TableCell>
                        <TableCell>
                          {row.data_venda?.replace('T00:00:00', '')}
                        </TableCell>
                        <TableCell>{row.vl_bem_venda}</TableCell>
                        <TableCell>{row.situacao_cobranca}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <ThemePagination
              currentPage={page}
              pagesCount={Math.ceil(tableVenda.length / 10)}
              handlePageChange={handleChangePage}
              marginLeft={'2px'}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography className={classes.tableTitle}>CANCELADOS</Typography>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellTitle}>
                      CCY
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Nome Cliente
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      DDD
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Fone
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Cpf Vendedor
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Contrato
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Grupo
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Cota
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Versão
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Venda
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Vlr. Bem
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Situação
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableVendaCancelamento
                    ?.slice(
                      (page - 1) * itemsPerPage,
                      (page - 1) * itemsPerPage + itemsPerPage
                    )
                    .map((row: ExtratoClassificacaoRequest, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.ccy}</TableCell>
                        <TableCell>{row.nome_cliente}</TableCell>
                        <TableCell>{row.ddd}</TableCell>
                        <TableCell>{row.telefone}</TableCell>
                        <TableCell>{row.cpf_vendedor}</TableCell>
                        <TableCell>{row.contrato}</TableCell>
                        <TableCell>{row.grupo}</TableCell>
                        <TableCell>{row.cota}</TableCell>
                        <TableCell>{row.versao}</TableCell>
                        <TableCell>
                          {row.data_venda?.replace('T00:00:00', '')}
                        </TableCell>
                        <TableCell>{row.vl_bem_venda}</TableCell>
                        <TableCell>{row.situacao_cobranca}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <ThemePagination
              currentPage={page}
              pagesCount={Math.ceil(tableVendaCancelamento.length / 10)}
              handlePageChange={handleChangePage}
              marginLeft={'2px'}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Typography className={classes.tableTitle}>
              ATIVOS N. CONTEMPLADOS ATRASO
            </Typography>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellTitle}>
                      CCY
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Nome Cliente
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      DDD
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Fone
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Cpf Vendedor
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Contrato
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Grupo
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Cota
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Versão
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Venda
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Vlr. Bem
                    </TableCell>
                    <TableCell className={classes.tableCellTitle}>
                      Situação
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableVendaAtraso
                    ?.slice(
                      (page - 1) * itemsPerPage,
                      (page - 1) * itemsPerPage + itemsPerPage
                    )
                    .map((row: ExtratoClassificacaoRequest, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.ccy}</TableCell>
                        <TableCell>{row.nome_cliente}</TableCell>
                        <TableCell>{row.ddd}</TableCell>
                        <TableCell>{row.telefone}</TableCell>
                        <TableCell>{row.cpf_vendedor}</TableCell>
                        <TableCell>{row.contrato}</TableCell>
                        <TableCell>{row.grupo}</TableCell>
                        <TableCell>{row.cota}</TableCell>
                        <TableCell>{row.versao}</TableCell>
                        <TableCell>
                          {row.data_venda?.replace('T00:00:00', '')}
                        </TableCell>
                        <TableCell>{row.vl_bem_venda}</TableCell>
                        <TableCell>{row.situacao_cobranca}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <ThemePagination
              currentPage={page}
              pagesCount={Math.ceil(tableVendaAtraso.length / 10)}
              handlePageChange={handleChangePage}
              marginLeft={'2px'}
            />
          </TabPanel>
        </Box>

        <Box hidden={tipoConsulta === 'venda' ? false : true}>
          <Grid container className={classes.gridTableTitle}>
            <TableHeader
              title={'Média de Venda' + dataAtual}
              onClickPdfButton={handleExportPdfMediaVenda}
              onClickXlsButton={handleExportXLSMediaVenda}
            />
          </Grid>
          <Divider />
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>CCY</TableCell>
                  <TableCell className={classes.tableCellTitle}>
                    Nome Cliente
                  </TableCell>
                  <TableCell className={classes.tableCellTitle}>DDD</TableCell>
                  <TableCell className={classes.tableCellTitle}>Fone</TableCell>
                  <TableCell className={classes.tableCellTitle}>
                    Cpf Vendedor
                  </TableCell>
                  <TableCell className={classes.tableCellTitle}>
                    Contrato
                  </TableCell>
                  <TableCell className={classes.tableCellTitle}>
                    Grupo
                  </TableCell>
                  <TableCell className={classes.tableCellTitle}>Cota</TableCell>
                  <TableCell className={classes.tableCellTitle}>
                    Versão
                  </TableCell>
                  <TableCell className={classes.tableCellTitle}>
                    Venda
                  </TableCell>
                  <TableCell className={classes.tableCellTitle}>
                    Vlr. Bem
                  </TableCell>
                  <TableCell className={classes.tableCellTitle}>
                    Situação
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData
                  ?.slice(
                    (page - 1) * itemsPerPage,
                    (page - 1) * itemsPerPage + itemsPerPage
                  )
                  .map((row: ExtratoClassificacaoRequest, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.ccy}</TableCell>
                      <TableCell>{row.nome_cliente}</TableCell>
                      <TableCell>{row.ddd}</TableCell>
                      <TableCell>{row.telefone}</TableCell>
                      <TableCell>{row.cpf_vendedor}</TableCell>
                      <TableCell>{row.contrato}</TableCell>
                      <TableCell>{row.grupo}</TableCell>
                      <TableCell>{row.cota}</TableCell>
                      <TableCell>{row.versao}</TableCell>
                      <TableCell>
                        {row.data_venda?.replace('T00:00:00', '')}
                      </TableCell>
                      <TableCell>{row.vl_bem_venda}</TableCell>
                      <TableCell>{row.situacao_cobranca}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ThemePagination
            currentPage={page}
            pagesCount={Math.ceil(tableData.length / 10)}
            handlePageChange={handleChangePage}
            marginLeft={'2px'}
          />
        </Box>
      </Paper>
    </Box>
  );
};
