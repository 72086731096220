import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Alert } from '@material-ui/lab';
import { getSystemParm } from '../../redux/ducks/systemParameter';
import { Loading } from '../../ui/Loading';
import { TitleCard } from '../../base/TitleCard';

// import { MyDataRequest } from '../../Models/MyDataRequest';
// import { UserData } from '../../Models/User';
// import { getMyData, putMyData } from '../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  paperWrap: {
    margin: 20,
    overflow: 'hidden',
  },
  innerPadding: {
    padding: 20,
  },
  spacer: {
    height: 4,
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperWrapA: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: '0px',
  },
  frame: {
    width: `100%`,
    height: `500px`,
    border: '0 solid transparent',
    borderRadius: '4px',
    marginTop: 30,
  },
}));

interface ICalendarioComponentProps {
  loading: boolean;
  sysParmList: any[];
  loadSysParm: any;
}

const CalendarioComponent = ({
  loading,
  sysParmList,
  loadSysParm,
}: ICalendarioComponentProps) => {
  const classes = useStyles();

  /**
   * vao virar parametros do parametros de sistema
   */
  const actionCodigo = 'ASSEMBLEIAS_CALENDARIO_ACTION_URL';
  // useEffect(loadUserData, []);
  useEffect(loadSysParm, []);

  const otherFields = [
    {
      name: '__VIEWSTATE',
      value: '',
    },
    {
      name: '__VSTATE',
      value:
        'H4sIAAAAAAAEAG2OwUvDMBSH29g4NgdR0eJB52BDkF22ynCgl25eZArihteSNW9tsE0weYP512vaHYYguXz58nv5vR+fXRDaSZKZVmh0Yd/hayMNvGmLU55+zuE7ScKAnqdYDId9WWZJXCAYvgCVc3q7088lz2C6QdQqTsFavpAIsSilkhYNF9pw2vs/qp+k5YVrhxT5nxoEJWQJCjW93OlqR9gI3c+MiK2FclWA5CwTjLZG95NoFI0n0R0TIVkzFpIOScXaXQhxqr2uTONDWummclHLgPiVbUqlwORYFvRqxgvXe9OLovGDkboroLuvEmTOHgfeCfGPQ6867bOB5wlyUNcFS9giPY3LlXRbQzW6BItghSC0Slz7ucPmHls7Cg9Jo/6h+cq3L6AyzEnXPR/VyaBmtudfHp/lEbQBAAA=',
    },
    {
      name: '__EVENTTARGET',
      value: '',
    },
    {
      name: '__EVENTARGUMENT',
      value: '',
    },
    {
      name: '__EVENTVALIDATION',
      value:
        'UN7KWDzGHYf6sZLYWwYL92KIePgjngnTcb3WnRgF/xxfyRwBMFcqrlzovcdPbI2xD+wc9G7YGJSL66sR6kZueX3MumoXX4+9SpR+EmtVryw1gRQB7THaJ+IsMmT/+UsGphnzC8CqP6ImjIQC6wGgm3ptU/G0sDIHfNcqpf7oK/nkATcGric09WA3ybekG1679RREtMf2WWBCHB9hIHWx+NpKB/Dx4FaUd94PBHyAWVGNMKFNxoERLEOsGIaPzPqYY65ytg2jttXICKGdjPcAJy6RGTB9sbZmIsJ2Gnm/yL9sswBGkLXDYOoM3r1jTh3Ukumpt0hGFhZHV3JU9oVtSpcaazKSZb30fTEoNaaDwVuURRrl54EHFg5Rv2yNuRU8SgQGlsi8UgAGlWi8CfbQ1S6/m64+kUWtoLXxnBmC7Dy3SK34Bh50U450zo9RPBrxvXj5L6EtJ4dNBhGQlSmc20KRqfcBxHo/VrGdp5WNcQ3eb9ENolR5EpVw4HrwksnzvmC3GA8tiygJucd4k++/u+cBIOEFAlGEAsksBv14+uZECATGYL2HIqamdO8oApiqvJVhOGGsN9ZsOx4/M84Lq0r9Oyx3hTYuWRUr8koY7CO+3OTOQDbhxxC3PKDiWKKaZ8osbA==',
    },
    {
      name: 'ctl00$hdnID_Modulo',
      value: '',
    },
    {
      name: 'ctl00$Conteudo$btnSelecionar',
      value: 'Selecionar',
    },
    {
      name: 'ctl00$Conteudo$edtDtVencimentoInicial',
      value: '01/01/1900',
    },
    {
      name: 'ctl00$Conteudo$edtDtVencimentoFinal',
      value: '31/12/3000',
    },
    {
      name: 'ctl00$Conteudo$edtDtMontagemInicial',
      value: '01/01/1900',
    },
    {
      name: 'ctl00$Conteudo$edtDtMontagemFinal',
      value: '31/12/3000',
    },
    {
      name: 'ctl00$Conteudo$edtAgrupamento_Inicial',
      value: '000000',
    },
    {
      name: 'ctl00$Conteudo$edtAgrupamento_Final',
      value: 'ZZZZZZ',
    },
    {
      name: 'ctl00$Conteudo$edtLocal_Inicial',
      value: '000000',
    },
    {
      name: 'ctl00$Conteudo$edtLocal_Final',
      value: 'ZZZZZZ',
    },
    {
      name: 'ctl00$Conteudo$edtNO_Assembleia_Inicial',
      value: '001',
    },
    {
      name: 'ctl00$Conteudo$edtNO_Assembleia_Final',
      value: '999',
    },
    {
      name: 'ctl00$Conteudo$edtST_Inicial',
      value: 'A',
    },
    {
      name: 'ctl00$Conteudo$edtST_Final',
      value: 'Z',
    },
    {
      name: 'ctl00$Conteudo$rblOrdem',
      value: 'N',
    },
  ];

  let [grupoInicial, setGrupoInicial] = useState<string | null>("000000");
  let [grupoFinal, setGrupoFinal] = useState<string | null>("ZZZZZZ");
  let [dataInicial, setDataInicial] = useState<Date | null>(new Date());
  let [dataFinal, setDataFinal] = useState<Date | null>(new Date());
  let [actionUrl, setActionUrl] = useState<string | undefined>();
  function LoadData(codigo: string) {
    const sysParm = sysParmList?.find((x) => x.codigo === codigo);
    sysParm ? setActionUrl(sysParm.texto) : setActionUrl('Error');
  }

  useEffect(() => LoadData(actionCodigo), []);
  return (
    <>
      <Box>
        <TitleCard>Calendário de Assembleias</TitleCard>
      </Box>
      <Box>
        <Paper className={classes.paperWrapA}>
          <Loading loading={loading} />
          <Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <form method="post" action={actionUrl} target="frame">
                <Grid container spacing={3}>
                  {/* <Grid item xs={12} md={2}>
                    <TextField
                      required
                      name="ctl00$Conteudo$edtGrupoInicial"
                      id="grupoInicial"
                      label="Grupo Inicial"
                      fullWidth
                      autoComplete="grupoInicial"
                      value={grupoInicial}
                      inputProps={{ maxLength: 6 }}
                      onChange={(e) => setGrupoInicial(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      required
                      name="ctl00$Conteudo$edtGrupoFinal"
                      label="Grupo Final"
                      id="GrupoFinal"
                      autoComplete="GrupoFinal"
                      fullWidth
                      value={grupoFinal}
                      inputProps={{ maxLength: 6 }}
                      onChange={(e) => setGrupoFinal(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      name="ctl00$Conteudo$edtDtAssenbleiaInicial"
                      format="dd/MM/yyyy"
                      id="DataInicial"
                      autoComplete="DataInicial"
                      label="Data Inicial"
                      value={dataInicial}
                      onChange={(date: Date | null) => setDataInicial(date)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <KeyboardDatePicker
                      disableToolbar
                      name="ctl00$Conteudo$edtDtAssembleiaFinal"
                      variant="inline"
                      id="DataFinal"
                      format="dd/MM/yyyy"
                      autoComplete="DataFinal"
                      label="Data Final"
                      value={dataFinal}
                      onChange={(date: Date | null) => setDataFinal(date)}
                    />
                  </Grid> 
                  <Grid item xs={12} md={3}>
                    <Button type="submit" variant="contained" color="primary">
                      Busca Simples
                    </Button>
                  </Grid>
                  <div style={{ display: 'none' }}>
                    {otherFields.map((x) => (
                      <input name={x.name} value={x.value} />
                    ))}
                  </div> */}
                </Grid>
              </form>
              {actionUrl !== 'Error' ? (
                <iframe
                  sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                  src={actionUrl}
                  style={{ display: 'block' }}
                  className={classes.frame}
                  name="frame"
                  title="Calendario"
                ></iframe>
              ) : (
                <div>
                  <iframe
                    sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                    style={{ display: 'block' }}
                    src={actionUrl}
                    className={classes.frame}
                    name="frame"
                    title="Calendario"
                  ></iframe>
                  <Alert style={{ marginTop: 30 }} severity="warning">
                    Endereço não encontrado!
                  </Alert>
                </div>
              )}
            </MuiPickersUtilsProvider>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.systemParameter.loading,
  sysParmList: state.systemParameter.sysParmList,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUserData: () => {
  },
  loadSysParm: () => {
    dispatch(getSystemParm());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarioComponent);
