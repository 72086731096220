import DateFnsUtils from '@date-io/date-fns';
import { Paper } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState, useContext } from 'react';

import { ComissaoContext } from '../../providers/Comissao';
import api from '../../service/api';
import ComissoesFuturasTable from './components/Tables/ComissoesFuturasTable';
import { FormComissoesFuturas } from './components/Forms/FormComissoesFuturas';
import { useStyles } from './style';
import { parseRelatorioParams } from '../../utils/utils';

export const ComissoesFuturasComponent = ({ handleOpenDetalhes }: any) => {
  const { dataInicial, dataFinal, searchField, searchText, tipoComissao } =
    useContext(ComissaoContext);
  const [tableData, setTableData] = useState([]);
  const [itemsAmount, setItemsAmount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = (index: number, count?: boolean) => {
    setLoading(true);
    var params = {
      dataInicial,
      dataFinal,
      searchField,
      searchText,
    };

    params = parseRelatorioParams(params);

    if (count) {
      api
        .get('/Relatorio/ComissaoFuturaCount', {
          params: params,
        })
        .then((response) => {
          setItemsAmount(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    var paramsData = {
      dataInicial,
      dataFinal,
      searchField,
      searchText,
      index: index * 10,
      size: 10,
    };

    paramsData = parseRelatorioParams(paramsData);

    api
      .get('/Relatorio/ComissaoFutura', {
        params: paramsData,
      })
      .then((response) => {
        setTableData(response.data.list);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  var [loading, setLoading] = useState(false);

  const classes = useStyles();
  return tipoComissao === 'Comissões Futuras' ? (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Paper className={classes.paperWrap}>
          <FormComissoesFuturas
            loading={loading}
            fetchData={fetchData}
            setCurrentPage={setCurrentPage}
          />
        </Paper>
      </MuiPickersUtilsProvider>
      <ComissoesFuturasTable
        tableData={tableData}
        updateData={fetchData}
        handleOpenDetalhes={handleOpenDetalhes}
        itemsAmount={itemsAmount}
        setLoading={setLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  ) : (
    <></>
  );
};
