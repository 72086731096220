import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core'
import React from 'react'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 999
    },
  })
)

interface ICustomFab {
  label: string,
  icon: any,
  onClick: () => void
}

export default function CustomFab({ label, icon, onClick }: ICustomFab) {
  const classes = useStyles();

  return (
    <Fab variant="extended" color="primary" aria-label="add" className={classes.fab} onClick={onClick}>
      {icon}
      {label}
    </Fab>
  )
}
