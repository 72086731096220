import { Button, Grid, Typography } from "@material-ui/core";

import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GridOnIcon from "@material-ui/icons/GridOn";
import React from "react";
import { useStyles } from "./style";

interface ITableHeader {
  title: string;
  onClickBackButton?: () => void | null;
  onClickPdfButton?: () => void;
  onClickXlsButton?: () => void;
}

export const TableHeader = ({
  title,
  onClickBackButton,
  onClickPdfButton,
  onClickXlsButton,
}: ITableHeader) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.gridTableTitle}>
      <Grid item xs={12} md={6}>
        <Typography className={classes.tableTitle}>{title}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {onClickBackButton && (
          <Button
            variant="contained"
            className={classes.backButtonTableHeader}
            onClick={onClickBackButton}
            color="primary"
          >
            VOLTAR
          </Button>
        )}
        <Button
          variant="contained"
          onClick={onClickPdfButton}
          className={classes.exportButton}
        >
          <PictureAsPdfIcon className={classes.buttonIcon} /> Exportar PDF
        </Button>

        <Button
          variant="contained"
          onClick={onClickXlsButton}
          className={classes.exportButton}
        >
          <GridOnIcon className={classes.buttonIcon} /> Exportar XLS
        </Button>
      </Grid>
    </Grid>
  );
};
