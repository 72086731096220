import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { format, parseISO } from "date-fns";
import { ThemePagination } from "../../../../base/Pagination";
import { TableHeader } from "../../../../base/TableHeader";
import { ZeroResultados } from "../ZeroResultados";
import { useStyles } from "../../style";
import api from "../../../../service/api";
import { maskCurrency } from "../../../../utils/masks";
import { ComissaoContext } from "../../../../providers/Comissao";

export default ({
  updateData,
  tableData,
  itemsAmount,
  setLoading,
  handleOpenDetalhes,
  currentPage,
  setCurrentPage,
}: any) => {
  const { dataInicial, dataFinal, searchField, searchText } =
    useContext(ComissaoContext);
  const classes = useStyles();
  const numberOfPages =
    Math.floor(itemsAmount % 10) === 0
      ? Math.floor(itemsAmount / 10)
      : Math.floor(itemsAmount / 10) + 1;

  const handleExportPdf = () => {
    setLoading(true);
    api
      .get("/Relatorio/ComissaoFutura", {
        params: {
          dataInicial,
          dataFinal,
          searchField,
          searchText,
          reportPdf: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportXLS = () => {
    setLoading(true);
    api
      .get("/Relatorio/ComissaoFutura", {
        params: {
          dataInicial,
          dataFinal,
          searchField,
          searchText,
          reportXLSX: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ComissaoFutura.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const TableContent = () => (
    <>
      <TableHeader
        title="Comissões Futuras"
        onClickPdfButton={handleExportPdf}
        onClickXlsButton={handleExportXLS}
      />
      <Divider />

      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellTitle}>Contrato</TableCell>
              <TableCell className={classes.tableCellTitle}>Grupo</TableCell>
              <TableCell className={classes.tableCellTitle}>Cota</TableCell>
              <TableCell className={classes.tableCellTitle}>Versão</TableCell>
              <TableCell className={classes.tableCellTitle}>
                CPF. Vendedor
              </TableCell>
              <TableCell className={classes.tableCellTitle}>Vlr. Bem</TableCell>
              <TableCell className={classes.tableCellTitle}>
                Data Venda
              </TableCell>
              <TableCell className={classes.tableCellTitle}>
                Parcela Comissão
              </TableCell>
              <TableCell className={classes.tableCellTitle}>
                Comissão (%)
              </TableCell>
              <TableCell className={classes.tableCellTitle}>Estorno</TableCell>
              <TableCell className={classes.tableCellTitle}>
                Valor Total
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row: any) => (
              <TableRow key={row.idPessoa}>
                <TableCell>{row.contrato}</TableCell>
                <TableCell>{row.grupo}</TableCell>
                <TableCell>{row.cota}</TableCell>
                <TableCell align="center">{row.versao}</TableCell>
                <TableCell>{row.cpfVendedor}</TableCell>
                <TableCell>{maskCurrency(row.valorBem)}</TableCell>
                <TableCell>
                  {row.dataVenda
                    ? format(parseISO(row.dataVenda), "dd/MM/yyyy")
                    : ""}
                </TableCell>
                <TableCell align="center">{row.parcela} </TableCell>
                <TableCell align="center">{row.percComissao}</TableCell>
                <TableCell>{maskCurrency(row.valorEsterno)}</TableCell>
                <TableCell>{maskCurrency(row.valorTotal)}</TableCell>
                <TableCell className={classes.eyeIcon}>
                  <IconButton
                    color="primary"
                    onClick={() => handleOpenDetalhes(row)}
                  >
                    <VisibilityIcon className={classes.eyeIcon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const handleChangePage = (event: any, newPage: number) => {
    updateData(newPage - 1);
    setCurrentPage(newPage);
  };

  return (
    <>
      <Box maxWidth="lg" className={classes.container}>
        {itemsAmount != null && (
          <Paper className={classes.paperSpace}>
            {itemsAmount === 0 && <ZeroResultados />}
            {itemsAmount > 0 && <TableContent />}
          </Paper>
        )}
      </Box>
      {itemsAmount > 0 && (
        <ThemePagination
          marginLeft="0px"
          pagesCount={numberOfPages}
          currentPage={currentPage}
          handlePageChange={handleChangePage}
        />
      )}
    </>
  );
};
