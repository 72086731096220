import { Button, createStyles, Grid, makeStyles, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { PainelVendasItemRequest } from "../../Models/PainelVendasRequest";
import { Loading } from "../../ui/Loading";
import CloseIcon from "@material-ui/icons/Close";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GridOnIcon from "@material-ui/icons/GridOn";
import { ThemePagination } from "../../base/Pagination";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: "absolute",
            width: "80%",
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
        },
        head: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#0288D1",
            color: "white",
            borderRadius: "0px",
            padding: theme.spacing(2),
        },
        closeIcon: {
            color: "white",
        },
        modalTablePaperWrap: {
            padding: theme.spacing(2),
        },
        exportButton: {
            background: "#FAFAFA",
            marginLeft: "5px",
            marginRight: "5px",
        },
        buttonIcon: {
            paddingRight: theme.spacing(1),
        },
        tableCellTitle: {
            color: "#808080",
            fontWeight: "bold",
        },
    })
)



export default function PainelVendasDetalhes({ openDetalhe, handleCloseDetalhes, handleChangePage, loading, itens, page, count, handleExportPdf, handleExportXLS }: any) {
    const [size] = useState<number>(10);
    const classes = useStyles();

    return (
        <Modal
            open={openDetalhe}
            onClose={handleCloseDetalhes}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >

            <Grid className={classes.paper}>
                <Paper className={classes.head}>
                    <Typography variant="h5">Detalhes</Typography>
                    <Button>
                        <CloseIcon
                            className={classes.closeIcon}
                            fontSize="large"
                            onClick={handleCloseDetalhes}
                        />
                    </Button>
                </Paper>
                <Paper className={classes.modalTablePaperWrap}>
                    <Grid >
                        <Grid
                            item
                            xs={6}
                            style={{ display: "flex", justifyContent: "flex-start" }}
                        >
                            <Button variant="contained" className={classes.exportButton} onClick={handleExportPdf}>
                                <PictureAsPdfIcon className={classes.buttonIcon} /> Exportar PDF
                            </Button>
                            <Button variant="contained" className={classes.exportButton} onClick={handleExportXLS}>
                                <GridOnIcon className={classes.buttonIcon} /> Exportar XLS
                            </Button>
                        </Grid>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>

                                    <TableRow>
                                        <TableCell className={classes.tableCellTitle}>
                                            Contrato
                                        </TableCell>
                                        <TableCell className={classes.tableCellTitle}>
                                            Situação
                                        </TableCell>
                                        <TableCell className={classes.tableCellTitle}>
                                            Grupo
                                        </TableCell>
                                        <TableCell className={classes.tableCellTitle}>
                                            Cota
                                        </TableCell>
                                        <TableCell className={classes.tableCellTitle}>
                                            Nome
                                        </TableCell>
                                        <TableCell className={classes.tableCellTitle}>
                                            Telefone
                                        </TableCell>
                                        <TableCell className={classes.tableCellTitle}>
                                            E-Mail
                                        </TableCell>
                                        <TableCell className={classes.tableCellTitle}>
                                            Bem
                                        </TableCell>
                                        <TableCell className={classes.tableCellTitle}>
                                            Crédito
                                        </TableCell>
                                        <TableCell className={classes.tableCellTitle}>
                                            Prazo
                                        </TableCell>
                                        <TableCell className={classes.tableCellTitle}>
                                            Valor
                                        </TableCell>
                                        <TableCell className={classes.tableCellTitle}>
                                            Vendedor
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {itens?.map((row: PainelVendasItemRequest, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row.contrato}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.situacao}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.grupo}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.cota}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.nome}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.telefone}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.email}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.bem}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.credito?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.prazo}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.valor?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.vendedor}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <ThemePagination
                            marginLeft="0px"
                            pagesCount={count}
                            currentPage={page}
                            handlePageChange={handleChangePage}
                        />

                        <Loading loading={loading}></Loading>
                    </Grid>
                </Paper>
            </Grid>
        </Modal>
    );
}