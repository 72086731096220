import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useContext } from "react";
import { ComissaoContext } from "../../../providers/Comissao";

interface ISelectComissao {
  loading: boolean;
}

export const SelectComissao = ({ loading }: ISelectComissao) => {
  var { tipoComissao, setTipoComissao } = useContext(ComissaoContext);

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label" required>
        Tipo Comissão
      </InputLabel>

      <Select
        disabled={loading}
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={tipoComissao}
        onChange={(e) => setTipoComissao(e.target.value as string)}
        label="Tipo Comissão"
      >
        <MenuItem value={"Aguardando Emissão N.F."}>
          Aguardando Emissão N.F.
        </MenuItem>
        <MenuItem value={"Comissões Pagas"}>Comissões Pagas</MenuItem>
        <MenuItem value={"Comissões Retidas por Pendência"}>
          Comissões Retidas por Pendência
        </MenuItem>
        <MenuItem value={"Comissões Futuras"}>Comissões Futuras</MenuItem>
        <MenuItem value={"Bônus Conversão"}>Bônus Conversão</MenuItem>
      </Select>
    </FormControl>
  );
};
