import { Box, Container, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getSystemParm } from '../../../redux/ducks/systemParameter';
import { Loading } from '../../../ui/Loading';
import { Alert } from '@material-ui/lab';
import { TitleCard } from '../../../base/TitleCard';

// import { MyDataRequest } from '../../Models/MyDataRequest';
// import { UserData } from '../../Models/User';
// import { getMyData, putMyData } from '../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperWrapA: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: '0px',
  },
  frame: {
    width: `100%`,
    height: `500px`,
    border: '0 solid transparent',
    borderRadius: '4px',
    marginTop: 30,
  },
}));

interface ICadastroVendaComponentProps {
  loading: boolean;
  sysParmList: any[];
  loadSysParm: any;
}

function resizeIframe(obj: any) {
  obj.target.style.height =
    obj.target.ownerDocument.scrollingElement.offsetHeight + 'px';
}

const CadastroVendaComponent = ({
  loading,
  sysParmList,
  loadSysParm,
}: ICadastroVendaComponentProps) => {
  const classes = useStyles();

  const NewKey = 'VENDAS_CADASTRO_VENDAS_URL';
  useEffect(loadSysParm, []);

  let [urlNewKey, setUrlNewKey] = useState<string | undefined>();

  const urlPage = () => {
    const sysParm = sysParmList?.find((x) => x.codigo === NewKey);
    sysParm ? setUrlNewKey(sysParm.texto) : setUrlNewKey('Error');
  };

  useEffect(urlPage, []);

  return (
    <>
      <Box>
        <TitleCard>Cadastro da Venda</TitleCard>
      </Box>
      <Box>
        <Paper className={classes.paperWrapA}>
          <Loading loading={loading} />
          {urlNewKey !== 'Error' ? (
            <iframe
              sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
              src={urlNewKey}
              className={classes.frame}
              onLoad={(e) => resizeIframe(e)}
              name="frame"
              title="Boleto Venda"
            ></iframe>
          ) : (
            <div>
              <iframe
                sandbox="allow-same-origin allow-modals allow-scripts allow-forms allow-popups"
                style={{ display: 'none' }}
                src={urlNewKey}
                className={classes.frame}
                onLoad={(e) => resizeIframe(e)}
                name="frame"
                title="Boleto Venda"
              ></iframe>
              <Alert style={{ marginTop: 30 }} severity="warning">
                Endereço não encontrado!
              </Alert>
            </div>
          )}
        </Paper>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.systemParameter.loading,
  sysParmList: state.systemParameter.sysParmList,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUserData: () => {
    // dispatch(getMyData())
  },
  loadSysParm: () => {
    dispatch(getSystemParm());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadastroVendaComponent);
