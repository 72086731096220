import { Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Modal, Paper, Select, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import { UserProfile } from '../../Models/UserProfile';
import ListSelect from '../../ui/ListSelect';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "79%",
    top: `50%`,
    left: `50%`,
    overflowY: "auto",
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(4),
  },
  head: {
    // padding: theme.spacing(4),
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  foot: {
    padding: theme.spacing(4, 0, 0, 0),
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: 60,
    paddingBottom: 40,
  },
  input: {
    paddingBottom: 20, 
  },
}));

export const UserProfileCreate = ({
  openCadastro,
  handleCloseCadastro,
  postPerfilUsuario,
  loading,
  telasPermitidasList,
  telasPermitidasLoading
}: any) => {
  // const [id, setId] = useState<number>();
  const [administradorSistema, setAdministradorSistema] = useState<"S" | "N">("N");
  const [tipoPerfil, setTipoPerfil] = useState<"FAB" | "REP" | "CCY">("FAB");
  const [nome, setNome] = useState<string>("");
  const [telas, setTelas] = useState<number[]>([]);
  
  const classes = useStyles();

  const options = telasPermitidasList?.map((x: any) => ({
    title: x.titulo,
    value: x.id
  }))

  const handlePost = () => {
    let pu: UserProfile = {
      id: null,
      administradorSistema,
      tipoPerfil,
      nome,
      telas: telas.map((x: any) => x.value)
    }
    postPerfilUsuario(pu);
    handleCloseCadastro();
  }

  return (
    <Modal
      open={openCadastro}
      onClose={handleCloseCadastro}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.paperWrap}>
          <div className={classes.head}>
            <Typography variant="h6" >
              Cadastro de Perfil de Usuário
            </Typography>
              <CancelIcon
                style={{
                  color: "#ff0000",
                }}
                fontSize="large"
                onClick={handleCloseCadastro}
              />
          </div>

          <div style={{ position: "relative", paddingTop: 40 }}>
            <FormControl className={classes.input} fullWidth>
              <InputLabel id="selector-de-tipo-de-pu">Tipo do Ponto de Venda</InputLabel>
              <Select
                labelId="selector-de-tipo-de-pu"
                label="Tipo do Ponto de Venda"
                required
                name="tipo"
                value={tipoPerfil}
                fullWidth
                onChange={e => setTipoPerfil(e.target.value as "CCY" | "REP" | "FAB")}
              >
                <MenuItem value="CCY">Concessionária</MenuItem>
                <MenuItem value="REP">Representante</MenuItem>
                <MenuItem value="FAB">Fabricante</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.input} fullWidth>
              <InputLabel id="selector-de-adm-de-pu">Administrador de Sistema</InputLabel>
              <Select
                labelId="selector-de-adm-de-pu"
                label="Administrador de Sistema"
                required
                name="tipo"
                value={administradorSistema}
                fullWidth
                onChange={e => setAdministradorSistema(e.target.value as "S" | "N")}
              >
                <MenuItem value="S">Sim</MenuItem>
                <MenuItem value="N">Não</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className={classes.input}
              required
              name="nome"
              label="Nome"
              value={nome}
              fullWidth
              onChange={e => setNome(e.target.value)}
            />
            <ListSelect
              className={classes.input}
              label="Telas Permitidas"
              placeholder="Selecione as telas..."
            
              fullWidth
              options={options}
              value={telas}
              setValue={setTelas}
            />
            <div className={classes.foot}>
              <Button
                onClick={handlePost}
                color="primary"
                disabled={loading || telasPermitidasLoading}
              >
                Sim
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>
    </Modal>
  )
}
