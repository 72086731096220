import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { EmailConfigRequest } from "../../Models/EmailConfigRequest";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  getEmailConfig,
  updateEmailConfig,
} from "../../redux/ducks/emailConfiguration";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { TitleCard } from "../../base/TitleCard";
import { useStyles } from "./style";

interface IEmailConfigComponentProps {
  emailConfigList: EmailConfigRequest;
  loadEmailParm: () => void;
  updateEmailConfig: (emailConfig: EmailConfigRequest) => void;
  loading: boolean;
}

const DataTable = ({
  emailConfigList,
  loadEmailParm,
  updateEmailConfig,
  loading,
}: IEmailConfigComponentProps) => {
  const classes = useStyles();

  useEffect(loadEmailParm, []);

  const [id] = useState<number>(emailConfigList.id);
  const [emailRemetente, setEmailRemetente] = useState<string | null>(
    emailConfigList.emailRemetente
  );
  const [emailReposta, setEmailReposta] = useState<string | null>(
    emailConfigList.emailReposta
  );
  const [fallback, setFallback] = useState<boolean>(emailConfigList.fallback);
  const [necessitaAutenticacao, setNecessitaAutenticacao] = useState<boolean>(
    emailConfigList.necessitaAutenticacao
  );
  const [porta, setPorta] = React.useState<string | null>(
    emailConfigList.porta
  );
  const [senha, setSenha] = useState<string | null>(emailConfigList.senha);
  const [servidor, setServidor] = useState<string | null>(
    emailConfigList.servidor
  );
  const [tipo, setTipo] = useState<string | null>(emailConfigList.tipo); //<"tsl" | "ssl" | null>(null)
  const [usuario, setUsuario] = useState<string | null>(
    emailConfigList.usuario
  );

  const handleUpdate = () => {
    let emailConfig: EmailConfigRequest = {
      id: id ?? null,
      emailRemetente: emailRemetente ?? "",
      emailReposta: emailReposta ?? "",
      fallback: fallback,
      necessitaAutenticacao: necessitaAutenticacao,
      porta: porta ?? "",
      senha: senha ?? "",
      servidor: servidor ?? "",
      tipo: tipo ?? "",
      usuario: usuario ?? "",
    };
    updateEmailConfig(emailConfig);
  };

  return (
    <>
      <Box>
        <TitleCard>Configuração do email</TitleCard>
      </Box>

      <Box>
        <Paper className={classes.paperWrap}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={loading}
                  required
                  id="servidor"
                  name="servidor"
                  label="Servidor"
                  fullWidth
                  autoComplete="Servidor"
                  value={servidor}
                  onChange={(e) => setServidor(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={loading}
                  required
                  id="porta"
                  name="porta"
                  label="Numero da porta"
                  fullWidth
                  autoComplete="Porta"
                  type="number"
                  value={porta}
                  onChange={(e) => setPorta(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={loading}
                  required
                  id="tipo"
                  name="tipo"
                  label="Tipo"
                  fullWidth
                  autoComplete="Tipo"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={loading}
                  required
                  id="usuario"
                  name="usuario"
                  label="Usuario"
                  fullWidth
                  autoComplete="Usuario"
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={loading}
                  required
                  id="senha"
                  name="senha"
                  label="Senha"
                  fullWidth
                  autoComplete="Senha"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={loading}
                  required
                  id="emailRemetente"
                  name="emailRemetente"
                  label="Email Remetente"
                  fullWidth
                  autoComplete="EmailRemetente"
                  value={emailRemetente}
                  onChange={(e) => setEmailRemetente(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={loading}
                  required
                  id="emailReposta"
                  name="emailReposta"
                  label="Email Resposta"
                  fullWidth
                  autoComplete="Porta"
                  value={emailReposta}
                  onChange={(e) => setEmailReposta(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel
                  className={classes.label}
                  id="necessitaAutenticacaoLabel"
                >
                  Necessita Autenticação *
                </InputLabel>
                <Select
                  disabled={loading}
                  labelId="necessitaAutenticacaoLabel"
                  id="necessitaAutenticacao"
                  fullWidth
                  name="necessitaAutenticacao"
                  label="Necessita Autenticação"
                  value={necessitaAutenticacao}
                  onChange={(e) =>
                    setNecessitaAutenticacao(e.target.value as boolean)
                  }
                >
                  <MenuItem value={"True"}>Sim</MenuItem>
                  <MenuItem value={"False"}>Não</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel className={classes.label} id="fallbackLabel">
                  Utilizar Fallback *
                </InputLabel>
                <Select
                  disabled={loading}
                  labelId="fallback"
                  id="fallback"
                  fullWidth
                  name="fallback"
                  label="Utilizar Fallback"
                  value={fallback}
                  onChange={(e) => setFallback(e.target.value as boolean)}
                >
                  <MenuItem value={"True"}>Sim</MenuItem>
                  <MenuItem value={"False"}>Não</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdate}
                  disabled={loading}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Paper>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  emailConfigList: state.emailConfiguration.emailConfigList,
  loading: state.emailConfiguration.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadEmailParm: () => {
    dispatch(getEmailConfig());
  },
  updateEmailConfig: (emailConfig: EmailConfigRequest) => {
    dispatch(updateEmailConfig(emailConfig)).then(() =>
      dispatch(getEmailConfig())
    );
  },
});
export const EmailConfiguration = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataTable);
