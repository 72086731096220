import { SysParameterRequest } from './../../Models/SysParameterRequest';

const FETCH_SYSTEM_PARAMETER_FAIL = "systemParameter/FETCH_SYSTEM_PARAMETER_FAIL";
const FETCH_SYSTEM_PARAMETER = "systemParameter/FETCH_MY_DATA";
const FETCH_SYSTEM_PARAMETER_SUCCESS = "systemParameter/FETCH_MY_DATA_SUCCESS";
const DELETE_SYSTEM_PARAMETER = "systemParameter/DELETE_SYSTEM_PARAMETER";
const DELETE_SYSTEM_PARAMETER_SUCCESS = "systemParameter/DELETE_SYSTEM_PARAMETER_SUCCESS";
const DELETE_SYSTEM_PARAMETER_FAIL = "systemParameter/DELETE_SYSTEM_PARAMETER_FAIL";
const UPDATE_SYSTEM_PARAMETER = "users/UPDATE__SYSTEM_PARAMETER";
const UPDATE_SYSTEM_PARAMETER_SUCCESS = "users/UPDATE__SYSTEM_PARAMETER_SUCCESS";
const UPDATE_SYSTEM_PARAMETER_FAIL = "users/UPDATE__SYSTEM_PARAMETER_FAIL";
const POST_SYSTEM_PARAMETER = "createSystemParameter/FETCH_MY_DATA";
const POST_SYSTEM_PARAMETER_SUCCESS = "createSystemParameter/FETCH_MY_DATA_SUCCESS";
const POST_SYSTEM_PARAMETER_FAIL = "createSystemParameter/FETCH_MY_DATA_FAIL";



// Default state
export const defaultState = {
  loading: false,
  sysParmList: []
}

// Reducer
export default function reducer(state: any = defaultState, action: any = {}) {
  switch (action.type) {
    case FETCH_SYSTEM_PARAMETER:
      return {
        ...state,
        loading: true
      };
    case FETCH_SYSTEM_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false,
        sysParmList: action.payload.data,
      };
    case FETCH_SYSTEM_PARAMETER_FAIL:
      return {
        ...state,
        loading: false
      };

    case POST_SYSTEM_PARAMETER:
      return {
        ...state,
        loading: true
      };

    case POST_SYSTEM_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case POST_SYSTEM_PARAMETER_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;

    case DELETE_SYSTEM_PARAMETER:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SYSTEM_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case DELETE_SYSTEM_PARAMETER_FAIL:
      return {
        ...state,
        loading: false
      };
      case UPDATE_SYSTEM_PARAMETER:
        return {
          ...state,
          loading: true
        }
      case UPDATE_SYSTEM_PARAMETER_SUCCESS:
        return {
          ...state,
          loading: false
        }
      case UPDATE_SYSTEM_PARAMETER_FAIL:
        return {
          ...state,
          loading: false
        }
  }
}

// Action Creators
export function getSystemParm() {
  return {
    type: FETCH_SYSTEM_PARAMETER,
    payload: {
      request: {
        method: "GET",
        url: "/ParametroSistema"
      }
    }
  }
}

export function postSystemParm(sysParam: SysParameterRequest) {
  return {
    type: POST_SYSTEM_PARAMETER,
    payload: {
      request: {
        method: "POST",
        url: "/ParametroSistema",
        data: sysParam,
      }
    }
  }
}

export function deleteSystemParm(id: any) {
  return {
    type: DELETE_SYSTEM_PARAMETER,
    payload: {
      request: {
        method: "DELETE",
        url: `/ParametroSistema?id=${id}`,
      },
    },
  };
}

export function putSystemParm(sysParam: SysParameterRequest) {
  return {
    type: UPDATE_SYSTEM_PARAMETER,
    payload: {
      request: {
        method: "PUT",
        url: "/ParametroSistema",
        data: sysParam
      },
    },
  };
}