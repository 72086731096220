import {
  Box,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { ThemePagination } from "../../../../base/Pagination";
import { TableHeader } from "../../../../base/TableHeader";
import { ComissoesAguardandoNFRequest } from "../../../../Models/ComissoesAguardandoNF";
import { ZeroResultados } from "../ZeroResultados";
import { useStyles } from "../../style";
import VisibilityIcon from "@material-ui/icons/Visibility";
import api from "../../../../service/api";
import { useEffect } from "react";
import { ComissaoNFDetalhes } from "../../../../Models/ComissaoNFDetalhes";
import { maskCpf, maskCurrency } from "../../../../utils/masks";
import { format, parseISO } from "date-fns";
import { ComissaoContext } from "../../../../providers/Comissao";

type FetchDetailsParams = {
  empresa: string;
  mes: number;
  ano: number;
};

export default ({
  updateData,
  tableData,
  itemsAmount,
  overviewTableActive,
  setOverviewTableActive,
  handleOpenDetalhes,
  setLoading,
  currentPage,
  setCurrentPage,
  currentPageDetails,
  setCurrentPageDetails,
}: any) => {
  const classes = useStyles();

  const [mes, setMes] = useState<number>(0);
  const [ano, setAno] = useState<number>(0);
  const [empresa, setEmpresa] = useState<string>("");
  const numberOfPages = Math.ceil(itemsAmount / 10);
  const [detailsListSize, setDetailsListSize] = useState(0);

  const [detailsData, setDetailsData] = useState<ComissaoNFDetalhes[]>([]);
  var { dataInicial, dataFinal } = useContext(ComissaoContext);
  const handleChangePage = async (event: any, newPage: number) => {
    if (overviewTableActive) {
      await updateData(newPage - 1);
      setCurrentPage(newPage);
    } else {
      setCurrentPageDetails(newPage);
    }
  };

  const handleExportPdf = () => {
    setLoading(true);
    api
      .get("/Relatorio/ExtratoComissaoNF", {
        params: {
          dataInicial,
          dataFinal,
          reportPdf: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        let blob = new Blob([response.data], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);

        window.open(url);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportXLS = () => {
    setLoading(true);
    api
      .get("/Relatorio/ExtratoComissaoNF", {
        params: {
          dataInicial,
          dataFinal,
          reportXLSX: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ExtratoComissaoNF.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportPdfDetails = () => {
    setLoading(true);
    var detailsInitialDate = new Date(ano, mes - 1, 1)
      .toISOString()
      .substring(0, 10);
    var detailsFinalDate = new Date(ano, mes, 0).toISOString().substring(0, 10);

    setLoading(true);
    api
      .get("/Relatorio/DetalhesComissaoNF", {
        params: {
          dataInicial: detailsInitialDate,
          dataFinal: detailsFinalDate,
          empresa,
          reportPdf: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        let blob = new Blob([response.data], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);

        window.open(url);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExportXLSDetails = () => {
    setLoading(true);
    var detailsInitialDate = new Date(ano, mes - 1, 1)
      .toISOString()
      .substring(0, 10);
    var detailsFinalDate = new Date(ano, mes, 0).toISOString().substring(0, 10);

    api
      .get("/Relatorio/DetalhesComissaoNF", {
        params: {
          dataInicial: detailsInitialDate,
          dataFinal: detailsFinalDate,
          empresa,
          reportXLSX: true,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ExtratoComissaoNF.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEyeClick = ({ empresa, mes, ano }: FetchDetailsParams) => {
    setCurrentPageDetails(1);
    setEmpresa(empresa);
    setAno(ano);
    setMes(mes);
    setOverviewTableActive(false);
  };

  const fetchDetailsData = () => {
    if (overviewTableActive) return;
    setLoading(true);
    var detailsInitialDate = new Date(ano, mes - 1, 1)
      .toISOString()
      .substring(0, 10);
    var detailsFinalDate = new Date(ano, mes, 0).toISOString().substring(0, 10);

    api
      .get("/Relatorio/DetalhesComissaoNF", {
        params: {
          dataInicial: detailsInitialDate,
          dataFinal: detailsFinalDate,
          empresa,
          size: 10,
          index: (currentPageDetails - 1) * 10,
        },
      })
      .then(({ data }) => {
        setDetailsData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDetailsDataSize = () => {
    if (overviewTableActive) return;
    setLoading(true);
    var detailsInitialDate = new Date(ano, mes - 1, 1)
      .toISOString()
      .substring(0, 10);
    var detailsFinalDate = new Date(ano, mes, 0).toISOString().substring(0, 10);

    api
      .get("/Relatorio/DetalhesComissaoNFCount", {
        params: {
          dataInicial: detailsInitialDate,
          dataFinal: detailsFinalDate,
          empresa,
          ano,
          mes,
        },
      })
      .then(({ data }) => {
        setDetailsListSize(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(fetchDetailsData, [mes, ano, empresa, currentPageDetails]);

  useEffect(fetchDetailsDataSize, [mes, ano, empresa]);

  const TableContentOverview = () => {
    return (
      <>
        <TableHeader
          title="Aguardando emissão N.F"
          onClickPdfButton={handleExportPdf}
          onClickXlsButton={handleExportXLS}
        />
        <Divider />
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>Mês</TableCell>
                <TableCell className={classes.tableCellTitle}>Ano</TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Período
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Empresa
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Valor Calculado
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map(
                (row: ComissoesAguardandoNFRequest, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{row.mes}</TableCell>
                    <TableCell>{row.ano}</TableCell>
                    <TableCell>{row.periodo}</TableCell>
                    <TableCell>{row.empresa}</TableCell>
                    <TableCell>
                      {row.valorCalculado &&
                        maskCurrency(row.valorCalculado?.toString())}
                    </TableCell>
                    <TableCell className={classes.eyeIcon}>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleEyeClick({
                            empresa: row.empresa,
                            mes: row.mes,
                            ano: row.ano,
                          })
                        }
                      >
                        <VisibilityIcon className={classes.eyeIcon} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const TableContentDetails = () => {
    return (
      <>
        <TableHeader
          title="Aguardando emissão N.F"
          onClickPdfButton={handleExportPdfDetails}
          onClickXlsButton={handleExportXLSDetails}
          onClickBackButton={() => {
            setOverviewTableActive(true);
          }}
        />
        <Divider />
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>
                  Contrato
                </TableCell>
                <TableCell className={classes.tableCellTitle}>Grupo</TableCell>
                <TableCell className={classes.tableCellTitle}>Cota</TableCell>
                <TableCell className={classes.tableCellTitle}>Versão</TableCell>
                <TableCell className={classes.tableCellTitle}>
                  CPF Vendedor
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Vlr. Bem
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Data Venda
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Parcela Comissão
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Comissão (%)
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Estorno
                </TableCell>
                <TableCell className={classes.tableCellTitle}>
                  Vlr. Total
                </TableCell>
                <TableCell className={classes.tableCellTitle}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailsData.map((row: ComissaoNFDetalhes, index: number) => (
                <TableRow key={index}>
                  <TableCell>{row.id_documento}</TableCell>
                  <TableCell>{row.cd_grupo}</TableCell>
                  <TableCell>{row.cd_cota}</TableCell>
                  <TableCell>{row.versao}</TableCell>
                  <TableCell>{maskCpf(row.cpfVendedor)}</TableCell>
                  <TableCell>{maskCurrency(row.vl_bem.toString())}</TableCell>
                  <TableCell>
                    {row.dt_venda
                      ? format(parseISO(row.dt_venda.toString()), "dd/MM/yyyy")
                      : ""}
                  </TableCell>
                  <TableCell>{row.no_parcela}</TableCell>
                  <TableCell>{row.pe_comissao}</TableCell>
                  <TableCell>
                    {maskCurrency(row.vl_estorno.toString())}
                  </TableCell>
                  <TableCell>{maskCurrency(row.vl_total.toString())}</TableCell>
                  <TableCell className={classes.eyeIcon}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleOpenDetalhes(row);
                      }}
                    >
                      <VisibilityIcon className={classes.eyeIcon} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <>
      <Box className={classes.container}>
        {itemsAmount != null && (
          <Paper className={classes.paperSpace}>
            {itemsAmount === 0 && <ZeroResultados />}
            {itemsAmount > 0 && overviewTableActive && <TableContentOverview />}
            {!overviewTableActive && <TableContentDetails />}
          </Paper>
        )}
      </Box>
      {itemsAmount > 0 && (
        <ThemePagination
          marginLeft="0px"
          pagesCount={
            overviewTableActive
              ? numberOfPages
              : Math.ceil(detailsListSize / 10)
          }
          currentPage={overviewTableActive ? currentPage : currentPageDetails}
          handlePageChange={handleChangePage}
        />
      )}
    </>
  );
};
