import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingContainer: {
      paddingTop: "15px",
    },
    tableCellTitle: {
      color: "#808080",
      fontWeight: "bold",
    },
    container: {
      paddingTop: theme.spacing(4),
    },
    paperSpace: {
      marginTop: theme.spacing(1),
      width: "100%",
      padding: theme.spacing(2),
    },

    paperZeroResultados: {
      background: "#FFF9C4",
      padding: 16,
      borderRadius: 0,
    },
    paperZeroResultadosTitle: {
      color: "#75725A",
      fontSize: "24px",
    },
    paperZeroResultadosSubtitle: {
      color: "#75725A",
      fontSize: "16px",
    },
    eyeIcon: {
      color: "#75725A",
      cursor: "pointer",
    },
    paperWrap: {
      width: "100%",
      padding: theme.spacing(2),
    },
  })
);
