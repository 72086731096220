import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ListSelect from "../../ui/ListSelect";
import { GrupoTelaRequest, TelaRequest } from "../../Models/GrupoTelaRequest";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "800px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(4),
  },
  head: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  foot: {
    padding: theme.spacing(4, 0, 0, 0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  vstack: {
    margin: "16px 0px",
  },
}));
export const GrupoTelaCreate = ({
  openCadastro,
  handleCloseCadastro,
  postGrupoTela,
  loading,
  telasPermitidas,
  grupoTelaLoading,
}: any) => {
  const [icone, setIcone] = useState<string | undefined>();
  const [ordem, setOrdem] = useState<string | undefined>();
  const [nome, setNome] = useState<string | undefined>();
  const [selecao, setSelecao] = React.useState<any[]>([] || undefined);
  const [tela] = React.useState<TelaRequest[]>([] || undefined || null);

  const classes = useStyles();

  const options = telasPermitidas.map((x: any) => ({
    title: x.comando,
    value: x.id,
  }));

  const handleOrdemChange = (e: any) => {
    var value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    setOrdem(value);
  };

  const handlePost = () => {
    for (var item of selecao) {
      for (var i of telasPermitidas) {
        if (item.value === i.id) {
          let telalist: TelaRequest = {
            comando: i.comando,
            icone: i.icone,
            id: i.id,
            idGrupo: i.idGrupo,
            ordem: i.ordem,
            tipo: i.tipo,
            titulo: i.titulo,
          };
          tela.push(telalist);
          break;
        }
      }
    }

    let grupoTela: GrupoTelaRequest = {
      id: null,
      icone: icone,
      nome: nome,
      ordem: ordem,
      tela: tela,
    };
    postGrupoTela(grupoTela);
    handleCloseCadastro();
    setIcone("");
    setOrdem("");
    setNome("");
    setSelecao([]);
  };

  return (
    <Modal
      open={openCadastro}
      onClose={handleCloseCadastro}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.paperWrap}>
          <div className={classes.head}>
            <Typography variant="h6">Cadastro de Grupo de Tela</Typography>

            <IconButton onClick={handleCloseCadastro}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>

          <div style={{ position: "relative", paddingTop: 40 }}>
            <TextField
              className={classes.vstack}
              required
              name="Nome"
              label="Nome"
              value={nome}
              fullWidth
              onChange={(e) => setNome(e.target.value)}
            />
            <TextField
              className={classes.vstack}
              name="Icone"
              label="Icone"
              value={icone}
              fullWidth
              onChange={(e) => setIcone(e.target.value)}
            />
            <TextField
              className={classes.vstack}
              name="Ordem"
              label="Ordem"
              value={ordem}
              inputProps={{ maxLength: 2 }}
              fullWidth
              onChange={handleOrdemChange}
            />

            <ListSelect
              className={classes.vstack}
              label="Grupo de Telas"
              placeholder="Selecione as telas..."
              fullWidth
              isMulti
              options={options}
              value={selecao}
              setValue={setSelecao}
            />
            <div className={classes.foot}>
              <Button
                onClick={handlePost}
                color="primary"
                disabled={loading || grupoTelaLoading}
              >
                Salvar
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>
    </Modal>
  );
};
