import { Button, Grid, makeStyles, Modal, Paper, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import { TipoProdutoRequest } from '../../Models/TipoProdutoRequest';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "800px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  paperWrap: {
    padding: theme.spacing(4),
  },
  head: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  foot: {
    padding: theme.spacing(4, 0, 0, 0),
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
}));

export const TipoProdutoCreate = ({
  openCadastro,
  handleCloseCadastro,
  postPontoVenda,
  loading,
  tipoProdutoLoading
}: any) => {
  const [nome, setNome] = useState<string | null>(null);
  const [sigla, setSigla] = useState<string | null>(null);


  const classes = useStyles();

  const handlePost = () => {
    let tipoProduto: TipoProdutoRequest = {
      id: null,
      nome: nome,
      sigla: sigla
    }
    postPontoVenda(tipoProduto);
    handleClose();
  }

  const handleClose = () => {
    resetOnClose()
    handleCloseCadastro()
  }

  const resetOnClose = () => {
    setNome("");
    setSigla("");
  }

  return (
    <Modal
      open={openCadastro}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid className={classes.paper}>
        <Paper className={classes.paperWrap}>
          <div className={classes.head}>
            <Typography variant="h6" >
              Cadastro de Tipo Produto
            </Typography>
            <CancelIcon
              style={{
                color: "#ff0000",
              }}
              fontSize="large"
              onClick={handleClose}
            />
          </div>

          <div style={{ position: "relative", paddingTop: 20 }}>

            <TextField
              required
              name="Sigla"
              label="Sigla"
              value={sigla}
              fullWidth
              onChange={e => setSigla(e.target.value)}
            />
            <TextField
              required
              name="Nome"
              label="Nome"
              value={nome}
              fullWidth
              onChange={e => setNome(e.target.value)}
            />


            <div className={classes.foot}>
              <Button
                onClick={handlePost}
                color="primary"
                disabled={loading || tipoProdutoLoading}
              >
                Sim
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>
    </Modal>
  )
}