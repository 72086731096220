import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputField: {
      marginLeft: "20px",
      marginRight: "20px",
    },

    paperWrap: {
      width: "100%",
      padding: theme.spacing(2),
    },

    formPaperWrap: {
      margin: theme.spacing(4),
      padding: "16px",
      borderRadius: "0px",
    },

    tableTitle: { color: "#808080", fontWeight: "bold" },
    gridTableTitle: {
      padding: theme.spacing(1),
      height: "70px",
      display: "flex",
      alignItems: "center",
    },

    tablePaperWrap: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      padding: theme.spacing(2),
    },

    tableCellTitle: {
      color: "#808080",
      fontWeight: "bold",
    },

    eyeIcon: {
      color: "#808080",
    },

    exportButton: {
      background: "#FAFAFA",
      marginLeft: "5px",
      marginRight: "5px",
    },

    buttonIcon: {
      paddingRight: theme.spacing(1),
    },
    paginationItem: {
      height: "56px",
    },
    paginationPaper: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paginationWrapper: {
      marginTop: 15,
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  })
);
